export const authenticationWording = {
  back: 'Back',
  or: 'or',
  resetError:
    'Error setting new password. Please try again or contact us if the error persists.',
  error: 'Sorry, something went wrong.',
};

export const emailVerificationWording = {
  error:
    'Error verifying email. Please try again or contact us if the error persists.',
  success: 'Your account is now verified! Please login to continue.',
  warning:
    'You already have an account with this email address. Please sign in with your email & password.',
};
