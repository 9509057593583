import type { Operation } from '@apollo/client';
import { captureException, withScope } from '@sentry/remix';
import { AxiosError } from 'axios';
import type { GraphQLFormattedError } from 'graphql';

export const getExtrasForGraphQlOperation = (
  operation: Operation
): Record<string, unknown> => {
  return {
    payload: JSON.stringify(operation.variables, null, 2),
    query: operation?.query?.loc?.source.body.trim(),
  };
};

export const getExtrasForGraphQlError = (
  error: GraphQLFormattedError
): Record<string, unknown> => {
  return {
    error: JSON.stringify(error.extensions, null, 2),
    locations: error.locations,
  };
};

export const captureNetworkError = (
  error: unknown,
  transactionName: string,
  fingerprints: string[],
  type: string,
  extras: Record<string, unknown> | null
) => {
  withScope((scope) => {
    scope.setTransactionName(transactionName);
    scope.setFingerprint(fingerprints);
    scope.setTag('type', type);

    if (extras !== null) {
      scope.setExtras(extras);
    }

    captureException(error);
  });
};

export const createAxiosSentryErrorInterceptor = (type: string) => {
  const axiosSentryErrorInterceptor = (error: AxiosError) => {
    try {
      const method = (error.config?.method ?? 'undefined_method').toUpperCase();
      const errorCode = error.code;
      const statusCode = error.response?.status ?? null;
      const url = error.config?.url ?? '[ undefined url ]';

      const title = `[Axios] ${method} ${url}`;

      const extras = {
        errorCode, // ERR_NETWORK here most likely indicates a CORS issue
        statusCode,
      };

      captureNetworkError(
        error,
        title,
        [method, url, String(statusCode ?? errorCode)],
        type,
        extras
      );
    } catch {
      // ignore any errors
    }

    // re-throw error for further processing
    throw error;
  };

  return axiosSentryErrorInterceptor;
};
