/* tslint:disable */

/* eslint-disable */

/* istanbul ignore file */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

import * as Types from './types';

const defaultOptions = {} as const;
export const IBankAccountInformationFragmentDoc = gql`
  fragment IBankAccountInformation on IBankAccountInformation {
    uuid
    status
    beneficiary_name
    bank_name
    bank_address
    account_number
    routing_number
    swift_code
    feedback {
      message
    }
  }
`;
export const BankAccountInformationFragmentDoc = gql`
  fragment BankAccountInformation on BankAccountInformation {
    ...IBankAccountInformation
  }
  ${IBankAccountInformationFragmentDoc}
`;
export const CustomerBankAccountInformationFragmentDoc = gql`
  fragment CustomerBankAccountInformation on CustomerBankAccountInformation {
    ...IBankAccountInformation
    contact_name
    contact_email
    contact_phone_number
  }
  ${IBankAccountInformationFragmentDoc}
`;
export const ComplianceInformationFinancingFragmentDoc = gql`
  fragment ComplianceInformationFinancing on ComplianceInformationFinancing {
    uuid
    employment
    occupation
    employer
    employer_address
    employer_business
    position
    years_in_position
    net_worth
    is_broker_dealer
    is_related_to_broker_dealer
    is_financial_industry_regulatory_authority
    is_political_officer
    is_senior_officer
    is_fund_member
    other
    status
    feedback {
      message
    }
  }
`;
export const EquityVerificationFragmentDoc = gql`
  fragment EquityVerification on EquityVerification {
    uuid
    financing_object
    status
  }
`;
export const FinancingOptionGrantsFragmentDoc = gql`
  fragment FinancingOptionGrants on FinancingOptionGrant {
    uuid
    equity_verification
    exercisable_quantity
    exercise_cost {
      currency
      value
    }
    file {
      file_name
      file_type
      url
      uuid
    }
    financing_object
    grant_date
    name
    option_type
    owner
    quantity
    feedback {
      message
      created_at
      created_by
    }
    selected_quantity
    strike_price {
      currency
      value
    }
    verification_status
  }
`;
export const FinancingShareGrantsFragmentDoc = gql`
  fragment FinancingShareGrants on FinancingShareGrant {
    uuid
    equity_verification
    file {
      file_name
      file_type
      url
      uuid
    }
    financing_object
    date_of_acquisition
    name
    owner
    feedback {
      message
      created_at
      created_by
    }
    selected_quantity
    shares_purchased
    share_type
    share_value_at_acquisition {
      currency
      value
    }
    verification_status
    vested_quantity
  }
`;
export const FinancingFormModelFragmentDoc = gql`
  fragment FinancingFormModel on FinancingFormModel {
    uuid
    status
    feedback {
      message
      created_at
      created_by
    }
  }
`;
export const IdentificationInformationFragmentDoc = gql`
  fragment IdentificationInformation on IdentificationInformation {
    uuid
    files {
      uuid
      file_name
      file_type
      url
    }
    status
    feedback {
      message
    }
  }
`;
export const IntermediaryServicesAgreementFragmentDoc = gql`
  fragment IntermediaryServicesAgreement on IntermediaryServicesAgreement {
    uuid
    status
    docusign_id
    feedback {
      message
    }
  }
`;
export const PersonalInformationFinancingFragmentDoc = gql`
  fragment PersonalInformationFinancing on PersonalInformationFinancing {
    uuid
    legal_name
    date_of_birth
    citizenship
    country_of_tax_residence
    social_security
    taxpayer_id
    marital_status
    physical_address
    mailing_address
    phone_number
    preferred_email
    status
    feedback {
      message
    }
    address_street_1
    address_street_2
    postal_code
    subdivision
    country
    city
  }
`;
export const TaxWithholdingsOptionRelationFragmentDoc = gql`
  fragment TaxWithholdingsOptionRelation on TaxWithholdingsOptionRelation {
    uuid
    amount {
      currency
      value
    }
    feedback {
      created_at
      created_by
      message
    }
    file {
      file_name
      file_type
      url
      uuid
    }
    financing_option_grant {
      uuid
      option_type
      selected_quantity
      strike_price {
        currency
        value
      }
    }
    verification_status
  }
`;
export const TaxWithholdingsFragmentDoc = gql`
  fragment TaxWithholdings on TaxWithholdings {
    uuid
    amount_type
    feedback {
      created_at
      created_by
      message
    }
    file {
      file_name
      file_type
      url
      uuid
    }
    financing_object
    financing_option_relations {
      ...TaxWithholdingsOptionRelation
    }
    single_amount {
      currency
      value
    }
    status
  }
  ${TaxWithholdingsOptionRelationFragmentDoc}
`;
export const W9FormFragmentDoc = gql`
  fragment W9Form on W9Form {
    uuid
    status
    docusign_id
    feedback {
      message
    }
  }
`;
export const FinancingCategoryStatusFragmentDoc = gql`
  fragment FinancingCategoryStatus on FinancingCategoryStatus {
    has_feedback
    name: category
    status
  }
`;
export const GenericContractFragmentDoc = gql`
  fragment GenericContract on Contract {
    uuid
    status
    client_signed_at
    file {
      url
    }
  }
`;
export const CurrencyFragmentFragmentDoc = gql`
  fragment CurrencyFragment on CurrencyModel {
    value
    currency
  }
`;
export const SelectedOptionGrantFragmentFragmentDoc = gql`
  fragment SelectedOptionGrantFragment on SelectedOptionGrant {
    excluded
    name
    option_type
    quantity
    selected_quantity
    start_date
    strike_price {
      ...CurrencyFragment
    }
    uuid
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const SelectedShareGrantFragmentFragmentDoc = gql`
  fragment SelectedShareGrantFragment on SelectedShareGrant {
    uuid
    quantity
    selected_quantity
    excluded
    share_type
    share_value_at_acquisition {
      ...CurrencyFragment
    }
    start_date
    selected_quantity
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const ProposalRequestFragmentFragmentDoc = gql`
  fragment ProposalRequestFragment on ProposalRequest {
    uuid
    affiliation {
      uuid
      name
      company_assessment {
        uuid
        verified
      }
      company {
        uuid
        name
        company_status
        company_substatus
      }
      period
      customer {
        uuid
        phone_number
        user {
          uuid
          username
          first_name
          last_name
        }
      }
    }
    proposal_reason
    shares_advance_amount {
      ...CurrencyFragment
    }
    user_share_price {
      ...CurrencyFragment
    }
    shares_advance
    comment
    date_submitted
    exercise_date
    income {
      ...CurrencyFragment
    }
    filing_type
    dependents
    four01k_contributions {
      ...CurrencyFragment
    }
    property_state_local_taxes {
      ...CurrencyFragment
    }
    mortage_interest {
      ...CurrencyFragment
    }
    charitable_contributions {
      ...CurrencyFragment
    }
    ira_contribution {
      ...CurrencyFragment
    }
    additional_deductions {
      ...CurrencyFragment
    }
    self_employment_income {
      ...CurrencyFragment
    }
    dividend_income {
      ...CurrencyFragment
    }
    short_term_capital_gains {
      ...CurrencyFragment
    }
    net_rental_real_estate_income {
      ...CurrencyFragment
    }
    long_term_capital_gains {
      ...CurrencyFragment
    }
    additional_income {
      ...CurrencyFragment
    }
    medical_expenses {
      ...CurrencyFragment
    }
    retirement_plan
    country
    subdivision
    city
    option_grants {
      ...SelectedOptionGrantFragment
    }
    four09a_share_value {
      ...CurrencyFragment
    }
    preferred_share_value {
      ...CurrencyFragment
    }
    share_grants {
      ...SelectedShareGrantFragment
    }
    collateral_shares
    collateral_value {
      ...CurrencyFragment
    }
    advance_to_equity_ratio
    total_financing_need {
      ...CurrencyFragment
    }
    created_by {
      uuid
      username
    }
  }
  ${CurrencyFragmentFragmentDoc}
  ${SelectedOptionGrantFragmentFragmentDoc}
  ${SelectedShareGrantFragmentFragmentDoc}
`;
export const TaxBreakdownFragmentFragmentDoc = gql`
  fragment TaxBreakdownFragment on TaxBreakdown {
    amt
    capital_gains_tax
    income_tax
    medicare_tax
    net_investment_income_tax
    social_security_tax
    state_amt
    state_income_tax
    total_owed
  }
`;
export const FinancingFormStatusFragmentDoc = gql`
  fragment FinancingFormStatus on FinancingFormStatus {
    category
    feedback {
      message
    }
    name
    status
    uuid
    form_grouping
  }
`;
export const TimelinePointFragmentDoc = gql`
  fragment TimelinePoint on TimelinePoint {
    timestamp
    value
  }
`;
export const AllocationTableEntryErrorFragmentDoc = gql`
  fragment AllocationTableEntryError on PortfolioError {
    code
    details {
      ... on GenericErrorDetails {
        message
      }
    }
  }
`;
export const GraphMarkerFragmentDoc = gql`
  fragment GraphMarker on GraphMarker {
    __typename
    ... on GraphMarkerCliff {
      timestamp
      company_name
      grant_type
      percentage_vested
      vested
    }
    ... on GraphMarkerEndOfEmployment {
      timestamp
      company_name
    }
    ... on GraphMarkerEndOfVesting {
      timestamp
      company_name
      grant_type
      vested
    }
    ... on GraphMarkerFirstHolding {
      timestamp
      institution_name
    }
    ... on GraphMarkerFundingRound {
      timestamp
      company_name
      round_name
    }
    ... on GraphMarkerStockSplit {
      timestamp
      company_name
      split_ratio
    }
    ... on GraphMarkerIPO {
      timestamp
      company_name
    }
  }
`;
export const EquityGraphFragmentDoc = gql`
  fragment EquityGraph on EquityGraph {
    totalPrefValueLine {
      ...TimelinePoint
    }
    totalPublicValueLine {
      ...TimelinePoint
    }
    vestedPrefValueLine {
      ...TimelinePoint
    }
    vestedPublicValueLine {
      ...TimelinePoint
    }
    errors {
      ...AllocationTableEntryError
    }
    markers {
      ...GraphMarker
    }
  }
  ${TimelinePointFragmentDoc}
  ${AllocationTableEntryErrorFragmentDoc}
  ${GraphMarkerFragmentDoc}
`;
export const ExerciseCostEstimationGrantFragmentDoc = gql`
  fragment ExerciseCostEstimationGrant on EPGrantExerciseCostsEstimation {
    exerciseCost
    taxDueOnExercise
    totalExerciseCost
    uuid
    vestedQuantity
  }
`;
export const OptionGrantExitValueEstimationFragmentDoc = gql`
  fragment OptionGrantExitValueEstimation on EPOptionGrantsExitValueEstimation {
    uuid
    optionType
    netGains
  }
`;
export const ShareGrantsExitValueEstimationFragmentDoc = gql`
  fragment ShareGrantsExitValueEstimation on EPShareGrantsExitValueEstimation {
    uuid
    netGains
  }
`;
export const CompanyForecastFragmentDoc = gql`
  fragment CompanyForecast on CompanyForecast {
    uuid
    company
    nr_of_outstanding_shares
    exit_value {
      ...CurrencyFragment
    }
    updated_at
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const ExitScenarioDetailFragmentDoc = gql`
  fragment ExitScenarioDetail on ExitScenarioDetail {
    common_share_price
    investors_cut
    total_common_shares
    total_shares
    total_value_of_common_shares
    liquidation_preferences_applied
    liquidation_preference_loss
    date_of_latest_equity_round
    name_of_latest_equity_round
    total_amount_invested
    exit_valuation
  }
`;
export const ProposalRequestPersonalSnapshotFragmentFragmentDoc = gql`
  fragment ProposalRequestPersonalSnapshotFragment on ProposalRequestPersonalSnapshot {
    proposal_reason
    shares_advance_amount {
      ...CurrencyFragment
    }
    user_share_price {
      ...CurrencyFragment
    }
    shares_advance
    income {
      ...CurrencyFragment
    }
    filing_type
    dependents
    four01k_contributions {
      ...CurrencyFragment
    }
    property_state_local_taxes {
      ...CurrencyFragment
    }
    mortage_interest {
      ...CurrencyFragment
    }
    charitable_contributions {
      ...CurrencyFragment
    }
    ira_contribution {
      ...CurrencyFragment
    }
    additional_deductions {
      ...CurrencyFragment
    }
    self_employment_income {
      ...CurrencyFragment
    }
    dividend_income {
      ...CurrencyFragment
    }
    short_term_capital_gains {
      ...CurrencyFragment
    }
    net_rental_real_estate_income {
      ...CurrencyFragment
    }
    long_term_capital_gains {
      ...CurrencyFragment
    }
    additional_income {
      ...CurrencyFragment
    }
    medical_expenses {
      ...CurrencyFragment
    }
    retirement_plan
    country
    subdivision
    city
    option_grants {
      ...SelectedOptionGrantFragment
    }
    four09a_share_value {
      ...CurrencyFragment
    }
    preferred_share_value {
      ...CurrencyFragment
    }
    share_grants {
      ...SelectedShareGrantFragment
    }
  }
  ${CurrencyFragmentFragmentDoc}
  ${SelectedOptionGrantFragmentFragmentDoc}
  ${SelectedShareGrantFragmentFragmentDoc}
`;
export const EpExerciseNowEarlyGrantDataFragmentDoc = gql`
  fragment EPExerciseNowEarlyGrantData on EPExerciseNowEarlyGrantData {
    quantity
    uuid
  }
`;
export const EpExerciseNowEarlyDataFragmentDoc = gql`
  fragment EPExerciseNowEarlyData on EPExerciseNowEarlyData {
    optionGrants {
      ...EPExerciseNowEarlyGrantData
    }
  }
  ${EpExerciseNowEarlyGrantDataFragmentDoc}
`;
export const ExerciseLaterProjectionDataFragmentDoc = gql`
  fragment ExerciseLaterProjectionData on EPExerciseLaterProjectionData {
    exerciseDate
    four09aPrice {
      ...CurrencyFragment
    }
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const PeriodFragmentDoc = gql`
  fragment Period on Period {
    amount
    timeUnit
  }
`;
export const EquityCurrencyFragmentDoc = gql`
  fragment EquityCurrency on EquityCurrency {
    currency
    value
  }
`;
export const SaleEventFragmentDoc = gql`
  fragment SaleEvent on SaleEvent {
    createdAt
    eventDate
    fmv {
      ...EquityCurrency
    }
    price {
      ...EquityCurrency
    }
    quantity
    uuid
  }
  ${EquityCurrencyFragmentDoc}
`;
export const ExerciseEventFragmentDoc = gql`
  fragment ExerciseEvent on ExerciseEvent {
    createdAt
    eventDate
    migrationDefaultEventDate
    fmv {
      currency
      value
    }
    quantity
    uuid
    saleEvents {
      ...SaleEvent
    }
  }
  ${SaleEventFragmentDoc}
`;
export const StockOptionGrantSummaryFragmentDoc = gql`
  fragment StockOptionGrantSummary on StockOptionGrantSummary {
    exercisableQuantity
    exercisedQuantity
    soldQuantity
    ownedQuantity
    totalVestedQuantity
  }
`;
export const VestingScheduleEventFragmentDoc = gql`
  fragment VestingScheduleEvent on VestingScheduleEvent {
    eventDate
    quantity
    type
  }
`;
export const VestingScheduleTemplateFragmentDoc = gql`
  fragment VestingScheduleTemplate on VestingScheduleTemplate {
    cliff
    vestingFrequency
    vestingPeriod
  }
`;
export const StockOptionGrantFragmentDoc = gql`
  fragment StockOptionGrant on StockOptionGrant {
    uuid
    affiliation
    createdAt
    customer
    deadlineAfterLeaving {
      ...Period
    }
    migrationDefaultVestingSchedule
    earlyExercisable
    exerciseEvents {
      ...ExerciseEvent
    }
    grantName
    grantType
    quantity
    strikePrice {
      ...EquityCurrency
    }
    summary {
      ...StockOptionGrantSummary
    }
    updatedAt
    uuid
    verified
    vestingEvents {
      ...VestingScheduleEvent
    }
    vestingScheduleTemplate {
      ...VestingScheduleTemplate
    }
    vestingStartDate
    vestingEndDate
  }
  ${PeriodFragmentDoc}
  ${ExerciseEventFragmentDoc}
  ${EquityCurrencyFragmentDoc}
  ${StockOptionGrantSummaryFragmentDoc}
  ${VestingScheduleEventFragmentDoc}
  ${VestingScheduleTemplateFragmentDoc}
`;
export const EpOptionGrantItemFragmentDoc = gql`
  fragment EPOptionGrantItem on EPOptionGrantItemData {
    optionGrant {
      ...StockOptionGrant
    }
  }
  ${StockOptionGrantFragmentDoc}
`;
export const EquityShareGrantSummaryFragmentDoc = gql`
  fragment EquityShareGrantSummary on EquityShareGrantSummary {
    soldQuantity
    ownedQuantity
    totalVestedQuantity
  }
`;
export const EquityShareGrantFragmentDoc = gql`
  fragment EquityShareGrant on EquityShareGrant {
    affiliation
    uuid
    createdAt
    customer
    issueOrPurchaseDate
    grantName
    grantType
    migrationDefaultDateOfIssue
    issueOrPurchasePrice {
      ...EquityCurrency
    }
    quantity
    source
    summary {
      ...EquityShareGrantSummary
    }
    updatedAt
    uuid
    verified
    vestingEvents {
      ...VestingScheduleEvent
    }
    vestingScheduleTemplate {
      ...VestingScheduleTemplate
    }
    vestingStartDate
    vestingEndDate
    exerciseDetail {
      uuid
      fmv {
        currency
        value
      }
      stockOptionGrant {
        uuid
        grantType
      }
    }
    saleEvents {
      ...SaleEvent
    }
  }
  ${EquityCurrencyFragmentDoc}
  ${EquityShareGrantSummaryFragmentDoc}
  ${VestingScheduleEventFragmentDoc}
  ${VestingScheduleTemplateFragmentDoc}
  ${SaleEventFragmentDoc}
`;
export const EpShareGrantItemFragmentDoc = gql`
  fragment EPShareGrantItem on EPShareGrantItemData {
    shareGrant {
      ...EquityShareGrant
    }
  }
  ${EquityShareGrantFragmentDoc}
`;
export const EpCompanyAndTaxDetailsItemFragmentDoc = gql`
  fragment EPCompanyAndTaxDetailsItem on EPCompanyAndTaxDetailsItemData {
    country
    dependents
    filingType
    four09aPrice
    income
    subdivision
    usTaxInformationUuid
    additionalDeductions
    additionalIncome
    charitableContributions
    dividendIncome
    four01kContributions
    iraContribution
    longTermCapitalGains
    medicalExpenses
    mortgageInterest
    netRentalRealEstateIncome
    propertyStateLocalTaxes
    selfEmploymentIncome
    shortTermCapitalGains
  }
`;
export const EpTimelineFragmentDoc = gql`
  fragment EPTimeline on EPTimeline {
    hasGeneratedPlans
    isSelectedPlansOutdated
    hasEarlyExercisableGrant
    exerciseNowEarlyData {
      ...EPExerciseNowEarlyData
    }
    equityPlanConfigUuid
    tenderOfferData {
      closeDate
      investmentRound
      optionGrants {
        quantity
        uuid
      }
      price
      shareGrants {
        quantity
        uuid
      }
    }
    secondaryMarketData {
      expectedDate
      price
      shareGrants {
        quantity
        uuid
      }
      optionGrants {
        quantity
        uuid
      }
    }
    exerciseLaterProjectionData {
      ...ExerciseLaterProjectionData
    }
    configProgress {
      overallProgress
      breakdown {
        grants
        companyAndTaxDetails
        exitProjection
        plans
      }
    }
    items {
      title
      type
      date
      state
      plan
      data {
        __typename
        ... on EPOptionGrantItemData {
          ...EPOptionGrantItem
        }
        ... on EPShareGrantItemData {
          ...EPShareGrantItem
        }
        ... on EPCompanyAndTaxDetailsItemData {
          ...EPCompanyAndTaxDetailsItem
        }
        ... on EPExitProjectionItemData {
          exitDate
          sharePrice
        }
        ... on EPExerciseItemData {
          investment
          exercisableQuantities {
            optionType
            quantity
          }
        }
        ... on EPGainsItemData {
          gains
        }
        ... on EPTenderOfferItemData {
          grantsSold {
            type
            quantity
          }
        }
        ... on EPLiquidityFromTenderOfferItemData {
          netGains
        }
        ... on EPSecondaryMarketItemData {
          grantsSold {
            type
            quantity
          }
        }
        ... on EPLiquidityFromSecondaryMarketItemData {
          netGains
        }
      }
    }
  }
  ${EpExerciseNowEarlyDataFragmentDoc}
  ${ExerciseLaterProjectionDataFragmentDoc}
  ${EpOptionGrantItemFragmentDoc}
  ${EpShareGrantItemFragmentDoc}
  ${EpCompanyAndTaxDetailsItemFragmentDoc}
`;
export const PayStubAccountStatementFragmentDoc = gql`
  fragment PayStubAccountStatement on PayStubAccountStatement {
    uuid
    status
    feedback {
      created_at
      created_by
      message
    }
    files {
      created_at
      document_type
      file_name
      file_type
      updated_at
      url
      uuid
    }
  }
`;
export const RiskAssessmentFragmentDoc = gql`
  fragment RiskAssessment on RiskAssessment {
    download_file_name
    status
    uuid
    questionnaire {
      uuid
      title
      possible_answers {
        answer_title
      }
      answer_title
    }
    feedback {
      created_at
      created_by
      message
    }
    files {
      created_at
      file_name
      file_type
      updated_at
      url
      uuid
    }
  }
`;
export const AdvisorFragmentDoc = gql`
  fragment Advisor on Advisor {
    uuid
    avatar
    full_name
    phone
    public_email
    title
    bio
    calendly_intake_call_link
    calendly_proposal_call_link
    calendly_wealth_insights_call_link
    calendly_advisory_insights_call_link
    linkedin
    twitter
  }
`;
export const RiaDealFragmentDoc = gql`
  fragment RiaDeal on RIADeal {
    product
    productCategory
    status
    substatus
    advisoryAgreementUrl
    uuid
    createdAt
    updatedAt
    advisor {
      ...Advisor
    }
  }
  ${AdvisorFragmentDoc}
`;
export const CustomerWaitlistFragmentDoc = gql`
  fragment CustomerWaitlist on CustomerWaitlist {
    createdAt
    uuid
    waitlistCategory
    waitlistName
    waitlistPrice
  }
`;
export const CompanyShortInfoFragmentDoc = gql`
  fragment CompanyShortInfo on CompanyShortInfo {
    uuid
    name
    logo
    website
  }
`;
export const CompanySearchResultItemFragmentDoc = gql`
  fragment CompanySearchResultItem on CompanySearchResultItem {
    uuid
    name
    logo
    website
    imported
    importKey
    isPublic
  }
`;
export const ScheduledAppointmentFragmentDoc = gql`
  fragment ScheduledAppointment on ScheduledAppointment {
    uuid
    appointment_timestamp
    appointment_type
    calendly_event_uri
    calendly_invitee_id
  }
`;
export const RsuGrantSummaryFragmentDoc = gql`
  fragment RSUGrantSummary on RSUGrantSummary {
    soldQuantity
    totalVestedQuantity
  }
`;
export const RsuGrantFragmentDoc = gql`
  fragment RSUGrant on RSUGrant {
    affiliation
    createdAt
    uuid
    customer
    grantName
    performanceTrigger
    quantity
    migrationDefaultVestingSchedule
    summary {
      ...RSUGrantSummary
    }
    updatedAt
    uuid
    grantType @client
    verified
    vestingEvents {
      ...VestingScheduleEvent
    }
    vestingEndDate
    vestingScheduleTemplate {
      ...VestingScheduleTemplate
    }
    vestingStartDate
  }
  ${RsuGrantSummaryFragmentDoc}
  ${VestingScheduleEventFragmentDoc}
  ${VestingScheduleTemplateFragmentDoc}
`;
export const UsTaxInformationFragmentDoc = gql`
  fragment UsTaxInformation on UsTaxInformation {
    uuid
    dependents
    filing_type
    income {
      ...CurrencyFragment
    }
    dividend_income {
      ...CurrencyFragment
    }
    medical_expenses {
      ...CurrencyFragment
    }
    ira_contribution {
      ...CurrencyFragment
    }
    mortgage_interest {
      ...CurrencyFragment
    }
    additional_income {
      ...CurrencyFragment
    }
    additional_deductions {
      ...CurrencyFragment
    }
    four01k_contributions {
      ...CurrencyFragment
    }
    self_employment_income {
      ...CurrencyFragment
    }
    long_term_capital_gains {
      ...CurrencyFragment
    }
    charitable_contributions {
      ...CurrencyFragment
    }
    short_term_capital_gains {
      ...CurrencyFragment
    }
    property_state_local_taxes {
      ...CurrencyFragment
    }
    net_rental_real_estate_income {
      ...CurrencyFragment
    }
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const CompanyAssessmentFragmentDoc = gql`
  fragment CompanyAssessment on CompanyAssessment {
    uuid
    updated_at
    verified
    ipo_or_manda
    common_share_value {
      ...CurrencyFragment
    }
    expected_exit_date
    preferred_share_value {
      ...CurrencyFragment
    }
    expected_exit_multiplier
    four09a_share_value {
      ...CurrencyFragment
    }
    expected_exit_share_value {
      ...CurrencyFragment
    }
    verified_captable_requested
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const AffiliationDetailsFragmentDoc = gql`
  fragment AffiliationDetails on Affiliation {
    uuid
    country
    subdivision
    city
    period
    company {
      uuid
      name
      latest_equity_series_date
      cap_table_verified
      is_fast_track
      has_verified_pref_price
      logo
      ipo_date
      company_status
      company_substatus
    }
    external_source
    company_assessment {
      ...CompanyAssessment
    }
  }
  ${CompanyAssessmentFragmentDoc}
`;
export const AdjustedFundingRoundFragmentDoc = gql`
  fragment AdjustedFundingRound on AdjustedFundingRound {
    description
    date
    adjusted_issue_price
    issue_price
    post_valuation
  }
`;
export const CompanyFragmentDoc = gql`
  fragment Company on Company {
    uuid
    name
    logo
    legal_name
    latest_equity_series_date
    last_issue_price_date
    cap_table_verified
    company_status
    company_substatus
    is_fast_track
    website
    ipo_date
    ticker
    public_price {
      ...CurrencyFragment
    }
    ipo_lockup_end_date
    adjusted_funding_rounds {
      ...AdjustedFundingRound
    }
    latest_verified_company_assessment {
      ...CompanyAssessment
    }
  }
  ${CurrencyFragmentFragmentDoc}
  ${AdjustedFundingRoundFragmentDoc}
  ${CompanyAssessmentFragmentDoc}
`;
export const AffiliationOverviewFragmentDoc = gql`
  fragment AffiliationOverview on Affiliation {
    uuid
    country
    subdivision
    city
    period
    user_triage_answers
    has_active_financing_request
    external_source
    company {
      ...Company
    }
    customer {
      uuid
      show_initial_screen
    }
    company_assessment {
      ...CompanyAssessment
    }
    us_tax_information {
      uuid
      dependents
      income {
        ...CurrencyFragment
      }
      filing_type
      additional_deductions {
        ...CurrencyFragment
      }
      additional_income {
        ...CurrencyFragment
      }
      charitable_contributions {
        ...CurrencyFragment
      }
      dividend_income {
        ...CurrencyFragment
      }
      four01k_contributions {
        ...CurrencyFragment
      }
      ira_contribution {
        ...CurrencyFragment
      }
      long_term_capital_gains {
        ...CurrencyFragment
      }
      medical_expenses {
        ...CurrencyFragment
      }
      mortgage_interest {
        ...CurrencyFragment
      }
      net_rental_real_estate_income {
        ...CurrencyFragment
      }
      property_state_local_taxes {
        ...CurrencyFragment
      }
      self_employment_income {
        ...CurrencyFragment
      }
      short_term_capital_gains {
        ...CurrencyFragment
      }
    }
    has_financing_request
    has_pending_financing_request
    has_generated_plans
  }
  ${CompanyFragmentDoc}
  ${CompanyAssessmentFragmentDoc}
  ${CurrencyFragmentFragmentDoc}
`;
export const AllocationTableEntryFragmentDoc = gql`
  fragment AllocationTableEntry on AllocationTableEntry {
    type
    name
    logo
    value
    weight
    quantity
    companyUuid
    errors {
      ...AllocationTableEntryError
    }
  }
  ${AllocationTableEntryErrorFragmentDoc}
`;
export const NetWorthAllocationTableFragmentDoc = gql`
  fragment NetWorthAllocationTable on AllocationTable {
    totalValue
    items {
      ...AllocationTableEntry
    }
  }
  ${AllocationTableEntryFragmentDoc}
`;
export const CustomerFlagFragmentDoc = gql`
  fragment CustomerFlag on CustomerFlag {
    customer
    affiliation
    flag
    createdAt
  }
`;
export const UserInfoFragmentFragmentDoc = gql`
  fragment UserInfoFragment on User {
    uuid
    email
    first_name
    last_name
    fullName @client
    initials @client
    username
  }
`;
export const FinancingObjectFragmentDoc = gql`
  fragment FinancingObject on FinancingObject {
    uuid
    advisor {
      ...Advisor
    }
    status
    document_storage_url
    intake_call_timestamp
    intake_call_invitee_id
    intake_call_uuid
    is_intake_call_cancelled
    last_seen
    owner
    products_interested
    proposal_call_timestamp
    proposal_call_invitee_id
    proposal_call_uuid
    is_proposal_call_cancelled
    signed_nda
    substatus
    is_legacy_onboarding
    date_proposal_accepted
  }
  ${AdvisorFragmentDoc}
`;
export const ProposalSummaryFragmentDoc = gql`
  fragment ProposalSummary on Proposal {
    uuid
    collateral_shares
    advance_provided {
      ...CurrencyFragment
    }
    advance_rate
    stock_fee
    rampup_fees {
      equity_rate
      month
    }
    hash_id
    issued_date
    name
    published
    expiration_date
    is_expired
    regeneration_requested
    proposal_reason
  }
  ${CurrencyFragmentFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    account_source
    date_joined
    email
    first_name
    fullName @client
    is_google_sso
    initials @client
    is_admin
    is_verified
    last_name
    username
    uuid
    customer @client {
      uuid
      show_equity_planner
      nickname
    }
  }
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    uuid
    address_1
    address_2
    city
    country
    kyc_status
    nickname
    phone_number
    pipedrive_deal_id
    subdivision
    zip_code
    show_equity_planner
    nickname
  }
`;
export const InstitutionFragmentDoc = gql`
  fragment Institution on Institution {
    institution_id
    last_snapshot_date
    last_updated
    logo
    name
    status
    website
  }
`;
export const LatestPublicPriceForDateDocument = gql`
  query LatestPublicPriceForDate(
    $customer: UUID!
    $ticker: String!
    $pricingDate: CalendarDate!
  ) {
    latestPublicPriceForDate(
      customer: $customer
      ticker: $ticker
      pricingDate: $pricingDate
    ) {
      ticker
      price
    }
  }
`;

/**
 * __useLatestPublicPriceForDateQuery__
 *
 * To run a query within a React component, call `useLatestPublicPriceForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPublicPriceForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPublicPriceForDateQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      ticker: // value for 'ticker'
 *      pricingDate: // value for 'pricingDate'
 *   },
 * });
 */
export function useLatestPublicPriceForDateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.LatestPublicPriceForDateQuery,
    Types.LatestPublicPriceForDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.LatestPublicPriceForDateQuery,
    Types.LatestPublicPriceForDateQueryVariables
  >(LatestPublicPriceForDateDocument, options);
}
export function useLatestPublicPriceForDateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LatestPublicPriceForDateQuery,
    Types.LatestPublicPriceForDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.LatestPublicPriceForDateQuery,
    Types.LatestPublicPriceForDateQueryVariables
  >(LatestPublicPriceForDateDocument, options);
}
export type LatestPublicPriceForDateQueryHookResult = ReturnType<
  typeof useLatestPublicPriceForDateQuery
>;
export type LatestPublicPriceForDateLazyQueryHookResult = ReturnType<
  typeof useLatestPublicPriceForDateLazyQuery
>;
export type LatestPublicPriceForDateQueryResult = ApolloReactCommon.QueryResult<
  Types.LatestPublicPriceForDateQuery,
  Types.LatestPublicPriceForDateQueryVariables
>;
export const ProcessCartaSignupCallbackDocument = gql`
  mutation ProcessCartaSignupCallback($code: String!, $state: String!) {
    processSignUpWithCartaCallback(code: $code, state: $state) {
      uuid
      refreshToken
      accessToken
      duration
      token
    }
  }
`;
export type ProcessCartaSignupCallbackMutationFn = ApolloReactCommon.MutationFunction<
  Types.ProcessCartaSignupCallbackMutation,
  Types.ProcessCartaSignupCallbackMutationVariables
>;

/**
 * __useProcessCartaSignupCallbackMutation__
 *
 * To run a mutation, you first call `useProcessCartaSignupCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCartaSignupCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCartaSignupCallbackMutation, { data, loading, error }] = useProcessCartaSignupCallbackMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useProcessCartaSignupCallbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ProcessCartaSignupCallbackMutation,
    Types.ProcessCartaSignupCallbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ProcessCartaSignupCallbackMutation,
    Types.ProcessCartaSignupCallbackMutationVariables
  >(ProcessCartaSignupCallbackDocument, options);
}
export type ProcessCartaSignupCallbackMutationHookResult = ReturnType<
  typeof useProcessCartaSignupCallbackMutation
>;
export type ProcessCartaSignupCallbackMutationResult = ApolloReactCommon.MutationResult<Types.ProcessCartaSignupCallbackMutation>;
export type ProcessCartaSignupCallbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ProcessCartaSignupCallbackMutation,
  Types.ProcessCartaSignupCallbackMutationVariables
>;
export const CreateAccountPasswordlessDocument = gql`
  mutation createAccountPasswordless($payload: SignUpPasswordlessInput) {
    createAccountPasswordless(payload: $payload) {
      refresh_token
      access_token
      token
      duration
      uuid
      first_name
      last_name
      email
      username
      customer
      is_verified
    }
  }
`;
export type CreateAccountPasswordlessMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateAccountPasswordlessMutation,
  Types.CreateAccountPasswordlessMutationVariables
>;

/**
 * __useCreateAccountPasswordlessMutation__
 *
 * To run a mutation, you first call `useCreateAccountPasswordlessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountPasswordlessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountPasswordlessMutation, { data, loading, error }] = useCreateAccountPasswordlessMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateAccountPasswordlessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateAccountPasswordlessMutation,
    Types.CreateAccountPasswordlessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateAccountPasswordlessMutation,
    Types.CreateAccountPasswordlessMutationVariables
  >(CreateAccountPasswordlessDocument, options);
}
export type CreateAccountPasswordlessMutationHookResult = ReturnType<
  typeof useCreateAccountPasswordlessMutation
>;
export type CreateAccountPasswordlessMutationResult = ApolloReactCommon.MutationResult<Types.CreateAccountPasswordlessMutation>;
export type CreateAccountPasswordlessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateAccountPasswordlessMutation,
  Types.CreateAccountPasswordlessMutationVariables
>;
export const UserIdentificationDocument = gql`
  query UserIdentification(
    $email: String!
    $firstName: String
    $lastName: String
  ) {
    userIdentification(
      email: $email
      first_name: $firstName
      last_name: $lastName
    ) {
      token
    }
  }
`;

/**
 * __useUserIdentificationQuery__
 *
 * To run a query within a React component, call `useUserIdentificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentificationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUserIdentificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.UserIdentificationQuery,
    Types.UserIdentificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.UserIdentificationQuery,
    Types.UserIdentificationQueryVariables
  >(UserIdentificationDocument, options);
}
export function useUserIdentificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserIdentificationQuery,
    Types.UserIdentificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.UserIdentificationQuery,
    Types.UserIdentificationQueryVariables
  >(UserIdentificationDocument, options);
}
export type UserIdentificationQueryHookResult = ReturnType<
  typeof useUserIdentificationQuery
>;
export type UserIdentificationLazyQueryHookResult = ReturnType<
  typeof useUserIdentificationLazyQuery
>;
export type UserIdentificationQueryResult = ApolloReactCommon.QueryResult<
  Types.UserIdentificationQuery,
  Types.UserIdentificationQueryVariables
>;
export const GetMockProposalDocument = gql`
  query getMockProposal($affiliationUuid: ID!, $exitMultiplier: Float!) {
    mockProposal: getMockProposal(
      affiliationUuid: $affiliationUuid
      exitMultiplier: $exitMultiplier
    ) {
      collateral_shares
      total_selected_options
      total_selected_shares
      exercise_cost {
        ...CurrencyFragment
      }
      potential_liquidity {
        ...CurrencyFragment
      }
      total_advance {
        ...CurrencyFragment
      }
      total_fees {
        ...CurrencyFragment
      }
      total_taxes {
        ...CurrencyFragment
      }
      exit_scenarios {
        net_gain_exercise_now {
          ...CurrencyFragment
        }
        net_gain_wait_until_exit {
          ...CurrencyFragment
        }
      }
    }
  }
  ${CurrencyFragmentFragmentDoc}
`;

/**
 * __useGetMockProposalQuery__
 *
 * To run a query within a React component, call `useGetMockProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMockProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMockProposalQuery({
 *   variables: {
 *      affiliationUuid: // value for 'affiliationUuid'
 *      exitMultiplier: // value for 'exitMultiplier'
 *   },
 * });
 */
export function useGetMockProposalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetMockProposalQuery,
    Types.GetMockProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetMockProposalQuery,
    Types.GetMockProposalQueryVariables
  >(GetMockProposalDocument, options);
}
export function useGetMockProposalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetMockProposalQuery,
    Types.GetMockProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetMockProposalQuery,
    Types.GetMockProposalQueryVariables
  >(GetMockProposalDocument, options);
}
export type GetMockProposalQueryHookResult = ReturnType<
  typeof useGetMockProposalQuery
>;
export type GetMockProposalLazyQueryHookResult = ReturnType<
  typeof useGetMockProposalLazyQuery
>;
export type GetMockProposalQueryResult = ApolloReactCommon.QueryResult<
  Types.GetMockProposalQuery,
  Types.GetMockProposalQueryVariables
>;
export const GetBankAccountInformationDocument = gql`
  query GetBankAccountInformation($financing_object: String!) {
    bankAccountInformation(financing_object: $financing_object) {
      ...BankAccountInformation
    }
  }
  ${BankAccountInformationFragmentDoc}
`;

/**
 * __useGetBankAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetBankAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountInformationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetBankAccountInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetBankAccountInformationQuery,
    Types.GetBankAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetBankAccountInformationQuery,
    Types.GetBankAccountInformationQueryVariables
  >(GetBankAccountInformationDocument, options);
}
export function useGetBankAccountInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetBankAccountInformationQuery,
    Types.GetBankAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetBankAccountInformationQuery,
    Types.GetBankAccountInformationQueryVariables
  >(GetBankAccountInformationDocument, options);
}
export type GetBankAccountInformationQueryHookResult = ReturnType<
  typeof useGetBankAccountInformationQuery
>;
export type GetBankAccountInformationLazyQueryHookResult = ReturnType<
  typeof useGetBankAccountInformationLazyQuery
>;
export type GetBankAccountInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetBankAccountInformationQuery,
  Types.GetBankAccountInformationQueryVariables
>;
export const CreateOrUpdateBankAccountInformationDocument = gql`
  mutation CreateOrUpdateBankAccountInformation(
    $financing_object: String!
    $payload: BankAccountInformationInput!
  ) {
    bankAccountInformation: createOrUpdateBankAccountInformation(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...BankAccountInformation
    }
  }
  ${BankAccountInformationFragmentDoc}
`;
export type CreateOrUpdateBankAccountInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateBankAccountInformationMutation,
  Types.CreateOrUpdateBankAccountInformationMutationVariables
>;

/**
 * __useCreateOrUpdateBankAccountInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateBankAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateBankAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateBankAccountInformationMutation, { data, loading, error }] = useCreateOrUpdateBankAccountInformationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateBankAccountInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateBankAccountInformationMutation,
    Types.CreateOrUpdateBankAccountInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateBankAccountInformationMutation,
    Types.CreateOrUpdateBankAccountInformationMutationVariables
  >(CreateOrUpdateBankAccountInformationDocument, options);
}
export type CreateOrUpdateBankAccountInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateBankAccountInformationMutation
>;
export type CreateOrUpdateBankAccountInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateBankAccountInformationMutation>;
export type CreateOrUpdateBankAccountInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateBankAccountInformationMutation,
  Types.CreateOrUpdateBankAccountInformationMutationVariables
>;
export const GetCustomerBankAccountInformationDocument = gql`
  query GetCustomerBankAccountInformation($financing_object: String!) {
    CustomerBankAccountInformation(financing_object: $financing_object) {
      ...CustomerBankAccountInformation
    }
  }
  ${CustomerBankAccountInformationFragmentDoc}
`;

/**
 * __useGetCustomerBankAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetCustomerBankAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerBankAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerBankAccountInformationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetCustomerBankAccountInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCustomerBankAccountInformationQuery,
    Types.GetCustomerBankAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCustomerBankAccountInformationQuery,
    Types.GetCustomerBankAccountInformationQueryVariables
  >(GetCustomerBankAccountInformationDocument, options);
}
export function useGetCustomerBankAccountInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCustomerBankAccountInformationQuery,
    Types.GetCustomerBankAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCustomerBankAccountInformationQuery,
    Types.GetCustomerBankAccountInformationQueryVariables
  >(GetCustomerBankAccountInformationDocument, options);
}
export type GetCustomerBankAccountInformationQueryHookResult = ReturnType<
  typeof useGetCustomerBankAccountInformationQuery
>;
export type GetCustomerBankAccountInformationLazyQueryHookResult = ReturnType<
  typeof useGetCustomerBankAccountInformationLazyQuery
>;
export type GetCustomerBankAccountInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCustomerBankAccountInformationQuery,
  Types.GetCustomerBankAccountInformationQueryVariables
>;
export const CreateOrUpdateCustomerBankAccountInformationDocument = gql`
  mutation CreateOrUpdateCustomerBankAccountInformation(
    $financing_object: String!
    $payload: CustomerBankAccountInformationInput!
  ) {
    CustomerBankAccountInformation: createOrUpdateCustomerBankAccountInformation(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...CustomerBankAccountInformation
    }
  }
  ${CustomerBankAccountInformationFragmentDoc}
`;
export type CreateOrUpdateCustomerBankAccountInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateCustomerBankAccountInformationMutation,
  Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
>;

/**
 * __useCreateOrUpdateCustomerBankAccountInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCustomerBankAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCustomerBankAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCustomerBankAccountInformationMutation, { data, loading, error }] = useCreateOrUpdateCustomerBankAccountInformationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateCustomerBankAccountInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateCustomerBankAccountInformationMutation,
    Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateCustomerBankAccountInformationMutation,
    Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
  >(CreateOrUpdateCustomerBankAccountInformationDocument, options);
}
export type CreateOrUpdateCustomerBankAccountInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateCustomerBankAccountInformationMutation
>;
export type CreateOrUpdateCustomerBankAccountInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateCustomerBankAccountInformationMutation>;
export type CreateOrUpdateCustomerBankAccountInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateCustomerBankAccountInformationMutation,
  Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
>;
export const GetComplianceInformationDocument = gql`
  query GetComplianceInformation($financing_object: String!) {
    complianceInformation(financing_object: $financing_object) {
      ...ComplianceInformationFinancing
    }
  }
  ${ComplianceInformationFinancingFragmentDoc}
`;

/**
 * __useGetComplianceInformationQuery__
 *
 * To run a query within a React component, call `useGetComplianceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceInformationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetComplianceInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetComplianceInformationQuery,
    Types.GetComplianceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetComplianceInformationQuery,
    Types.GetComplianceInformationQueryVariables
  >(GetComplianceInformationDocument, options);
}
export function useGetComplianceInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetComplianceInformationQuery,
    Types.GetComplianceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetComplianceInformationQuery,
    Types.GetComplianceInformationQueryVariables
  >(GetComplianceInformationDocument, options);
}
export type GetComplianceInformationQueryHookResult = ReturnType<
  typeof useGetComplianceInformationQuery
>;
export type GetComplianceInformationLazyQueryHookResult = ReturnType<
  typeof useGetComplianceInformationLazyQuery
>;
export type GetComplianceInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetComplianceInformationQuery,
  Types.GetComplianceInformationQueryVariables
>;
export const CreateOrUpdateComplianceInformationDocument = gql`
  mutation CreateOrUpdateComplianceInformation(
    $financing_object: String!
    $payload: ComplianceInformationFinancingInput!
  ) {
    complianceInformation: createOrUpdateComplianceInformation(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...ComplianceInformationFinancing
    }
  }
  ${ComplianceInformationFinancingFragmentDoc}
`;
export type CreateOrUpdateComplianceInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateComplianceInformationMutation,
  Types.CreateOrUpdateComplianceInformationMutationVariables
>;

/**
 * __useCreateOrUpdateComplianceInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateComplianceInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateComplianceInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateComplianceInformationMutation, { data, loading, error }] = useCreateOrUpdateComplianceInformationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateComplianceInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateComplianceInformationMutation,
    Types.CreateOrUpdateComplianceInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateComplianceInformationMutation,
    Types.CreateOrUpdateComplianceInformationMutationVariables
  >(CreateOrUpdateComplianceInformationDocument, options);
}
export type CreateOrUpdateComplianceInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateComplianceInformationMutation
>;
export type CreateOrUpdateComplianceInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateComplianceInformationMutation>;
export type CreateOrUpdateComplianceInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateComplianceInformationMutation,
  Types.CreateOrUpdateComplianceInformationMutationVariables
>;
export const GetEquityVerificationDocument = gql`
  query GetEquityVerification($financing_object: String!) {
    equityVerification: getEquityVerificationByUuid(
      financing_object: $financing_object
    ) {
      ...EquityVerification
    }
  }
  ${EquityVerificationFragmentDoc}
`;

/**
 * __useGetEquityVerificationQuery__
 *
 * To run a query within a React component, call `useGetEquityVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquityVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquityVerificationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetEquityVerificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetEquityVerificationQuery,
    Types.GetEquityVerificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetEquityVerificationQuery,
    Types.GetEquityVerificationQueryVariables
  >(GetEquityVerificationDocument, options);
}
export function useGetEquityVerificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetEquityVerificationQuery,
    Types.GetEquityVerificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetEquityVerificationQuery,
    Types.GetEquityVerificationQueryVariables
  >(GetEquityVerificationDocument, options);
}
export type GetEquityVerificationQueryHookResult = ReturnType<
  typeof useGetEquityVerificationQuery
>;
export type GetEquityVerificationLazyQueryHookResult = ReturnType<
  typeof useGetEquityVerificationLazyQuery
>;
export type GetEquityVerificationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetEquityVerificationQuery,
  Types.GetEquityVerificationQueryVariables
>;
export const CreateOrUpdateEquityVerificationDocument = gql`
  mutation CreateOrUpdateEquityVerification(
    $financing_object: String!
    $payload: EquityVerificationInput!
  ) {
    equityVerification: createOrUpdateEquityVerification(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...EquityVerification
    }
  }
  ${EquityVerificationFragmentDoc}
`;
export type CreateOrUpdateEquityVerificationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateEquityVerificationMutation,
  Types.CreateOrUpdateEquityVerificationMutationVariables
>;

/**
 * __useCreateOrUpdateEquityVerificationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateEquityVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateEquityVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateEquityVerificationMutation, { data, loading, error }] = useCreateOrUpdateEquityVerificationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateEquityVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateEquityVerificationMutation,
    Types.CreateOrUpdateEquityVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateEquityVerificationMutation,
    Types.CreateOrUpdateEquityVerificationMutationVariables
  >(CreateOrUpdateEquityVerificationDocument, options);
}
export type CreateOrUpdateEquityVerificationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateEquityVerificationMutation
>;
export type CreateOrUpdateEquityVerificationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateEquityVerificationMutation>;
export type CreateOrUpdateEquityVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateEquityVerificationMutation,
  Types.CreateOrUpdateEquityVerificationMutationVariables
>;
export const GetFinancingOptionGrantsDocument = gql`
  query GetFinancingOptionGrants($financing_object: UUID!) {
    financingOptionGrants: getFinancingOptionGrants(
      financingObjectUuid: $financing_object
    ) {
      ...FinancingOptionGrants
    }
  }
  ${FinancingOptionGrantsFragmentDoc}
`;

/**
 * __useGetFinancingOptionGrantsQuery__
 *
 * To run a query within a React component, call `useGetFinancingOptionGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingOptionGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingOptionGrantsQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetFinancingOptionGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingOptionGrantsQuery,
    Types.GetFinancingOptionGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingOptionGrantsQuery,
    Types.GetFinancingOptionGrantsQueryVariables
  >(GetFinancingOptionGrantsDocument, options);
}
export function useGetFinancingOptionGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingOptionGrantsQuery,
    Types.GetFinancingOptionGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingOptionGrantsQuery,
    Types.GetFinancingOptionGrantsQueryVariables
  >(GetFinancingOptionGrantsDocument, options);
}
export type GetFinancingOptionGrantsQueryHookResult = ReturnType<
  typeof useGetFinancingOptionGrantsQuery
>;
export type GetFinancingOptionGrantsLazyQueryHookResult = ReturnType<
  typeof useGetFinancingOptionGrantsLazyQuery
>;
export type GetFinancingOptionGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingOptionGrantsQuery,
  Types.GetFinancingOptionGrantsQueryVariables
>;
export const UpdateFinancingOptionGrantDocument = gql`
  mutation UpdateFinancingOptionGrant(
    $uuid: UUID!
    $payload: UpdateFinancingOptionGrantInputType!
  ) {
    financingOptionGrants: updateFinancingOptionGrant(
      financing_option_grant_uuid: $uuid
      payload: $payload
    ) {
      ...FinancingOptionGrants
    }
  }
  ${FinancingOptionGrantsFragmentDoc}
`;
export type UpdateFinancingOptionGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateFinancingOptionGrantMutation,
  Types.UpdateFinancingOptionGrantMutationVariables
>;

/**
 * __useUpdateFinancingOptionGrantMutation__
 *
 * To run a mutation, you first call `useUpdateFinancingOptionGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancingOptionGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancingOptionGrantMutation, { data, loading, error }] = useUpdateFinancingOptionGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateFinancingOptionGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateFinancingOptionGrantMutation,
    Types.UpdateFinancingOptionGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateFinancingOptionGrantMutation,
    Types.UpdateFinancingOptionGrantMutationVariables
  >(UpdateFinancingOptionGrantDocument, options);
}
export type UpdateFinancingOptionGrantMutationHookResult = ReturnType<
  typeof useUpdateFinancingOptionGrantMutation
>;
export type UpdateFinancingOptionGrantMutationResult = ApolloReactCommon.MutationResult<Types.UpdateFinancingOptionGrantMutation>;
export type UpdateFinancingOptionGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateFinancingOptionGrantMutation,
  Types.UpdateFinancingOptionGrantMutationVariables
>;
export const GetFinancingShareGrantsDocument = gql`
  query GetFinancingShareGrants($financing_object: UUID!) {
    financingShareGrants: getFinancingShareGrants(
      financingObjectUuid: $financing_object
    ) {
      ...FinancingShareGrants
    }
  }
  ${FinancingShareGrantsFragmentDoc}
`;

/**
 * __useGetFinancingShareGrantsQuery__
 *
 * To run a query within a React component, call `useGetFinancingShareGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingShareGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingShareGrantsQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetFinancingShareGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingShareGrantsQuery,
    Types.GetFinancingShareGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingShareGrantsQuery,
    Types.GetFinancingShareGrantsQueryVariables
  >(GetFinancingShareGrantsDocument, options);
}
export function useGetFinancingShareGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingShareGrantsQuery,
    Types.GetFinancingShareGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingShareGrantsQuery,
    Types.GetFinancingShareGrantsQueryVariables
  >(GetFinancingShareGrantsDocument, options);
}
export type GetFinancingShareGrantsQueryHookResult = ReturnType<
  typeof useGetFinancingShareGrantsQuery
>;
export type GetFinancingShareGrantsLazyQueryHookResult = ReturnType<
  typeof useGetFinancingShareGrantsLazyQuery
>;
export type GetFinancingShareGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingShareGrantsQuery,
  Types.GetFinancingShareGrantsQueryVariables
>;
export const UpdateFinancingShareGrantDocument = gql`
  mutation UpdateFinancingShareGrant(
    $uuid: UUID!
    $payload: UpdateFinancingShareGrantInputType!
  ) {
    financingShareGrants: updateFinancingShareGrant(
      financing_share_grant_uuid: $uuid
      payload: $payload
    ) {
      ...FinancingShareGrants
    }
  }
  ${FinancingShareGrantsFragmentDoc}
`;
export type UpdateFinancingShareGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateFinancingShareGrantMutation,
  Types.UpdateFinancingShareGrantMutationVariables
>;

/**
 * __useUpdateFinancingShareGrantMutation__
 *
 * To run a mutation, you first call `useUpdateFinancingShareGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancingShareGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancingShareGrantMutation, { data, loading, error }] = useUpdateFinancingShareGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateFinancingShareGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateFinancingShareGrantMutation,
    Types.UpdateFinancingShareGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateFinancingShareGrantMutation,
    Types.UpdateFinancingShareGrantMutationVariables
  >(UpdateFinancingShareGrantDocument, options);
}
export type UpdateFinancingShareGrantMutationHookResult = ReturnType<
  typeof useUpdateFinancingShareGrantMutation
>;
export type UpdateFinancingShareGrantMutationResult = ApolloReactCommon.MutationResult<Types.UpdateFinancingShareGrantMutation>;
export type UpdateFinancingShareGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateFinancingShareGrantMutation,
  Types.UpdateFinancingShareGrantMutationVariables
>;
export const GetIdentificationInformationDocument = gql`
  query GetIdentificationInformation($financing_object: String!) {
    identificationInformation: identificationInformation(
      financing_object: $financing_object
    ) {
      ...IdentificationInformation
    }
  }
  ${IdentificationInformationFragmentDoc}
`;

/**
 * __useGetIdentificationInformationQuery__
 *
 * To run a query within a React component, call `useGetIdentificationInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentificationInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentificationInformationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetIdentificationInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetIdentificationInformationQuery,
    Types.GetIdentificationInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetIdentificationInformationQuery,
    Types.GetIdentificationInformationQueryVariables
  >(GetIdentificationInformationDocument, options);
}
export function useGetIdentificationInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetIdentificationInformationQuery,
    Types.GetIdentificationInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetIdentificationInformationQuery,
    Types.GetIdentificationInformationQueryVariables
  >(GetIdentificationInformationDocument, options);
}
export type GetIdentificationInformationQueryHookResult = ReturnType<
  typeof useGetIdentificationInformationQuery
>;
export type GetIdentificationInformationLazyQueryHookResult = ReturnType<
  typeof useGetIdentificationInformationLazyQuery
>;
export type GetIdentificationInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetIdentificationInformationQuery,
  Types.GetIdentificationInformationQueryVariables
>;
export const CreateOrUpdateIdentificationInformationDocument = gql`
  mutation CreateOrUpdateIdentificationInformation(
    $financing_object: String!
    $payload: IdentificationInformationInput!
  ) {
    identificationInformation: createOrUpdateIdentificationInformation(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...IdentificationInformation
    }
  }
  ${IdentificationInformationFragmentDoc}
`;
export type CreateOrUpdateIdentificationInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateIdentificationInformationMutation,
  Types.CreateOrUpdateIdentificationInformationMutationVariables
>;

/**
 * __useCreateOrUpdateIdentificationInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateIdentificationInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateIdentificationInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateIdentificationInformationMutation, { data, loading, error }] = useCreateOrUpdateIdentificationInformationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateIdentificationInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateIdentificationInformationMutation,
    Types.CreateOrUpdateIdentificationInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateIdentificationInformationMutation,
    Types.CreateOrUpdateIdentificationInformationMutationVariables
  >(CreateOrUpdateIdentificationInformationDocument, options);
}
export type CreateOrUpdateIdentificationInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateIdentificationInformationMutation
>;
export type CreateOrUpdateIdentificationInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateIdentificationInformationMutation>;
export type CreateOrUpdateIdentificationInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateIdentificationInformationMutation,
  Types.CreateOrUpdateIdentificationInformationMutationVariables
>;
export const StartKycVerificationDocument = gql`
  mutation startKYCVerification($financingObjectUuid: UUID!) {
    kycUser: initKYCFlowFinancing(financingObjectUuid: $financingObjectUuid) {
      inquiry_id
      session_token
    }
  }
`;
export type StartKycVerificationMutationFn = ApolloReactCommon.MutationFunction<
  Types.StartKycVerificationMutation,
  Types.StartKycVerificationMutationVariables
>;

/**
 * __useStartKycVerificationMutation__
 *
 * To run a mutation, you first call `useStartKycVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartKycVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startKycVerificationMutation, { data, loading, error }] = useStartKycVerificationMutation({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *   },
 * });
 */
export function useStartKycVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.StartKycVerificationMutation,
    Types.StartKycVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.StartKycVerificationMutation,
    Types.StartKycVerificationMutationVariables
  >(StartKycVerificationDocument, options);
}
export type StartKycVerificationMutationHookResult = ReturnType<
  typeof useStartKycVerificationMutation
>;
export type StartKycVerificationMutationResult = ApolloReactCommon.MutationResult<Types.StartKycVerificationMutation>;
export type StartKycVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.StartKycVerificationMutation,
  Types.StartKycVerificationMutationVariables
>;
export const EndKycVerificationDocument = gql`
  mutation endKYCVerification($financingObjectUuid: UUID!, $inquiry_id: UUID!) {
    endKYC: endKYCFlowFinancing(
      financingObjectUuid: $financingObjectUuid
      inquiry_id: $inquiry_id
    ) {
      financing_object
      identification_form_verification_status
      inquiry_id
    }
  }
`;
export type EndKycVerificationMutationFn = ApolloReactCommon.MutationFunction<
  Types.EndKycVerificationMutation,
  Types.EndKycVerificationMutationVariables
>;

/**
 * __useEndKycVerificationMutation__
 *
 * To run a mutation, you first call `useEndKycVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndKycVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endKycVerificationMutation, { data, loading, error }] = useEndKycVerificationMutation({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *      inquiry_id: // value for 'inquiry_id'
 *   },
 * });
 */
export function useEndKycVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EndKycVerificationMutation,
    Types.EndKycVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EndKycVerificationMutation,
    Types.EndKycVerificationMutationVariables
  >(EndKycVerificationDocument, options);
}
export type EndKycVerificationMutationHookResult = ReturnType<
  typeof useEndKycVerificationMutation
>;
export type EndKycVerificationMutationResult = ApolloReactCommon.MutationResult<Types.EndKycVerificationMutation>;
export type EndKycVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EndKycVerificationMutation,
  Types.EndKycVerificationMutationVariables
>;
export const GetIntermediaryServicesAgreementDocument = gql`
  query GetIntermediaryServicesAgreement($financing_object: String!) {
    intermediaryServicesAgreement(financing_object: $financing_object) {
      ...IntermediaryServicesAgreement
    }
  }
  ${IntermediaryServicesAgreementFragmentDoc}
`;

/**
 * __useGetIntermediaryServicesAgreementQuery__
 *
 * To run a query within a React component, call `useGetIntermediaryServicesAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntermediaryServicesAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntermediaryServicesAgreementQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetIntermediaryServicesAgreementQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetIntermediaryServicesAgreementQuery,
    Types.GetIntermediaryServicesAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetIntermediaryServicesAgreementQuery,
    Types.GetIntermediaryServicesAgreementQueryVariables
  >(GetIntermediaryServicesAgreementDocument, options);
}
export function useGetIntermediaryServicesAgreementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetIntermediaryServicesAgreementQuery,
    Types.GetIntermediaryServicesAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetIntermediaryServicesAgreementQuery,
    Types.GetIntermediaryServicesAgreementQueryVariables
  >(GetIntermediaryServicesAgreementDocument, options);
}
export type GetIntermediaryServicesAgreementQueryHookResult = ReturnType<
  typeof useGetIntermediaryServicesAgreementQuery
>;
export type GetIntermediaryServicesAgreementLazyQueryHookResult = ReturnType<
  typeof useGetIntermediaryServicesAgreementLazyQuery
>;
export type GetIntermediaryServicesAgreementQueryResult = ApolloReactCommon.QueryResult<
  Types.GetIntermediaryServicesAgreementQuery,
  Types.GetIntermediaryServicesAgreementQueryVariables
>;
export const GetPersonalInformationDocument = gql`
  query GetPersonalInformation($financing_object: String!) {
    personalInformation(financing_object: $financing_object) {
      ...PersonalInformationFinancing
    }
  }
  ${PersonalInformationFinancingFragmentDoc}
`;

/**
 * __useGetPersonalInformationQuery__
 *
 * To run a query within a React component, call `useGetPersonalInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalInformationQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetPersonalInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPersonalInformationQuery,
    Types.GetPersonalInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPersonalInformationQuery,
    Types.GetPersonalInformationQueryVariables
  >(GetPersonalInformationDocument, options);
}
export function useGetPersonalInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPersonalInformationQuery,
    Types.GetPersonalInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPersonalInformationQuery,
    Types.GetPersonalInformationQueryVariables
  >(GetPersonalInformationDocument, options);
}
export type GetPersonalInformationQueryHookResult = ReturnType<
  typeof useGetPersonalInformationQuery
>;
export type GetPersonalInformationLazyQueryHookResult = ReturnType<
  typeof useGetPersonalInformationLazyQuery
>;
export type GetPersonalInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPersonalInformationQuery,
  Types.GetPersonalInformationQueryVariables
>;
export const GetPersonalInformationCitizenshipOptionsDocument = gql`
  query GetPersonalInformationCitizenshipOptions {
    options: FormOptions {
      countries {
        label
        value
      }
    }
  }
`;

/**
 * __useGetPersonalInformationCitizenshipOptionsQuery__
 *
 * To run a query within a React component, call `useGetPersonalInformationCitizenshipOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalInformationCitizenshipOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalInformationCitizenshipOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonalInformationCitizenshipOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetPersonalInformationCitizenshipOptionsQuery,
    Types.GetPersonalInformationCitizenshipOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPersonalInformationCitizenshipOptionsQuery,
    Types.GetPersonalInformationCitizenshipOptionsQueryVariables
  >(GetPersonalInformationCitizenshipOptionsDocument, options);
}
export function useGetPersonalInformationCitizenshipOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPersonalInformationCitizenshipOptionsQuery,
    Types.GetPersonalInformationCitizenshipOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPersonalInformationCitizenshipOptionsQuery,
    Types.GetPersonalInformationCitizenshipOptionsQueryVariables
  >(GetPersonalInformationCitizenshipOptionsDocument, options);
}
export type GetPersonalInformationCitizenshipOptionsQueryHookResult = ReturnType<
  typeof useGetPersonalInformationCitizenshipOptionsQuery
>;
export type GetPersonalInformationCitizenshipOptionsLazyQueryHookResult = ReturnType<
  typeof useGetPersonalInformationCitizenshipOptionsLazyQuery
>;
export type GetPersonalInformationCitizenshipOptionsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPersonalInformationCitizenshipOptionsQuery,
  Types.GetPersonalInformationCitizenshipOptionsQueryVariables
>;
export const CreateOrUpdatePersonalInformationDocument = gql`
  mutation CreateOrUpdatePersonalInformation(
    $financing_object: String!
    $payload: PersonalInformationFinancingInput!
  ) {
    personalInformation: createOrUpdatePersonalInformation(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...PersonalInformationFinancing
    }
  }
  ${PersonalInformationFinancingFragmentDoc}
`;
export type CreateOrUpdatePersonalInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdatePersonalInformationMutation,
  Types.CreateOrUpdatePersonalInformationMutationVariables
>;

/**
 * __useCreateOrUpdatePersonalInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePersonalInformationMutation, { data, loading, error }] = useCreateOrUpdatePersonalInformationMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdatePersonalInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdatePersonalInformationMutation,
    Types.CreateOrUpdatePersonalInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdatePersonalInformationMutation,
    Types.CreateOrUpdatePersonalInformationMutationVariables
  >(CreateOrUpdatePersonalInformationDocument, options);
}
export type CreateOrUpdatePersonalInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdatePersonalInformationMutation
>;
export type CreateOrUpdatePersonalInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdatePersonalInformationMutation>;
export type CreateOrUpdatePersonalInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdatePersonalInformationMutation,
  Types.CreateOrUpdatePersonalInformationMutationVariables
>;
export const GetTaxWithholdingsDocument = gql`
  query GetTaxWithholdings($financing_object: String!) {
    taxWithholdings: getTaxWithholdingsByUuid(
      financing_object: $financing_object
    ) {
      ...TaxWithholdings
    }
  }
  ${TaxWithholdingsFragmentDoc}
`;

/**
 * __useGetTaxWithholdingsQuery__
 *
 * To run a query within a React component, call `useGetTaxWithholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxWithholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxWithholdingsQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetTaxWithholdingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetTaxWithholdingsQuery,
    Types.GetTaxWithholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetTaxWithholdingsQuery,
    Types.GetTaxWithholdingsQueryVariables
  >(GetTaxWithholdingsDocument, options);
}
export function useGetTaxWithholdingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetTaxWithholdingsQuery,
    Types.GetTaxWithholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetTaxWithholdingsQuery,
    Types.GetTaxWithholdingsQueryVariables
  >(GetTaxWithholdingsDocument, options);
}
export type GetTaxWithholdingsQueryHookResult = ReturnType<
  typeof useGetTaxWithholdingsQuery
>;
export type GetTaxWithholdingsLazyQueryHookResult = ReturnType<
  typeof useGetTaxWithholdingsLazyQuery
>;
export type GetTaxWithholdingsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetTaxWithholdingsQuery,
  Types.GetTaxWithholdingsQueryVariables
>;
export const UpdateTaxWithholdingsDocument = gql`
  mutation UpdateTaxWithholdings(
    $financing_object: String!
    $payload: TaxWithholdingsInput!
  ) {
    taxWithholdings: updateTaxWithholdings(
      financing_object: $financing_object
      payload: $payload
    ) {
      ...TaxWithholdings
    }
  }
  ${TaxWithholdingsFragmentDoc}
`;
export type UpdateTaxWithholdingsMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateTaxWithholdingsMutation,
  Types.UpdateTaxWithholdingsMutationVariables
>;

/**
 * __useUpdateTaxWithholdingsMutation__
 *
 * To run a mutation, you first call `useUpdateTaxWithholdingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxWithholdingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxWithholdingsMutation, { data, loading, error }] = useUpdateTaxWithholdingsMutation({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTaxWithholdingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateTaxWithholdingsMutation,
    Types.UpdateTaxWithholdingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateTaxWithholdingsMutation,
    Types.UpdateTaxWithholdingsMutationVariables
  >(UpdateTaxWithholdingsDocument, options);
}
export type UpdateTaxWithholdingsMutationHookResult = ReturnType<
  typeof useUpdateTaxWithholdingsMutation
>;
export type UpdateTaxWithholdingsMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTaxWithholdingsMutation>;
export type UpdateTaxWithholdingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateTaxWithholdingsMutation,
  Types.UpdateTaxWithholdingsMutationVariables
>;
export const UpdateTaxWithholdingsOptionRelationDocument = gql`
  mutation UpdateTaxWithholdingsOptionRelation(
    $financing_option_grant_uuid: UUID!
    $payload: UpdateTaxWithholdingsOptionRelationInputType!
  ) {
    taxWithholdings: updateTaxWithholdingsOptionRelation(
      financing_option_grant_uuid: $financing_option_grant_uuid
      payload: $payload
    ) {
      ...TaxWithholdingsOptionRelation
    }
  }
  ${TaxWithholdingsOptionRelationFragmentDoc}
`;
export type UpdateTaxWithholdingsOptionRelationMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateTaxWithholdingsOptionRelationMutation,
  Types.UpdateTaxWithholdingsOptionRelationMutationVariables
>;

/**
 * __useUpdateTaxWithholdingsOptionRelationMutation__
 *
 * To run a mutation, you first call `useUpdateTaxWithholdingsOptionRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxWithholdingsOptionRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxWithholdingsOptionRelationMutation, { data, loading, error }] = useUpdateTaxWithholdingsOptionRelationMutation({
 *   variables: {
 *      financing_option_grant_uuid: // value for 'financing_option_grant_uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTaxWithholdingsOptionRelationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateTaxWithholdingsOptionRelationMutation,
    Types.UpdateTaxWithholdingsOptionRelationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateTaxWithholdingsOptionRelationMutation,
    Types.UpdateTaxWithholdingsOptionRelationMutationVariables
  >(UpdateTaxWithholdingsOptionRelationDocument, options);
}
export type UpdateTaxWithholdingsOptionRelationMutationHookResult = ReturnType<
  typeof useUpdateTaxWithholdingsOptionRelationMutation
>;
export type UpdateTaxWithholdingsOptionRelationMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTaxWithholdingsOptionRelationMutation>;
export type UpdateTaxWithholdingsOptionRelationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateTaxWithholdingsOptionRelationMutation,
  Types.UpdateTaxWithholdingsOptionRelationMutationVariables
>;
export const GetW9FormDocument = gql`
  query GetW9Form($financing_object: String!) {
    w9Form(financing_object: $financing_object) {
      ...W9Form
    }
  }
  ${W9FormFragmentDoc}
`;

/**
 * __useGetW9FormQuery__
 *
 * To run a query within a React component, call `useGetW9FormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetW9FormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetW9FormQuery({
 *   variables: {
 *      financing_object: // value for 'financing_object'
 *   },
 * });
 */
export function useGetW9FormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetW9FormQuery,
    Types.GetW9FormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetW9FormQuery,
    Types.GetW9FormQueryVariables
  >(GetW9FormDocument, options);
}
export function useGetW9FormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetW9FormQuery,
    Types.GetW9FormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetW9FormQuery,
    Types.GetW9FormQueryVariables
  >(GetW9FormDocument, options);
}
export type GetW9FormQueryHookResult = ReturnType<typeof useGetW9FormQuery>;
export type GetW9FormLazyQueryHookResult = ReturnType<
  typeof useGetW9FormLazyQuery
>;
export type GetW9FormQueryResult = ApolloReactCommon.QueryResult<
  Types.GetW9FormQuery,
  Types.GetW9FormQueryVariables
>;
export const RequestProposalRegenerationDocument = gql`
  mutation RequestProposalRegeneration($uuid: ID!) {
    requestProposalRegeneration(uuid: $uuid) {
      uuid
      regeneration_requested
    }
  }
`;
export type RequestProposalRegenerationMutationFn = ApolloReactCommon.MutationFunction<
  Types.RequestProposalRegenerationMutation,
  Types.RequestProposalRegenerationMutationVariables
>;

/**
 * __useRequestProposalRegenerationMutation__
 *
 * To run a mutation, you first call `useRequestProposalRegenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestProposalRegenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestProposalRegenerationMutation, { data, loading, error }] = useRequestProposalRegenerationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRequestProposalRegenerationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RequestProposalRegenerationMutation,
    Types.RequestProposalRegenerationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.RequestProposalRegenerationMutation,
    Types.RequestProposalRegenerationMutationVariables
  >(RequestProposalRegenerationDocument, options);
}
export type RequestProposalRegenerationMutationHookResult = ReturnType<
  typeof useRequestProposalRegenerationMutation
>;
export type RequestProposalRegenerationMutationResult = ApolloReactCommon.MutationResult<Types.RequestProposalRegenerationMutation>;
export type RequestProposalRegenerationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RequestProposalRegenerationMutation,
  Types.RequestProposalRegenerationMutationVariables
>;
export const ConfirmAcceptProposalDocument = gql`
  mutation ConfirmAcceptProposal(
    $financingObjectUuid: ID!
    $proposalUuid: ID!
  ) {
    acceptProposal(
      financingObjectUuid: $financingObjectUuid
      proposalUuid: $proposalUuid
    ) {
      ...FinancingObject
      selected_proposal {
        ...ProposalSummary
      }
    }
  }
  ${FinancingObjectFragmentDoc}
  ${ProposalSummaryFragmentDoc}
`;
export type ConfirmAcceptProposalMutationFn = ApolloReactCommon.MutationFunction<
  Types.ConfirmAcceptProposalMutation,
  Types.ConfirmAcceptProposalMutationVariables
>;

/**
 * __useConfirmAcceptProposalMutation__
 *
 * To run a mutation, you first call `useConfirmAcceptProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAcceptProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAcceptProposalMutation, { data, loading, error }] = useConfirmAcceptProposalMutation({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *      proposalUuid: // value for 'proposalUuid'
 *   },
 * });
 */
export function useConfirmAcceptProposalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ConfirmAcceptProposalMutation,
    Types.ConfirmAcceptProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ConfirmAcceptProposalMutation,
    Types.ConfirmAcceptProposalMutationVariables
  >(ConfirmAcceptProposalDocument, options);
}
export type ConfirmAcceptProposalMutationHookResult = ReturnType<
  typeof useConfirmAcceptProposalMutation
>;
export type ConfirmAcceptProposalMutationResult = ApolloReactCommon.MutationResult<Types.ConfirmAcceptProposalMutation>;
export type ConfirmAcceptProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ConfirmAcceptProposalMutation,
  Types.ConfirmAcceptProposalMutationVariables
>;
export const CreateProposalRequestDocument = gql`
  mutation CreateProposalRequest($payload: ProposalRequestCreateInput!) {
    proposalRequest: createProposalRequest(payload: $payload) {
      ...ProposalRequestFragment
    }
  }
  ${ProposalRequestFragmentFragmentDoc}
`;
export type CreateProposalRequestMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateProposalRequestMutation,
  Types.CreateProposalRequestMutationVariables
>;

/**
 * __useCreateProposalRequestMutation__
 *
 * To run a mutation, you first call `useCreateProposalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalRequestMutation, { data, loading, error }] = useCreateProposalRequestMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateProposalRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateProposalRequestMutation,
    Types.CreateProposalRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateProposalRequestMutation,
    Types.CreateProposalRequestMutationVariables
  >(CreateProposalRequestDocument, options);
}
export type CreateProposalRequestMutationHookResult = ReturnType<
  typeof useCreateProposalRequestMutation
>;
export type CreateProposalRequestMutationResult = ApolloReactCommon.MutationResult<Types.CreateProposalRequestMutation>;
export type CreateProposalRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateProposalRequestMutation,
  Types.CreateProposalRequestMutationVariables
>;
export const InitContractSigningDocument = gql`
  mutation InitContractSigning($payload: SignContractInputType!, $uuid: UUID!) {
    initContractSigning: initContractSigning(payload: $payload, uuid: $uuid) {
      docusign_url
    }
  }
`;
export type InitContractSigningMutationFn = ApolloReactCommon.MutationFunction<
  Types.InitContractSigningMutation,
  Types.InitContractSigningMutationVariables
>;

/**
 * __useInitContractSigningMutation__
 *
 * To run a mutation, you first call `useInitContractSigningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitContractSigningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initContractSigningMutation, { data, loading, error }] = useInitContractSigningMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useInitContractSigningMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InitContractSigningMutation,
    Types.InitContractSigningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.InitContractSigningMutation,
    Types.InitContractSigningMutationVariables
  >(InitContractSigningDocument, options);
}
export type InitContractSigningMutationHookResult = ReturnType<
  typeof useInitContractSigningMutation
>;
export type InitContractSigningMutationResult = ApolloReactCommon.MutationResult<Types.InitContractSigningMutation>;
export type InitContractSigningMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InitContractSigningMutation,
  Types.InitContractSigningMutationVariables
>;
export const ProcessScheduledCallDocument = gql`
  mutation ProcessScheduledCall(
    $financingObjectUuid: ID!
    $calendlyEventUri: String!
    $inviteeUri: String!
    $callType: CallType!
    $scheduledAppointmentUuid: ID!
  ) {
    processScheduledCall(
      financingObjectUuid: $financingObjectUuid
      calendlyEventUri: $calendlyEventUri
      inviteeUri: $inviteeUri
      callType: $callType
      scheduledAppointmentUuid: $scheduledAppointmentUuid
    ) {
      ...FinancingObject
    }
  }
  ${FinancingObjectFragmentDoc}
`;
export type ProcessScheduledCallMutationFn = ApolloReactCommon.MutationFunction<
  Types.ProcessScheduledCallMutation,
  Types.ProcessScheduledCallMutationVariables
>;

/**
 * __useProcessScheduledCallMutation__
 *
 * To run a mutation, you first call `useProcessScheduledCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessScheduledCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processScheduledCallMutation, { data, loading, error }] = useProcessScheduledCallMutation({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *      calendlyEventUri: // value for 'calendlyEventUri'
 *      inviteeUri: // value for 'inviteeUri'
 *      callType: // value for 'callType'
 *      scheduledAppointmentUuid: // value for 'scheduledAppointmentUuid'
 *   },
 * });
 */
export function useProcessScheduledCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ProcessScheduledCallMutation,
    Types.ProcessScheduledCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ProcessScheduledCallMutation,
    Types.ProcessScheduledCallMutationVariables
  >(ProcessScheduledCallDocument, options);
}
export type ProcessScheduledCallMutationHookResult = ReturnType<
  typeof useProcessScheduledCallMutation
>;
export type ProcessScheduledCallMutationResult = ApolloReactCommon.MutationResult<Types.ProcessScheduledCallMutation>;
export type ProcessScheduledCallMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ProcessScheduledCallMutation,
  Types.ProcessScheduledCallMutationVariables
>;
export const SubmitFinancingOnboardingFormDocument = gql`
  mutation SubmitFinancingOnboardingForm(
    $financing_object_uuid: String!
    $form_name: String!
  ) {
    submitForm(financing_object: $financing_object_uuid, form_name: $form_name)
  }
`;
export type SubmitFinancingOnboardingFormMutationFn = ApolloReactCommon.MutationFunction<
  Types.SubmitFinancingOnboardingFormMutation,
  Types.SubmitFinancingOnboardingFormMutationVariables
>;

/**
 * __useSubmitFinancingOnboardingFormMutation__
 *
 * To run a mutation, you first call `useSubmitFinancingOnboardingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFinancingOnboardingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFinancingOnboardingFormMutation, { data, loading, error }] = useSubmitFinancingOnboardingFormMutation({
 *   variables: {
 *      financing_object_uuid: // value for 'financing_object_uuid'
 *      form_name: // value for 'form_name'
 *   },
 * });
 */
export function useSubmitFinancingOnboardingFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SubmitFinancingOnboardingFormMutation,
    Types.SubmitFinancingOnboardingFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.SubmitFinancingOnboardingFormMutation,
    Types.SubmitFinancingOnboardingFormMutationVariables
  >(SubmitFinancingOnboardingFormDocument, options);
}
export type SubmitFinancingOnboardingFormMutationHookResult = ReturnType<
  typeof useSubmitFinancingOnboardingFormMutation
>;
export type SubmitFinancingOnboardingFormMutationResult = ApolloReactCommon.MutationResult<Types.SubmitFinancingOnboardingFormMutation>;
export type SubmitFinancingOnboardingFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SubmitFinancingOnboardingFormMutation,
  Types.SubmitFinancingOnboardingFormMutationVariables
>;
export const UpdateProposalRequestDocument = gql`
  mutation UpdateProposalRequest(
    $payload: ProposalRequestInput!
    $uuid: String!
  ) {
    proposalRequest: updateProposalRequest(payload: $payload, uuid: $uuid) {
      ...ProposalRequestFragment
    }
  }
  ${ProposalRequestFragmentFragmentDoc}
`;
export type UpdateProposalRequestMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateProposalRequestMutation,
  Types.UpdateProposalRequestMutationVariables
>;

/**
 * __useUpdateProposalRequestMutation__
 *
 * To run a mutation, you first call `useUpdateProposalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalRequestMutation, { data, loading, error }] = useUpdateProposalRequestMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateProposalRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateProposalRequestMutation,
    Types.UpdateProposalRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateProposalRequestMutation,
    Types.UpdateProposalRequestMutationVariables
  >(UpdateProposalRequestDocument, options);
}
export type UpdateProposalRequestMutationHookResult = ReturnType<
  typeof useUpdateProposalRequestMutation
>;
export type UpdateProposalRequestMutationResult = ApolloReactCommon.MutationResult<Types.UpdateProposalRequestMutation>;
export type UpdateProposalRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateProposalRequestMutation,
  Types.UpdateProposalRequestMutationVariables
>;
export const GetSeriesContractDocument = gql`
  query GetSeriesContract($uuid: String!) {
    seriesContract(financing_object: $uuid) {
      ...GenericContract
    }
  }
  ${GenericContractFragmentDoc}
`;

/**
 * __useGetSeriesContractQuery__
 *
 * To run a query within a React component, call `useGetSeriesContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesContractQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSeriesContractQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetSeriesContractQuery,
    Types.GetSeriesContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetSeriesContractQuery,
    Types.GetSeriesContractQueryVariables
  >(GetSeriesContractDocument, options);
}
export function useGetSeriesContractLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetSeriesContractQuery,
    Types.GetSeriesContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetSeriesContractQuery,
    Types.GetSeriesContractQueryVariables
  >(GetSeriesContractDocument, options);
}
export type GetSeriesContractQueryHookResult = ReturnType<
  typeof useGetSeriesContractQuery
>;
export type GetSeriesContractLazyQueryHookResult = ReturnType<
  typeof useGetSeriesContractLazyQuery
>;
export type GetSeriesContractQueryResult = ApolloReactCommon.QueryResult<
  Types.GetSeriesContractQuery,
  Types.GetSeriesContractQueryVariables
>;
export const GetInvestmentDirectionLetterDocument = gql`
  query GetInvestmentDirectionLetter($uuid: String!) {
    investmentDirectionLetter(financing_object: $uuid) {
      ...GenericContract
    }
  }
  ${GenericContractFragmentDoc}
`;

/**
 * __useGetInvestmentDirectionLetterQuery__
 *
 * To run a query within a React component, call `useGetInvestmentDirectionLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestmentDirectionLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestmentDirectionLetterQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetInvestmentDirectionLetterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetInvestmentDirectionLetterQuery,
    Types.GetInvestmentDirectionLetterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetInvestmentDirectionLetterQuery,
    Types.GetInvestmentDirectionLetterQueryVariables
  >(GetInvestmentDirectionLetterDocument, options);
}
export function useGetInvestmentDirectionLetterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetInvestmentDirectionLetterQuery,
    Types.GetInvestmentDirectionLetterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetInvestmentDirectionLetterQuery,
    Types.GetInvestmentDirectionLetterQueryVariables
  >(GetInvestmentDirectionLetterDocument, options);
}
export type GetInvestmentDirectionLetterQueryHookResult = ReturnType<
  typeof useGetInvestmentDirectionLetterQuery
>;
export type GetInvestmentDirectionLetterLazyQueryHookResult = ReturnType<
  typeof useGetInvestmentDirectionLetterLazyQuery
>;
export type GetInvestmentDirectionLetterQueryResult = ApolloReactCommon.QueryResult<
  Types.GetInvestmentDirectionLetterQuery,
  Types.GetInvestmentDirectionLetterQueryVariables
>;
export const GetSpringingLienAssignmentAgreementDocument = gql`
  query GetSpringingLienAssignmentAgreement($uuid: String!) {
    SpringingLienAssignmentAgreement(financing_object: $uuid) {
      uuid
      status
      client_signed_at
      file {
        url
      }
    }
  }
`;

/**
 * __useGetSpringingLienAssignmentAgreementQuery__
 *
 * To run a query within a React component, call `useGetSpringingLienAssignmentAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpringingLienAssignmentAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpringingLienAssignmentAgreementQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSpringingLienAssignmentAgreementQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetSpringingLienAssignmentAgreementQuery,
    Types.GetSpringingLienAssignmentAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetSpringingLienAssignmentAgreementQuery,
    Types.GetSpringingLienAssignmentAgreementQueryVariables
  >(GetSpringingLienAssignmentAgreementDocument, options);
}
export function useGetSpringingLienAssignmentAgreementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetSpringingLienAssignmentAgreementQuery,
    Types.GetSpringingLienAssignmentAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetSpringingLienAssignmentAgreementQuery,
    Types.GetSpringingLienAssignmentAgreementQueryVariables
  >(GetSpringingLienAssignmentAgreementDocument, options);
}
export type GetSpringingLienAssignmentAgreementQueryHookResult = ReturnType<
  typeof useGetSpringingLienAssignmentAgreementQuery
>;
export type GetSpringingLienAssignmentAgreementLazyQueryHookResult = ReturnType<
  typeof useGetSpringingLienAssignmentAgreementLazyQuery
>;
export type GetSpringingLienAssignmentAgreementQueryResult = ApolloReactCommon.QueryResult<
  Types.GetSpringingLienAssignmentAgreementQuery,
  Types.GetSpringingLienAssignmentAgreementQueryVariables
>;
export const GetSpringingLienPscDocument = gql`
  query GetSpringingLienPSC($uuid: String!) {
    SpringingLienPSC(financing_object: $uuid) {
      uuid
      status
      client_signed_at
      file {
        url
      }
    }
  }
`;

/**
 * __useGetSpringingLienPscQuery__
 *
 * To run a query within a React component, call `useGetSpringingLienPscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpringingLienPscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpringingLienPscQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSpringingLienPscQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetSpringingLienPscQuery,
    Types.GetSpringingLienPscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetSpringingLienPscQuery,
    Types.GetSpringingLienPscQueryVariables
  >(GetSpringingLienPscDocument, options);
}
export function useGetSpringingLienPscLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetSpringingLienPscQuery,
    Types.GetSpringingLienPscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetSpringingLienPscQuery,
    Types.GetSpringingLienPscQueryVariables
  >(GetSpringingLienPscDocument, options);
}
export type GetSpringingLienPscQueryHookResult = ReturnType<
  typeof useGetSpringingLienPscQuery
>;
export type GetSpringingLienPscLazyQueryHookResult = ReturnType<
  typeof useGetSpringingLienPscLazyQuery
>;
export type GetSpringingLienPscQueryResult = ApolloReactCommon.QueryResult<
  Types.GetSpringingLienPscQuery,
  Types.GetSpringingLienPscQueryVariables
>;
export const GetSpringingLienStockPledgeDocument = gql`
  query GetSpringingLienStockPledge($uuid: String!) {
    SpringingLienStockPledge(financing_object: $uuid) {
      uuid
      status
      client_signed_at
      file {
        url
      }
    }
  }
`;

/**
 * __useGetSpringingLienStockPledgeQuery__
 *
 * To run a query within a React component, call `useGetSpringingLienStockPledgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpringingLienStockPledgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpringingLienStockPledgeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSpringingLienStockPledgeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetSpringingLienStockPledgeQuery,
    Types.GetSpringingLienStockPledgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetSpringingLienStockPledgeQuery,
    Types.GetSpringingLienStockPledgeQueryVariables
  >(GetSpringingLienStockPledgeDocument, options);
}
export function useGetSpringingLienStockPledgeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetSpringingLienStockPledgeQuery,
    Types.GetSpringingLienStockPledgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetSpringingLienStockPledgeQuery,
    Types.GetSpringingLienStockPledgeQueryVariables
  >(GetSpringingLienStockPledgeDocument, options);
}
export type GetSpringingLienStockPledgeQueryHookResult = ReturnType<
  typeof useGetSpringingLienStockPledgeQuery
>;
export type GetSpringingLienStockPledgeLazyQueryHookResult = ReturnType<
  typeof useGetSpringingLienStockPledgeLazyQuery
>;
export type GetSpringingLienStockPledgeQueryResult = ApolloReactCommon.QueryResult<
  Types.GetSpringingLienStockPledgeQuery,
  Types.GetSpringingLienStockPledgeQueryVariables
>;
export const GetPrivateSecuritiesContractDocument = gql`
  query GetPrivateSecuritiesContract($uuid: String!) {
    privateSecuritiesContract(financing_object: $uuid) {
      uuid
      status
      client_signed_at
      file {
        url
      }
    }
  }
`;

/**
 * __useGetPrivateSecuritiesContractQuery__
 *
 * To run a query within a React component, call `useGetPrivateSecuritiesContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateSecuritiesContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateSecuritiesContractQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetPrivateSecuritiesContractQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPrivateSecuritiesContractQuery,
    Types.GetPrivateSecuritiesContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPrivateSecuritiesContractQuery,
    Types.GetPrivateSecuritiesContractQueryVariables
  >(GetPrivateSecuritiesContractDocument, options);
}
export function useGetPrivateSecuritiesContractLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPrivateSecuritiesContractQuery,
    Types.GetPrivateSecuritiesContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPrivateSecuritiesContractQuery,
    Types.GetPrivateSecuritiesContractQueryVariables
  >(GetPrivateSecuritiesContractDocument, options);
}
export type GetPrivateSecuritiesContractQueryHookResult = ReturnType<
  typeof useGetPrivateSecuritiesContractQuery
>;
export type GetPrivateSecuritiesContractLazyQueryHookResult = ReturnType<
  typeof useGetPrivateSecuritiesContractLazyQuery
>;
export type GetPrivateSecuritiesContractQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPrivateSecuritiesContractQuery,
  Types.GetPrivateSecuritiesContractQueryVariables
>;
export const GetFinancingCategoryStatusesDocument = gql`
  query GetFinancingCategoryStatuses($financingObjectUuid: String!) {
    financingCategoryStatuses: allFinancingCategoryStatuses(
      financingObjectUuid: $financingObjectUuid
    ) {
      ...FinancingCategoryStatus
    }
  }
  ${FinancingCategoryStatusFragmentDoc}
`;

/**
 * __useGetFinancingCategoryStatusesQuery__
 *
 * To run a query within a React component, call `useGetFinancingCategoryStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingCategoryStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingCategoryStatusesQuery({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *   },
 * });
 */
export function useGetFinancingCategoryStatusesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingCategoryStatusesQuery,
    Types.GetFinancingCategoryStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingCategoryStatusesQuery,
    Types.GetFinancingCategoryStatusesQueryVariables
  >(GetFinancingCategoryStatusesDocument, options);
}
export function useGetFinancingCategoryStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingCategoryStatusesQuery,
    Types.GetFinancingCategoryStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingCategoryStatusesQuery,
    Types.GetFinancingCategoryStatusesQueryVariables
  >(GetFinancingCategoryStatusesDocument, options);
}
export type GetFinancingCategoryStatusesQueryHookResult = ReturnType<
  typeof useGetFinancingCategoryStatusesQuery
>;
export type GetFinancingCategoryStatusesLazyQueryHookResult = ReturnType<
  typeof useGetFinancingCategoryStatusesLazyQuery
>;
export type GetFinancingCategoryStatusesQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingCategoryStatusesQuery,
  Types.GetFinancingCategoryStatusesQueryVariables
>;
export const GetProposalDocument = gql`
  query GetProposal($uuid: String!) {
    proposal: Proposal(uuid: $uuid) {
      uuid
      issued_date
      published
      is_expired
      regeneration_requested
      name
      proposal_reason
      country
      subdivision
      filing_type
      dependents
      income {
        ...CurrencyFragment
      }
      four09a_share_value {
        ...CurrencyFragment
      }
      exercise_need {
        ...CurrencyFragment
      }
      exercise_cost {
        ...CurrencyFragment
      }
      taxes {
        ...CurrencyFragment
      }
      platform_fee {
        ...CurrencyFragment
      }
      platform_fee_percentage
      collateral_shares
      collateral_value {
        ...CurrencyFragment
      }
      value_per_share {
        ...CurrencyFragment
      }
      advance_provided {
        ...CurrencyFragment
      }
      average_strike_price {
        ...CurrencyFragment
      }
      total_financing_need {
        ...CurrencyFragment
      }
      liquidity {
        ...CurrencyFragment
      }
      advance_to_equity_ratio
      stock_fee
      advance_rate
      rampup_fees {
        equity_rate
        month
      }
      net_rampup_fees {
        equity_rate
        month
      }
      interpolated_rampup_fees {
        equity_rate
        month
      }
      net_stock_fee
      net_advance_rate
      expected_exit_share_value {
        ...CurrencyFragment
      }
      expected_exit_date
      show_proposal_breakdown
      expiration_date
      options_to_exercise
      trust_setup_fee {
        ...CurrencyFragment
      }
      trust_closing_fee {
        ...CurrencyFragment
      }
      trust_monthly_administrative_fee {
        ...CurrencyFragment
      }
      net_rate_components {
        capital_gains_tax_rate
        state_capital_gains_tax_rate
        net_investment_income_tax_rate
      }
      show_waiting_for_ipo_comparison
      one_year_peg
      option_grants {
        ...SelectedOptionGrantFragment
      }
      share_grants {
        ...SelectedShareGrantFragment
      }
      is_trust_fee_included
      proposal_request {
        uuid
      }
    }
  }
  ${CurrencyFragmentFragmentDoc}
  ${SelectedOptionGrantFragmentFragmentDoc}
  ${SelectedShareGrantFragmentFragmentDoc}
`;

/**
 * __useGetProposalQuery__
 *
 * To run a query within a React component, call `useGetProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProposalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetProposalQuery,
    Types.GetProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetProposalQuery,
    Types.GetProposalQueryVariables
  >(GetProposalDocument, options);
}
export function useGetProposalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetProposalQuery,
    Types.GetProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetProposalQuery,
    Types.GetProposalQueryVariables
  >(GetProposalDocument, options);
}
export type GetProposalQueryHookResult = ReturnType<typeof useGetProposalQuery>;
export type GetProposalLazyQueryHookResult = ReturnType<
  typeof useGetProposalLazyQuery
>;
export type GetProposalQueryResult = ApolloReactCommon.QueryResult<
  Types.GetProposalQuery,
  Types.GetProposalQueryVariables
>;
export const GetProposalExitComparisonDocument = gql`
  query GetProposalExitComparison(
    $uuid: String!
    $payload: ProposalTaxDetailsInput!
  ) {
    tax: ProposalTaxDetails(uuid: $uuid, payload: $payload) {
      exit_comparison {
        exit_scenarios {
          share_price_multiplier
          share_price
          valuation
          secfi_scenario_borrower_returns
          cashless_borrower_returns
          difference_nominal
          difference_percentage
        }
      }
    }
  }
`;

/**
 * __useGetProposalExitComparisonQuery__
 *
 * To run a query within a React component, call `useGetProposalExitComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalExitComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalExitComparisonQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetProposalExitComparisonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetProposalExitComparisonQuery,
    Types.GetProposalExitComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetProposalExitComparisonQuery,
    Types.GetProposalExitComparisonQueryVariables
  >(GetProposalExitComparisonDocument, options);
}
export function useGetProposalExitComparisonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetProposalExitComparisonQuery,
    Types.GetProposalExitComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetProposalExitComparisonQuery,
    Types.GetProposalExitComparisonQueryVariables
  >(GetProposalExitComparisonDocument, options);
}
export type GetProposalExitComparisonQueryHookResult = ReturnType<
  typeof useGetProposalExitComparisonQuery
>;
export type GetProposalExitComparisonLazyQueryHookResult = ReturnType<
  typeof useGetProposalExitComparisonLazyQuery
>;
export type GetProposalExitComparisonQueryResult = ApolloReactCommon.QueryResult<
  Types.GetProposalExitComparisonQuery,
  Types.GetProposalExitComparisonQueryVariables
>;
export const GetProposalRequestDocument = gql`
  query GetProposalRequest($uuid: String!) {
    proposalRequest: ProposalRequest(uuid: $uuid) {
      ...ProposalRequestFragment
    }
  }
  ${ProposalRequestFragmentFragmentDoc}
`;

/**
 * __useGetProposalRequestQuery__
 *
 * To run a query within a React component, call `useGetProposalRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalRequestQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProposalRequestQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetProposalRequestQuery,
    Types.GetProposalRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetProposalRequestQuery,
    Types.GetProposalRequestQueryVariables
  >(GetProposalRequestDocument, options);
}
export function useGetProposalRequestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetProposalRequestQuery,
    Types.GetProposalRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetProposalRequestQuery,
    Types.GetProposalRequestQueryVariables
  >(GetProposalRequestDocument, options);
}
export type GetProposalRequestQueryHookResult = ReturnType<
  typeof useGetProposalRequestQuery
>;
export type GetProposalRequestLazyQueryHookResult = ReturnType<
  typeof useGetProposalRequestLazyQuery
>;
export type GetProposalRequestQueryResult = ApolloReactCommon.QueryResult<
  Types.GetProposalRequestQuery,
  Types.GetProposalRequestQueryVariables
>;
export const GetProposalTaxDetailsDocument = gql`
  query GetProposalTaxDetails(
    $uuid: String!
    $payload: ProposalTaxDetailsInput!
  ) {
    tax: ProposalTaxDetails(uuid: $uuid, payload: $payload) {
      self_fund_scenario {
        exercise_tax_breakdown {
          ...TaxBreakdownFragment
        }
      }
      secfi_financing_scenario {
        exit_tax_details {
          total_capital_gains_tax
          long_term_capital_gains
          effective_tax_rates {
            state_capital_gains_tax
            net_investment_income_tax
            capital_gains_tax
            total_capital_gains_tax
          }
        }
        collateral_shares
        borrower_returns
        exit_equity_value
        financing_plus_interest
        compounded_interest
        investor_fees
        investor_returns
        long_term_capital_gains_on_exit_from_shares
        long_term_capital_gains_on_exit_from_contract
        long_term_capital_gains_on_exit
        total_stock_fee
        exit_tax_breakdown {
          ...TaxBreakdownFragment
        }
      }
      trust_details {
        trust_setup_fee {
          ...CurrencyFragment
        }
        trust_closing_fee {
          ...CurrencyFragment
        }
        trust_monthly_administrative_fee {
          ...CurrencyFragment
        }
        trust_fees_between_today_and_exit {
          ...CurrencyFragment
        }
        trust_monthly_fees_until_exit {
          ...CurrencyFragment
        }
      }
      after_tax_returns {
        ...CurrencyFragment
      }
      cash_left_over_on_exit {
        ...CurrencyFragment
      }
      exercise_on_exit_scenario {
        total_exercise_cost
        total_tax_owed_on_exercise
        total_tax_owed_on_exit
        borrower_returns
      }
    }
  }
  ${TaxBreakdownFragmentFragmentDoc}
  ${CurrencyFragmentFragmentDoc}
`;

/**
 * __useGetProposalTaxDetailsQuery__
 *
 * To run a query within a React component, call `useGetProposalTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalTaxDetailsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetProposalTaxDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetProposalTaxDetailsQuery,
    Types.GetProposalTaxDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetProposalTaxDetailsQuery,
    Types.GetProposalTaxDetailsQueryVariables
  >(GetProposalTaxDetailsDocument, options);
}
export function useGetProposalTaxDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetProposalTaxDetailsQuery,
    Types.GetProposalTaxDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetProposalTaxDetailsQuery,
    Types.GetProposalTaxDetailsQueryVariables
  >(GetProposalTaxDetailsDocument, options);
}
export type GetProposalTaxDetailsQueryHookResult = ReturnType<
  typeof useGetProposalTaxDetailsQuery
>;
export type GetProposalTaxDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProposalTaxDetailsLazyQuery
>;
export type GetProposalTaxDetailsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetProposalTaxDetailsQuery,
  Types.GetProposalTaxDetailsQueryVariables
>;
export const AddSecondariesRequestDocument = gql`
  mutation AddSecondariesRequest($input: SecondariesRequestInput!) {
    addSecondariesRequest(input: $input) {
      uuid
      shares_available_for_sale
      offer_price
      offer_intent
      issuer_allows_sale
    }
  }
`;
export type AddSecondariesRequestMutationFn = ApolloReactCommon.MutationFunction<
  Types.AddSecondariesRequestMutation,
  Types.AddSecondariesRequestMutationVariables
>;

/**
 * __useAddSecondariesRequestMutation__
 *
 * To run a mutation, you first call `useAddSecondariesRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSecondariesRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSecondariesRequestMutation, { data, loading, error }] = useAddSecondariesRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSecondariesRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AddSecondariesRequestMutation,
    Types.AddSecondariesRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.AddSecondariesRequestMutation,
    Types.AddSecondariesRequestMutationVariables
  >(AddSecondariesRequestDocument, options);
}
export type AddSecondariesRequestMutationHookResult = ReturnType<
  typeof useAddSecondariesRequestMutation
>;
export type AddSecondariesRequestMutationResult = ApolloReactCommon.MutationResult<Types.AddSecondariesRequestMutation>;
export type AddSecondariesRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AddSecondariesRequestMutation,
  Types.AddSecondariesRequestMutationVariables
>;
export const UpdateFinancingObjectDocument = gql`
  mutation UpdateFinancingObject(
    $uuid: String!
    $payload: FinancingObjectUpdateInput!
  ) {
    updateFinancingObject: updateFinancingObject(
      uuid: $uuid
      payload: $payload
    ) {
      uuid
      last_seen
    }
  }
`;
export type UpdateFinancingObjectMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateFinancingObjectMutation,
  Types.UpdateFinancingObjectMutationVariables
>;

/**
 * __useUpdateFinancingObjectMutation__
 *
 * To run a mutation, you first call `useUpdateFinancingObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancingObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancingObjectMutation, { data, loading, error }] = useUpdateFinancingObjectMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateFinancingObjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateFinancingObjectMutation,
    Types.UpdateFinancingObjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateFinancingObjectMutation,
    Types.UpdateFinancingObjectMutationVariables
  >(UpdateFinancingObjectDocument, options);
}
export type UpdateFinancingObjectMutationHookResult = ReturnType<
  typeof useUpdateFinancingObjectMutation
>;
export type UpdateFinancingObjectMutationResult = ApolloReactCommon.MutationResult<Types.UpdateFinancingObjectMutation>;
export type UpdateFinancingObjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateFinancingObjectMutation,
  Types.UpdateFinancingObjectMutationVariables
>;
export const UpdateSecondariesRequestDocument = gql`
  mutation UpdateSecondariesRequest(
    $payload: updateSecondariesRequestInput!
    $uuid: UUID!
  ) {
    updateSecondariesRequest(payload: $payload, uuid: $uuid) {
      uuid
      shares_available_for_sale
      offer_price
      offer_intent
      issuer_allows_sale
      affiliation
    }
  }
`;
export type UpdateSecondariesRequestMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateSecondariesRequestMutation,
  Types.UpdateSecondariesRequestMutationVariables
>;

/**
 * __useUpdateSecondariesRequestMutation__
 *
 * To run a mutation, you first call `useUpdateSecondariesRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSecondariesRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSecondariesRequestMutation, { data, loading, error }] = useUpdateSecondariesRequestMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateSecondariesRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateSecondariesRequestMutation,
    Types.UpdateSecondariesRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateSecondariesRequestMutation,
    Types.UpdateSecondariesRequestMutationVariables
  >(UpdateSecondariesRequestDocument, options);
}
export type UpdateSecondariesRequestMutationHookResult = ReturnType<
  typeof useUpdateSecondariesRequestMutation
>;
export type UpdateSecondariesRequestMutationResult = ApolloReactCommon.MutationResult<Types.UpdateSecondariesRequestMutation>;
export type UpdateSecondariesRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateSecondariesRequestMutation,
  Types.UpdateSecondariesRequestMutationVariables
>;
export const GetFinancingBasicInfoDocument = gql`
  query GetFinancingBasicInfo($uuid: String!) {
    financingObject: FinancingObject(uuid: $uuid) {
      ...FinancingObject
      selected_proposal {
        uuid
        collateral_shares
        advance_provided {
          ...CurrencyFragment
        }
        advance_rate
        stock_fee
      }
    }
  }
  ${FinancingObjectFragmentDoc}
  ${CurrencyFragmentFragmentDoc}
`;

/**
 * __useGetFinancingBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetFinancingBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingBasicInfoQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetFinancingBasicInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingBasicInfoQuery,
    Types.GetFinancingBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingBasicInfoQuery,
    Types.GetFinancingBasicInfoQueryVariables
  >(GetFinancingBasicInfoDocument, options);
}
export function useGetFinancingBasicInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingBasicInfoQuery,
    Types.GetFinancingBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingBasicInfoQuery,
    Types.GetFinancingBasicInfoQueryVariables
  >(GetFinancingBasicInfoDocument, options);
}
export type GetFinancingBasicInfoQueryHookResult = ReturnType<
  typeof useGetFinancingBasicInfoQuery
>;
export type GetFinancingBasicInfoLazyQueryHookResult = ReturnType<
  typeof useGetFinancingBasicInfoLazyQuery
>;
export type GetFinancingBasicInfoQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingBasicInfoQuery,
  Types.GetFinancingBasicInfoQueryVariables
>;
export const GetFinancingFormStatusesDocument = gql`
  query GetFinancingFormStatuses($financingObjectUuid: String!) {
    financingFormStatuses: allFinancingFormStatuses(
      financingObjectUuid: $financingObjectUuid
    ) {
      ...FinancingFormStatus
    }
  }
  ${FinancingFormStatusFragmentDoc}
`;

/**
 * __useGetFinancingFormStatusesQuery__
 *
 * To run a query within a React component, call `useGetFinancingFormStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingFormStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingFormStatusesQuery({
 *   variables: {
 *      financingObjectUuid: // value for 'financingObjectUuid'
 *   },
 * });
 */
export function useGetFinancingFormStatusesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingFormStatusesQuery,
    Types.GetFinancingFormStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingFormStatusesQuery,
    Types.GetFinancingFormStatusesQueryVariables
  >(GetFinancingFormStatusesDocument, options);
}
export function useGetFinancingFormStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingFormStatusesQuery,
    Types.GetFinancingFormStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingFormStatusesQuery,
    Types.GetFinancingFormStatusesQueryVariables
  >(GetFinancingFormStatusesDocument, options);
}
export type GetFinancingFormStatusesQueryHookResult = ReturnType<
  typeof useGetFinancingFormStatusesQuery
>;
export type GetFinancingFormStatusesLazyQueryHookResult = ReturnType<
  typeof useGetFinancingFormStatusesLazyQuery
>;
export type GetFinancingFormStatusesQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingFormStatusesQuery,
  Types.GetFinancingFormStatusesQueryVariables
>;
export const GetFinancingSummaryDocument = gql`
  query GetFinancingSummary($uuid: String!) {
    financingObject: FinancingObject(uuid: $uuid) {
      ...FinancingObject
      step_reason_group
      proposal_request {
        uuid
        exercise_date
        comment
        option_grants {
          start_date
          name
          option_type
          selected_quantity
          quantity
          strike_price {
            value
            currency
          }
        }
        affiliation {
          uuid
          company {
            uuid
            logo
            name
            legal_name
            cap_table_verified
            is_fast_track
          }
          customer {
            uuid
            user {
              ...UserInfoFragment
            }
            phone_number
          }
        }
        collateral_shares
      }
      selected_proposal {
        ...ProposalSummary
        contract_type
      }
    }
  }
  ${FinancingObjectFragmentDoc}
  ${UserInfoFragmentFragmentDoc}
  ${ProposalSummaryFragmentDoc}
`;

/**
 * __useGetFinancingSummaryQuery__
 *
 * To run a query within a React component, call `useGetFinancingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetFinancingSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetFinancingSummaryQuery,
    Types.GetFinancingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFinancingSummaryQuery,
    Types.GetFinancingSummaryQueryVariables
  >(GetFinancingSummaryDocument, options);
}
export function useGetFinancingSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFinancingSummaryQuery,
    Types.GetFinancingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFinancingSummaryQuery,
    Types.GetFinancingSummaryQueryVariables
  >(GetFinancingSummaryDocument, options);
}
export type GetFinancingSummaryQueryHookResult = ReturnType<
  typeof useGetFinancingSummaryQuery
>;
export type GetFinancingSummaryLazyQueryHookResult = ReturnType<
  typeof useGetFinancingSummaryLazyQuery
>;
export type GetFinancingSummaryQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFinancingSummaryQuery,
  Types.GetFinancingSummaryQueryVariables
>;
export const GetProposalsListSummaryDocument = gql`
  query GetProposalsListSummary($filters: ProposalInput) {
    proposals: allProposals(filters: $filters) {
      nodes {
        ...ProposalSummary
      }
    }
  }
  ${ProposalSummaryFragmentDoc}
`;

/**
 * __useGetProposalsListSummaryQuery__
 *
 * To run a query within a React component, call `useGetProposalsListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalsListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalsListSummaryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProposalsListSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetProposalsListSummaryQuery,
    Types.GetProposalsListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetProposalsListSummaryQuery,
    Types.GetProposalsListSummaryQueryVariables
  >(GetProposalsListSummaryDocument, options);
}
export function useGetProposalsListSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetProposalsListSummaryQuery,
    Types.GetProposalsListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetProposalsListSummaryQuery,
    Types.GetProposalsListSummaryQueryVariables
  >(GetProposalsListSummaryDocument, options);
}
export type GetProposalsListSummaryQueryHookResult = ReturnType<
  typeof useGetProposalsListSummaryQuery
>;
export type GetProposalsListSummaryLazyQueryHookResult = ReturnType<
  typeof useGetProposalsListSummaryLazyQuery
>;
export type GetProposalsListSummaryQueryResult = ApolloReactCommon.QueryResult<
  Types.GetProposalsListSummaryQuery,
  Types.GetProposalsListSummaryQueryVariables
>;
export const GetAllSecondariesRequestsDocument = gql`
  query GetAllSecondariesRequests($user: UUID!) {
    getAllSecondariesRequests(user: $user) {
      uuid
      shares_available_for_sale
      offer_price
      offer_intent
      issuer_allows_sale
      affiliation
    }
  }
`;

/**
 * __useGetAllSecondariesRequestsQuery__
 *
 * To run a query within a React component, call `useGetAllSecondariesRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSecondariesRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSecondariesRequestsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetAllSecondariesRequestsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetAllSecondariesRequestsQuery,
    Types.GetAllSecondariesRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllSecondariesRequestsQuery,
    Types.GetAllSecondariesRequestsQueryVariables
  >(GetAllSecondariesRequestsDocument, options);
}
export function useGetAllSecondariesRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllSecondariesRequestsQuery,
    Types.GetAllSecondariesRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllSecondariesRequestsQuery,
    Types.GetAllSecondariesRequestsQueryVariables
  >(GetAllSecondariesRequestsDocument, options);
}
export type GetAllSecondariesRequestsQueryHookResult = ReturnType<
  typeof useGetAllSecondariesRequestsQuery
>;
export type GetAllSecondariesRequestsLazyQueryHookResult = ReturnType<
  typeof useGetAllSecondariesRequestsLazyQuery
>;
export type GetAllSecondariesRequestsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllSecondariesRequestsQuery,
  Types.GetAllSecondariesRequestsQueryVariables
>;
export const GetGraphAndTableForAssetTypeDocument = gql`
  query GetGraphAndTableForAssetType(
    $customerId: UUID!
    $assetType: AssetType!
    $managedBy: PortfolioManagedBySelector
  ) {
    assetTypeOverview(
      customer: $customerId
      assetType: $assetType
      managedBy: $managedBy
    ) {
      allocationTable {
        totalValue
        items {
          ...AllocationTableEntry
        }
      }
      graph {
        totalValue {
          ...TimelinePoint
        }
        markers {
          ...GraphMarker
        }
      }
    }
  }
  ${AllocationTableEntryFragmentDoc}
  ${TimelinePointFragmentDoc}
  ${GraphMarkerFragmentDoc}
`;

/**
 * __useGetGraphAndTableForAssetTypeQuery__
 *
 * To run a query within a React component, call `useGetGraphAndTableForAssetTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphAndTableForAssetTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphAndTableForAssetTypeQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      assetType: // value for 'assetType'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetGraphAndTableForAssetTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetGraphAndTableForAssetTypeQuery,
    Types.GetGraphAndTableForAssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetGraphAndTableForAssetTypeQuery,
    Types.GetGraphAndTableForAssetTypeQueryVariables
  >(GetGraphAndTableForAssetTypeDocument, options);
}
export function useGetGraphAndTableForAssetTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetGraphAndTableForAssetTypeQuery,
    Types.GetGraphAndTableForAssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetGraphAndTableForAssetTypeQuery,
    Types.GetGraphAndTableForAssetTypeQueryVariables
  >(GetGraphAndTableForAssetTypeDocument, options);
}
export type GetGraphAndTableForAssetTypeQueryHookResult = ReturnType<
  typeof useGetGraphAndTableForAssetTypeQuery
>;
export type GetGraphAndTableForAssetTypeLazyQueryHookResult = ReturnType<
  typeof useGetGraphAndTableForAssetTypeLazyQuery
>;
export type GetGraphAndTableForAssetTypeQueryResult = ApolloReactCommon.QueryResult<
  Types.GetGraphAndTableForAssetTypeQuery,
  Types.GetGraphAndTableForAssetTypeQueryVariables
>;
export const GetSyncStatusForAssetTypeDocument = gql`
  query GetSyncStatusForAssetType(
    $customerId: UUID!
    $assetType: AssetType!
    $managedBy: PortfolioManagedBySelector
  ) {
    assetTypeOverview(
      customer: $customerId
      assetType: $assetType
      managedBy: $managedBy
    ) {
      syncStatus
      willNotifyReady
    }
  }
`;

/**
 * __useGetSyncStatusForAssetTypeQuery__
 *
 * To run a query within a React component, call `useGetSyncStatusForAssetTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSyncStatusForAssetTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSyncStatusForAssetTypeQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      assetType: // value for 'assetType'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetSyncStatusForAssetTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetSyncStatusForAssetTypeQuery,
    Types.GetSyncStatusForAssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetSyncStatusForAssetTypeQuery,
    Types.GetSyncStatusForAssetTypeQueryVariables
  >(GetSyncStatusForAssetTypeDocument, options);
}
export function useGetSyncStatusForAssetTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetSyncStatusForAssetTypeQuery,
    Types.GetSyncStatusForAssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetSyncStatusForAssetTypeQuery,
    Types.GetSyncStatusForAssetTypeQueryVariables
  >(GetSyncStatusForAssetTypeDocument, options);
}
export type GetSyncStatusForAssetTypeQueryHookResult = ReturnType<
  typeof useGetSyncStatusForAssetTypeQuery
>;
export type GetSyncStatusForAssetTypeLazyQueryHookResult = ReturnType<
  typeof useGetSyncStatusForAssetTypeLazyQuery
>;
export type GetSyncStatusForAssetTypeQueryResult = ApolloReactCommon.QueryResult<
  Types.GetSyncStatusForAssetTypeQuery,
  Types.GetSyncStatusForAssetTypeQueryVariables
>;
export const ExchangePublicTokenDocument = gql`
  mutation ExchangePublicToken($customerUuid: UUID!, $publicToken: String!) {
    exchangePublicToken(customer: $customerUuid, publicToken: $publicToken)
  }
`;
export type ExchangePublicTokenMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExchangePublicTokenMutation,
  Types.ExchangePublicTokenMutationVariables
>;

/**
 * __useExchangePublicTokenMutation__
 *
 * To run a mutation, you first call `useExchangePublicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangePublicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangePublicTokenMutation, { data, loading, error }] = useExchangePublicTokenMutation({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useExchangePublicTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExchangePublicTokenMutation,
    Types.ExchangePublicTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ExchangePublicTokenMutation,
    Types.ExchangePublicTokenMutationVariables
  >(ExchangePublicTokenDocument, options);
}
export type ExchangePublicTokenMutationHookResult = ReturnType<
  typeof useExchangePublicTokenMutation
>;
export type ExchangePublicTokenMutationResult = ApolloReactCommon.MutationResult<Types.ExchangePublicTokenMutation>;
export type ExchangePublicTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExchangePublicTokenMutation,
  Types.ExchangePublicTokenMutationVariables
>;
export const GetLinkTokenDocument = gql`
  mutation GetLinkToken($customer: UUID!) {
    token: getLinkToken(customer: $customer)
  }
`;
export type GetLinkTokenMutationFn = ApolloReactCommon.MutationFunction<
  Types.GetLinkTokenMutation,
  Types.GetLinkTokenMutationVariables
>;

/**
 * __useGetLinkTokenMutation__
 *
 * To run a mutation, you first call `useGetLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getLinkTokenMutation, { data, loading, error }] = useGetLinkTokenMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetLinkTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.GetLinkTokenMutation,
    Types.GetLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.GetLinkTokenMutation,
    Types.GetLinkTokenMutationVariables
  >(GetLinkTokenDocument, options);
}
export type GetLinkTokenMutationHookResult = ReturnType<
  typeof useGetLinkTokenMutation
>;
export type GetLinkTokenMutationResult = ApolloReactCommon.MutationResult<Types.GetLinkTokenMutation>;
export type GetLinkTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GetLinkTokenMutation,
  Types.GetLinkTokenMutationVariables
>;
export const GetLinkTokenForUpdateDocument = gql`
  mutation GetLinkTokenForUpdate($customer: UUID!, $institution: String!) {
    token: getLinkTokenForUpdate(customer: $customer, institution: $institution)
  }
`;
export type GetLinkTokenForUpdateMutationFn = ApolloReactCommon.MutationFunction<
  Types.GetLinkTokenForUpdateMutation,
  Types.GetLinkTokenForUpdateMutationVariables
>;

/**
 * __useGetLinkTokenForUpdateMutation__
 *
 * To run a mutation, you first call `useGetLinkTokenForUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetLinkTokenForUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getLinkTokenForUpdateMutation, { data, loading, error }] = useGetLinkTokenForUpdateMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      institution: // value for 'institution'
 *   },
 * });
 */
export function useGetLinkTokenForUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.GetLinkTokenForUpdateMutation,
    Types.GetLinkTokenForUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.GetLinkTokenForUpdateMutation,
    Types.GetLinkTokenForUpdateMutationVariables
  >(GetLinkTokenForUpdateDocument, options);
}
export type GetLinkTokenForUpdateMutationHookResult = ReturnType<
  typeof useGetLinkTokenForUpdateMutation
>;
export type GetLinkTokenForUpdateMutationResult = ApolloReactCommon.MutationResult<Types.GetLinkTokenForUpdateMutation>;
export type GetLinkTokenForUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GetLinkTokenForUpdateMutation,
  Types.GetLinkTokenForUpdateMutationVariables
>;
export const GetPortfolioPrivateEquityGraphDocument = gql`
  query getPortfolioPrivateEquityGraph(
    $customerUuid: UUID!
    $affiliationId: UUID!
  ) {
    affiliationGraph(customer: $customerUuid, affiliation: $affiliationId) {
      ...EquityGraph
    }
  }
  ${EquityGraphFragmentDoc}
`;

/**
 * __useGetPortfolioPrivateEquityGraphQuery__
 *
 * To run a query within a React component, call `useGetPortfolioPrivateEquityGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioPrivateEquityGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioPrivateEquityGraphQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *      affiliationId: // value for 'affiliationId'
 *   },
 * });
 */
export function useGetPortfolioPrivateEquityGraphQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPortfolioPrivateEquityGraphQuery,
    Types.GetPortfolioPrivateEquityGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPortfolioPrivateEquityGraphQuery,
    Types.GetPortfolioPrivateEquityGraphQueryVariables
  >(GetPortfolioPrivateEquityGraphDocument, options);
}
export function useGetPortfolioPrivateEquityGraphLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPortfolioPrivateEquityGraphQuery,
    Types.GetPortfolioPrivateEquityGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPortfolioPrivateEquityGraphQuery,
    Types.GetPortfolioPrivateEquityGraphQueryVariables
  >(GetPortfolioPrivateEquityGraphDocument, options);
}
export type GetPortfolioPrivateEquityGraphQueryHookResult = ReturnType<
  typeof useGetPortfolioPrivateEquityGraphQuery
>;
export type GetPortfolioPrivateEquityGraphLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioPrivateEquityGraphLazyQuery
>;
export type GetPortfolioPrivateEquityGraphQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPortfolioPrivateEquityGraphQuery,
  Types.GetPortfolioPrivateEquityGraphQueryVariables
>;
export const GetInstitutionsListDocument = gql`
  query getInstitutionsList($customer: UUID!) {
    customerInstitutions(customer: $customer) {
      ...Institution
    }
  }
  ${InstitutionFragmentDoc}
`;

/**
 * __useGetInstitutionsListQuery__
 *
 * To run a query within a React component, call `useGetInstitutionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstitutionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstitutionsListQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetInstitutionsListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetInstitutionsListQuery,
    Types.GetInstitutionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetInstitutionsListQuery,
    Types.GetInstitutionsListQueryVariables
  >(GetInstitutionsListDocument, options);
}
export function useGetInstitutionsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetInstitutionsListQuery,
    Types.GetInstitutionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetInstitutionsListQuery,
    Types.GetInstitutionsListQueryVariables
  >(GetInstitutionsListDocument, options);
}
export type GetInstitutionsListQueryHookResult = ReturnType<
  typeof useGetInstitutionsListQuery
>;
export type GetInstitutionsListLazyQueryHookResult = ReturnType<
  typeof useGetInstitutionsListLazyQuery
>;
export type GetInstitutionsListQueryResult = ApolloReactCommon.QueryResult<
  Types.GetInstitutionsListQuery,
  Types.GetInstitutionsListQueryVariables
>;
export const UnlinkInstitutionDocument = gql`
  mutation unlinkInstitution($customer: UUID!, $institution: String!) {
    unlinkInstitution(customer: $customer, institution: $institution)
  }
`;
export type UnlinkInstitutionMutationFn = ApolloReactCommon.MutationFunction<
  Types.UnlinkInstitutionMutation,
  Types.UnlinkInstitutionMutationVariables
>;

/**
 * __useUnlinkInstitutionMutation__
 *
 * To run a mutation, you first call `useUnlinkInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkInstitutionMutation, { data, loading, error }] = useUnlinkInstitutionMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      institution: // value for 'institution'
 *   },
 * });
 */
export function useUnlinkInstitutionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UnlinkInstitutionMutation,
    Types.UnlinkInstitutionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UnlinkInstitutionMutation,
    Types.UnlinkInstitutionMutationVariables
  >(UnlinkInstitutionDocument, options);
}
export type UnlinkInstitutionMutationHookResult = ReturnType<
  typeof useUnlinkInstitutionMutation
>;
export type UnlinkInstitutionMutationResult = ApolloReactCommon.MutationResult<Types.UnlinkInstitutionMutation>;
export type UnlinkInstitutionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UnlinkInstitutionMutation,
  Types.UnlinkInstitutionMutationVariables
>;
export const RelinkInstitutionDocument = gql`
  mutation relinkInstitution($customer: UUID!, $institution: String!) {
    relinkInstitution(customer: $customer, institution: $institution)
  }
`;
export type RelinkInstitutionMutationFn = ApolloReactCommon.MutationFunction<
  Types.RelinkInstitutionMutation,
  Types.RelinkInstitutionMutationVariables
>;

/**
 * __useRelinkInstitutionMutation__
 *
 * To run a mutation, you first call `useRelinkInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelinkInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relinkInstitutionMutation, { data, loading, error }] = useRelinkInstitutionMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      institution: // value for 'institution'
 *   },
 * });
 */
export function useRelinkInstitutionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RelinkInstitutionMutation,
    Types.RelinkInstitutionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.RelinkInstitutionMutation,
    Types.RelinkInstitutionMutationVariables
  >(RelinkInstitutionDocument, options);
}
export type RelinkInstitutionMutationHookResult = ReturnType<
  typeof useRelinkInstitutionMutation
>;
export type RelinkInstitutionMutationResult = ApolloReactCommon.MutationResult<Types.RelinkInstitutionMutation>;
export type RelinkInstitutionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RelinkInstitutionMutation,
  Types.RelinkInstitutionMutationVariables
>;
export const GetAllFinancingObjectsDocument = gql`
  query GetAllFinancingObjects(
    $search: String
    $orderBy: GenericOrderInput
    $filters: FinancingObjectFilterInput
    $offset: Float
    $first: Float
  ) {
    financingObjects: allFinancingObjects(
      search: $search
      orderBy: $orderBy
      filters: $filters
      offset: $offset
      first: $first
    ) {
      nodes {
        uuid
        substatus
        status
        advisor {
          ...Advisor
        }
        last_seen
        proposal_request {
          uuid
          date_submitted
          option_grants {
            ...SelectedOptionGrantFragment
          }
          share_grants {
            ...SelectedShareGrantFragment
          }
          affiliation {
            uuid
            company {
              uuid
              name
              is_fast_track
              logo
            }
          }
        }
      }
    }
  }
  ${AdvisorFragmentDoc}
  ${SelectedOptionGrantFragmentFragmentDoc}
  ${SelectedShareGrantFragmentFragmentDoc}
`;

/**
 * __useGetAllFinancingObjectsQuery__
 *
 * To run a query within a React component, call `useGetAllFinancingObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFinancingObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFinancingObjectsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetAllFinancingObjectsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetAllFinancingObjectsQuery,
    Types.GetAllFinancingObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllFinancingObjectsQuery,
    Types.GetAllFinancingObjectsQueryVariables
  >(GetAllFinancingObjectsDocument, options);
}
export function useGetAllFinancingObjectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllFinancingObjectsQuery,
    Types.GetAllFinancingObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllFinancingObjectsQuery,
    Types.GetAllFinancingObjectsQueryVariables
  >(GetAllFinancingObjectsDocument, options);
}
export type GetAllFinancingObjectsQueryHookResult = ReturnType<
  typeof useGetAllFinancingObjectsQuery
>;
export type GetAllFinancingObjectsLazyQueryHookResult = ReturnType<
  typeof useGetAllFinancingObjectsLazyQuery
>;
export type GetAllFinancingObjectsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllFinancingObjectsQuery,
  Types.GetAllFinancingObjectsQueryVariables
>;
export const GetPortfolioOverviewDocument = gql`
  query GetPortfolioOverview(
    $customer: UUID!
    $managedBy: PortfolioManagedBySelector
  ) {
    totalNetWorth(customer: $customer, managedBy: $managedBy) {
      graph {
        totalValue {
          ...TimelinePoint
        }
        markers {
          ...GraphMarker
        }
        dailyGranularityStartDate
      }
      hasWealthPotential
      lastManagedHoldingsSnapshotDate
      privateAssetsLatestPrefPriceDate
    }
    customerInstitutions(customer: $customer) {
      ...Institution
    }
  }
  ${TimelinePointFragmentDoc}
  ${GraphMarkerFragmentDoc}
  ${InstitutionFragmentDoc}
`;

/**
 * __useGetPortfolioOverviewQuery__
 *
 * To run a query within a React component, call `useGetPortfolioOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioOverviewQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetPortfolioOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPortfolioOverviewQuery,
    Types.GetPortfolioOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPortfolioOverviewQuery,
    Types.GetPortfolioOverviewQueryVariables
  >(GetPortfolioOverviewDocument, options);
}
export function useGetPortfolioOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPortfolioOverviewQuery,
    Types.GetPortfolioOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPortfolioOverviewQuery,
    Types.GetPortfolioOverviewQueryVariables
  >(GetPortfolioOverviewDocument, options);
}
export type GetPortfolioOverviewQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewQuery
>;
export type GetPortfolioOverviewLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewLazyQuery
>;
export type GetPortfolioOverviewQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPortfolioOverviewQuery,
  Types.GetPortfolioOverviewQueryVariables
>;
export const GetPortfolioOverviewAllocationTableDocument = gql`
  query GetPortfolioOverviewAllocationTable(
    $customer: UUID!
    $managedBy: PortfolioManagedBySelector
  ) {
    totalNetWorth(customer: $customer, managedBy: $managedBy) {
      allocationTable {
        ...NetWorthAllocationTable
      }
    }
  }
  ${NetWorthAllocationTableFragmentDoc}
`;

/**
 * __useGetPortfolioOverviewAllocationTableQuery__
 *
 * To run a query within a React component, call `useGetPortfolioOverviewAllocationTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioOverviewAllocationTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioOverviewAllocationTableQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetPortfolioOverviewAllocationTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPortfolioOverviewAllocationTableQuery,
    Types.GetPortfolioOverviewAllocationTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPortfolioOverviewAllocationTableQuery,
    Types.GetPortfolioOverviewAllocationTableQueryVariables
  >(GetPortfolioOverviewAllocationTableDocument, options);
}
export function useGetPortfolioOverviewAllocationTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPortfolioOverviewAllocationTableQuery,
    Types.GetPortfolioOverviewAllocationTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPortfolioOverviewAllocationTableQuery,
    Types.GetPortfolioOverviewAllocationTableQueryVariables
  >(GetPortfolioOverviewAllocationTableDocument, options);
}
export type GetPortfolioOverviewAllocationTableQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewAllocationTableQuery
>;
export type GetPortfolioOverviewAllocationTableLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewAllocationTableLazyQuery
>;
export type GetPortfolioOverviewAllocationTableQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPortfolioOverviewAllocationTableQuery,
  Types.GetPortfolioOverviewAllocationTableQueryVariables
>;
export const GetPortfolioOverviewSyncStatusDocument = gql`
  query GetPortfolioOverviewSyncStatus(
    $customer: UUID!
    $managedBy: PortfolioManagedBySelector
  ) {
    totalNetWorth(customer: $customer, managedBy: $managedBy) {
      syncStatus
      willNotifyReady
    }
  }
`;

/**
 * __useGetPortfolioOverviewSyncStatusQuery__
 *
 * To run a query within a React component, call `useGetPortfolioOverviewSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioOverviewSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioOverviewSyncStatusQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetPortfolioOverviewSyncStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPortfolioOverviewSyncStatusQuery,
    Types.GetPortfolioOverviewSyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPortfolioOverviewSyncStatusQuery,
    Types.GetPortfolioOverviewSyncStatusQueryVariables
  >(GetPortfolioOverviewSyncStatusDocument, options);
}
export function useGetPortfolioOverviewSyncStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPortfolioOverviewSyncStatusQuery,
    Types.GetPortfolioOverviewSyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPortfolioOverviewSyncStatusQuery,
    Types.GetPortfolioOverviewSyncStatusQueryVariables
  >(GetPortfolioOverviewSyncStatusDocument, options);
}
export type GetPortfolioOverviewSyncStatusQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewSyncStatusQuery
>;
export type GetPortfolioOverviewSyncStatusLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioOverviewSyncStatusLazyQuery
>;
export type GetPortfolioOverviewSyncStatusQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPortfolioOverviewSyncStatusQuery,
  Types.GetPortfolioOverviewSyncStatusQueryVariables
>;
export const PersonalReferralCodeDocument = gql`
  query personalReferralCode {
    personalReferralCode
  }
`;

/**
 * __usePersonalReferralCodeQuery__
 *
 * To run a query within a React component, call `usePersonalReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalReferralCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PersonalReferralCodeQuery,
    Types.PersonalReferralCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.PersonalReferralCodeQuery,
    Types.PersonalReferralCodeQueryVariables
  >(PersonalReferralCodeDocument, options);
}
export function usePersonalReferralCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PersonalReferralCodeQuery,
    Types.PersonalReferralCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.PersonalReferralCodeQuery,
    Types.PersonalReferralCodeQueryVariables
  >(PersonalReferralCodeDocument, options);
}
export type PersonalReferralCodeQueryHookResult = ReturnType<
  typeof usePersonalReferralCodeQuery
>;
export type PersonalReferralCodeLazyQueryHookResult = ReturnType<
  typeof usePersonalReferralCodeLazyQuery
>;
export type PersonalReferralCodeQueryResult = ApolloReactCommon.QueryResult<
  Types.PersonalReferralCodeQuery,
  Types.PersonalReferralCodeQueryVariables
>;
export const RequestAccountDeletionDocument = gql`
  mutation RequestAccountDeletion(
    $feedback: String
    $reason: String!
    $uuid: String!
  ) {
    requestDeletion(
      deletion_feedback: $feedback
      deletion_reason: $reason
      uuid: $uuid
    )
  }
`;
export type RequestAccountDeletionMutationFn = ApolloReactCommon.MutationFunction<
  Types.RequestAccountDeletionMutation,
  Types.RequestAccountDeletionMutationVariables
>;

/**
 * __useRequestAccountDeletionMutation__
 *
 * To run a mutation, you first call `useRequestAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountDeletionMutation, { data, loading, error }] = useRequestAccountDeletionMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *      reason: // value for 'reason'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRequestAccountDeletionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RequestAccountDeletionMutation,
    Types.RequestAccountDeletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.RequestAccountDeletionMutation,
    Types.RequestAccountDeletionMutationVariables
  >(RequestAccountDeletionDocument, options);
}
export type RequestAccountDeletionMutationHookResult = ReturnType<
  typeof useRequestAccountDeletionMutation
>;
export type RequestAccountDeletionMutationResult = ApolloReactCommon.MutationResult<Types.RequestAccountDeletionMutation>;
export type RequestAccountDeletionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RequestAccountDeletionMutation,
  Types.RequestAccountDeletionMutationVariables
>;
export const ConfirmAccountDeletionDocument = gql`
  mutation ConfirmAccountDeletion($token: String!, $uuid: String!) {
    deleteUser(deletion_token: $token, uuid: $uuid)
  }
`;
export type ConfirmAccountDeletionMutationFn = ApolloReactCommon.MutationFunction<
  Types.ConfirmAccountDeletionMutation,
  Types.ConfirmAccountDeletionMutationVariables
>;

/**
 * __useConfirmAccountDeletionMutation__
 *
 * To run a mutation, you first call `useConfirmAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAccountDeletionMutation, { data, loading, error }] = useConfirmAccountDeletionMutation({
 *   variables: {
 *      token: // value for 'token'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConfirmAccountDeletionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ConfirmAccountDeletionMutation,
    Types.ConfirmAccountDeletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ConfirmAccountDeletionMutation,
    Types.ConfirmAccountDeletionMutationVariables
  >(ConfirmAccountDeletionDocument, options);
}
export type ConfirmAccountDeletionMutationHookResult = ReturnType<
  typeof useConfirmAccountDeletionMutation
>;
export type ConfirmAccountDeletionMutationResult = ApolloReactCommon.MutationResult<Types.ConfirmAccountDeletionMutation>;
export type ConfirmAccountDeletionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ConfirmAccountDeletionMutation,
  Types.ConfirmAccountDeletionMutationVariables
>;
export const MoveToPlanPhaseDocument = gql`
  mutation moveToPlanPhase($affiliation: UUID!) {
    EP_moveToPlanPhase(affiliation: $affiliation) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type MoveToPlanPhaseMutationFn = ApolloReactCommon.MutationFunction<
  Types.MoveToPlanPhaseMutation,
  Types.MoveToPlanPhaseMutationVariables
>;

/**
 * __useMoveToPlanPhaseMutation__
 *
 * To run a mutation, you first call `useMoveToPlanPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToPlanPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToPlanPhaseMutation, { data, loading, error }] = useMoveToPlanPhaseMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useMoveToPlanPhaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.MoveToPlanPhaseMutation,
    Types.MoveToPlanPhaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.MoveToPlanPhaseMutation,
    Types.MoveToPlanPhaseMutationVariables
  >(MoveToPlanPhaseDocument, options);
}
export type MoveToPlanPhaseMutationHookResult = ReturnType<
  typeof useMoveToPlanPhaseMutation
>;
export type MoveToPlanPhaseMutationResult = ApolloReactCommon.MutationResult<Types.MoveToPlanPhaseMutation>;
export type MoveToPlanPhaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.MoveToPlanPhaseMutation,
  Types.MoveToPlanPhaseMutationVariables
>;
export const EpSetExerciseLaterProjectionDataDocument = gql`
  mutation EPSetExerciseLaterProjectionData(
    $affiliation: UUID!
    $data: EPExerciseLaterProjectionDataInput!
  ) {
    EP_setExerciseLaterProjectionData(affiliation: $affiliation, data: $data) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetExerciseLaterProjectionDataMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetExerciseLaterProjectionDataMutation,
  Types.EpSetExerciseLaterProjectionDataMutationVariables
>;

/**
 * __useEpSetExerciseLaterProjectionDataMutation__
 *
 * To run a mutation, you first call `useEpSetExerciseLaterProjectionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetExerciseLaterProjectionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetExerciseLaterProjectionDataMutation, { data, loading, error }] = useEpSetExerciseLaterProjectionDataMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEpSetExerciseLaterProjectionDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetExerciseLaterProjectionDataMutation,
    Types.EpSetExerciseLaterProjectionDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetExerciseLaterProjectionDataMutation,
    Types.EpSetExerciseLaterProjectionDataMutationVariables
  >(EpSetExerciseLaterProjectionDataDocument, options);
}
export type EpSetExerciseLaterProjectionDataMutationHookResult = ReturnType<
  typeof useEpSetExerciseLaterProjectionDataMutation
>;
export type EpSetExerciseLaterProjectionDataMutationResult = ApolloReactCommon.MutationResult<Types.EpSetExerciseLaterProjectionDataMutation>;
export type EpSetExerciseLaterProjectionDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetExerciseLaterProjectionDataMutation,
  Types.EpSetExerciseLaterProjectionDataMutationVariables
>;
export const EpSetExerciseNowEarlyDataDocument = gql`
  mutation EPSetExerciseNowEarlyData(
    $affiliation: UUID!
    $data: EPExerciseNowEarlyDataInput!
  ) {
    EP_setExerciseNowEarlyData(affiliation: $affiliation, data: $data) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetExerciseNowEarlyDataMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetExerciseNowEarlyDataMutation,
  Types.EpSetExerciseNowEarlyDataMutationVariables
>;

/**
 * __useEpSetExerciseNowEarlyDataMutation__
 *
 * To run a mutation, you first call `useEpSetExerciseNowEarlyDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetExerciseNowEarlyDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetExerciseNowEarlyDataMutation, { data, loading, error }] = useEpSetExerciseNowEarlyDataMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEpSetExerciseNowEarlyDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetExerciseNowEarlyDataMutation,
    Types.EpSetExerciseNowEarlyDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetExerciseNowEarlyDataMutation,
    Types.EpSetExerciseNowEarlyDataMutationVariables
  >(EpSetExerciseNowEarlyDataDocument, options);
}
export type EpSetExerciseNowEarlyDataMutationHookResult = ReturnType<
  typeof useEpSetExerciseNowEarlyDataMutation
>;
export type EpSetExerciseNowEarlyDataMutationResult = ApolloReactCommon.MutationResult<Types.EpSetExerciseNowEarlyDataMutation>;
export type EpSetExerciseNowEarlyDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetExerciseNowEarlyDataMutation,
  Types.EpSetExerciseNowEarlyDataMutationVariables
>;
export const EpSelectOptionGrantsDocument = gql`
  mutation EPSelectOptionGrants($affiliation: UUID!, $grantUuids: [UUID!]!) {
    EP_selectOptionGrants(affiliation: $affiliation, grantUuids: $grantUuids) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSelectOptionGrantsMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSelectOptionGrantsMutation,
  Types.EpSelectOptionGrantsMutationVariables
>;

/**
 * __useEpSelectOptionGrantsMutation__
 *
 * To run a mutation, you first call `useEpSelectOptionGrantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSelectOptionGrantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSelectOptionGrantsMutation, { data, loading, error }] = useEpSelectOptionGrantsMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      grantUuids: // value for 'grantUuids'
 *   },
 * });
 */
export function useEpSelectOptionGrantsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSelectOptionGrantsMutation,
    Types.EpSelectOptionGrantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSelectOptionGrantsMutation,
    Types.EpSelectOptionGrantsMutationVariables
  >(EpSelectOptionGrantsDocument, options);
}
export type EpSelectOptionGrantsMutationHookResult = ReturnType<
  typeof useEpSelectOptionGrantsMutation
>;
export type EpSelectOptionGrantsMutationResult = ApolloReactCommon.MutationResult<Types.EpSelectOptionGrantsMutation>;
export type EpSelectOptionGrantsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSelectOptionGrantsMutation,
  Types.EpSelectOptionGrantsMutationVariables
>;
export const EpSelectShareGrantsDocument = gql`
  mutation EPSelectShareGrants($affiliation: UUID!, $grantUuids: [UUID!]!) {
    EP_selectShareGrants(affiliation: $affiliation, grantUuids: $grantUuids) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSelectShareGrantsMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSelectShareGrantsMutation,
  Types.EpSelectShareGrantsMutationVariables
>;

/**
 * __useEpSelectShareGrantsMutation__
 *
 * To run a mutation, you first call `useEpSelectShareGrantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSelectShareGrantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSelectShareGrantsMutation, { data, loading, error }] = useEpSelectShareGrantsMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      grantUuids: // value for 'grantUuids'
 *   },
 * });
 */
export function useEpSelectShareGrantsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSelectShareGrantsMutation,
    Types.EpSelectShareGrantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSelectShareGrantsMutation,
    Types.EpSelectShareGrantsMutationVariables
  >(EpSelectShareGrantsDocument, options);
}
export type EpSelectShareGrantsMutationHookResult = ReturnType<
  typeof useEpSelectShareGrantsMutation
>;
export type EpSelectShareGrantsMutationResult = ApolloReactCommon.MutationResult<Types.EpSelectShareGrantsMutation>;
export type EpSelectShareGrantsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSelectShareGrantsMutation,
  Types.EpSelectShareGrantsMutationVariables
>;
export const EpSetCompanyAndTaxDetailsDocument = gql`
  mutation EPSetCompanyAndTaxDetails(
    $affiliation: UUID!
    $details: EPCompanyAndTaxDetails!
  ) {
    EP_setCompanyAndTaxDetails(
      affiliation: $affiliation
      companyAndTaxDetails: $details
    ) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetCompanyAndTaxDetailsMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetCompanyAndTaxDetailsMutation,
  Types.EpSetCompanyAndTaxDetailsMutationVariables
>;

/**
 * __useEpSetCompanyAndTaxDetailsMutation__
 *
 * To run a mutation, you first call `useEpSetCompanyAndTaxDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetCompanyAndTaxDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetCompanyAndTaxDetailsMutation, { data, loading, error }] = useEpSetCompanyAndTaxDetailsMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useEpSetCompanyAndTaxDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetCompanyAndTaxDetailsMutation,
    Types.EpSetCompanyAndTaxDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetCompanyAndTaxDetailsMutation,
    Types.EpSetCompanyAndTaxDetailsMutationVariables
  >(EpSetCompanyAndTaxDetailsDocument, options);
}
export type EpSetCompanyAndTaxDetailsMutationHookResult = ReturnType<
  typeof useEpSetCompanyAndTaxDetailsMutation
>;
export type EpSetCompanyAndTaxDetailsMutationResult = ApolloReactCommon.MutationResult<Types.EpSetCompanyAndTaxDetailsMutation>;
export type EpSetCompanyAndTaxDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetCompanyAndTaxDetailsMutation,
  Types.EpSetCompanyAndTaxDetailsMutationVariables
>;
export const EpSetExitProjectionDocument = gql`
  mutation EPSetExitProjection(
    $affiliation: UUID!
    $exitProjection: EPExitProjection!
  ) {
    EP_setExitProjection(
      affiliation: $affiliation
      exitProjection: $exitProjection
    ) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetExitProjectionMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetExitProjectionMutation,
  Types.EpSetExitProjectionMutationVariables
>;

/**
 * __useEpSetExitProjectionMutation__
 *
 * To run a mutation, you first call `useEpSetExitProjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetExitProjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetExitProjectionMutation, { data, loading, error }] = useEpSetExitProjectionMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      exitProjection: // value for 'exitProjection'
 *   },
 * });
 */
export function useEpSetExitProjectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetExitProjectionMutation,
    Types.EpSetExitProjectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetExitProjectionMutation,
    Types.EpSetExitProjectionMutationVariables
  >(EpSetExitProjectionDocument, options);
}
export type EpSetExitProjectionMutationHookResult = ReturnType<
  typeof useEpSetExitProjectionMutation
>;
export type EpSetExitProjectionMutationResult = ApolloReactCommon.MutationResult<Types.EpSetExitProjectionMutation>;
export type EpSetExitProjectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetExitProjectionMutation,
  Types.EpSetExitProjectionMutationVariables
>;
export const UpdateSelectedPlanDatesDocument = gql`
  mutation UpdateSelectedPlanDates($affiliation: UUID!) {
    EP_updateSelectedPlanDates(affiliation: $affiliation) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type UpdateSelectedPlanDatesMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateSelectedPlanDatesMutation,
  Types.UpdateSelectedPlanDatesMutationVariables
>;

/**
 * __useUpdateSelectedPlanDatesMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedPlanDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedPlanDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedPlanDatesMutation, { data, loading, error }] = useUpdateSelectedPlanDatesMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useUpdateSelectedPlanDatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateSelectedPlanDatesMutation,
    Types.UpdateSelectedPlanDatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateSelectedPlanDatesMutation,
    Types.UpdateSelectedPlanDatesMutationVariables
  >(UpdateSelectedPlanDatesDocument, options);
}
export type UpdateSelectedPlanDatesMutationHookResult = ReturnType<
  typeof useUpdateSelectedPlanDatesMutation
>;
export type UpdateSelectedPlanDatesMutationResult = ApolloReactCommon.MutationResult<Types.UpdateSelectedPlanDatesMutation>;
export type UpdateSelectedPlanDatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateSelectedPlanDatesMutation,
  Types.UpdateSelectedPlanDatesMutationVariables
>;
export const EpSetSelectedPlansDocument = gql`
  mutation EPSetSelectedPlans($affiliation: UUID!, $planTypes: [EPPlanType!]!) {
    EP_setSelectedPlans(affiliation: $affiliation, planTypes: $planTypes) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetSelectedPlansMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetSelectedPlansMutation,
  Types.EpSetSelectedPlansMutationVariables
>;

/**
 * __useEpSetSelectedPlansMutation__
 *
 * To run a mutation, you first call `useEpSetSelectedPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetSelectedPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetSelectedPlansMutation, { data, loading, error }] = useEpSetSelectedPlansMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      planTypes: // value for 'planTypes'
 *   },
 * });
 */
export function useEpSetSelectedPlansMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetSelectedPlansMutation,
    Types.EpSetSelectedPlansMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetSelectedPlansMutation,
    Types.EpSetSelectedPlansMutationVariables
  >(EpSetSelectedPlansDocument, options);
}
export type EpSetSelectedPlansMutationHookResult = ReturnType<
  typeof useEpSetSelectedPlansMutation
>;
export type EpSetSelectedPlansMutationResult = ApolloReactCommon.MutationResult<Types.EpSetSelectedPlansMutation>;
export type EpSetSelectedPlansMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetSelectedPlansMutation,
  Types.EpSetSelectedPlansMutationVariables
>;
export const EpSetTenderOfferDataDocument = gql`
  mutation EPSetTenderOfferData(
    $affiliation: UUID!
    $data: EPTenderOfferDataInput!
  ) {
    EP_setTenderOfferData(affiliation: $affiliation, data: $data) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetTenderOfferDataMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetTenderOfferDataMutation,
  Types.EpSetTenderOfferDataMutationVariables
>;

/**
 * __useEpSetTenderOfferDataMutation__
 *
 * To run a mutation, you first call `useEpSetTenderOfferDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetTenderOfferDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetTenderOfferDataMutation, { data, loading, error }] = useEpSetTenderOfferDataMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEpSetTenderOfferDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetTenderOfferDataMutation,
    Types.EpSetTenderOfferDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetTenderOfferDataMutation,
    Types.EpSetTenderOfferDataMutationVariables
  >(EpSetTenderOfferDataDocument, options);
}
export type EpSetTenderOfferDataMutationHookResult = ReturnType<
  typeof useEpSetTenderOfferDataMutation
>;
export type EpSetTenderOfferDataMutationResult = ApolloReactCommon.MutationResult<Types.EpSetTenderOfferDataMutation>;
export type EpSetTenderOfferDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetTenderOfferDataMutation,
  Types.EpSetTenderOfferDataMutationVariables
>;
export const EpSetSecondaryMarketDataDocument = gql`
  mutation EPSetSecondaryMarketData(
    $affiliation: UUID!
    $data: EPSecondaryMarketDataInput!
  ) {
    EP_setSecondaryMarketData(affiliation: $affiliation, data: $data) {
      timeline {
        ...EPTimeline
      }
    }
  }
  ${EpTimelineFragmentDoc}
`;
export type EpSetSecondaryMarketDataMutationFn = ApolloReactCommon.MutationFunction<
  Types.EpSetSecondaryMarketDataMutation,
  Types.EpSetSecondaryMarketDataMutationVariables
>;

/**
 * __useEpSetSecondaryMarketDataMutation__
 *
 * To run a mutation, you first call `useEpSetSecondaryMarketDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpSetSecondaryMarketDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epSetSecondaryMarketDataMutation, { data, loading, error }] = useEpSetSecondaryMarketDataMutation({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEpSetSecondaryMarketDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EpSetSecondaryMarketDataMutation,
    Types.EpSetSecondaryMarketDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EpSetSecondaryMarketDataMutation,
    Types.EpSetSecondaryMarketDataMutationVariables
  >(EpSetSecondaryMarketDataDocument, options);
}
export type EpSetSecondaryMarketDataMutationHookResult = ReturnType<
  typeof useEpSetSecondaryMarketDataMutation
>;
export type EpSetSecondaryMarketDataMutationResult = ApolloReactCommon.MutationResult<Types.EpSetSecondaryMarketDataMutation>;
export type EpSetSecondaryMarketDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EpSetSecondaryMarketDataMutation,
  Types.EpSetSecondaryMarketDataMutationVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer($customerUuid: String!) {
    customer: Customer(uuid: $customerUuid) {
      uuid
      country
      subdivision
      city
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCustomerQuery,
  Types.GetCustomerQueryVariables
>;
export const GetExerciseCostsEstimationDocument = gql`
  query getExerciseCostsEstimation(
    $affiliation: UUID!
    $details: EPCompanyAndTaxDetails!
    $targetDate: CalendarDate
  ) {
    exerciseCostsEstimation: EP_exerciseCostsEstimation(
      affiliation: $affiliation
      companyAndTaxDetails: $details
      targetDate: $targetDate
    ) {
      grantsExerciseCost {
        ...ExerciseCostEstimationGrant
      }
      totalExerciseCost
    }
  }
  ${ExerciseCostEstimationGrantFragmentDoc}
`;

/**
 * __useGetExerciseCostsEstimationQuery__
 *
 * To run a query within a React component, call `useGetExerciseCostsEstimationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseCostsEstimationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseCostsEstimationQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      details: // value for 'details'
 *      targetDate: // value for 'targetDate'
 *   },
 * });
 */
export function useGetExerciseCostsEstimationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetExerciseCostsEstimationQuery,
    Types.GetExerciseCostsEstimationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetExerciseCostsEstimationQuery,
    Types.GetExerciseCostsEstimationQueryVariables
  >(GetExerciseCostsEstimationDocument, options);
}
export function useGetExerciseCostsEstimationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetExerciseCostsEstimationQuery,
    Types.GetExerciseCostsEstimationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetExerciseCostsEstimationQuery,
    Types.GetExerciseCostsEstimationQueryVariables
  >(GetExerciseCostsEstimationDocument, options);
}
export type GetExerciseCostsEstimationQueryHookResult = ReturnType<
  typeof useGetExerciseCostsEstimationQuery
>;
export type GetExerciseCostsEstimationLazyQueryHookResult = ReturnType<
  typeof useGetExerciseCostsEstimationLazyQuery
>;
export type GetExerciseCostsEstimationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetExerciseCostsEstimationQuery,
  Types.GetExerciseCostsEstimationQueryVariables
>;
export const GetExitValueEstimationDocument = gql`
  query getExitValueEstimation(
    $affiliation: UUID!
    $exitProjection: EPExitProjection!
  ) {
    exitValueEstimation: EP_exitValueEstimation(
      affiliation: $affiliation
      exitProjection: $exitProjection
    ) {
      optionGrants {
        ...OptionGrantExitValueEstimation
      }
      shareGrants {
        ...ShareGrantsExitValueEstimation
      }
      netGains
    }
  }
  ${OptionGrantExitValueEstimationFragmentDoc}
  ${ShareGrantsExitValueEstimationFragmentDoc}
`;

/**
 * __useGetExitValueEstimationQuery__
 *
 * To run a query within a React component, call `useGetExitValueEstimationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExitValueEstimationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExitValueEstimationQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      exitProjection: // value for 'exitProjection'
 *   },
 * });
 */
export function useGetExitValueEstimationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetExitValueEstimationQuery,
    Types.GetExitValueEstimationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetExitValueEstimationQuery,
    Types.GetExitValueEstimationQueryVariables
  >(GetExitValueEstimationDocument, options);
}
export function useGetExitValueEstimationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetExitValueEstimationQuery,
    Types.GetExitValueEstimationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetExitValueEstimationQuery,
    Types.GetExitValueEstimationQueryVariables
  >(GetExitValueEstimationDocument, options);
}
export type GetExitValueEstimationQueryHookResult = ReturnType<
  typeof useGetExitValueEstimationQuery
>;
export type GetExitValueEstimationLazyQueryHookResult = ReturnType<
  typeof useGetExitValueEstimationLazyQuery
>;
export type GetExitValueEstimationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetExitValueEstimationQuery,
  Types.GetExitValueEstimationQueryVariables
>;
export const GetExitMultiplierChoicesDocument = gql`
  query getExitMultiplierChoices($affiliation: UUID!) {
    exitMultiplierChoices: EP_exitMultipliers(affiliation: $affiliation) {
      choices {
        description
        id
        label
        multiplier
      }
      id
    }
  }
`;

/**
 * __useGetExitMultiplierChoicesQuery__
 *
 * To run a query within a React component, call `useGetExitMultiplierChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExitMultiplierChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExitMultiplierChoicesQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetExitMultiplierChoicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetExitMultiplierChoicesQuery,
    Types.GetExitMultiplierChoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetExitMultiplierChoicesQuery,
    Types.GetExitMultiplierChoicesQueryVariables
  >(GetExitMultiplierChoicesDocument, options);
}
export function useGetExitMultiplierChoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetExitMultiplierChoicesQuery,
    Types.GetExitMultiplierChoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetExitMultiplierChoicesQuery,
    Types.GetExitMultiplierChoicesQueryVariables
  >(GetExitMultiplierChoicesDocument, options);
}
export type GetExitMultiplierChoicesQueryHookResult = ReturnType<
  typeof useGetExitMultiplierChoicesQuery
>;
export type GetExitMultiplierChoicesLazyQueryHookResult = ReturnType<
  typeof useGetExitMultiplierChoicesLazyQuery
>;
export type GetExitMultiplierChoicesQueryResult = ApolloReactCommon.QueryResult<
  Types.GetExitMultiplierChoicesQuery,
  Types.GetExitMultiplierChoicesQueryVariables
>;
export const GetExitDatesChoicesDocument = gql`
  query getExitDatesChoices($affiliation: UUID!) {
    exitDatesChoices: EP_exitDates(affiliation: $affiliation) {
      choices {
        description
        exitDate
        id
        label
      }
      id
    }
  }
`;

/**
 * __useGetExitDatesChoicesQuery__
 *
 * To run a query within a React component, call `useGetExitDatesChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExitDatesChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExitDatesChoicesQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetExitDatesChoicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetExitDatesChoicesQuery,
    Types.GetExitDatesChoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetExitDatesChoicesQuery,
    Types.GetExitDatesChoicesQueryVariables
  >(GetExitDatesChoicesDocument, options);
}
export function useGetExitDatesChoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetExitDatesChoicesQuery,
    Types.GetExitDatesChoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetExitDatesChoicesQuery,
    Types.GetExitDatesChoicesQueryVariables
  >(GetExitDatesChoicesDocument, options);
}
export type GetExitDatesChoicesQueryHookResult = ReturnType<
  typeof useGetExitDatesChoicesQuery
>;
export type GetExitDatesChoicesLazyQueryHookResult = ReturnType<
  typeof useGetExitDatesChoicesLazyQuery
>;
export type GetExitDatesChoicesQueryResult = ApolloReactCommon.QueryResult<
  Types.GetExitDatesChoicesQuery,
  Types.GetExitDatesChoicesQueryVariables
>;
export const GetPlansDocument = gql`
  query getPlans($affiliation: UUID!) {
    EP_getPlans(affiliation: $affiliation) {
      planType
      exerciseCosts
      grossReturnFromSelling
      sharePriceOnSellDate
      netGains
      personalCapitalInvested
      riskOfCostsIncreasing
      riskOfCostsIncreasingTooltipDescription
      securedShares
      title
      taxesDueOnExercise
      totalExerciseCost
      taxesDueOnExit
      vestedOptionsAtExercise
      description
      talkToStrategistOption
      optionsQuantity
      exerciseDate
      sharePrice
      shareGrants {
        quantity
      }
      optionGrants {
        quantity
        type
        vestedQuantity
        remainingQuantity
      }
    }
  }
`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetPlansQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPlansQuery,
    Types.GetPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPlansQuery,
    Types.GetPlansQueryVariables
  >(GetPlansDocument, options);
}
export function useGetPlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPlansQuery,
    Types.GetPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPlansQuery,
    Types.GetPlansQueryVariables
  >(GetPlansDocument, options);
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<
  typeof useGetPlansLazyQuery
>;
export type GetPlansQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPlansQuery,
  Types.GetPlansQueryVariables
>;
export const GetVestedGrantsForDateDocument = gql`
  query getVestedGrantsForDate(
    $affiliation: UUID!
    $targetDate: CalendarDate!
  ) {
    EP_vestedGrantQuantities(
      affiliation: $affiliation
      targetDate: $targetDate
    ) {
      uuid
      vestedQuantity
    }
  }
`;

/**
 * __useGetVestedGrantsForDateQuery__
 *
 * To run a query within a React component, call `useGetVestedGrantsForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestedGrantsForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestedGrantsForDateQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      targetDate: // value for 'targetDate'
 *   },
 * });
 */
export function useGetVestedGrantsForDateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetVestedGrantsForDateQuery,
    Types.GetVestedGrantsForDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetVestedGrantsForDateQuery,
    Types.GetVestedGrantsForDateQueryVariables
  >(GetVestedGrantsForDateDocument, options);
}
export function useGetVestedGrantsForDateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetVestedGrantsForDateQuery,
    Types.GetVestedGrantsForDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetVestedGrantsForDateQuery,
    Types.GetVestedGrantsForDateQueryVariables
  >(GetVestedGrantsForDateDocument, options);
}
export type GetVestedGrantsForDateQueryHookResult = ReturnType<
  typeof useGetVestedGrantsForDateQuery
>;
export type GetVestedGrantsForDateLazyQueryHookResult = ReturnType<
  typeof useGetVestedGrantsForDateLazyQuery
>;
export type GetVestedGrantsForDateQueryResult = ApolloReactCommon.QueryResult<
  Types.GetVestedGrantsForDateQuery,
  Types.GetVestedGrantsForDateQueryVariables
>;
export const GetAllCompanyForecastsDocument = gql`
  query GetAllCompanyForecasts(
    $filters: CompanyForecastFiltersInput
    $orderBy: GenericOrderInput
  ) {
    companyForecasts: allCompanyForecasts(
      filters: $filters
      orderBy: $orderBy
    ) {
      nodes {
        ...CompanyForecast
      }
    }
  }
  ${CompanyForecastFragmentDoc}
`;

/**
 * __useGetAllCompanyForecastsQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyForecastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyForecastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyForecastsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCompanyForecastsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetAllCompanyForecastsQuery,
    Types.GetAllCompanyForecastsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllCompanyForecastsQuery,
    Types.GetAllCompanyForecastsQueryVariables
  >(GetAllCompanyForecastsDocument, options);
}
export function useGetAllCompanyForecastsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllCompanyForecastsQuery,
    Types.GetAllCompanyForecastsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllCompanyForecastsQuery,
    Types.GetAllCompanyForecastsQueryVariables
  >(GetAllCompanyForecastsDocument, options);
}
export type GetAllCompanyForecastsQueryHookResult = ReturnType<
  typeof useGetAllCompanyForecastsQuery
>;
export type GetAllCompanyForecastsLazyQueryHookResult = ReturnType<
  typeof useGetAllCompanyForecastsLazyQuery
>;
export type GetAllCompanyForecastsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllCompanyForecastsQuery,
  Types.GetAllCompanyForecastsQueryVariables
>;
export const CreateCompanyForecastDocument = gql`
  mutation CreateCompanyForecast($companyForecast: CompanyForecastCreateInput) {
    companyForecast: createCompanyForecast(payload: $companyForecast) {
      ...CompanyForecast
    }
  }
  ${CompanyForecastFragmentDoc}
`;
export type CreateCompanyForecastMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateCompanyForecastMutation,
  Types.CreateCompanyForecastMutationVariables
>;

/**
 * __useCreateCompanyForecastMutation__
 *
 * To run a mutation, you first call `useCreateCompanyForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyForecastMutation, { data, loading, error }] = useCreateCompanyForecastMutation({
 *   variables: {
 *      companyForecast: // value for 'companyForecast'
 *   },
 * });
 */
export function useCreateCompanyForecastMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateCompanyForecastMutation,
    Types.CreateCompanyForecastMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateCompanyForecastMutation,
    Types.CreateCompanyForecastMutationVariables
  >(CreateCompanyForecastDocument, options);
}
export type CreateCompanyForecastMutationHookResult = ReturnType<
  typeof useCreateCompanyForecastMutation
>;
export type CreateCompanyForecastMutationResult = ApolloReactCommon.MutationResult<Types.CreateCompanyForecastMutation>;
export type CreateCompanyForecastMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateCompanyForecastMutation,
  Types.CreateCompanyForecastMutationVariables
>;
export const UpdateCompanyForecastDocument = gql`
  mutation UpdateCompanyForecast(
    $uuid: String
    $companyForecast: CompanyForecastCreateInput
  ) {
    companyForecast: updateCompanyForecast(
      uuid: $uuid
      payload: $companyForecast
    ) {
      ...CompanyForecast
    }
  }
  ${CompanyForecastFragmentDoc}
`;
export type UpdateCompanyForecastMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateCompanyForecastMutation,
  Types.UpdateCompanyForecastMutationVariables
>;

/**
 * __useUpdateCompanyForecastMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyForecastMutation, { data, loading, error }] = useUpdateCompanyForecastMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      companyForecast: // value for 'companyForecast'
 *   },
 * });
 */
export function useUpdateCompanyForecastMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateCompanyForecastMutation,
    Types.UpdateCompanyForecastMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateCompanyForecastMutation,
    Types.UpdateCompanyForecastMutationVariables
  >(UpdateCompanyForecastDocument, options);
}
export type UpdateCompanyForecastMutationHookResult = ReturnType<
  typeof useUpdateCompanyForecastMutation
>;
export type UpdateCompanyForecastMutationResult = ApolloReactCommon.MutationResult<Types.UpdateCompanyForecastMutation>;
export type UpdateCompanyForecastMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateCompanyForecastMutation,
  Types.UpdateCompanyForecastMutationVariables
>;
export const GetExitScenarioDetailsDocument = gql`
  query GetExitScenarioDetails(
    $companyUuid: String
    $exitValuations: [Float!]
    $shareValues: [Float!]
  ) {
    exitScenario: ExitScenarioDetails(
      uuid: $companyUuid
      exit_valuations: $exitValuations
      share_prices: $shareValues
    ) {
      company_uuid
      exit_scenario_details {
        ...ExitScenarioDetail
      }
      exit_valuations
      share_prices
    }
  }
  ${ExitScenarioDetailFragmentDoc}
`;

/**
 * __useGetExitScenarioDetailsQuery__
 *
 * To run a query within a React component, call `useGetExitScenarioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExitScenarioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExitScenarioDetailsQuery({
 *   variables: {
 *      companyUuid: // value for 'companyUuid'
 *      exitValuations: // value for 'exitValuations'
 *      shareValues: // value for 'shareValues'
 *   },
 * });
 */
export function useGetExitScenarioDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetExitScenarioDetailsQuery,
    Types.GetExitScenarioDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetExitScenarioDetailsQuery,
    Types.GetExitScenarioDetailsQueryVariables
  >(GetExitScenarioDetailsDocument, options);
}
export function useGetExitScenarioDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetExitScenarioDetailsQuery,
    Types.GetExitScenarioDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetExitScenarioDetailsQuery,
    Types.GetExitScenarioDetailsQueryVariables
  >(GetExitScenarioDetailsDocument, options);
}
export type GetExitScenarioDetailsQueryHookResult = ReturnType<
  typeof useGetExitScenarioDetailsQuery
>;
export type GetExitScenarioDetailsLazyQueryHookResult = ReturnType<
  typeof useGetExitScenarioDetailsLazyQuery
>;
export type GetExitScenarioDetailsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetExitScenarioDetailsQuery,
  Types.GetExitScenarioDetailsQueryVariables
>;
export const RequestVerificationDocument = gql`
  mutation RequestVerification($payload: CompanyAssessmentCreateInput!) {
    requestVerification: createCompanyAssessment(payload: $payload) {
      ...CompanyAssessment
    }
  }
  ${CompanyAssessmentFragmentDoc}
`;
export type RequestVerificationMutationFn = ApolloReactCommon.MutationFunction<
  Types.RequestVerificationMutation,
  Types.RequestVerificationMutationVariables
>;

/**
 * __useRequestVerificationMutation__
 *
 * To run a mutation, you first call `useRequestVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVerificationMutation, { data, loading, error }] = useRequestVerificationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRequestVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RequestVerificationMutation,
    Types.RequestVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.RequestVerificationMutation,
    Types.RequestVerificationMutationVariables
  >(RequestVerificationDocument, options);
}
export type RequestVerificationMutationHookResult = ReturnType<
  typeof useRequestVerificationMutation
>;
export type RequestVerificationMutationResult = ApolloReactCommon.MutationResult<Types.RequestVerificationMutation>;
export type RequestVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RequestVerificationMutation,
  Types.RequestVerificationMutationVariables
>;
export const GetLatestVerifiedCompanyStatsDocument = gql`
  query GetLatestVerifiedCompanyStats($companyUuid: String!) {
    Company(uuid: $companyUuid) {
      uuid
      cap_table_verified
      latest_equity_series_date
      total_raised {
        ...CurrencyFragment
      }
      latest_valuation {
        ...CurrencyFragment
      }
      latest_verified_pref_price {
        ...CurrencyFragment
      }
      latest_verified_company_assessment {
        uuid
        four09a_share_value {
          ...CurrencyFragment
        }
      }
    }
  }
  ${CurrencyFragmentFragmentDoc}
`;

/**
 * __useGetLatestVerifiedCompanyStatsQuery__
 *
 * To run a query within a React component, call `useGetLatestVerifiedCompanyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVerifiedCompanyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVerifiedCompanyStatsQuery({
 *   variables: {
 *      companyUuid: // value for 'companyUuid'
 *   },
 * });
 */
export function useGetLatestVerifiedCompanyStatsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetLatestVerifiedCompanyStatsQuery,
    Types.GetLatestVerifiedCompanyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetLatestVerifiedCompanyStatsQuery,
    Types.GetLatestVerifiedCompanyStatsQueryVariables
  >(GetLatestVerifiedCompanyStatsDocument, options);
}
export function useGetLatestVerifiedCompanyStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetLatestVerifiedCompanyStatsQuery,
    Types.GetLatestVerifiedCompanyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetLatestVerifiedCompanyStatsQuery,
    Types.GetLatestVerifiedCompanyStatsQueryVariables
  >(GetLatestVerifiedCompanyStatsDocument, options);
}
export type GetLatestVerifiedCompanyStatsQueryHookResult = ReturnType<
  typeof useGetLatestVerifiedCompanyStatsQuery
>;
export type GetLatestVerifiedCompanyStatsLazyQueryHookResult = ReturnType<
  typeof useGetLatestVerifiedCompanyStatsLazyQuery
>;
export type GetLatestVerifiedCompanyStatsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetLatestVerifiedCompanyStatsQuery,
  Types.GetLatestVerifiedCompanyStatsQueryVariables
>;
export const UpdateProposalRequestPersonalSnapshotDocument = gql`
  mutation updateProposalRequestPersonalSnapshot(
    $payload: ProposalRequestPersonalSnapshotInput
    $uuid: String
  ) {
    updateProposalRequestPersonalSnapshot(payload: $payload, uuid: $uuid) {
      ...ProposalRequestPersonalSnapshotFragment
    }
  }
  ${ProposalRequestPersonalSnapshotFragmentFragmentDoc}
`;
export type UpdateProposalRequestPersonalSnapshotMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateProposalRequestPersonalSnapshotMutation,
  Types.UpdateProposalRequestPersonalSnapshotMutationVariables
>;

/**
 * __useUpdateProposalRequestPersonalSnapshotMutation__
 *
 * To run a mutation, you first call `useUpdateProposalRequestPersonalSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalRequestPersonalSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalRequestPersonalSnapshotMutation, { data, loading, error }] = useUpdateProposalRequestPersonalSnapshotMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateProposalRequestPersonalSnapshotMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateProposalRequestPersonalSnapshotMutation,
    Types.UpdateProposalRequestPersonalSnapshotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateProposalRequestPersonalSnapshotMutation,
    Types.UpdateProposalRequestPersonalSnapshotMutationVariables
  >(UpdateProposalRequestPersonalSnapshotDocument, options);
}
export type UpdateProposalRequestPersonalSnapshotMutationHookResult = ReturnType<
  typeof useUpdateProposalRequestPersonalSnapshotMutation
>;
export type UpdateProposalRequestPersonalSnapshotMutationResult = ApolloReactCommon.MutationResult<Types.UpdateProposalRequestPersonalSnapshotMutation>;
export type UpdateProposalRequestPersonalSnapshotMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateProposalRequestPersonalSnapshotMutation,
  Types.UpdateProposalRequestPersonalSnapshotMutationVariables
>;
export const GetAllProposalRequestsDocument = gql`
  query GetAllProposalRequests($filters: ProposalRequestInput) {
    allProposalRequests(filters: $filters) {
      nodes {
        uuid
        date_submitted
        affiliation {
          uuid
        }
      }
    }
  }
`;

/**
 * __useGetAllProposalRequestsQuery__
 *
 * To run a query within a React component, call `useGetAllProposalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProposalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProposalRequestsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllProposalRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetAllProposalRequestsQuery,
    Types.GetAllProposalRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllProposalRequestsQuery,
    Types.GetAllProposalRequestsQueryVariables
  >(GetAllProposalRequestsDocument, options);
}
export function useGetAllProposalRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllProposalRequestsQuery,
    Types.GetAllProposalRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllProposalRequestsQuery,
    Types.GetAllProposalRequestsQueryVariables
  >(GetAllProposalRequestsDocument, options);
}
export type GetAllProposalRequestsQueryHookResult = ReturnType<
  typeof useGetAllProposalRequestsQuery
>;
export type GetAllProposalRequestsLazyQueryHookResult = ReturnType<
  typeof useGetAllProposalRequestsLazyQuery
>;
export type GetAllProposalRequestsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllProposalRequestsQuery,
  Types.GetAllProposalRequestsQueryVariables
>;
export const GetEquityPlanTimelineDocument = gql`
  query GetEquityPlanTimeline($affiliation: UUID!) {
    EP_equityPlanTimeline(affiliation: $affiliation) {
      ...EPTimeline
    }
  }
  ${EpTimelineFragmentDoc}
`;

/**
 * __useGetEquityPlanTimelineQuery__
 *
 * To run a query within a React component, call `useGetEquityPlanTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquityPlanTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquityPlanTimelineQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetEquityPlanTimelineQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetEquityPlanTimelineQuery,
    Types.GetEquityPlanTimelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetEquityPlanTimelineQuery,
    Types.GetEquityPlanTimelineQueryVariables
  >(GetEquityPlanTimelineDocument, options);
}
export function useGetEquityPlanTimelineLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetEquityPlanTimelineQuery,
    Types.GetEquityPlanTimelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetEquityPlanTimelineQuery,
    Types.GetEquityPlanTimelineQueryVariables
  >(GetEquityPlanTimelineDocument, options);
}
export type GetEquityPlanTimelineQueryHookResult = ReturnType<
  typeof useGetEquityPlanTimelineQuery
>;
export type GetEquityPlanTimelineLazyQueryHookResult = ReturnType<
  typeof useGetEquityPlanTimelineLazyQuery
>;
export type GetEquityPlanTimelineQueryResult = ApolloReactCommon.QueryResult<
  Types.GetEquityPlanTimelineQuery,
  Types.GetEquityPlanTimelineQueryVariables
>;
export const CompletePaymentDocument = gql`
  mutation completePayment($riaDeal: UUID!) {
    riaDealMutations {
      completePayment(riaDeal: $riaDeal) {
        ...RiaDeal
      }
    }
  }
  ${RiaDealFragmentDoc}
`;
export type CompletePaymentMutationFn = ApolloReactCommon.MutationFunction<
  Types.CompletePaymentMutation,
  Types.CompletePaymentMutationVariables
>;

/**
 * __useCompletePaymentMutation__
 *
 * To run a mutation, you first call `useCompletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePaymentMutation, { data, loading, error }] = useCompletePaymentMutation({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useCompletePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CompletePaymentMutation,
    Types.CompletePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CompletePaymentMutation,
    Types.CompletePaymentMutationVariables
  >(CompletePaymentDocument, options);
}
export type CompletePaymentMutationHookResult = ReturnType<
  typeof useCompletePaymentMutation
>;
export type CompletePaymentMutationResult = ApolloReactCommon.MutationResult<Types.CompletePaymentMutation>;
export type CompletePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CompletePaymentMutation,
  Types.CompletePaymentMutationVariables
>;
export const EndKycFlowDocument = gql`
  mutation endKYCFlow($inquiryUUID: UUID!, $riaDeal: UUID!) {
    mutationsRIA {
      endKYCFlow(inquiry_id: $inquiryUUID, ria_deal: $riaDeal) {
        inquiry_id
      }
    }
  }
`;
export type EndKycFlowMutationFn = ApolloReactCommon.MutationFunction<
  Types.EndKycFlowMutation,
  Types.EndKycFlowMutationVariables
>;

/**
 * __useEndKycFlowMutation__
 *
 * To run a mutation, you first call `useEndKycFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndKycFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endKycFlowMutation, { data, loading, error }] = useEndKycFlowMutation({
 *   variables: {
 *      inquiryUUID: // value for 'inquiryUUID'
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useEndKycFlowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EndKycFlowMutation,
    Types.EndKycFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.EndKycFlowMutation,
    Types.EndKycFlowMutationVariables
  >(EndKycFlowDocument, options);
}
export type EndKycFlowMutationHookResult = ReturnType<
  typeof useEndKycFlowMutation
>;
export type EndKycFlowMutationResult = ApolloReactCommon.MutationResult<Types.EndKycFlowMutation>;
export type EndKycFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EndKycFlowMutation,
  Types.EndKycFlowMutationVariables
>;
export const InitKycFlowDocument = gql`
  mutation initKYCFlow($riaDeal: UUID!, $owner: UUID!) {
    mutationsRIA {
      initKYCFlow(ria_deal: $riaDeal, owner: $owner) {
        inquiry_id
        session_token
      }
    }
  }
`;
export type InitKycFlowMutationFn = ApolloReactCommon.MutationFunction<
  Types.InitKycFlowMutation,
  Types.InitKycFlowMutationVariables
>;

/**
 * __useInitKycFlowMutation__
 *
 * To run a mutation, you first call `useInitKycFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitKycFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initKycFlowMutation, { data, loading, error }] = useInitKycFlowMutation({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useInitKycFlowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InitKycFlowMutation,
    Types.InitKycFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.InitKycFlowMutation,
    Types.InitKycFlowMutationVariables
  >(InitKycFlowDocument, options);
}
export type InitKycFlowMutationHookResult = ReturnType<
  typeof useInitKycFlowMutation
>;
export type InitKycFlowMutationResult = ApolloReactCommon.MutationResult<Types.InitKycFlowMutation>;
export type InitKycFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InitKycFlowMutation,
  Types.InitKycFlowMutationVariables
>;
export const UpdateAdvisoryAgreementDocument = gql`
  mutation updateAdvisoryAgreement(
    $owner: UUID!
    $payload: AdvisoryAgreementInput!
  ) {
    mutationsRIA {
      updateAdvisoryAgreement(owner: $owner, payload: $payload)
    }
  }
`;
export type UpdateAdvisoryAgreementMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateAdvisoryAgreementMutation,
  Types.UpdateAdvisoryAgreementMutationVariables
>;

/**
 * __useUpdateAdvisoryAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateAdvisoryAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvisoryAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvisoryAgreementMutation, { data, loading, error }] = useUpdateAdvisoryAgreementMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateAdvisoryAgreementMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateAdvisoryAgreementMutation,
    Types.UpdateAdvisoryAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateAdvisoryAgreementMutation,
    Types.UpdateAdvisoryAgreementMutationVariables
  >(UpdateAdvisoryAgreementDocument, options);
}
export type UpdateAdvisoryAgreementMutationHookResult = ReturnType<
  typeof useUpdateAdvisoryAgreementMutation
>;
export type UpdateAdvisoryAgreementMutationResult = ApolloReactCommon.MutationResult<Types.UpdateAdvisoryAgreementMutation>;
export type UpdateAdvisoryAgreementMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateAdvisoryAgreementMutation,
  Types.UpdateAdvisoryAgreementMutationVariables
>;
export const UpdateComplianceInformationDocument = gql`
  mutation updateComplianceInformation(
    $owner: UUID!
    $payload: ComplianceInformationRIAInput!
  ) {
    mutationsRIA {
      updateComplianceInformation(owner: $owner, payload: $payload) {
        data {
          employer
          employment
          financial_industry_regulatory_authority
          income
          industry
          is_financial_industry_regulatory_authority
          is_political_officer
          is_senior_officer
          liquid_net_worth
          political_officer_country
          position
          senior_officer_company
          senior_officer_ticker
          total_net_worth
        }
        feedback {
          created_at
          created_by
          message
        }
        ria_deal
        status
        uuid
      }
    }
  }
`;
export type UpdateComplianceInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateComplianceInformationMutation,
  Types.UpdateComplianceInformationMutationVariables
>;

/**
 * __useUpdateComplianceInformationMutation__
 *
 * To run a mutation, you first call `useUpdateComplianceInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComplianceInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComplianceInformationMutation, { data, loading, error }] = useUpdateComplianceInformationMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateComplianceInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateComplianceInformationMutation,
    Types.UpdateComplianceInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateComplianceInformationMutation,
    Types.UpdateComplianceInformationMutationVariables
  >(UpdateComplianceInformationDocument, options);
}
export type UpdateComplianceInformationMutationHookResult = ReturnType<
  typeof useUpdateComplianceInformationMutation
>;
export type UpdateComplianceInformationMutationResult = ApolloReactCommon.MutationResult<Types.UpdateComplianceInformationMutation>;
export type UpdateComplianceInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateComplianceInformationMutation,
  Types.UpdateComplianceInformationMutationVariables
>;
export const UpdatePayStubAccountStatementDocument = gql`
  mutation updatePayStubAccountStatement(
    $owner: UUID!
    $payload: PayStubAccountStatementInput!
  ) {
    mutationsRIA {
      updatePayStubAccountStatement(owner: $owner, payload: $payload) {
        uuid
        status
        feedback {
          created_at
          created_by
          message
        }
        files {
          created_at
          document_type
          file_name
          file_type
          updated_at
          url
          uuid
        }
      }
    }
  }
`;
export type UpdatePayStubAccountStatementMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdatePayStubAccountStatementMutation,
  Types.UpdatePayStubAccountStatementMutationVariables
>;

/**
 * __useUpdatePayStubAccountStatementMutation__
 *
 * To run a mutation, you first call `useUpdatePayStubAccountStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayStubAccountStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayStubAccountStatementMutation, { data, loading, error }] = useUpdatePayStubAccountStatementMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdatePayStubAccountStatementMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdatePayStubAccountStatementMutation,
    Types.UpdatePayStubAccountStatementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdatePayStubAccountStatementMutation,
    Types.UpdatePayStubAccountStatementMutationVariables
  >(UpdatePayStubAccountStatementDocument, options);
}
export type UpdatePayStubAccountStatementMutationHookResult = ReturnType<
  typeof useUpdatePayStubAccountStatementMutation
>;
export type UpdatePayStubAccountStatementMutationResult = ApolloReactCommon.MutationResult<Types.UpdatePayStubAccountStatementMutation>;
export type UpdatePayStubAccountStatementMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdatePayStubAccountStatementMutation,
  Types.UpdatePayStubAccountStatementMutationVariables
>;
export const UpdatePersonalInformationDocument = gql`
  mutation updatePersonalInformation(
    $owner: UUID!
    $payload: PersonalInformationRIAInput!
  ) {
    mutationsRIA {
      updatePersonalInformation(owner: $owner, payload: $payload) {
        data {
          address_street_1
          address_street_2
          citizenship
          city
          country
          country_of_tax_residence
          date_of_birth
          legal_name
          marital_status
          phone_number
          postal_code
          preferred_email
          social_security
          subdivision
          taxpayer_id
        }
        feedback {
          created_at
          created_by
          message
        }
        ria_deal
        status
        uuid
      }
    }
  }
`;
export type UpdatePersonalInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdatePersonalInformationMutation,
  Types.UpdatePersonalInformationMutationVariables
>;

/**
 * __useUpdatePersonalInformationMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalInformationMutation, { data, loading, error }] = useUpdatePersonalInformationMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdatePersonalInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdatePersonalInformationMutation,
    Types.UpdatePersonalInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdatePersonalInformationMutation,
    Types.UpdatePersonalInformationMutationVariables
  >(UpdatePersonalInformationDocument, options);
}
export type UpdatePersonalInformationMutationHookResult = ReturnType<
  typeof useUpdatePersonalInformationMutation
>;
export type UpdatePersonalInformationMutationResult = ApolloReactCommon.MutationResult<Types.UpdatePersonalInformationMutation>;
export type UpdatePersonalInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdatePersonalInformationMutation,
  Types.UpdatePersonalInformationMutationVariables
>;
export const UpdateRiskAssessmentDocument = gql`
  mutation updateRiskAssessment($owner: UUID!, $payload: RiskAssessmentInput!) {
    mutationsRIA {
      updateRiskAssessment(owner: $owner, payload: $payload) {
        download_file_name
        status
        uuid
        feedback {
          created_at
          created_by
          message
        }
        files {
          created_at
          file_name
          file_type
          updated_at
          url
          uuid
        }
      }
    }
  }
`;
export type UpdateRiskAssessmentMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateRiskAssessmentMutation,
  Types.UpdateRiskAssessmentMutationVariables
>;

/**
 * __useUpdateRiskAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateRiskAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiskAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiskAssessmentMutation, { data, loading, error }] = useUpdateRiskAssessmentMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateRiskAssessmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateRiskAssessmentMutation,
    Types.UpdateRiskAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateRiskAssessmentMutation,
    Types.UpdateRiskAssessmentMutationVariables
  >(UpdateRiskAssessmentDocument, options);
}
export type UpdateRiskAssessmentMutationHookResult = ReturnType<
  typeof useUpdateRiskAssessmentMutation
>;
export type UpdateRiskAssessmentMutationResult = ApolloReactCommon.MutationResult<Types.UpdateRiskAssessmentMutation>;
export type UpdateRiskAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateRiskAssessmentMutation,
  Types.UpdateRiskAssessmentMutationVariables
>;
export const GetRiaComplianceInformationDocument = gql`
  query getRIAComplianceInformation($riaDeal: UUID!) {
    queriesRIA {
      ComplianceInformation(ria_deal: $riaDeal) {
        data {
          employer
          employment
          financial_industry_regulatory_authority
          income
          industry
          is_financial_industry_regulatory_authority
          is_political_officer
          is_senior_officer
          liquid_net_worth
          political_officer_country
          position
          senior_officer_company
          senior_officer_ticker
          total_net_worth
        }
        feedback {
          created_at
          created_by
          message
        }
        ria_deal
        status
        uuid
      }
    }
  }
`;

/**
 * __useGetRiaComplianceInformationQuery__
 *
 * To run a query within a React component, call `useGetRiaComplianceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiaComplianceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiaComplianceInformationQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetRiaComplianceInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRiaComplianceInformationQuery,
    Types.GetRiaComplianceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRiaComplianceInformationQuery,
    Types.GetRiaComplianceInformationQueryVariables
  >(GetRiaComplianceInformationDocument, options);
}
export function useGetRiaComplianceInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRiaComplianceInformationQuery,
    Types.GetRiaComplianceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRiaComplianceInformationQuery,
    Types.GetRiaComplianceInformationQueryVariables
  >(GetRiaComplianceInformationDocument, options);
}
export type GetRiaComplianceInformationQueryHookResult = ReturnType<
  typeof useGetRiaComplianceInformationQuery
>;
export type GetRiaComplianceInformationLazyQueryHookResult = ReturnType<
  typeof useGetRiaComplianceInformationLazyQuery
>;
export type GetRiaComplianceInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRiaComplianceInformationQuery,
  Types.GetRiaComplianceInformationQueryVariables
>;
export const GetRiaIdentificationInformationDocument = gql`
  query getRIAIdentificationInformation($riaDeal: UUID!) {
    queriesRIA {
      IdentificationInformation(ria_deal: $riaDeal) {
        ria_deal
        status
        uuid
        feedback {
          message
        }
      }
    }
  }
`;

/**
 * __useGetRiaIdentificationInformationQuery__
 *
 * To run a query within a React component, call `useGetRiaIdentificationInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiaIdentificationInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiaIdentificationInformationQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetRiaIdentificationInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRiaIdentificationInformationQuery,
    Types.GetRiaIdentificationInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRiaIdentificationInformationQuery,
    Types.GetRiaIdentificationInformationQueryVariables
  >(GetRiaIdentificationInformationDocument, options);
}
export function useGetRiaIdentificationInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRiaIdentificationInformationQuery,
    Types.GetRiaIdentificationInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRiaIdentificationInformationQuery,
    Types.GetRiaIdentificationInformationQueryVariables
  >(GetRiaIdentificationInformationDocument, options);
}
export type GetRiaIdentificationInformationQueryHookResult = ReturnType<
  typeof useGetRiaIdentificationInformationQuery
>;
export type GetRiaIdentificationInformationLazyQueryHookResult = ReturnType<
  typeof useGetRiaIdentificationInformationLazyQuery
>;
export type GetRiaIdentificationInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRiaIdentificationInformationQuery,
  Types.GetRiaIdentificationInformationQueryVariables
>;
export const GetPayStubAccountStatementDocument = gql`
  query getPayStubAccountStatement($riaDeal: UUID!) {
    queriesRIA {
      PayStubAccountStatement(ria_deal: $riaDeal) {
        ...PayStubAccountStatement
      }
    }
  }
  ${PayStubAccountStatementFragmentDoc}
`;

/**
 * __useGetPayStubAccountStatementQuery__
 *
 * To run a query within a React component, call `useGetPayStubAccountStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubAccountStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubAccountStatementQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetPayStubAccountStatementQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetPayStubAccountStatementQuery,
    Types.GetPayStubAccountStatementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetPayStubAccountStatementQuery,
    Types.GetPayStubAccountStatementQueryVariables
  >(GetPayStubAccountStatementDocument, options);
}
export function useGetPayStubAccountStatementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetPayStubAccountStatementQuery,
    Types.GetPayStubAccountStatementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetPayStubAccountStatementQuery,
    Types.GetPayStubAccountStatementQueryVariables
  >(GetPayStubAccountStatementDocument, options);
}
export type GetPayStubAccountStatementQueryHookResult = ReturnType<
  typeof useGetPayStubAccountStatementQuery
>;
export type GetPayStubAccountStatementLazyQueryHookResult = ReturnType<
  typeof useGetPayStubAccountStatementLazyQuery
>;
export type GetPayStubAccountStatementQueryResult = ApolloReactCommon.QueryResult<
  Types.GetPayStubAccountStatementQuery,
  Types.GetPayStubAccountStatementQueryVariables
>;
export const GetRiaPersonalInformationDocument = gql`
  query getRIAPersonalInformation($riaDeal: UUID!) {
    queriesRIA {
      PersonalInformation(ria_deal: $riaDeal) {
        data {
          address_street_1
          address_street_2
          citizenship
          city
          country
          country_of_tax_residence
          date_of_birth
          legal_name
          marital_status
          phone_number
          postal_code
          preferred_email
          social_security
          subdivision
          taxpayer_id
        }
        feedback {
          created_at
          created_by
          message
        }
        ria_deal
        status
        uuid
      }
    }
  }
`;

/**
 * __useGetRiaPersonalInformationQuery__
 *
 * To run a query within a React component, call `useGetRiaPersonalInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiaPersonalInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiaPersonalInformationQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetRiaPersonalInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRiaPersonalInformationQuery,
    Types.GetRiaPersonalInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRiaPersonalInformationQuery,
    Types.GetRiaPersonalInformationQueryVariables
  >(GetRiaPersonalInformationDocument, options);
}
export function useGetRiaPersonalInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRiaPersonalInformationQuery,
    Types.GetRiaPersonalInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRiaPersonalInformationQuery,
    Types.GetRiaPersonalInformationQueryVariables
  >(GetRiaPersonalInformationDocument, options);
}
export type GetRiaPersonalInformationQueryHookResult = ReturnType<
  typeof useGetRiaPersonalInformationQuery
>;
export type GetRiaPersonalInformationLazyQueryHookResult = ReturnType<
  typeof useGetRiaPersonalInformationLazyQuery
>;
export type GetRiaPersonalInformationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRiaPersonalInformationQuery,
  Types.GetRiaPersonalInformationQueryVariables
>;
export const GetRiskAssessmentDocument = gql`
  query getRiskAssessment($riaDeal: UUID!) {
    queriesRIA {
      RiskAssessment(ria_deal: $riaDeal) {
        ...RiskAssessment
      }
    }
  }
  ${RiskAssessmentFragmentDoc}
`;

/**
 * __useGetRiskAssessmentQuery__
 *
 * To run a query within a React component, call `useGetRiskAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskAssessmentQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetRiskAssessmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRiskAssessmentQuery,
    Types.GetRiskAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRiskAssessmentQuery,
    Types.GetRiskAssessmentQueryVariables
  >(GetRiskAssessmentDocument, options);
}
export function useGetRiskAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRiskAssessmentQuery,
    Types.GetRiskAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRiskAssessmentQuery,
    Types.GetRiskAssessmentQueryVariables
  >(GetRiskAssessmentDocument, options);
}
export type GetRiskAssessmentQueryHookResult = ReturnType<
  typeof useGetRiskAssessmentQuery
>;
export type GetRiskAssessmentLazyQueryHookResult = ReturnType<
  typeof useGetRiskAssessmentLazyQuery
>;
export type GetRiskAssessmentQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRiskAssessmentQuery,
  Types.GetRiskAssessmentQueryVariables
>;
export const CreateRiaDealsDocument = gql`
  mutation CreateRiaDeals(
    $customer: UUID!
    $productCategory: RIADealProductCategory!
    $product: RIADealProduct!
    $signedUpWithLink: Boolean
    $source: RIADealSource!
    $signedUpWithWaitList: Boolean
    $questionnaireAnswers: [RIADealQuestionnaireAnswerInput!]
  ) {
    riaDealMutations {
      create(
        customer: $customer
        product: $product
        productCategory: $productCategory
        signedUpWithLink: $signedUpWithLink
        source: $source
        signedUpWithWaitlist: $signedUpWithWaitList
        questionnaireAnswers: $questionnaireAnswers
      ) {
        productCategory
        uuid
      }
    }
  }
`;
export type CreateRiaDealsMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateRiaDealsMutation,
  Types.CreateRiaDealsMutationVariables
>;

/**
 * __useCreateRiaDealsMutation__
 *
 * To run a mutation, you first call `useCreateRiaDealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRiaDealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRiaDealsMutation, { data, loading, error }] = useCreateRiaDealsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      productCategory: // value for 'productCategory'
 *      product: // value for 'product'
 *      signedUpWithLink: // value for 'signedUpWithLink'
 *      source: // value for 'source'
 *      signedUpWithWaitList: // value for 'signedUpWithWaitList'
 *      questionnaireAnswers: // value for 'questionnaireAnswers'
 *   },
 * });
 */
export function useCreateRiaDealsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateRiaDealsMutation,
    Types.CreateRiaDealsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateRiaDealsMutation,
    Types.CreateRiaDealsMutationVariables
  >(CreateRiaDealsDocument, options);
}
export type CreateRiaDealsMutationHookResult = ReturnType<
  typeof useCreateRiaDealsMutation
>;
export type CreateRiaDealsMutationResult = ApolloReactCommon.MutationResult<Types.CreateRiaDealsMutation>;
export type CreateRiaDealsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateRiaDealsMutation,
  Types.CreateRiaDealsMutationVariables
>;
export const MoveAdvisoryCustomerToWealthDocument = gql`
  mutation MoveAdvisoryCustomerToWealth($customer: UUID!) {
    riaDealMutations {
      moveToWealthIntroCall(customer: $customer) {
        uuid
      }
    }
  }
`;
export type MoveAdvisoryCustomerToWealthMutationFn = ApolloReactCommon.MutationFunction<
  Types.MoveAdvisoryCustomerToWealthMutation,
  Types.MoveAdvisoryCustomerToWealthMutationVariables
>;

/**
 * __useMoveAdvisoryCustomerToWealthMutation__
 *
 * To run a mutation, you first call `useMoveAdvisoryCustomerToWealthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveAdvisoryCustomerToWealthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveAdvisoryCustomerToWealthMutation, { data, loading, error }] = useMoveAdvisoryCustomerToWealthMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useMoveAdvisoryCustomerToWealthMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.MoveAdvisoryCustomerToWealthMutation,
    Types.MoveAdvisoryCustomerToWealthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.MoveAdvisoryCustomerToWealthMutation,
    Types.MoveAdvisoryCustomerToWealthMutationVariables
  >(MoveAdvisoryCustomerToWealthDocument, options);
}
export type MoveAdvisoryCustomerToWealthMutationHookResult = ReturnType<
  typeof useMoveAdvisoryCustomerToWealthMutation
>;
export type MoveAdvisoryCustomerToWealthMutationResult = ApolloReactCommon.MutationResult<Types.MoveAdvisoryCustomerToWealthMutation>;
export type MoveAdvisoryCustomerToWealthMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.MoveAdvisoryCustomerToWealthMutation,
  Types.MoveAdvisoryCustomerToWealthMutationVariables
>;
export const MoveToStatusDocument = gql`
  mutation MoveToStatus(
    $riaDeal: UUID!
    $status: RIADealStatus!
    $substatus: RIADealSubstatus!
  ) {
    riaDealMutations {
      moveToStatus(riaDeal: $riaDeal, status: $status, substatus: $substatus) {
        uuid
        status
        substatus
      }
    }
  }
`;
export type MoveToStatusMutationFn = ApolloReactCommon.MutationFunction<
  Types.MoveToStatusMutation,
  Types.MoveToStatusMutationVariables
>;

/**
 * __useMoveToStatusMutation__
 *
 * To run a mutation, you first call `useMoveToStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToStatusMutation, { data, loading, error }] = useMoveToStatusMutation({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *      status: // value for 'status'
 *      substatus: // value for 'substatus'
 *   },
 * });
 */
export function useMoveToStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.MoveToStatusMutation,
    Types.MoveToStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.MoveToStatusMutation,
    Types.MoveToStatusMutationVariables
  >(MoveToStatusDocument, options);
}
export type MoveToStatusMutationHookResult = ReturnType<
  typeof useMoveToStatusMutation
>;
export type MoveToStatusMutationResult = ApolloReactCommon.MutationResult<Types.MoveToStatusMutation>;
export type MoveToStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.MoveToStatusMutation,
  Types.MoveToStatusMutationVariables
>;
export const GetCustomerActiveRiaDealDocument = gql`
  query GetCustomerActiveRiaDeal($user: UUID!) {
    riaDealQueries {
      activeByUser(user: $user) {
        ...RiaDeal
      }
    }
  }
  ${RiaDealFragmentDoc}
`;

/**
 * __useGetCustomerActiveRiaDealQuery__
 *
 * To run a query within a React component, call `useGetCustomerActiveRiaDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerActiveRiaDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerActiveRiaDealQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetCustomerActiveRiaDealQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCustomerActiveRiaDealQuery,
    Types.GetCustomerActiveRiaDealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCustomerActiveRiaDealQuery,
    Types.GetCustomerActiveRiaDealQueryVariables
  >(GetCustomerActiveRiaDealDocument, options);
}
export function useGetCustomerActiveRiaDealLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCustomerActiveRiaDealQuery,
    Types.GetCustomerActiveRiaDealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCustomerActiveRiaDealQuery,
    Types.GetCustomerActiveRiaDealQueryVariables
  >(GetCustomerActiveRiaDealDocument, options);
}
export type GetCustomerActiveRiaDealQueryHookResult = ReturnType<
  typeof useGetCustomerActiveRiaDealQuery
>;
export type GetCustomerActiveRiaDealLazyQueryHookResult = ReturnType<
  typeof useGetCustomerActiveRiaDealLazyQuery
>;
export type GetCustomerActiveRiaDealQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCustomerActiveRiaDealQuery,
  Types.GetCustomerActiveRiaDealQueryVariables
>;
export const AddCustomerToWaitlistDocument = gql`
  mutation AddCustomerToWaitlist(
    $waitlistCategory: String!
    $waitlistName: String!
    $waitlistPrice: Float!
    $source: String
    $questions: [CustomerWaitlistQuestionInput]
  ) {
    addCustomerToWaitlist(
      waitlistCategory: $waitlistCategory
      waitlistName: $waitlistName
      waitlistPrice: $waitlistPrice
      questions: $questions
      source: $source
    ) {
      ...CustomerWaitlist
    }
  }
  ${CustomerWaitlistFragmentDoc}
`;
export type AddCustomerToWaitlistMutationFn = ApolloReactCommon.MutationFunction<
  Types.AddCustomerToWaitlistMutation,
  Types.AddCustomerToWaitlistMutationVariables
>;

/**
 * __useAddCustomerToWaitlistMutation__
 *
 * To run a mutation, you first call `useAddCustomerToWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerToWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerToWaitlistMutation, { data, loading, error }] = useAddCustomerToWaitlistMutation({
 *   variables: {
 *      waitlistCategory: // value for 'waitlistCategory'
 *      waitlistName: // value for 'waitlistName'
 *      waitlistPrice: // value for 'waitlistPrice'
 *      source: // value for 'source'
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useAddCustomerToWaitlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AddCustomerToWaitlistMutation,
    Types.AddCustomerToWaitlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.AddCustomerToWaitlistMutation,
    Types.AddCustomerToWaitlistMutationVariables
  >(AddCustomerToWaitlistDocument, options);
}
export type AddCustomerToWaitlistMutationHookResult = ReturnType<
  typeof useAddCustomerToWaitlistMutation
>;
export type AddCustomerToWaitlistMutationResult = ApolloReactCommon.MutationResult<Types.AddCustomerToWaitlistMutation>;
export type AddCustomerToWaitlistMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AddCustomerToWaitlistMutation,
  Types.AddCustomerToWaitlistMutationVariables
>;
export const GetWaitlistsDocument = gql`
  query GetWaitlists {
    allWaitlists {
      ...CustomerWaitlist
    }
  }
  ${CustomerWaitlistFragmentDoc}
`;

/**
 * __useGetWaitlistsQuery__
 *
 * To run a query within a React component, call `useGetWaitlistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitlistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitlistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWaitlistsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetWaitlistsQuery,
    Types.GetWaitlistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetWaitlistsQuery,
    Types.GetWaitlistsQueryVariables
  >(GetWaitlistsDocument, options);
}
export function useGetWaitlistsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetWaitlistsQuery,
    Types.GetWaitlistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetWaitlistsQuery,
    Types.GetWaitlistsQueryVariables
  >(GetWaitlistsDocument, options);
}
export type GetWaitlistsQueryHookResult = ReturnType<
  typeof useGetWaitlistsQuery
>;
export type GetWaitlistsLazyQueryHookResult = ReturnType<
  typeof useGetWaitlistsLazyQuery
>;
export type GetWaitlistsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetWaitlistsQuery,
  Types.GetWaitlistsQueryVariables
>;
export const SearchCompaniesDocument = gql`
  query SearchCompanies($term: String!) {
    companies: searchCompanies(term: $term) {
      ...CompanySearchResultItem
    }
  }
  ${CompanySearchResultItemFragmentDoc}
`;

/**
 * __useSearchCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchCompaniesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchCompaniesQuery,
    Types.SearchCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.SearchCompaniesQuery,
    Types.SearchCompaniesQueryVariables
  >(SearchCompaniesDocument, options);
}
export function useSearchCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchCompaniesQuery,
    Types.SearchCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.SearchCompaniesQuery,
    Types.SearchCompaniesQueryVariables
  >(SearchCompaniesDocument, options);
}
export type SearchCompaniesQueryHookResult = ReturnType<
  typeof useSearchCompaniesQuery
>;
export type SearchCompaniesLazyQueryHookResult = ReturnType<
  typeof useSearchCompaniesLazyQuery
>;
export type SearchCompaniesQueryResult = ApolloReactCommon.QueryResult<
  Types.SearchCompaniesQuery,
  Types.SearchCompaniesQueryVariables
>;
export const SelectNewCompanyDocument = gql`
  mutation SelectNewCompany(
    $importCompany: ImportCompanyInput
    $newCompany: NewCompanyInput
  ) {
    selectNewCompany(importCompany: $importCompany, newCompany: $newCompany) {
      ...CompanyShortInfo
    }
  }
  ${CompanyShortInfoFragmentDoc}
`;
export type SelectNewCompanyMutationFn = ApolloReactCommon.MutationFunction<
  Types.SelectNewCompanyMutation,
  Types.SelectNewCompanyMutationVariables
>;

/**
 * __useSelectNewCompanyMutation__
 *
 * To run a mutation, you first call `useSelectNewCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectNewCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectNewCompanyMutation, { data, loading, error }] = useSelectNewCompanyMutation({
 *   variables: {
 *      importCompany: // value for 'importCompany'
 *      newCompany: // value for 'newCompany'
 *   },
 * });
 */
export function useSelectNewCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SelectNewCompanyMutation,
    Types.SelectNewCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.SelectNewCompanyMutation,
    Types.SelectNewCompanyMutationVariables
  >(SelectNewCompanyDocument, options);
}
export type SelectNewCompanyMutationHookResult = ReturnType<
  typeof useSelectNewCompanyMutation
>;
export type SelectNewCompanyMutationResult = ApolloReactCommon.MutationResult<Types.SelectNewCompanyMutation>;
export type SelectNewCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SelectNewCompanyMutation,
  Types.SelectNewCompanyMutationVariables
>;
export const GetCompanyStatusAndSubstatusDocument = gql`
  query GetCompanyStatusAndSubstatus($uuid: String!) {
    Company(uuid: $uuid) {
      company_status
      company_substatus
    }
  }
`;

/**
 * __useGetCompanyStatusAndSubstatusQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatusAndSubstatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatusAndSubstatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatusAndSubstatusQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCompanyStatusAndSubstatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCompanyStatusAndSubstatusQuery,
    Types.GetCompanyStatusAndSubstatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCompanyStatusAndSubstatusQuery,
    Types.GetCompanyStatusAndSubstatusQueryVariables
  >(GetCompanyStatusAndSubstatusDocument, options);
}
export function useGetCompanyStatusAndSubstatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCompanyStatusAndSubstatusQuery,
    Types.GetCompanyStatusAndSubstatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCompanyStatusAndSubstatusQuery,
    Types.GetCompanyStatusAndSubstatusQueryVariables
  >(GetCompanyStatusAndSubstatusDocument, options);
}
export type GetCompanyStatusAndSubstatusQueryHookResult = ReturnType<
  typeof useGetCompanyStatusAndSubstatusQuery
>;
export type GetCompanyStatusAndSubstatusLazyQueryHookResult = ReturnType<
  typeof useGetCompanyStatusAndSubstatusLazyQuery
>;
export type GetCompanyStatusAndSubstatusQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCompanyStatusAndSubstatusQuery,
  Types.GetCompanyStatusAndSubstatusQueryVariables
>;
export const CreateScheduledAppointmentDocument = gql`
  mutation CreateScheduledAppointment(
    $affiliationUuid: ID
    $analytics: ScheduledAppointmentAnalyticsInput!
    $appointmentType: AppointmentType!
    $calendlyEventUri: String!
    $calendlyInviteeUri: String!
    $riaDeal: ID
    $rescheduledAppointmentUuid: ID
  ) {
    createScheduledAppointment(
      affiliationUuid: $affiliationUuid
      analytics: $analytics
      appointmentType: $appointmentType
      calendlyEventUri: $calendlyEventUri
      calendlyInviteeUri: $calendlyInviteeUri
      riaDeal: $riaDeal
      rescheduledAppointmentUuid: $rescheduledAppointmentUuid
    ) {
      ...ScheduledAppointment
    }
  }
  ${ScheduledAppointmentFragmentDoc}
`;
export type CreateScheduledAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateScheduledAppointmentMutation,
  Types.CreateScheduledAppointmentMutationVariables
>;

/**
 * __useCreateScheduledAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateScheduledAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledAppointmentMutation, { data, loading, error }] = useCreateScheduledAppointmentMutation({
 *   variables: {
 *      affiliationUuid: // value for 'affiliationUuid'
 *      analytics: // value for 'analytics'
 *      appointmentType: // value for 'appointmentType'
 *      calendlyEventUri: // value for 'calendlyEventUri'
 *      calendlyInviteeUri: // value for 'calendlyInviteeUri'
 *      riaDeal: // value for 'riaDeal'
 *      rescheduledAppointmentUuid: // value for 'rescheduledAppointmentUuid'
 *   },
 * });
 */
export function useCreateScheduledAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateScheduledAppointmentMutation,
    Types.CreateScheduledAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateScheduledAppointmentMutation,
    Types.CreateScheduledAppointmentMutationVariables
  >(CreateScheduledAppointmentDocument, options);
}
export type CreateScheduledAppointmentMutationHookResult = ReturnType<
  typeof useCreateScheduledAppointmentMutation
>;
export type CreateScheduledAppointmentMutationResult = ApolloReactCommon.MutationResult<Types.CreateScheduledAppointmentMutation>;
export type CreateScheduledAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateScheduledAppointmentMutation,
  Types.CreateScheduledAppointmentMutationVariables
>;
export const GetScheduledAppointmentsDocument = gql`
  query GetScheduledAppointments(
    $user: ID!
    $affiliationUuid: ID
    $appointmentType: AppointmentType!
    $riaDeal: ID
  ) {
    scheduledAppointments(
      user: $user
      affiliationUuid: $affiliationUuid
      appointmentType: $appointmentType
      riaDeal: $riaDeal
    ) {
      ...ScheduledAppointment
    }
  }
  ${ScheduledAppointmentFragmentDoc}
`;

/**
 * __useGetScheduledAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetScheduledAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledAppointmentsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      affiliationUuid: // value for 'affiliationUuid'
 *      appointmentType: // value for 'appointmentType'
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useGetScheduledAppointmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetScheduledAppointmentsQuery,
    Types.GetScheduledAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetScheduledAppointmentsQuery,
    Types.GetScheduledAppointmentsQueryVariables
  >(GetScheduledAppointmentsDocument, options);
}
export function useGetScheduledAppointmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetScheduledAppointmentsQuery,
    Types.GetScheduledAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetScheduledAppointmentsQuery,
    Types.GetScheduledAppointmentsQueryVariables
  >(GetScheduledAppointmentsDocument, options);
}
export type GetScheduledAppointmentsQueryHookResult = ReturnType<
  typeof useGetScheduledAppointmentsQuery
>;
export type GetScheduledAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetScheduledAppointmentsLazyQuery
>;
export type GetScheduledAppointmentsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetScheduledAppointmentsQuery,
  Types.GetScheduledAppointmentsQueryVariables
>;
export const UpdateScheduledAppointmentDocument = gql`
  mutation UpdateScheduledAppointment($appointment_uuid: ID!, $riaDeal: ID) {
    updateScheduledAppointment(uuid: $appointment_uuid, riaDeal: $riaDeal) {
      ...ScheduledAppointment
    }
  }
  ${ScheduledAppointmentFragmentDoc}
`;
export type UpdateScheduledAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateScheduledAppointmentMutation,
  Types.UpdateScheduledAppointmentMutationVariables
>;

/**
 * __useUpdateScheduledAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledAppointmentMutation, { data, loading, error }] = useUpdateScheduledAppointmentMutation({
 *   variables: {
 *      appointment_uuid: // value for 'appointment_uuid'
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useUpdateScheduledAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateScheduledAppointmentMutation,
    Types.UpdateScheduledAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateScheduledAppointmentMutation,
    Types.UpdateScheduledAppointmentMutationVariables
  >(UpdateScheduledAppointmentDocument, options);
}
export type UpdateScheduledAppointmentMutationHookResult = ReturnType<
  typeof useUpdateScheduledAppointmentMutation
>;
export type UpdateScheduledAppointmentMutationResult = ApolloReactCommon.MutationResult<Types.UpdateScheduledAppointmentMutation>;
export type UpdateScheduledAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateScheduledAppointmentMutation,
  Types.UpdateScheduledAppointmentMutationVariables
>;
export const CreateExerciseEventDocument = gql`
  mutation CreateExerciseEvent($payload: ExerciseEventInput!) {
    equity {
      createExerciseEvent(payload: $payload) {
        ...ExerciseEvent
      }
    }
  }
  ${ExerciseEventFragmentDoc}
`;
export type CreateExerciseEventMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateExerciseEventMutation,
  Types.CreateExerciseEventMutationVariables
>;

/**
 * __useCreateExerciseEventMutation__
 *
 * To run a mutation, you first call `useCreateExerciseEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExerciseEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExerciseEventMutation, { data, loading, error }] = useCreateExerciseEventMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateExerciseEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateExerciseEventMutation,
    Types.CreateExerciseEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateExerciseEventMutation,
    Types.CreateExerciseEventMutationVariables
  >(CreateExerciseEventDocument, options);
}
export type CreateExerciseEventMutationHookResult = ReturnType<
  typeof useCreateExerciseEventMutation
>;
export type CreateExerciseEventMutationResult = ApolloReactCommon.MutationResult<Types.CreateExerciseEventMutation>;
export type CreateExerciseEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateExerciseEventMutation,
  Types.CreateExerciseEventMutationVariables
>;
export const CreateRsuGrantDocument = gql`
  mutation CreateRsuGrant($payload: RSUGrantInput!) {
    equity {
      createRsuGrant(payload: $payload) {
        ...RSUGrant
      }
    }
  }
  ${RsuGrantFragmentDoc}
`;
export type CreateRsuGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateRsuGrantMutation,
  Types.CreateRsuGrantMutationVariables
>;

/**
 * __useCreateRsuGrantMutation__
 *
 * To run a mutation, you first call `useCreateRsuGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRsuGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRsuGrantMutation, { data, loading, error }] = useCreateRsuGrantMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateRsuGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateRsuGrantMutation,
    Types.CreateRsuGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateRsuGrantMutation,
    Types.CreateRsuGrantMutationVariables
  >(CreateRsuGrantDocument, options);
}
export type CreateRsuGrantMutationHookResult = ReturnType<
  typeof useCreateRsuGrantMutation
>;
export type CreateRsuGrantMutationResult = ApolloReactCommon.MutationResult<Types.CreateRsuGrantMutation>;
export type CreateRsuGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateRsuGrantMutation,
  Types.CreateRsuGrantMutationVariables
>;
export const CreateEquityShareGrantDocument = gql`
  mutation CreateEquityShareGrant($payload: EquityShareGrantInput!) {
    equity {
      createShareGrant(payload: $payload) {
        ...EquityShareGrant
      }
    }
  }
  ${EquityShareGrantFragmentDoc}
`;
export type CreateEquityShareGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateEquityShareGrantMutation,
  Types.CreateEquityShareGrantMutationVariables
>;

/**
 * __useCreateEquityShareGrantMutation__
 *
 * To run a mutation, you first call `useCreateEquityShareGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquityShareGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquityShareGrantMutation, { data, loading, error }] = useCreateEquityShareGrantMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateEquityShareGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateEquityShareGrantMutation,
    Types.CreateEquityShareGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateEquityShareGrantMutation,
    Types.CreateEquityShareGrantMutationVariables
  >(CreateEquityShareGrantDocument, options);
}
export type CreateEquityShareGrantMutationHookResult = ReturnType<
  typeof useCreateEquityShareGrantMutation
>;
export type CreateEquityShareGrantMutationResult = ApolloReactCommon.MutationResult<Types.CreateEquityShareGrantMutation>;
export type CreateEquityShareGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateEquityShareGrantMutation,
  Types.CreateEquityShareGrantMutationVariables
>;
export const CreateStockOptionGrantDocument = gql`
  mutation CreateStockOptionGrant($payload: StockOptionGrantInput!) {
    equity {
      createStockOptionGrant(payload: $payload) {
        ...StockOptionGrant
      }
    }
  }
  ${StockOptionGrantFragmentDoc}
`;
export type CreateStockOptionGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateStockOptionGrantMutation,
  Types.CreateStockOptionGrantMutationVariables
>;

/**
 * __useCreateStockOptionGrantMutation__
 *
 * To run a mutation, you first call `useCreateStockOptionGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockOptionGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockOptionGrantMutation, { data, loading, error }] = useCreateStockOptionGrantMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateStockOptionGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateStockOptionGrantMutation,
    Types.CreateStockOptionGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateStockOptionGrantMutation,
    Types.CreateStockOptionGrantMutationVariables
  >(CreateStockOptionGrantDocument, options);
}
export type CreateStockOptionGrantMutationHookResult = ReturnType<
  typeof useCreateStockOptionGrantMutation
>;
export type CreateStockOptionGrantMutationResult = ApolloReactCommon.MutationResult<Types.CreateStockOptionGrantMutation>;
export type CreateStockOptionGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateStockOptionGrantMutation,
  Types.CreateStockOptionGrantMutationVariables
>;
export const DeleteExerciseEventDocument = gql`
  mutation DeleteExerciseEvent($grantUuid: UUID!, $uuid: UUID!) {
    equity {
      deleteExerciseEvent(grantUuid: $grantUuid, uuid: $uuid)
    }
  }
`;
export type DeleteExerciseEventMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteExerciseEventMutation,
  Types.DeleteExerciseEventMutationVariables
>;

/**
 * __useDeleteExerciseEventMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseEventMutation, { data, loading, error }] = useDeleteExerciseEventMutation({
 *   variables: {
 *      grantUuid: // value for 'grantUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteExerciseEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DeleteExerciseEventMutation,
    Types.DeleteExerciseEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DeleteExerciseEventMutation,
    Types.DeleteExerciseEventMutationVariables
  >(DeleteExerciseEventDocument, options);
}
export type DeleteExerciseEventMutationHookResult = ReturnType<
  typeof useDeleteExerciseEventMutation
>;
export type DeleteExerciseEventMutationResult = ApolloReactCommon.MutationResult<Types.DeleteExerciseEventMutation>;
export type DeleteExerciseEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteExerciseEventMutation,
  Types.DeleteExerciseEventMutationVariables
>;
export const DeleteRsuGrantDocument = gql`
  mutation DeleteRsuGrant($uuid: UUID!) {
    equity {
      deleteRsuGrant(uuid: $uuid)
    }
  }
`;
export type DeleteRsuGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteRsuGrantMutation,
  Types.DeleteRsuGrantMutationVariables
>;

/**
 * __useDeleteRsuGrantMutation__
 *
 * To run a mutation, you first call `useDeleteRsuGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRsuGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRsuGrantMutation, { data, loading, error }] = useDeleteRsuGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteRsuGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DeleteRsuGrantMutation,
    Types.DeleteRsuGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DeleteRsuGrantMutation,
    Types.DeleteRsuGrantMutationVariables
  >(DeleteRsuGrantDocument, options);
}
export type DeleteRsuGrantMutationHookResult = ReturnType<
  typeof useDeleteRsuGrantMutation
>;
export type DeleteRsuGrantMutationResult = ApolloReactCommon.MutationResult<Types.DeleteRsuGrantMutation>;
export type DeleteRsuGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteRsuGrantMutation,
  Types.DeleteRsuGrantMutationVariables
>;
export const DeleteEquityShareGrantDocument = gql`
  mutation DeleteEquityShareGrant($uuid: UUID!) {
    equity {
      deleteShareGrant(uuid: $uuid)
    }
  }
`;
export type DeleteEquityShareGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteEquityShareGrantMutation,
  Types.DeleteEquityShareGrantMutationVariables
>;

/**
 * __useDeleteEquityShareGrantMutation__
 *
 * To run a mutation, you first call `useDeleteEquityShareGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquityShareGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquityShareGrantMutation, { data, loading, error }] = useDeleteEquityShareGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteEquityShareGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DeleteEquityShareGrantMutation,
    Types.DeleteEquityShareGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DeleteEquityShareGrantMutation,
    Types.DeleteEquityShareGrantMutationVariables
  >(DeleteEquityShareGrantDocument, options);
}
export type DeleteEquityShareGrantMutationHookResult = ReturnType<
  typeof useDeleteEquityShareGrantMutation
>;
export type DeleteEquityShareGrantMutationResult = ApolloReactCommon.MutationResult<Types.DeleteEquityShareGrantMutation>;
export type DeleteEquityShareGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteEquityShareGrantMutation,
  Types.DeleteEquityShareGrantMutationVariables
>;
export const DeleteStockOptionGrantDocument = gql`
  mutation DeleteStockOptionGrant($uuid: UUID!) {
    equity {
      deleteStockOptionGrant(uuid: $uuid)
    }
  }
`;
export type DeleteStockOptionGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteStockOptionGrantMutation,
  Types.DeleteStockOptionGrantMutationVariables
>;

/**
 * __useDeleteStockOptionGrantMutation__
 *
 * To run a mutation, you first call `useDeleteStockOptionGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockOptionGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockOptionGrantMutation, { data, loading, error }] = useDeleteStockOptionGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteStockOptionGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DeleteStockOptionGrantMutation,
    Types.DeleteStockOptionGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DeleteStockOptionGrantMutation,
    Types.DeleteStockOptionGrantMutationVariables
  >(DeleteStockOptionGrantDocument, options);
}
export type DeleteStockOptionGrantMutationHookResult = ReturnType<
  typeof useDeleteStockOptionGrantMutation
>;
export type DeleteStockOptionGrantMutationResult = ApolloReactCommon.MutationResult<Types.DeleteStockOptionGrantMutation>;
export type DeleteStockOptionGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteStockOptionGrantMutation,
  Types.DeleteStockOptionGrantMutationVariables
>;
export const UpdateRsuGrantDocument = gql`
  mutation UpdateRsuGrant($uuid: UUID!, $payload: RSUGrantInput!) {
    equity {
      updateRsuGrant(uuid: $uuid, payload: $payload) {
        uuid
        ...RSUGrant
      }
    }
  }
  ${RsuGrantFragmentDoc}
`;
export type UpdateRsuGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateRsuGrantMutation,
  Types.UpdateRsuGrantMutationVariables
>;

/**
 * __useUpdateRsuGrantMutation__
 *
 * To run a mutation, you first call `useUpdateRsuGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRsuGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRsuGrantMutation, { data, loading, error }] = useUpdateRsuGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateRsuGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateRsuGrantMutation,
    Types.UpdateRsuGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateRsuGrantMutation,
    Types.UpdateRsuGrantMutationVariables
  >(UpdateRsuGrantDocument, options);
}
export type UpdateRsuGrantMutationHookResult = ReturnType<
  typeof useUpdateRsuGrantMutation
>;
export type UpdateRsuGrantMutationResult = ApolloReactCommon.MutationResult<Types.UpdateRsuGrantMutation>;
export type UpdateRsuGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateRsuGrantMutation,
  Types.UpdateRsuGrantMutationVariables
>;
export const UpdateEquityShareGrantDocument = gql`
  mutation UpdateEquityShareGrant(
    $uuid: UUID!
    $payload: EquityShareGrantInput!
  ) {
    equity {
      updateShareGrant(uuid: $uuid, payload: $payload) {
        uuid
        ...EquityShareGrant
      }
    }
  }
  ${EquityShareGrantFragmentDoc}
`;
export type UpdateEquityShareGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateEquityShareGrantMutation,
  Types.UpdateEquityShareGrantMutationVariables
>;

/**
 * __useUpdateEquityShareGrantMutation__
 *
 * To run a mutation, you first call `useUpdateEquityShareGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquityShareGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquityShareGrantMutation, { data, loading, error }] = useUpdateEquityShareGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateEquityShareGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateEquityShareGrantMutation,
    Types.UpdateEquityShareGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateEquityShareGrantMutation,
    Types.UpdateEquityShareGrantMutationVariables
  >(UpdateEquityShareGrantDocument, options);
}
export type UpdateEquityShareGrantMutationHookResult = ReturnType<
  typeof useUpdateEquityShareGrantMutation
>;
export type UpdateEquityShareGrantMutationResult = ApolloReactCommon.MutationResult<Types.UpdateEquityShareGrantMutation>;
export type UpdateEquityShareGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateEquityShareGrantMutation,
  Types.UpdateEquityShareGrantMutationVariables
>;
export const UpdateStockOptionGrantDocument = gql`
  mutation UpdateStockOptionGrant(
    $uuid: UUID!
    $payload: StockOptionGrantInput!
  ) {
    equity {
      updateStockOptionGrant(uuid: $uuid, payload: $payload) {
        uuid
        ...StockOptionGrant
      }
    }
  }
  ${StockOptionGrantFragmentDoc}
`;
export type UpdateStockOptionGrantMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateStockOptionGrantMutation,
  Types.UpdateStockOptionGrantMutationVariables
>;

/**
 * __useUpdateStockOptionGrantMutation__
 *
 * To run a mutation, you first call `useUpdateStockOptionGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockOptionGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockOptionGrantMutation, { data, loading, error }] = useUpdateStockOptionGrantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateStockOptionGrantMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateStockOptionGrantMutation,
    Types.UpdateStockOptionGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateStockOptionGrantMutation,
    Types.UpdateStockOptionGrantMutationVariables
  >(UpdateStockOptionGrantDocument, options);
}
export type UpdateStockOptionGrantMutationHookResult = ReturnType<
  typeof useUpdateStockOptionGrantMutation
>;
export type UpdateStockOptionGrantMutationResult = ApolloReactCommon.MutationResult<Types.UpdateStockOptionGrantMutation>;
export type UpdateStockOptionGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateStockOptionGrantMutation,
  Types.UpdateStockOptionGrantMutationVariables
>;
export const UpdateExerciseEventDocument = gql`
  mutation UpdateExerciseEvent($uuid: UUID!, $payload: ExerciseEventInput!) {
    equity {
      updateExerciseEvent(uuid: $uuid, payload: $payload) {
        uuid
        ...ExerciseEvent
      }
    }
  }
  ${ExerciseEventFragmentDoc}
`;
export type UpdateExerciseEventMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateExerciseEventMutation,
  Types.UpdateExerciseEventMutationVariables
>;

/**
 * __useUpdateExerciseEventMutation__
 *
 * To run a mutation, you first call `useUpdateExerciseEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExerciseEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExerciseEventMutation, { data, loading, error }] = useUpdateExerciseEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateExerciseEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateExerciseEventMutation,
    Types.UpdateExerciseEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateExerciseEventMutation,
    Types.UpdateExerciseEventMutationVariables
  >(UpdateExerciseEventDocument, options);
}
export type UpdateExerciseEventMutationHookResult = ReturnType<
  typeof useUpdateExerciseEventMutation
>;
export type UpdateExerciseEventMutationResult = ApolloReactCommon.MutationResult<Types.UpdateExerciseEventMutation>;
export type UpdateExerciseEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateExerciseEventMutation,
  Types.UpdateExerciseEventMutationVariables
>;
export const CalculateVestedOptionsDocument = gql`
  query CalculateVestedOptions($payload: VestedOptionsCalculationInput!) {
    equity {
      calculateVestedOptions(payload: $payload) {
        vestedOptions
      }
    }
  }
`;

/**
 * __useCalculateVestedOptionsQuery__
 *
 * To run a query within a React component, call `useCalculateVestedOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateVestedOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateVestedOptionsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCalculateVestedOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CalculateVestedOptionsQuery,
    Types.CalculateVestedOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.CalculateVestedOptionsQuery,
    Types.CalculateVestedOptionsQueryVariables
  >(CalculateVestedOptionsDocument, options);
}
export function useCalculateVestedOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CalculateVestedOptionsQuery,
    Types.CalculateVestedOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.CalculateVestedOptionsQuery,
    Types.CalculateVestedOptionsQueryVariables
  >(CalculateVestedOptionsDocument, options);
}
export type CalculateVestedOptionsQueryHookResult = ReturnType<
  typeof useCalculateVestedOptionsQuery
>;
export type CalculateVestedOptionsLazyQueryHookResult = ReturnType<
  typeof useCalculateVestedOptionsLazyQuery
>;
export type CalculateVestedOptionsQueryResult = ApolloReactCommon.QueryResult<
  Types.CalculateVestedOptionsQuery,
  Types.CalculateVestedOptionsQueryVariables
>;
export const GetAllGrantsDocument = gql`
  query GetAllGrants($affiliation: UUID, $customer: UUID!) {
    equity {
      getAllGrants(affiliation: $affiliation, customer: $customer) {
        stockOptionGrants {
          ...StockOptionGrant
        }
        shareGrants {
          ...EquityShareGrant
        }
        rsuGrants {
          ...RSUGrant
        }
      }
    }
  }
  ${StockOptionGrantFragmentDoc}
  ${EquityShareGrantFragmentDoc}
  ${RsuGrantFragmentDoc}
`;

/**
 * __useGetAllGrantsQuery__
 *
 * To run a query within a React component, call `useGetAllGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGrantsQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetAllGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetAllGrantsQuery,
    Types.GetAllGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllGrantsQuery,
    Types.GetAllGrantsQueryVariables
  >(GetAllGrantsDocument, options);
}
export function useGetAllGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllGrantsQuery,
    Types.GetAllGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllGrantsQuery,
    Types.GetAllGrantsQueryVariables
  >(GetAllGrantsDocument, options);
}
export type GetAllGrantsQueryHookResult = ReturnType<
  typeof useGetAllGrantsQuery
>;
export type GetAllGrantsLazyQueryHookResult = ReturnType<
  typeof useGetAllGrantsLazyQuery
>;
export type GetAllGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllGrantsQuery,
  Types.GetAllGrantsQueryVariables
>;
export const GetCartaConnectionStatusDocument = gql`
  query GetCartaConnectionStatus {
    getCartaConnectionStatus {
      status
      disconnectedAt
      lastUpdatedAt
    }
  }
`;

/**
 * __useGetCartaConnectionStatusQuery__
 *
 * To run a query within a React component, call `useGetCartaConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartaConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartaConnectionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCartaConnectionStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetCartaConnectionStatusQuery,
    Types.GetCartaConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCartaConnectionStatusQuery,
    Types.GetCartaConnectionStatusQueryVariables
  >(GetCartaConnectionStatusDocument, options);
}
export function useGetCartaConnectionStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCartaConnectionStatusQuery,
    Types.GetCartaConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCartaConnectionStatusQuery,
    Types.GetCartaConnectionStatusQueryVariables
  >(GetCartaConnectionStatusDocument, options);
}
export type GetCartaConnectionStatusQueryHookResult = ReturnType<
  typeof useGetCartaConnectionStatusQuery
>;
export type GetCartaConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useGetCartaConnectionStatusLazyQuery
>;
export type GetCartaConnectionStatusQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCartaConnectionStatusQuery,
  Types.GetCartaConnectionStatusQueryVariables
>;
export const GetCartaLoginUrlDocument = gql`
  query GetCartaLoginUrl(
    $redirectUrl: String!
    $flowType: CartaFlowType!
    $companyUuids: [UUID!]
  ) {
    getCartaLoginUrl(
      flowType: $flowType
      redirectUrl: $redirectUrl
      prioritisedIssuerMatchingCompanyUuids: $companyUuids
    ) {
      cartaLoginUrl
    }
  }
`;

/**
 * __useGetCartaLoginUrlQuery__
 *
 * To run a query within a React component, call `useGetCartaLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartaLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartaLoginUrlQuery({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      flowType: // value for 'flowType'
 *      companyUuids: // value for 'companyUuids'
 *   },
 * });
 */
export function useGetCartaLoginUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCartaLoginUrlQuery,
    Types.GetCartaLoginUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCartaLoginUrlQuery,
    Types.GetCartaLoginUrlQueryVariables
  >(GetCartaLoginUrlDocument, options);
}
export function useGetCartaLoginUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCartaLoginUrlQuery,
    Types.GetCartaLoginUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCartaLoginUrlQuery,
    Types.GetCartaLoginUrlQueryVariables
  >(GetCartaLoginUrlDocument, options);
}
export type GetCartaLoginUrlQueryHookResult = ReturnType<
  typeof useGetCartaLoginUrlQuery
>;
export type GetCartaLoginUrlLazyQueryHookResult = ReturnType<
  typeof useGetCartaLoginUrlLazyQuery
>;
export type GetCartaLoginUrlQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCartaLoginUrlQuery,
  Types.GetCartaLoginUrlQueryVariables
>;
export const GetEquityShareGrantDocument = gql`
  query GetEquityShareGrant($uuid: UUID!) {
    equity {
      getShareGrant(uuid: $uuid) {
        ...EquityShareGrant
      }
    }
  }
  ${EquityShareGrantFragmentDoc}
`;

/**
 * __useGetEquityShareGrantQuery__
 *
 * To run a query within a React component, call `useGetEquityShareGrantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquityShareGrantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquityShareGrantQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetEquityShareGrantQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetEquityShareGrantQuery,
    Types.GetEquityShareGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetEquityShareGrantQuery,
    Types.GetEquityShareGrantQueryVariables
  >(GetEquityShareGrantDocument, options);
}
export function useGetEquityShareGrantLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetEquityShareGrantQuery,
    Types.GetEquityShareGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetEquityShareGrantQuery,
    Types.GetEquityShareGrantQueryVariables
  >(GetEquityShareGrantDocument, options);
}
export type GetEquityShareGrantQueryHookResult = ReturnType<
  typeof useGetEquityShareGrantQuery
>;
export type GetEquityShareGrantLazyQueryHookResult = ReturnType<
  typeof useGetEquityShareGrantLazyQuery
>;
export type GetEquityShareGrantQueryResult = ApolloReactCommon.QueryResult<
  Types.GetEquityShareGrantQuery,
  Types.GetEquityShareGrantQueryVariables
>;
export const GetRsuGrantDocument = gql`
  query GetRsuGrant($uuid: UUID!) {
    equity {
      getRsuGrant(uuid: $uuid) {
        ...RSUGrant
      }
    }
  }
  ${RsuGrantFragmentDoc}
`;

/**
 * __useGetRsuGrantQuery__
 *
 * To run a query within a React component, call `useGetRsuGrantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRsuGrantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRsuGrantQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetRsuGrantQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRsuGrantQuery,
    Types.GetRsuGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRsuGrantQuery,
    Types.GetRsuGrantQueryVariables
  >(GetRsuGrantDocument, options);
}
export function useGetRsuGrantLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRsuGrantQuery,
    Types.GetRsuGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRsuGrantQuery,
    Types.GetRsuGrantQueryVariables
  >(GetRsuGrantDocument, options);
}
export type GetRsuGrantQueryHookResult = ReturnType<typeof useGetRsuGrantQuery>;
export type GetRsuGrantLazyQueryHookResult = ReturnType<
  typeof useGetRsuGrantLazyQuery
>;
export type GetRsuGrantQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRsuGrantQuery,
  Types.GetRsuGrantQueryVariables
>;
export const GetRsuGrantsDocument = gql`
  query GetRsuGrants($affiliation: UUID!, $customer: UUID!) {
    equity {
      getAllGrants(affiliation: $affiliation, customer: $customer) {
        rsuGrants {
          ...RSUGrant
        }
      }
    }
  }
  ${RsuGrantFragmentDoc}
`;

/**
 * __useGetRsuGrantsQuery__
 *
 * To run a query within a React component, call `useGetRsuGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRsuGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRsuGrantsQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetRsuGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetRsuGrantsQuery,
    Types.GetRsuGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetRsuGrantsQuery,
    Types.GetRsuGrantsQueryVariables
  >(GetRsuGrantsDocument, options);
}
export function useGetRsuGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetRsuGrantsQuery,
    Types.GetRsuGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetRsuGrantsQuery,
    Types.GetRsuGrantsQueryVariables
  >(GetRsuGrantsDocument, options);
}
export type GetRsuGrantsQueryHookResult = ReturnType<
  typeof useGetRsuGrantsQuery
>;
export type GetRsuGrantsLazyQueryHookResult = ReturnType<
  typeof useGetRsuGrantsLazyQuery
>;
export type GetRsuGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetRsuGrantsQuery,
  Types.GetRsuGrantsQueryVariables
>;
export const GetShareGrantsDocument = gql`
  query GetShareGrants($affiliation: UUID!, $customer: UUID!) {
    equity {
      getAllGrants(affiliation: $affiliation, customer: $customer) {
        shareGrants {
          ...EquityShareGrant
        }
      }
    }
  }
  ${EquityShareGrantFragmentDoc}
`;

/**
 * __useGetShareGrantsQuery__
 *
 * To run a query within a React component, call `useGetShareGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareGrantsQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetShareGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetShareGrantsQuery,
    Types.GetShareGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetShareGrantsQuery,
    Types.GetShareGrantsQueryVariables
  >(GetShareGrantsDocument, options);
}
export function useGetShareGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetShareGrantsQuery,
    Types.GetShareGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetShareGrantsQuery,
    Types.GetShareGrantsQueryVariables
  >(GetShareGrantsDocument, options);
}
export type GetShareGrantsQueryHookResult = ReturnType<
  typeof useGetShareGrantsQuery
>;
export type GetShareGrantsLazyQueryHookResult = ReturnType<
  typeof useGetShareGrantsLazyQuery
>;
export type GetShareGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetShareGrantsQuery,
  Types.GetShareGrantsQueryVariables
>;
export const GetStockOptionGrantDocument = gql`
  query GetStockOptionGrant($uuid: UUID!) {
    equity {
      getStockOptionGrant(uuid: $uuid) {
        ...StockOptionGrant
      }
    }
  }
  ${StockOptionGrantFragmentDoc}
`;

/**
 * __useGetStockOptionGrantQuery__
 *
 * To run a query within a React component, call `useGetStockOptionGrantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockOptionGrantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockOptionGrantQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetStockOptionGrantQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetStockOptionGrantQuery,
    Types.GetStockOptionGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetStockOptionGrantQuery,
    Types.GetStockOptionGrantQueryVariables
  >(GetStockOptionGrantDocument, options);
}
export function useGetStockOptionGrantLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetStockOptionGrantQuery,
    Types.GetStockOptionGrantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetStockOptionGrantQuery,
    Types.GetStockOptionGrantQueryVariables
  >(GetStockOptionGrantDocument, options);
}
export type GetStockOptionGrantQueryHookResult = ReturnType<
  typeof useGetStockOptionGrantQuery
>;
export type GetStockOptionGrantLazyQueryHookResult = ReturnType<
  typeof useGetStockOptionGrantLazyQuery
>;
export type GetStockOptionGrantQueryResult = ApolloReactCommon.QueryResult<
  Types.GetStockOptionGrantQuery,
  Types.GetStockOptionGrantQueryVariables
>;
export const GetStockOptionGrantsDocument = gql`
  query GetStockOptionGrants($affiliation: UUID!, $customer: UUID!) {
    equity {
      getAllGrants(affiliation: $affiliation, customer: $customer) {
        stockOptionGrants {
          ...StockOptionGrant
        }
      }
    }
  }
  ${StockOptionGrantFragmentDoc}
`;

/**
 * __useGetStockOptionGrantsQuery__
 *
 * To run a query within a React component, call `useGetStockOptionGrantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockOptionGrantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockOptionGrantsQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetStockOptionGrantsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetStockOptionGrantsQuery,
    Types.GetStockOptionGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetStockOptionGrantsQuery,
    Types.GetStockOptionGrantsQueryVariables
  >(GetStockOptionGrantsDocument, options);
}
export function useGetStockOptionGrantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetStockOptionGrantsQuery,
    Types.GetStockOptionGrantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetStockOptionGrantsQuery,
    Types.GetStockOptionGrantsQueryVariables
  >(GetStockOptionGrantsDocument, options);
}
export type GetStockOptionGrantsQueryHookResult = ReturnType<
  typeof useGetStockOptionGrantsQuery
>;
export type GetStockOptionGrantsLazyQueryHookResult = ReturnType<
  typeof useGetStockOptionGrantsLazyQuery
>;
export type GetStockOptionGrantsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetStockOptionGrantsQuery,
  Types.GetStockOptionGrantsQueryVariables
>;
export const ImportCartaDataDocument = gql`
  mutation ImportCartaData {
    importCartaData {
      scheduled
    }
  }
`;
export type ImportCartaDataMutationFn = ApolloReactCommon.MutationFunction<
  Types.ImportCartaDataMutation,
  Types.ImportCartaDataMutationVariables
>;

/**
 * __useImportCartaDataMutation__
 *
 * To run a mutation, you first call `useImportCartaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCartaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCartaDataMutation, { data, loading, error }] = useImportCartaDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportCartaDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ImportCartaDataMutation,
    Types.ImportCartaDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.ImportCartaDataMutation,
    Types.ImportCartaDataMutationVariables
  >(ImportCartaDataDocument, options);
}
export type ImportCartaDataMutationHookResult = ReturnType<
  typeof useImportCartaDataMutation
>;
export type ImportCartaDataMutationResult = ApolloReactCommon.MutationResult<Types.ImportCartaDataMutation>;
export type ImportCartaDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ImportCartaDataMutation,
  Types.ImportCartaDataMutationVariables
>;
export const GetCompanyNameByAffiliationDocument = gql`
  query getCompanyNameByAffiliation($affiliation: String!) {
    affiliation: Affiliation(uuid: $affiliation) {
      uuid
      company {
        uuid
        name
      }
    }
  }
`;

/**
 * __useGetCompanyNameByAffiliationQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameByAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameByAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameByAffiliationQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetCompanyNameByAffiliationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCompanyNameByAffiliationQuery,
    Types.GetCompanyNameByAffiliationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCompanyNameByAffiliationQuery,
    Types.GetCompanyNameByAffiliationQueryVariables
  >(GetCompanyNameByAffiliationDocument, options);
}
export function useGetCompanyNameByAffiliationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCompanyNameByAffiliationQuery,
    Types.GetCompanyNameByAffiliationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCompanyNameByAffiliationQuery,
    Types.GetCompanyNameByAffiliationQueryVariables
  >(GetCompanyNameByAffiliationDocument, options);
}
export type GetCompanyNameByAffiliationQueryHookResult = ReturnType<
  typeof useGetCompanyNameByAffiliationQuery
>;
export type GetCompanyNameByAffiliationLazyQueryHookResult = ReturnType<
  typeof useGetCompanyNameByAffiliationLazyQuery
>;
export type GetCompanyNameByAffiliationQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCompanyNameByAffiliationQuery,
  Types.GetCompanyNameByAffiliationQueryVariables
>;
export const CreateOrUpdateUsTaxInformationDocument = gql`
  mutation CreateOrUpdateUsTaxInformation(
    $usTaxInfo: UsTaxInformationBaseInput
  ) {
    usTaxInfo: createOrUpdateUsTaxInformation(payload: $usTaxInfo) {
      ...UsTaxInformation
    }
  }
  ${UsTaxInformationFragmentDoc}
`;
export type CreateOrUpdateUsTaxInformationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateOrUpdateUsTaxInformationMutation,
  Types.CreateOrUpdateUsTaxInformationMutationVariables
>;

/**
 * __useCreateOrUpdateUsTaxInformationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateUsTaxInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateUsTaxInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateUsTaxInformationMutation, { data, loading, error }] = useCreateOrUpdateUsTaxInformationMutation({
 *   variables: {
 *      usTaxInfo: // value for 'usTaxInfo'
 *   },
 * });
 */
export function useCreateOrUpdateUsTaxInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateOrUpdateUsTaxInformationMutation,
    Types.CreateOrUpdateUsTaxInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateOrUpdateUsTaxInformationMutation,
    Types.CreateOrUpdateUsTaxInformationMutationVariables
  >(CreateOrUpdateUsTaxInformationDocument, options);
}
export type CreateOrUpdateUsTaxInformationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateUsTaxInformationMutation
>;
export type CreateOrUpdateUsTaxInformationMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrUpdateUsTaxInformationMutation>;
export type CreateOrUpdateUsTaxInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateOrUpdateUsTaxInformationMutation,
  Types.CreateOrUpdateUsTaxInformationMutationVariables
>;
export const GetAllUsTaxInfoDocument = gql`
  query GetAllUsTaxInfo($customer: ID) {
    allTaxInfo: allUsTaxInformation(
      offset: 0
      first: 1
      filters: { customer: $customer }
    ) {
      nodes {
        ...UsTaxInformation
      }
      totalCount
    }
  }
  ${UsTaxInformationFragmentDoc}
`;

/**
 * __useGetAllUsTaxInfoQuery__
 *
 * To run a query within a React component, call `useGetAllUsTaxInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsTaxInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsTaxInfoQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetAllUsTaxInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetAllUsTaxInfoQuery,
    Types.GetAllUsTaxInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllUsTaxInfoQuery,
    Types.GetAllUsTaxInfoQueryVariables
  >(GetAllUsTaxInfoDocument, options);
}
export function useGetAllUsTaxInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllUsTaxInfoQuery,
    Types.GetAllUsTaxInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllUsTaxInfoQuery,
    Types.GetAllUsTaxInfoQueryVariables
  >(GetAllUsTaxInfoDocument, options);
}
export type GetAllUsTaxInfoQueryHookResult = ReturnType<
  typeof useGetAllUsTaxInfoQuery
>;
export type GetAllUsTaxInfoLazyQueryHookResult = ReturnType<
  typeof useGetAllUsTaxInfoLazyQuery
>;
export type GetAllUsTaxInfoQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllUsTaxInfoQuery,
  Types.GetAllUsTaxInfoQueryVariables
>;
export const DeleteAffiliationDocument = gql`
  mutation DeleteAffiliation($uuid: String!) {
    deleteAffiliation(uuid: $uuid)
  }
`;
export type DeleteAffiliationMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteAffiliationMutation,
  Types.DeleteAffiliationMutationVariables
>;

/**
 * __useDeleteAffiliationMutation__
 *
 * To run a mutation, you first call `useDeleteAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAffiliationMutation, { data, loading, error }] = useDeleteAffiliationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteAffiliationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DeleteAffiliationMutation,
    Types.DeleteAffiliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DeleteAffiliationMutation,
    Types.DeleteAffiliationMutationVariables
  >(DeleteAffiliationDocument, options);
}
export type DeleteAffiliationMutationHookResult = ReturnType<
  typeof useDeleteAffiliationMutation
>;
export type DeleteAffiliationMutationResult = ApolloReactCommon.MutationResult<Types.DeleteAffiliationMutation>;
export type DeleteAffiliationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteAffiliationMutation,
  Types.DeleteAffiliationMutationVariables
>;
export const ValidateEmploymentEndDateDocument = gql`
  query ValidateEmploymentEndDate(
    $customer: UUID!
    $affiliation: UUID!
    $eventDate: Date
  ) {
    equity {
      validateEmploymentEndDate(
        customer: $customer
        affiliation: $affiliation
        eventDate: $eventDate
      ) {
        isValid
      }
    }
  }
`;

/**
 * __useValidateEmploymentEndDateQuery__
 *
 * To run a query within a React component, call `useValidateEmploymentEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateEmploymentEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateEmploymentEndDateQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      affiliation: // value for 'affiliation'
 *      eventDate: // value for 'eventDate'
 *   },
 * });
 */
export function useValidateEmploymentEndDateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.ValidateEmploymentEndDateQuery,
    Types.ValidateEmploymentEndDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.ValidateEmploymentEndDateQuery,
    Types.ValidateEmploymentEndDateQueryVariables
  >(ValidateEmploymentEndDateDocument, options);
}
export function useValidateEmploymentEndDateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ValidateEmploymentEndDateQuery,
    Types.ValidateEmploymentEndDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.ValidateEmploymentEndDateQuery,
    Types.ValidateEmploymentEndDateQueryVariables
  >(ValidateEmploymentEndDateDocument, options);
}
export type ValidateEmploymentEndDateQueryHookResult = ReturnType<
  typeof useValidateEmploymentEndDateQuery
>;
export type ValidateEmploymentEndDateLazyQueryHookResult = ReturnType<
  typeof useValidateEmploymentEndDateLazyQuery
>;
export type ValidateEmploymentEndDateQueryResult = ApolloReactCommon.QueryResult<
  Types.ValidateEmploymentEndDateQuery,
  Types.ValidateEmploymentEndDateQueryVariables
>;
export const CreateAffiliationDocument = gql`
  mutation CreateAffiliation($payload: AffiliationCreateInput) {
    createAffiliation(payload: $payload) {
      ...AffiliationOverview
    }
  }
  ${AffiliationOverviewFragmentDoc}
`;
export type CreateAffiliationMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateAffiliationMutation,
  Types.CreateAffiliationMutationVariables
>;

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateAffiliationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateAffiliationMutation,
    Types.CreateAffiliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.CreateAffiliationMutation,
    Types.CreateAffiliationMutationVariables
  >(CreateAffiliationDocument, options);
}
export type CreateAffiliationMutationHookResult = ReturnType<
  typeof useCreateAffiliationMutation
>;
export type CreateAffiliationMutationResult = ApolloReactCommon.MutationResult<Types.CreateAffiliationMutation>;
export type CreateAffiliationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateAffiliationMutation,
  Types.CreateAffiliationMutationVariables
>;
export const DisconnectCartaAccountDocument = gql`
  mutation disconnectCartaAccount {
    disconnectCartaAccount {
      disconnected
    }
  }
`;
export type DisconnectCartaAccountMutationFn = ApolloReactCommon.MutationFunction<
  Types.DisconnectCartaAccountMutation,
  Types.DisconnectCartaAccountMutationVariables
>;

/**
 * __useDisconnectCartaAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectCartaAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCartaAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCartaAccountMutation, { data, loading, error }] = useDisconnectCartaAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectCartaAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DisconnectCartaAccountMutation,
    Types.DisconnectCartaAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.DisconnectCartaAccountMutation,
    Types.DisconnectCartaAccountMutationVariables
  >(DisconnectCartaAccountDocument, options);
}
export type DisconnectCartaAccountMutationHookResult = ReturnType<
  typeof useDisconnectCartaAccountMutation
>;
export type DisconnectCartaAccountMutationResult = ApolloReactCommon.MutationResult<Types.DisconnectCartaAccountMutation>;
export type DisconnectCartaAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DisconnectCartaAccountMutation,
  Types.DisconnectCartaAccountMutationVariables
>;
export const SetCustomerFlagDocument = gql`
  mutation SetCustomerFlag(
    $customer: UUID!
    $affiliation: UUID
    $flag: String!
  ) {
    setCustomerFlag(
      customer: $customer
      affiliation: $affiliation
      flag: $flag
    ) {
      customer
      affiliation
      flag
      createdAt
    }
  }
`;
export type SetCustomerFlagMutationFn = ApolloReactCommon.MutationFunction<
  Types.SetCustomerFlagMutation,
  Types.SetCustomerFlagMutationVariables
>;

/**
 * __useSetCustomerFlagMutation__
 *
 * To run a mutation, you first call `useSetCustomerFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerFlagMutation, { data, loading, error }] = useSetCustomerFlagMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      affiliation: // value for 'affiliation'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useSetCustomerFlagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SetCustomerFlagMutation,
    Types.SetCustomerFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.SetCustomerFlagMutation,
    Types.SetCustomerFlagMutationVariables
  >(SetCustomerFlagDocument, options);
}
export type SetCustomerFlagMutationHookResult = ReturnType<
  typeof useSetCustomerFlagMutation
>;
export type SetCustomerFlagMutationResult = ApolloReactCommon.MutationResult<Types.SetCustomerFlagMutation>;
export type SetCustomerFlagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SetCustomerFlagMutation,
  Types.SetCustomerFlagMutationVariables
>;
export const SignInWithSignInTokenDocument = gql`
  mutation SignInWithSignInToken($token: String!) {
    signInWithSignInToken(token: $token) {
      ... on SignInResponse {
        refresh_token
        access_token
        token
        duration
        uuid
        customer
        first_name
        last_name
        email
        username
        is_verified
      }
      ... on SignInTokenInvalidError {
        error
      }
    }
  }
`;
export type SignInWithSignInTokenMutationFn = ApolloReactCommon.MutationFunction<
  Types.SignInWithSignInTokenMutation,
  Types.SignInWithSignInTokenMutationVariables
>;

/**
 * __useSignInWithSignInTokenMutation__
 *
 * To run a mutation, you first call `useSignInWithSignInTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithSignInTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithSignInTokenMutation, { data, loading, error }] = useSignInWithSignInTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignInWithSignInTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SignInWithSignInTokenMutation,
    Types.SignInWithSignInTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.SignInWithSignInTokenMutation,
    Types.SignInWithSignInTokenMutationVariables
  >(SignInWithSignInTokenDocument, options);
}
export type SignInWithSignInTokenMutationHookResult = ReturnType<
  typeof useSignInWithSignInTokenMutation
>;
export type SignInWithSignInTokenMutationResult = ApolloReactCommon.MutationResult<Types.SignInWithSignInTokenMutation>;
export type SignInWithSignInTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SignInWithSignInTokenMutation,
  Types.SignInWithSignInTokenMutationVariables
>;
export const UpdateAffiliationDetailsDocument = gql`
  mutation UpdateAffiliationDetails(
    $uuid: String!
    $payload: AffiliationInput!
  ) {
    updateAffiliation(uuid: $uuid, payload: $payload) {
      ...AffiliationDetails
    }
  }
  ${AffiliationDetailsFragmentDoc}
`;
export type UpdateAffiliationDetailsMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateAffiliationDetailsMutation,
  Types.UpdateAffiliationDetailsMutationVariables
>;

/**
 * __useUpdateAffiliationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliationDetailsMutation, { data, loading, error }] = useUpdateAffiliationDetailsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateAffiliationDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateAffiliationDetailsMutation,
    Types.UpdateAffiliationDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateAffiliationDetailsMutation,
    Types.UpdateAffiliationDetailsMutationVariables
  >(UpdateAffiliationDetailsDocument, options);
}
export type UpdateAffiliationDetailsMutationHookResult = ReturnType<
  typeof useUpdateAffiliationDetailsMutation
>;
export type UpdateAffiliationDetailsMutationResult = ApolloReactCommon.MutationResult<Types.UpdateAffiliationDetailsMutation>;
export type UpdateAffiliationDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateAffiliationDetailsMutation,
  Types.UpdateAffiliationDetailsMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($uuid: String!, $payload: CustomerInput!) {
    customer: updateCustomer(payload: $payload, uuid: $uuid) {
      uuid
      country
      subdivision
      city
    }
  }
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateCustomerMutation,
  Types.UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateCustomerMutation,
    Types.UpdateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Types.UpdateCustomerMutation,
    Types.UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<Types.UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateCustomerMutation,
  Types.UpdateCustomerMutationVariables
>;
export const GetAffiliationDetailsDocument = gql`
  query GetAffiliationDetails($affiliationUuid: String!) {
    affiliation: Affiliation(uuid: $affiliationUuid) {
      ...AffiliationDetails
    }
  }
  ${AffiliationDetailsFragmentDoc}
`;

/**
 * __useGetAffiliationDetailsQuery__
 *
 * To run a query within a React component, call `useGetAffiliationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliationDetailsQuery({
 *   variables: {
 *      affiliationUuid: // value for 'affiliationUuid'
 *   },
 * });
 */
export function useGetAffiliationDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetAffiliationDetailsQuery,
    Types.GetAffiliationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAffiliationDetailsQuery,
    Types.GetAffiliationDetailsQueryVariables
  >(GetAffiliationDetailsDocument, options);
}
export function useGetAffiliationDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAffiliationDetailsQuery,
    Types.GetAffiliationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAffiliationDetailsQuery,
    Types.GetAffiliationDetailsQueryVariables
  >(GetAffiliationDetailsDocument, options);
}
export type GetAffiliationDetailsQueryHookResult = ReturnType<
  typeof useGetAffiliationDetailsQuery
>;
export type GetAffiliationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAffiliationDetailsLazyQuery
>;
export type GetAffiliationDetailsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAffiliationDetailsQuery,
  Types.GetAffiliationDetailsQueryVariables
>;
export const GetAffiliationOverviewDocument = gql`
  query GetAffiliationOverview($affiliation: String!) {
    affiliation: Affiliation(uuid: $affiliation) {
      ...AffiliationOverview
    }
  }
  ${AffiliationOverviewFragmentDoc}
`;

/**
 * __useGetAffiliationOverviewQuery__
 *
 * To run a query within a React component, call `useGetAffiliationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliationOverviewQuery({
 *   variables: {
 *      affiliation: // value for 'affiliation'
 *   },
 * });
 */
export function useGetAffiliationOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetAffiliationOverviewQuery,
    Types.GetAffiliationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAffiliationOverviewQuery,
    Types.GetAffiliationOverviewQueryVariables
  >(GetAffiliationOverviewDocument, options);
}
export function useGetAffiliationOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAffiliationOverviewQuery,
    Types.GetAffiliationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAffiliationOverviewQuery,
    Types.GetAffiliationOverviewQueryVariables
  >(GetAffiliationOverviewDocument, options);
}
export type GetAffiliationOverviewQueryHookResult = ReturnType<
  typeof useGetAffiliationOverviewQuery
>;
export type GetAffiliationOverviewLazyQueryHookResult = ReturnType<
  typeof useGetAffiliationOverviewLazyQuery
>;
export type GetAffiliationOverviewQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAffiliationOverviewQuery,
  Types.GetAffiliationOverviewQueryVariables
>;
export const GetAllAffiliationOverviewDocument = gql`
  query GetAllAffiliationOverview($customerUuid: ID!) {
    affiliations: allAffiliations(filters: { customer: $customerUuid }) {
      nodes {
        ...AffiliationOverview
      }
    }
  }
  ${AffiliationOverviewFragmentDoc}
`;

/**
 * __useGetAllAffiliationOverviewQuery__
 *
 * To run a query within a React component, call `useGetAllAffiliationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAffiliationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAffiliationOverviewQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useGetAllAffiliationOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetAllAffiliationOverviewQuery,
    Types.GetAllAffiliationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetAllAffiliationOverviewQuery,
    Types.GetAllAffiliationOverviewQueryVariables
  >(GetAllAffiliationOverviewDocument, options);
}
export function useGetAllAffiliationOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetAllAffiliationOverviewQuery,
    Types.GetAllAffiliationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetAllAffiliationOverviewQuery,
    Types.GetAllAffiliationOverviewQueryVariables
  >(GetAllAffiliationOverviewDocument, options);
}
export type GetAllAffiliationOverviewQueryHookResult = ReturnType<
  typeof useGetAllAffiliationOverviewQuery
>;
export type GetAllAffiliationOverviewLazyQueryHookResult = ReturnType<
  typeof useGetAllAffiliationOverviewLazyQuery
>;
export type GetAllAffiliationOverviewQueryResult = ApolloReactCommon.QueryResult<
  Types.GetAllAffiliationOverviewQuery,
  Types.GetAllAffiliationOverviewQueryVariables
>;
export const GetCurrentPublicPricesDocument = gql`
  query getCurrentPublicPrices($customerUuid: UUID!, $tickers: [String]!) {
    currentPublicPrices(customer: $customerUuid, tickers: $tickers) {
      ticker
      price
    }
  }
`;

/**
 * __useGetCurrentPublicPricesQuery__
 *
 * To run a query within a React component, call `useGetCurrentPublicPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPublicPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPublicPricesQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *      tickers: // value for 'tickers'
 *   },
 * });
 */
export function useGetCurrentPublicPricesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCurrentPublicPricesQuery,
    Types.GetCurrentPublicPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCurrentPublicPricesQuery,
    Types.GetCurrentPublicPricesQueryVariables
  >(GetCurrentPublicPricesDocument, options);
}
export function useGetCurrentPublicPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCurrentPublicPricesQuery,
    Types.GetCurrentPublicPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCurrentPublicPricesQuery,
    Types.GetCurrentPublicPricesQueryVariables
  >(GetCurrentPublicPricesDocument, options);
}
export type GetCurrentPublicPricesQueryHookResult = ReturnType<
  typeof useGetCurrentPublicPricesQuery
>;
export type GetCurrentPublicPricesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentPublicPricesLazyQuery
>;
export type GetCurrentPublicPricesQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCurrentPublicPricesQuery,
  Types.GetCurrentPublicPricesQueryVariables
>;
export const GetCustomerFlagDocument = gql`
  query GetCustomerFlag($customer: UUID!, $affiliation: UUID, $flag: String!) {
    getCustomerFlag(
      customer: $customer
      affiliation: $affiliation
      flag: $flag
    ) {
      customer
      affiliation
      flag
      createdAt
    }
  }
`;

/**
 * __useGetCustomerFlagQuery__
 *
 * To run a query within a React component, call `useGetCustomerFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFlagQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      affiliation: // value for 'affiliation'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useGetCustomerFlagQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetCustomerFlagQuery,
    Types.GetCustomerFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCustomerFlagQuery,
    Types.GetCustomerFlagQueryVariables
  >(GetCustomerFlagDocument, options);
}
export function useGetCustomerFlagLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCustomerFlagQuery,
    Types.GetCustomerFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCustomerFlagQuery,
    Types.GetCustomerFlagQueryVariables
  >(GetCustomerFlagDocument, options);
}
export type GetCustomerFlagQueryHookResult = ReturnType<
  typeof useGetCustomerFlagQuery
>;
export type GetCustomerFlagLazyQueryHookResult = ReturnType<
  typeof useGetCustomerFlagLazyQuery
>;
export type GetCustomerFlagQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCustomerFlagQuery,
  Types.GetCustomerFlagQueryVariables
>;
export const GetCustomersByUserDocument = gql`
  query GetCustomersByUser($userUuid: ID) {
    customers: allCustomers(filters: { user: $userUuid }) {
      nodes {
        ...Customer
      }
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useGetCustomersByUserQuery__
 *
 * To run a query within a React component, call `useGetCustomersByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersByUserQuery({
 *   variables: {
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useGetCustomersByUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetCustomersByUserQuery,
    Types.GetCustomersByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetCustomersByUserQuery,
    Types.GetCustomersByUserQueryVariables
  >(GetCustomersByUserDocument, options);
}
export function useGetCustomersByUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetCustomersByUserQuery,
    Types.GetCustomersByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetCustomersByUserQuery,
    Types.GetCustomersByUserQueryVariables
  >(GetCustomersByUserDocument, options);
}
export type GetCustomersByUserQueryHookResult = ReturnType<
  typeof useGetCustomersByUserQuery
>;
export type GetCustomersByUserLazyQueryHookResult = ReturnType<
  typeof useGetCustomersByUserLazyQuery
>;
export type GetCustomersByUserQueryResult = ApolloReactCommon.QueryResult<
  Types.GetCustomersByUserQuery,
  Types.GetCustomersByUserQueryVariables
>;
export const GetFormOptionsDocument = gql`
  query GetFormOptions {
    formOptions: FormOptions {
      countries {
        label
        value
      }
      subdivisions {
        country_code
        label
        value
      }
      filing_types {
        value
        label
      }
    }
  }
`;

/**
 * __useGetFormOptionsQuery__
 *
 * To run a query within a React component, call `useGetFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetFormOptionsQuery,
    Types.GetFormOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetFormOptionsQuery,
    Types.GetFormOptionsQueryVariables
  >(GetFormOptionsDocument, options);
}
export function useGetFormOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetFormOptionsQuery,
    Types.GetFormOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetFormOptionsQuery,
    Types.GetFormOptionsQueryVariables
  >(GetFormOptionsDocument, options);
}
export type GetFormOptionsQueryHookResult = ReturnType<
  typeof useGetFormOptionsQuery
>;
export type GetFormOptionsLazyQueryHookResult = ReturnType<
  typeof useGetFormOptionsLazyQuery
>;
export type GetFormOptionsQueryResult = ApolloReactCommon.QueryResult<
  Types.GetFormOptionsQuery,
  Types.GetFormOptionsQueryVariables
>;
export const GetTotalNetWorthDocument = gql`
  query GetTotalNetWorth(
    $customer: UUID!
    $managedBy: PortfolioManagedBySelector
  ) {
    totalNetWorth(customer: $customer, managedBy: $managedBy) {
      allocationTable {
        ...NetWorthAllocationTable
      }
    }
  }
  ${NetWorthAllocationTableFragmentDoc}
`;

/**
 * __useGetTotalNetWorthQuery__
 *
 * To run a query within a React component, call `useGetTotalNetWorthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalNetWorthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalNetWorthQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      managedBy: // value for 'managedBy'
 *   },
 * });
 */
export function useGetTotalNetWorthQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.GetTotalNetWorthQuery,
    Types.GetTotalNetWorthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetTotalNetWorthQuery,
    Types.GetTotalNetWorthQueryVariables
  >(GetTotalNetWorthDocument, options);
}
export function useGetTotalNetWorthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetTotalNetWorthQuery,
    Types.GetTotalNetWorthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetTotalNetWorthQuery,
    Types.GetTotalNetWorthQueryVariables
  >(GetTotalNetWorthDocument, options);
}
export type GetTotalNetWorthQueryHookResult = ReturnType<
  typeof useGetTotalNetWorthQuery
>;
export type GetTotalNetWorthLazyQueryHookResult = ReturnType<
  typeof useGetTotalNetWorthLazyQuery
>;
export type GetTotalNetWorthQueryResult = ApolloReactCommon.QueryResult<
  Types.GetTotalNetWorthQuery,
  Types.GetTotalNetWorthQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($uuid: String) {
    user: User(uuid: $uuid) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<
  Types.GetUserQuery,
  Types.GetUserQueryVariables
>;
export const FetchClientChecklistDocument = gql`
  query fetchClientChecklist($riaDeal: UUID!) {
    clientChecklistQueries {
      byRIADeal(riaDeal: $riaDeal) {
        uuid
        owner
        customer {
          uuid
        }
        checklistItems {
          uuid
          isCompleted
          completedAt
          actionUrl
          key
        }
      }
    }
  }
`;

/**
 * __useFetchClientChecklistQuery__
 *
 * To run a query within a React component, call `useFetchClientChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchClientChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchClientChecklistQuery({
 *   variables: {
 *      riaDeal: // value for 'riaDeal'
 *   },
 * });
 */
export function useFetchClientChecklistQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.FetchClientChecklistQuery,
    Types.FetchClientChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Types.FetchClientChecklistQuery,
    Types.FetchClientChecklistQueryVariables
  >(FetchClientChecklistDocument, options);
}
export function useFetchClientChecklistLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FetchClientChecklistQuery,
    Types.FetchClientChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Types.FetchClientChecklistQuery,
    Types.FetchClientChecklistQueryVariables
  >(FetchClientChecklistDocument, options);
}
export type FetchClientChecklistQueryHookResult = ReturnType<
  typeof useFetchClientChecklistQuery
>;
export type FetchClientChecklistLazyQueryHookResult = ReturnType<
  typeof useFetchClientChecklistLazyQuery
>;
export type FetchClientChecklistQueryResult = ApolloReactCommon.QueryResult<
  Types.FetchClientChecklistQuery,
  Types.FetchClientChecklistQueryVariables
>;
