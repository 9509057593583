import { Scalars } from '@generated';

export type UUID = Scalars['ID']['output'];
export type CalendarDate = Scalars['CalendarDate']['output'];
export type DateTime = Scalars['DateTime']['output'];

export const noop = () => void 0 as unknown;

export enum Duration {
  Days = 'Days',
  Months = 'Months',
  Years = 'Years',
}

export interface DateRange {
  lower?: CalendarDate;
  upper?: CalendarDate;
}

export interface EmptyType {}
