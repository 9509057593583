const filterNonNullItem = <T>(item: T | null | undefined): item is T => {
  return item !== null && typeof item !== 'undefined';
};

/**
 * Removes `null` and `undefined` items
 * from arrays (potentially undefined and/or null).
 */
export const extractNonNullItems = <T>(
  items: (T | null | undefined)[] | null | undefined
): T[] => {
  return items?.filter(filterNonNullItem) ?? [];
};
