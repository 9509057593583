import { AdvisorFragment, User } from '@generated';
import identity from 'lodash/identity';

export const getInitials = (
  user: Pick<User, 'first_name' | 'last_name'>
): string => {
  return getFullName(user)
    .split(' ') // split words
    .filter((str) => !!str) // filter falsies
    .map((str: string) => str.charAt(0)) // take initials
    .join(''); // join it all
};

export const getFullName = (user: Pick<User, 'first_name' | 'last_name'>) =>
  [user.first_name, user.last_name].filter(identity).join(' ');

export const getFirstName = (advisor: Pick<AdvisorFragment, 'full_name'>) =>
  advisor?.full_name?.split(' ')[0];

const generateRandomNumberToHex = (input: string) => {
  const numberInput = Number(input);
  return (numberInput ^ ((Math.random() * 16) >> (numberInput / 4))).toString(
    16
  );
};

export const generateRandomId = (pattern = '100009987000'): string => {
  return pattern.replace(/[018]/g, generateRandomNumberToHex);
};

export const generateRandomUuid = (): string => {
  return generateRandomId('10000000-1000-4000-8000-100000000000');
};

export const appendShortUuid = (original: string, uuid: string): string => {
  return `${original}-${uuid.split('-').at(-1)}`;
};

export const hasShortUuid = (str: string | null | undefined): boolean => {
  if (!str) {
    return false;
  }
  // Match a hyphen followed by a 12-character alphanumeric string at the end of the string
  const match = str.match(/(.+)-[0-9a-f]{12}$/i);
  if (match && match.length > 1) {
    return true;
  }
  // If no short UUID is found, return the original slug
  return false;
};

/**
 * Transform a string to kebab-case.
 * Eg. 'Credit karma' to 'credit-karma'
 *
 * @param {string} input - A string to slugify
 */
export const slugify = (input: string | undefined): string => {
  if (input) {
    return input
      .toLowerCase()
      .trim() // Remove spaces at the start and at the end
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-'); // Replace multiple - with single -
  }

  return '';
};

export function normalizeWebsite(address: string) {
  if (/(http|https):\/\//.test(address)) {
    return address;
  }
  // remove slash if address terminates with a forward slash
  if (address?.endsWith('/')) {
    return `https://${address.replace(/\/$/, '')}`;
  }
  return `https://${address}`;
}

export const getPluralForm = (label: string): string => {
  if (label?.endsWith('s')) {
    return `${label}es`;
  }
  return `${label}s`;
};

export const getPageTitle = (pageTitle: string): string =>
  `${pageTitle} | Secfi`;

export const stringToNumberOrNull = (value?: string) => {
  if (value === null || typeof value === 'undefined') {
    return null;
  }

  return Number(value);
};
