export const getDefaultHeaders = () => ({
  Accept: 'application/json',
  'Cache-Control': 'no-cache,no-store',
  'Content-Type': 'application/json',
});

// can ber regular token or access token
export const getHeaders = (token: string | undefined) => ({
  ...getDefaultHeaders(),
  ...(token ? { Authorization: `Bearer ${token}` } : {}),
});
