export enum AuthenticationQueryKeys {
  Username = 'username',
  Product = 'product',
  VerifyEmailToken = 'verify_email_token',

  SocialCodeKey = 'code',
  SocialStateKey = 'state',

  ResetCodeQueryKey = 'password_reset_code',
  PasswordlessSignupCodeQueryKey = 'passwordless_account_code',
  ClaimAccountCodeQueryKey = 'claim_account_code',

  // mirroring
  User = 'user',

  //errors
  ErrorDescription = 'error_description',
}
