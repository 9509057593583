/* tslint:disable */
/* eslint-disable */
/* istanbul ignore file */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date in YYYY-MM-DD format. */
  CalendarDate: { input: any; output: any };
  /** Date (isoformat) */
  Date: { input: any; output: any };
  /** Date range scalar type */
  DateRange: { input: any; output: any };
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any };
  Timestamp: { input: any; output: any };
  UUID: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
};

export type AccountAlreadyExistsError = {
  __typename?: 'AccountAlreadyExistsError';
  email: Scalars['String']['output'];
  error: Scalars['String']['output'];
};

/** All the relevant information about a private company funding round, with its adjusted price */
export type AdjustedFundingRound = {
  __typename?: 'AdjustedFundingRound';
  adjusted_issue_price?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  issue_price: Scalars['String']['output'];
  post_valuation?: Maybe<Scalars['String']['output']>;
  stock_split?: Maybe<Scalars['String']['output']>;
};

/** User subtype for Secfi operations employees */
export type Advisor = {
  __typename?: 'Advisor';
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  calendly_advisory_insights_call_link?: Maybe<Scalars['String']['output']>;
  calendly_intake_call_link?: Maybe<Scalars['String']['output']>;
  calendly_proposal_call_link?: Maybe<Scalars['String']['output']>;
  calendly_wealth_insights_call_link?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  public_email?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  user: User;
  uuid: Scalars['ID']['output'];
};

export type AdvisorAssigmentRuleInput = {
  advisor_uuid?: InputMaybe<Scalars['String']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
};

/** Model for assigning advisors to deal values */
export type AdvisorAssignmentRule = {
  __typename?: 'AdvisorAssignmentRule';
  advisor_uuid?: Maybe<Scalars['String']['output']>;
  threshold: Scalars['Float']['output'];
};

export type AdvisoryAgreementInput = {
  completed: Scalars['Boolean']['input'];
  ria_deal: Scalars['UUID']['input'];
};

/** Relationship between a user and a company */
export type Affiliation = {
  __typename?: 'Affiliation';
  city?: Maybe<Scalars['String']['output']>;
  company: Company;
  company_assessment?: Maybe<CompanyAssessment>;
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTimeISO']['output'];
  customer: Customer;
  external_source?: Maybe<ExternalSource>;
  has_active_financing_request: Scalars['Boolean']['output'];
  has_equity_plan: Scalars['Boolean']['output'];
  has_financing_request: Scalars['Boolean']['output'];
  has_generated_plans: Scalars['Boolean']['output'];
  has_pending_financing_request: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['DateRange']['output']>;
  subdivision?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
  us_tax_information?: Maybe<UsTaxInformation>;
  user_triage_answers?: Maybe<Array<Maybe<UserTriageAnswers>>>;
  uuid: Scalars['ID']['output'];
};

export type AffiliationCreateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['ID']['input'];
  company_assessment?: InputMaybe<CompanyAssessmentCreateInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  period?: InputMaybe<Scalars['DateRange']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTimeISO']['input']>;
  us_tax_information?: InputMaybe<Scalars['ID']['input']>;
  user_triage_answers?: InputMaybe<Array<InputMaybe<UserTriageAnswers>>>;
};

export type AffiliationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  company_assessment?: InputMaybe<CompanyAssessmentCreateInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  period?: InputMaybe<Scalars['DateRange']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTimeISO']['input']>;
  us_tax_information?: InputMaybe<Scalars['ID']['input']>;
  user_triage_answers?: InputMaybe<Array<InputMaybe<UserTriageAnswers>>>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type AllAdvisorsResponse = {
  __typename?: 'AllAdvisorsResponse';
  nodes: Array<Advisor>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllAffiliationsResponse = {
  __typename?: 'AllAffiliationsResponse';
  nodes: Array<Affiliation>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllCompanyAssessmentsResponse = {
  __typename?: 'AllCompanyAssessmentsResponse';
  nodes: Array<CompanyAssessment>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllCompanyForecastsResponse = {
  __typename?: 'AllCompanyForecastsResponse';
  nodes: Array<CompanyForecast>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllCustomersResponse = {
  __typename?: 'AllCustomersResponse';
  nodes: Array<Customer>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllFinancingObjects = {
  __typename?: 'AllFinancingObjects';
  nodes: Array<FinancingObject>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllFundsResponse = {
  __typename?: 'AllFundsResponse';
  nodes: Array<Fund>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllGrants = {
  __typename?: 'AllGrants';
  rsuGrants: Array<RsuGrant>;
  shareGrants: Array<EquityShareGrant>;
  stockOptionGrants: Array<StockOptionGrant>;
};

export type AllGrantsValidation = {
  __typename?: 'AllGrantsValidation';
  invalidatedRsuGrants: Array<RsuGrant>;
  invalidatedShareGrants: Array<EquityShareGrant>;
  invalidatedStockOptionGrants: Array<StockOptionGrant>;
  isValid: Scalars['Boolean']['output'];
};

export type AllInvestorsResponse = {
  __typename?: 'AllInvestorsResponse';
  nodes: Array<Investor>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllProposalRequests = {
  __typename?: 'AllProposalRequests';
  nodes: Array<ProposalRequest>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllProposalsResponse = {
  __typename?: 'AllProposalsResponse';
  nodes: Array<Proposal>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllUsTaxInformationResponse = {
  __typename?: 'AllUsTaxInformationResponse';
  nodes: Array<UsTaxInformation>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type AllocationTable = AllocationTableBase & {
  __typename?: 'AllocationTable';
  items: Array<AllocationTableEntry>;
  totalValue: Scalars['Float']['output'];
};

export type AllocationTableBase = {
  items: Array<AllocationTableEntry>;
  totalValue: Scalars['Float']['output'];
};

export type AllocationTableEntry = {
  __typename?: 'AllocationTableEntry';
  breakdown?: Maybe<Array<AllocationTableEntry>>;
  companyUuid?: Maybe<Scalars['UUID']['output']>;
  errors?: Maybe<Array<PortfolioError>>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  type: AssetType;
  value: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

/** Type for the Amt Cross Over Scenario */
export type AmtCrossOverScenario = {
  __typename?: 'AmtCrossOverScenario';
  amt_crossover_breakdown?: Maybe<AmtCrossoverBreakDown>;
  amt_summary_at_exercise?: Maybe<AmtSummaryAtExcercise>;
  base_tax_details?: Maybe<TaxDetails>;
  borrower_returns?: Maybe<Scalars['Float']['output']>;
  exercise_tax_breakdown?: Maybe<TaxBreakdown>;
  exercise_tax_details?: Maybe<TaxDetails>;
  exit_equity_value?: Maybe<Scalars['Float']['output']>;
  exit_share_price?: Maybe<Scalars['Float']['output']>;
  exit_tax_breakdown?: Maybe<TaxBreakdown>;
  exit_tax_details?: Maybe<TaxDetails>;
  four09a_equity_value?: Maybe<Scalars['Float']['output']>;
  investor_returns?: Maybe<Scalars['Float']['output']>;
  selling_equity_value?: Maybe<Scalars['Float']['output']>;
  selling_price?: Maybe<Scalars['Float']['output']>;
  target_date?: Maybe<Scalars['CalendarDate']['output']>;
  total_exercise_cost?: Maybe<Scalars['Float']['output']>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
  total_tax_breakdown?: Maybe<TaxBreakdown>;
  total_tax_owed_on_exercise?: Maybe<Scalars['Float']['output']>;
  total_tax_owed_on_exit?: Maybe<Scalars['Float']['output']>;
};

/** Object describing the Amt Cross Over break down */
export type AmtCrossoverBreakDown = {
  __typename?: 'AmtCrossoverBreakDown';
  total_benefit_realized?: Maybe<Scalars['Float']['output']>;
  total_exercise_cost?: Maybe<Scalars['Float']['output']>;
  total_income_benefit_realized?: Maybe<Scalars['Float']['output']>;
  total_quantity?: Maybe<Scalars['Float']['output']>;
};

/** Amt Summary at Excercise */
export type AmtSummaryAtExcercise = {
  __typename?: 'AmtSummaryAtExcercise';
  amt_gap?: Maybe<Scalars['Float']['output']>;
  amt_gap_generated?: Maybe<Scalars['Float']['output']>;
  amt_gap_used?: Maybe<Scalars['Float']['output']>;
  starting_amt_gap?: Maybe<Scalars['Float']['output']>;
  starting_state_amt_gap?: Maybe<Scalars['Float']['output']>;
  state_amt_gap?: Maybe<Scalars['Float']['output']>;
  state_amt_gap_generated?: Maybe<Scalars['Float']['output']>;
  state_amt_gap_used?: Maybe<Scalars['Float']['output']>;
  state_capital_gains_tax?: Maybe<Scalars['Float']['output']>;
};

export enum AnnualIncomeRia {
  AboveK500001 = 'ABOVE_K500001',
  BelowK100000 = 'BELOW_K100000',
  K100001K250000 = 'K100001_K250000',
  K250001K500000 = 'K250001_K500000',
}

export enum AppointmentType {
  AdvisoryInsights = 'advisory_insights',
  AdvisoryIntro = 'advisory_intro',
  ContractOnboarding = 'contract_onboarding',
  EquityPlanningSession = 'equity_planning_session',
  FinancingIntake = 'financing_intake',
  FinancingProposal = 'financing_proposal',
  WealthInsights = 'wealth_insights',
  WealthIntro = 'wealth_intro',
}

export type AsanaTask = {
  __typename?: 'AsanaTask';
  financing_object?: Maybe<Scalars['UUID']['output']>;
  task_gid?: Maybe<Scalars['String']['output']>;
  task_url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum AssetType {
  Cash = 'CASH',
  Crypto = 'CRYPTO',
  Derivative = 'DERIVATIVE',
  Etf = 'ETF',
  FixedIncome = 'FIXED_INCOME',
  Loan = 'LOAN',
  MutualFund = 'MUTUAL_FUND',
  Other = 'OTHER',
  PrivateEquity = 'PRIVATE_EQUITY',
  Stock = 'STOCK',
  Unknown = 'UNKNOWN',
}

export type AssetTypeOverview = PortfolioView & {
  __typename?: 'AssetTypeOverview';
  allocationTable: AllocationTable;
  graph: PortfolioGraph;
  syncStatus: PortfolioSyncStatus;
  willNotifyReady: Scalars['Boolean']['output'];
};

export type BankAccountInformation = IBankAccountInformation & {
  __typename?: 'BankAccountInformation';
  account_number?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  credit_to_account?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object?: Maybe<FinancingObject>;
  reference?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  swift_code?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type BankAccountInformationInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  bank_address?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  beneficiary_name?: InputMaybe<Scalars['String']['input']>;
  credit_to_account?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  routing_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  swift_code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type BasicFormOption = {
  __typename?: 'BasicFormOption';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Bracket = {
  __typename?: 'Bracket';
  bracket_number?: Maybe<Scalars['Float']['output']>;
  income_type?: Maybe<Scalars['String']['output']>;
  lower_limit?: Maybe<Scalars['Float']['output']>;
  payable_tax?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  upper_limit?: Maybe<Scalars['Float']['output']>;
};

export type BulkUpdateProposalRequestsInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  proposal_requests: Array<ProposalRequestBulkUpdateInput>;
  status?: InputMaybe<FinancingStatus>;
  step_reason?: InputMaybe<StepReason>;
  substatus?: InputMaybe<FinancingSubstatus>;
  trigger_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CallType {
  Intake = 'intake',
  Proposal = 'proposal',
}

/** A company's cap table */
export type CapTable = {
  __typename?: 'CapTable';
  author: Scalars['ID']['output'];
  company: Scalars['ID']['output'];
  date_of_latest_equity_round?: Maybe<Scalars['String']['output']>;
  equity_series?: Maybe<Array<Maybe<EquitySeries>>>;
  notes?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type CapTableInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  equity_series?: InputMaybe<Array<InputMaybe<EquitySeriesInput>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum CaptableProvider {
  Carta = 'carta',
  None = 'none',
  Shareworks = 'shareworks',
  Unknown = 'unknown',
}

export type CartaAccountDisconnectResult = {
  __typename?: 'CartaAccountDisconnectResult';
  disconnected: Scalars['Boolean']['output'];
};

export enum CartaConnectionStatus {
  Completed = 'COMPLETED',
  CompletedWithErrors = 'COMPLETED_WITH_ERRORS',
  ConnectionError = 'CONNECTION_ERROR',
  Disconnected = 'DISCONNECTED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  PermissionError = 'PERMISSION_ERROR',
}

export type CartaConnectionStatusResult = {
  __typename?: 'CartaConnectionStatusResult';
  disconnectedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CartaConnectionStatus;
};

export type CartaDataImportResult = {
  __typename?: 'CartaDataImportResult';
  scheduled: Scalars['Boolean']['output'];
};

export enum CartaFlowType {
  ConnectCarta = 'CONNECT_CARTA',
  SignUpWithCarta = 'SIGN_UP_WITH_CARTA',
}

export type CartaLoginUrlResponse = {
  __typename?: 'CartaLoginUrlResponse';
  cartaLoginUrl: Scalars['String']['output'];
};

export type CartaPasswordlessSignupResponse = {
  __typename?: 'CartaPasswordlessSignupResponse';
  accessToken: Scalars['String']['output'];
  customer: Scalars['UUID']['output'];
  duration: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  username: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export enum ChecklistCategory {
  Advisory = 'Advisory',
  Wealth = 'Wealth',
}

export enum ChecklistItemKey {
  AdvisoryAgreement = 'AdvisoryAgreement',
  ComplianceInformationForm = 'ComplianceInformationForm',
  IdentificationInformationForm = 'IdentificationInformationForm',
  InsightsCall = 'InsightsCall',
  PayStubs = 'PayStubs',
  PaymentLink = 'PaymentLink',
  PersonalInformationForm = 'PersonalInformationForm',
  RiskAssessment = 'RiskAssessment',
}

export enum ChecklistState {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type ClientChecklist = {
  __typename?: 'ClientChecklist';
  checklistItems?: Maybe<Array<Maybe<ClientChecklistItem>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Customer>;
  owner?: Maybe<Scalars['UUID']['output']>;
  productCategory?: Maybe<ChecklistCategory>;
  state?: Maybe<ChecklistState>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type ClientChecklistItem = {
  __typename?: 'ClientChecklistItem';
  actionUrl?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Customer>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  key: ChecklistItemKey;
  owner?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type ClientChecklistItemList = {
  __typename?: 'ClientChecklistItemList';
  nodes: Array<ClientChecklistItem>;
  total_count: Scalars['Int']['output'];
};

export type ClientChecklistList = {
  __typename?: 'ClientChecklistList';
  nodes: Array<ClientChecklist>;
  total_count: Scalars['Int']['output'];
};

/** All relevant information about a company */
export type Company = {
  __typename?: 'Company';
  adjusted_funding_rounds?: Maybe<Array<Maybe<AdjustedFundingRound>>>;
  advisor_assignment_rules?: Maybe<Array<Maybe<AdvisorAssignmentRule>>>;
  allow_liquidity?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  bank_account_information?: Maybe<CompanyBankAccountInformation>;
  cap_table_verified?: Maybe<Scalars['Boolean']['output']>;
  cap_tables?: Maybe<Array<Maybe<CapTable>>>;
  captable_provider?: Maybe<CaptableProvider>;
  company_status?: Maybe<CompanyStatusGql>;
  company_substatus?: Maybe<CompanySubstatusGql>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  crm_link?: Maybe<Scalars['String']['output']>;
  employees_at_exit?: Maybe<Scalars['Float']['output']>;
  exit_date?: Maybe<Scalars['CalendarDate']['output']>;
  exit_notes?: Maybe<Scalars['String']['output']>;
  exit_value?: Maybe<CurrencyModel>;
  founding_date?: Maybe<Scalars['CalendarDate']['output']>;
  has_verified_pref_price?: Maybe<Scalars['Boolean']['output']>;
  ipo_date?: Maybe<Scalars['CalendarDate']['output']>;
  ipo_lockup_end_date?: Maybe<Scalars['CalendarDate']['output']>;
  is_fast_track?: Maybe<Scalars['Boolean']['output']>;
  last_issue_price_date?: Maybe<Scalars['CalendarDate']['output']>;
  latest_equity_series_date?: Maybe<Scalars['CalendarDate']['output']>;
  latest_valuation?: Maybe<CurrencyModel>;
  latest_verified_company_assessment?: Maybe<CompanyAssessment>;
  latest_verified_pref_price?: Maybe<CurrencyModel>;
  legal_name?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  products_offered?: Maybe<Array<Maybe<ProductsOffered>>>;
  public_price?: Maybe<CurrencyModel>;
  raised_before_exit?: Maybe<CurrencyModel>;
  subdivision?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Array<Maybe<Terms>>>;
  ticker?: Maybe<Scalars['String']['output']>;
  total_raised?: Maybe<CurrencyModel>;
  uuid: Scalars['ID']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** Evaluation of the state of a company at a specific moment in time */
export type CompanyAssessment = {
  __typename?: 'CompanyAssessment';
  author?: Maybe<User>;
  common_share_value?: Maybe<CurrencyModel>;
  company: Company;
  created_at?: Maybe<Scalars['String']['output']>;
  exit_projections?: Maybe<CompanyExitProjections>;
  expected_exit_date?: Maybe<Scalars['CalendarDate']['output']>;
  expected_exit_multiplier?: Maybe<Scalars['Float']['output']>;
  expected_exit_share_value?: Maybe<CurrencyModel>;
  four09a_share_value?: Maybe<CurrencyModel>;
  fte?: Maybe<Scalars['Float']['output']>;
  has_updates?: Maybe<Scalars['Boolean']['output']>;
  investments: Array<Investor>;
  ipo_or_manda?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  preferred_share_value?: Maybe<CurrencyModel>;
  secondary_market_share_value?: Maybe<CurrencyModel>;
  total_raised?: Maybe<CurrencyModel>;
  updated_at?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
  valuation?: Maybe<CurrencyModel>;
  verified: Scalars['Boolean']['output'];
  verified_captable_requested?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyAssessmentCreateInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  common_share_value?: InputMaybe<CurrencyModelInput>;
  company: Scalars['ID']['input'];
  exit_projections?: InputMaybe<CompanyExitProjectionsInput>;
  expected_exit_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  expected_exit_multiplier?: InputMaybe<Scalars['Float']['input']>;
  expected_exit_share_value?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  fte?: InputMaybe<Scalars['Float']['input']>;
  investments?: InputMaybe<Array<InputMaybe<InvestorInput>>>;
  ipo_or_manda?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  secondary_market_share_value?: InputMaybe<CurrencyModelInput>;
  should_communicate_change?: InputMaybe<Scalars['Boolean']['input']>;
  total_raised?: InputMaybe<CurrencyModelInput>;
  user?: InputMaybe<Scalars['ID']['input']>;
  valuation?: InputMaybe<CurrencyModelInput>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  verified_captable_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyAssessmentFiltersInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  company?: InputMaybe<Scalars['ID']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Company bank account information. */
export type CompanyBankAccountInformation = {
  __typename?: 'CompanyBankAccountInformation';
  account_number?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_number?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  swift_code?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

/** Company bank account information. */
export type CompanyBankAccountInformationInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  bank_address?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  beneficiary_name?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone_number?: InputMaybe<Scalars['String']['input']>;
  routing_number?: InputMaybe<Scalars['String']['input']>;
  swift_code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyCreateInput = {
  advisor_assignment_rules?: InputMaybe<
    Array<InputMaybe<AdvisorAssigmentRuleInput>>
  >;
  allow_liquidity?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  bank_account_information?: InputMaybe<CompanyBankAccountInformationInput>;
  cap_table_verified?: InputMaybe<Scalars['Boolean']['input']>;
  captable_provider?: InputMaybe<CaptableProvider>;
  company_status?: InputMaybe<CompanyStatusGql>;
  company_substatus?: InputMaybe<CompanySubstatusGql>;
  country?: InputMaybe<Scalars['String']['input']>;
  crm_link?: InputMaybe<Scalars['String']['input']>;
  latest_verified_company_assessment?: InputMaybe<Scalars['ID']['input']>;
  latest_verified_company_assessment_created_at?: InputMaybe<
    Scalars['DateTimeISO']['input']
  >;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  products_offered?: InputMaybe<Array<InputMaybe<ProductsOffered>>>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  terms?: InputMaybe<Array<InputMaybe<TermsInput>>>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyExitProjections = {
  __typename?: 'CompanyExitProjections';
  base_case?: Maybe<ExitProjection>;
  best_case?: Maybe<ExitProjection>;
  worst_case?: Maybe<ExitProjection>;
};

export type CompanyExitProjectionsInput = {
  base_case?: InputMaybe<ExitProjectionInput>;
  best_case?: InputMaybe<ExitProjectionInput>;
  worst_case?: InputMaybe<ExitProjectionInput>;
};

export type CompanyForecast = {
  __typename?: 'CompanyForecast';
  company: Scalars['ID']['output'];
  created_at: Scalars['String']['output'];
  exit_value?: Maybe<CurrencyModel>;
  nr_of_outstanding_shares: Scalars['Float']['output'];
  updated_at: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type CompanyForecastCreateInput = {
  company: Scalars['ID']['input'];
  exit_value?: InputMaybe<CurrencyModelInput>;
  nr_of_outstanding_shares: Scalars['Float']['input'];
};

export type CompanyForecastFiltersInput = {
  company?: InputMaybe<Scalars['ID']['input']>;
  created_at?: InputMaybe<Scalars['String']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['String']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyInput = {
  advisor_assignment_rules?: InputMaybe<
    Array<InputMaybe<AdvisorAssigmentRuleInput>>
  >;
  allow_liquidity?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  bank_account_information?: InputMaybe<CompanyBankAccountInformationInput>;
  cap_table_verified?: InputMaybe<Scalars['Boolean']['input']>;
  captable_provider?: InputMaybe<CaptableProvider>;
  company_status?: InputMaybe<CompanyStatusGql>;
  company_substatus?: InputMaybe<CompanySubstatusGql>;
  country?: InputMaybe<Scalars['String']['input']>;
  crm_link?: InputMaybe<Scalars['String']['input']>;
  latest_verified_company_assessment?: InputMaybe<Scalars['ID']['input']>;
  latest_verified_company_assessment_created_at?: InputMaybe<
    Scalars['DateTimeISO']['input']
  >;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  products_offered?: InputMaybe<Array<InputMaybe<ProductsOffered>>>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  terms?: InputMaybe<Array<InputMaybe<TermsInput>>>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyListResponse = {
  __typename?: 'CompanyListResponse';
  nodes: Array<CompanyRow>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

/** Data displayed in the admin Companies page */
export type CompanyRow = {
  __typename?: 'CompanyRow';
  author?: Maybe<Scalars['String']['output']>;
  author_first_name?: Maybe<Scalars['String']['output']>;
  author_last_name?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_status?: Maybe<Scalars['String']['output']>;
  company_substatus?: Maybe<Scalars['String']['output']>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  company_website?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  crm_link?: Maybe<Scalars['String']['output']>;
  four09a_share_value?: Maybe<CurrencyModel>;
  fte?: Maybe<Scalars['Float']['output']>;
  investments?: Maybe<Array<Maybe<Investor>>>;
  preferred_share_value?: Maybe<CurrencyModel>;
  products_offered?: Maybe<Array<Maybe<ProductsOffered>>>;
  terms_count?: Maybe<Scalars['Float']['output']>;
  total_raised?: Maybe<CurrencyModel>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  valuation?: Maybe<CurrencyModel>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanySearchResultItem = {
  __typename?: 'CompanySearchResultItem';
  importKey?: Maybe<Scalars['String']['output']>;
  imported?: Maybe<Scalars['Boolean']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  uuid?: Maybe<Scalars['UUID']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanyShortInfo = {
  __typename?: 'CompanyShortInfo';
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  uuid?: Maybe<Scalars['UUID']['output']>;
  website: Scalars['String']['output'];
};

export enum CompanyStatusGql {
  Approved = 'approved',
  DueDilligence = 'due_dilligence',
  Exited = 'exited',
  Intake = 'intake',
  NeedsMomentum = 'needs_momentum',
  Rejected = 'rejected',
  Tracking = 'tracking',
  Triage = 'triage',
  Underwriting = 'underwriting',
}

export enum CompanySubstatusGql {
  Active = 'active',
  AwaitingData = 'awaiting_data',
  AwaitingUw = 'awaiting_uw',
  BeingUw = 'being_uw',
  EarlyStage = 'early_stage',
  ExitAnnounced = 'exit_announced',
  FailedUw = 'failed_uw',
  Inactive = 'inactive',
  NeedsDdContact = 'needs_dd_contact',
  NeedsLargerPipeline = 'needs_larger_pipeline',
  NeedsMoreInformation = 'needs_more_information',
  NeedsReview = 'needs_review',
  NotAlignedWithOurPhilosophy = 'not_aligned_with_our_philosophy',
  NotWillingToWorkWithUs = 'not_willing_to_work_with_us',
  OneOffDeals = 'one_off_deals',
  PendingSettlements = 'pending_settlements',
  PreviouslyPassed = 'previously_passed',
  PublicCompany = 'public_company',
  SettlementsComplete = 'settlements_complete',
  SettlementsInProgress = 'settlements_in_progress',
  UpdateNeeded = 'update_needed',
}

export type CompanyTriageListResponse = {
  __typename?: 'CompanyTriageListResponse';
  nodes: Array<CompanyTriageRow>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

/** Data displayed in the admin Companies page */
export type CompanyTriageRow = {
  __typename?: 'CompanyTriageRow';
  advisor_assignment_rules?: Maybe<Array<Maybe<AdvisorAssignmentRule>>>;
  all_users_count?: Maybe<Scalars['Float']['output']>;
  company_description?: Maybe<Scalars['String']['output']>;
  company_industries?: Maybe<Array<Maybe<IndustryTree>>>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_status?: Maybe<Scalars['String']['output']>;
  company_substatus?: Maybe<Scalars['String']['output']>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  company_verticals?: Maybe<Array<Maybe<Vertical>>>;
  company_website?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  financing_requests_count?: Maybe<Scalars['Float']['output']>;
  investments?: Maybe<Array<Maybe<Investor>>>;
  last_funding_round_series_date?: Maybe<Scalars['DateTimeISO']['output']>;
  last_funding_round_series_name?: Maybe<Scalars['String']['output']>;
  last_request_date?: Maybe<Scalars['DateTimeISO']['output']>;
  pitchbook_url?: Maybe<Scalars['String']['output']>;
  preferred_share_value?: Maybe<CurrencyModel>;
  total_raised?: Maybe<CurrencyModel>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  valuation?: Maybe<CurrencyModel>;
  value_of_requests?: Maybe<CurrencyModel>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Data displayed in the company triage view under a company. This includes users
 *     affiliated with the company, who made financing requests or have at least added their equity
 *     information
 */
export type CompanyTriageUserRow = {
  __typename?: 'CompanyTriageUserRow';
  advance_to_equity_ratio?: Maybe<Scalars['Float']['output']>;
  advisor?: Maybe<Scalars['String']['output']>;
  affiliation_uuid?: Maybe<Scalars['String']['output']>;
  collateral_value?: Maybe<CurrencyModel>;
  date_submitted?: Maybe<Scalars['DateTimeISO']['output']>;
  deal_status?: Maybe<FinancingStatus>;
  deal_substatus?: Maybe<FinancingSubstatus>;
  financing_object_uuid?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  has_minimum_personal_info?: Maybe<Scalars['Boolean']['output']>;
  linkedin_url?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  possible_status_transitions?: Maybe<Array<FinancingStatusPair>>;
  proposal_request_uuid?: Maybe<Scalars['String']['output']>;
  submitted_financing_request?: Maybe<Scalars['Boolean']['output']>;
  tenure_months?: Maybe<Scalars['Float']['output']>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
};

export type CompanyTriageUsersListResponse = {
  __typename?: 'CompanyTriageUsersListResponse';
  nodes: Array<CompanyTriageUserRow>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type ComplianceInformationDataRia = {
  __typename?: 'ComplianceInformationDataRIA';
  employer?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<Employment>;
  financial_industry_regulatory_authority?: Maybe<FinancialIndustryRegulatoryAuthorityRia>;
  income?: Maybe<AnnualIncomeRia>;
  industry?: Maybe<Scalars['String']['output']>;
  is_financial_industry_regulatory_authority?: Maybe<
    Scalars['Boolean']['output']
  >;
  is_political_officer?: Maybe<Scalars['Boolean']['output']>;
  is_senior_officer?: Maybe<Scalars['Boolean']['output']>;
  liquid_net_worth?: Maybe<LiquidNetWorthRia>;
  political_officer_country?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  senior_officer_company?: Maybe<Scalars['String']['output']>;
  senior_officer_ticker?: Maybe<Scalars['String']['output']>;
  total_net_worth?: Maybe<TotalNetWorthRia>;
};

export type ComplianceInformationDataRiaInput = {
  employer?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<Employment>;
  financial_industry_regulatory_authority?: InputMaybe<FinancialIndustryRegulatoryAuthorityRia>;
  income?: InputMaybe<AnnualIncomeRia>;
  industry?: InputMaybe<Scalars['String']['input']>;
  is_financial_industry_regulatory_authority?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  is_political_officer?: InputMaybe<Scalars['Boolean']['input']>;
  is_senior_officer?: InputMaybe<Scalars['Boolean']['input']>;
  liquid_net_worth?: InputMaybe<LiquidNetWorthRia>;
  political_officer_country?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  senior_officer_company?: InputMaybe<Scalars['String']['input']>;
  senior_officer_ticker?: InputMaybe<Scalars['String']['input']>;
  total_net_worth?: InputMaybe<TotalNetWorthRia>;
};

/** Financing form to capture information needed by the broker dealer about the user's employment and education history */
export type ComplianceInformationFinancing = {
  __typename?: 'ComplianceInformationFinancing';
  employer?: Maybe<Scalars['String']['output']>;
  employer_address?: Maybe<Scalars['String']['output']>;
  employer_business?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: FinancingObject;
  is_broker_dealer?: Maybe<Scalars['Boolean']['output']>;
  is_financial_industry_regulatory_authority?: Maybe<
    Scalars['Boolean']['output']
  >;
  is_fund_member?: Maybe<Scalars['Boolean']['output']>;
  is_political_officer?: Maybe<Scalars['Boolean']['output']>;
  is_related_to_broker_dealer?: Maybe<Scalars['Boolean']['output']>;
  is_senior_officer?: Maybe<Scalars['Boolean']['output']>;
  net_worth?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  other?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  uuid?: Maybe<Scalars['ID']['output']>;
  years_in_position?: Maybe<Scalars['Float']['output']>;
};

export type ComplianceInformationFinancingInput = {
  employer?: InputMaybe<Scalars['String']['input']>;
  employer_address?: InputMaybe<Scalars['String']['input']>;
  employer_business?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  is_broker_dealer?: InputMaybe<Scalars['Boolean']['input']>;
  is_financial_industry_regulatory_authority?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  is_fund_member?: InputMaybe<Scalars['Boolean']['input']>;
  is_political_officer?: InputMaybe<Scalars['Boolean']['input']>;
  is_related_to_broker_dealer?: InputMaybe<Scalars['Boolean']['input']>;
  is_senior_officer?: InputMaybe<Scalars['Boolean']['input']>;
  net_worth?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  years_in_position?: InputMaybe<Scalars['Float']['input']>;
};

export type ComplianceInformationRia = {
  __typename?: 'ComplianceInformationRIA';
  data?: Maybe<ComplianceInformationDataRia>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  ria_deal: Scalars['UUID']['output'];
  status?: Maybe<FormModelStatusGql>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ComplianceInformationRiaInput = {
  data?: InputMaybe<ComplianceInformationDataRiaInput>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  ria_deal: Scalars['UUID']['input'];
  status?: InputMaybe<FormModelStatusGql>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ConfigProgress = {
  __typename?: 'ConfigProgress';
  breakdown: ConfigProgressBreakdown;
  overallProgress: Scalars['Int']['output'];
};

export type ConfigProgressBreakdown = {
  __typename?: 'ConfigProgressBreakdown';
  companyAndTaxDetails: Scalars['Boolean']['output'];
  exitProjection: Scalars['Boolean']['output'];
  grants: Scalars['Boolean']['output'];
  plans: Scalars['Boolean']['output'];
};

export type Contract = FinancingFormModel & {
  __typename?: 'Contract';
  client_signed_at?: Maybe<Scalars['String']['output']>;
  execution_date?: Maybe<Scalars['CalendarDate']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<ContractFile>;
  files?: Maybe<Array<Maybe<ContractFile>>>;
  financing_object?: Maybe<FinancingObject>;
  is_outdated?: Maybe<Scalars['Boolean']['output']>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ContractFile = {
  __typename?: 'ContractFile';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ContractInput = {
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum ContractType {
  PrivateSecuritiesContract = 'private_securities_contract',
  SpringingLienPsc = 'springing_lien_psc',
  SpringingLienStockPledge = 'springing_lien_stock_pledge',
}

export enum ContractTypes {
  Investmentdirectionletter = 'investmentdirectionletter',
  Privatesecuritiescontract = 'privatesecuritiescontract',
  Seriescontract = 'seriescontract',
  Springinglienassignmentagreement = 'springinglienassignmentagreement',
  Springinglienpsc = 'springinglienpsc',
  Springinglienstockpledge = 'springinglienstockpledge',
}

export type ContractVariant = {
  __typename?: 'ContractVariant';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Currency = {
  __typename?: 'Currency';
  currency?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CurrencyInput = {
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

/** Secfi type to define monetary values */
export type CurrencyModel = {
  __typename?: 'CurrencyModel';
  currency: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CurrencyModelInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** User subtype for Secfi clients or potential clients */
export type Customer = {
  __typename?: 'Customer';
  address_1?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  kyc_status?: Maybe<KycStatus>;
  nickname?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pipedrive_deal_id?: Maybe<Scalars['Float']['output']>;
  reasons_interested?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  show_equity_planner?: Maybe<Scalars['Boolean']['output']>;
  show_initial_screen?: Maybe<Scalars['Boolean']['output']>;
  subdivision?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  uuid: Scalars['ID']['output'];
  zip_code?: Maybe<Scalars['String']['output']>;
};

/** Financing form to capture bank information from the customer's company */
export type CustomerBankAccountInformation = IBankAccountInformation & {
  __typename?: 'CustomerBankAccountInformation';
  account_number?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_number?: Maybe<Scalars['String']['output']>;
  copied_from_issuer?: Maybe<Scalars['Boolean']['output']>;
  credit_to_account?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: FinancingObject;
  reference?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  swift_code?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type CustomerBankAccountInformationInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  bank_address?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  beneficiary_name?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone_number?: InputMaybe<Scalars['String']['input']>;
  credit_to_account?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  routing_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  swift_code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerFlag = {
  __typename?: 'CustomerFlag';
  affiliation?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Scalars['UUID']['output'];
  flag: Scalars['String']['output'];
};

export type CustomerInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  pipedrive_deal_id?: InputMaybe<Scalars['Float']['input']>;
  reasons_interested?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  show_initial_screen?: InputMaybe<Scalars['Boolean']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerWaitlist = {
  __typename?: 'CustomerWaitlist';
  createdAt: Scalars['Date']['output'];
  customer: Scalars['String']['output'];
  questions?: Maybe<Array<Maybe<CustomerWaitlistQuestion>>>;
  source?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  waitlistCategory: Scalars['String']['output'];
  waitlistName: Scalars['String']['output'];
  waitlistPrice?: Maybe<Scalars['Float']['output']>;
};

export type CustomerWaitlistQuestion = {
  __typename?: 'CustomerWaitlistQuestion';
  answerDescription: Scalars['String']['output'];
  answerTitle: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CustomerWaitlistQuestionInput = {
  answerDescription: Scalars['String']['input'];
  answerTitle: Scalars['String']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum DataCaptureFormGroups {
  O1VerifyEquity = 'o1_verify_equity',
  O2PersonalInformation = 'o2_personal_information',
  O3BankInformation = 'o3_bank_information',
}

/** Object to cover the amounts of the deal calculations */
export type DealCalculations = {
  __typename?: 'DealCalculations';
  amounts_valid?: Maybe<Scalars['Boolean']['output']>;
  financing_object: Scalars['ID']['output'];
  individual_amount?: Maybe<CurrencyModel>;
  issuer_amount?: Maybe<CurrencyModel>;
  rampup_fees_overview?: Maybe<Array<RampScheduleOverview>>;
  secfi_amount?: Maybe<CurrencyModel>;
  secfi_fee_percentage?: Maybe<Scalars['Float']['output']>;
  status: FormModelStatus;
  total_amount?: Maybe<CurrencyModel>;
  trust_fee_amount?: Maybe<CurrencyModel>;
  uuid: Scalars['ID']['output'];
};

export type DealCalculationsInput = {
  individual_amount?: InputMaybe<CurrencyModelInput>;
  issuer_amount?: InputMaybe<CurrencyModelInput>;
  secfi_amount?: InputMaybe<CurrencyModelInput>;
  status?: InputMaybe<FormModelStatus>;
  total_amount?: InputMaybe<CurrencyModelInput>;
  trust_fee_amount?: InputMaybe<CurrencyModelInput>;
};

export type EpCompanyAndTaxDetails = {
  additionalDeductions: Scalars['Float']['input'];
  additionalIncome: Scalars['Float']['input'];
  charitableContributions: Scalars['Float']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  dependents: Scalars['Int']['input'];
  dividendIncome: Scalars['Float']['input'];
  filingType: Scalars['String']['input'];
  four01kContributions: Scalars['Float']['input'];
  four09aPrice: Scalars['Float']['input'];
  income: Scalars['Float']['input'];
  iraContribution: Scalars['Float']['input'];
  longTermCapitalGains: Scalars['Float']['input'];
  medicalExpenses: Scalars['Float']['input'];
  mortgageInterest: Scalars['Float']['input'];
  netRentalRealEstateIncome: Scalars['Float']['input'];
  propertyStateLocalTaxes: Scalars['Float']['input'];
  selfEmploymentIncome: Scalars['Float']['input'];
  shortTermCapitalGains: Scalars['Float']['input'];
  subdivision: Scalars['String']['input'];
  usTaxInformationUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type EpCompanyAndTaxDetailsItemData = {
  __typename?: 'EPCompanyAndTaxDetailsItemData';
  additionalDeductions?: Maybe<Scalars['Float']['output']>;
  additionalIncome?: Maybe<Scalars['Float']['output']>;
  charitableContributions?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dependents?: Maybe<Scalars['Int']['output']>;
  dividendIncome?: Maybe<Scalars['Float']['output']>;
  filingType?: Maybe<Scalars['String']['output']>;
  four01kContributions?: Maybe<Scalars['Float']['output']>;
  four09aPrice?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
  iraContribution?: Maybe<Scalars['Float']['output']>;
  longTermCapitalGains?: Maybe<Scalars['Float']['output']>;
  medicalExpenses?: Maybe<Scalars['Float']['output']>;
  mortgageInterest?: Maybe<Scalars['Float']['output']>;
  netRentalRealEstateIncome?: Maybe<Scalars['Float']['output']>;
  propertyStateLocalTaxes?: Maybe<Scalars['Float']['output']>;
  selfEmploymentIncome?: Maybe<Scalars['Float']['output']>;
  shortTermCapitalGains?: Maybe<Scalars['Float']['output']>;
  subdivision?: Maybe<Scalars['String']['output']>;
  usTaxInformationUuid?: Maybe<Scalars['UUID']['output']>;
};

export type EpCompanyDetails = {
  four09aPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type EpCompanyDetailsItemData = {
  __typename?: 'EPCompanyDetailsItemData';
  four09aPrice?: Maybe<Scalars['Float']['output']>;
};

export type EpCompanyPublicListingItemData = {
  __typename?: 'EPCompanyPublicListingItemData';
  price: Scalars['Float']['output'];
  ticker: Scalars['String']['output'];
};

export type EpExerciseCostsEstimation = {
  __typename?: 'EPExerciseCostsEstimation';
  grantsExerciseCost?: Maybe<Array<Maybe<EpGrantExerciseCostsEstimation>>>;
  totalExerciseCost?: Maybe<Scalars['Float']['output']>;
};

export type EpExerciseItemData = {
  __typename?: 'EPExerciseItemData';
  exercisableQuantities: Array<ExercisableQuantity>;
  investment: Scalars['Float']['output'];
};

export type EpExerciseLaterProjectionData = {
  __typename?: 'EPExerciseLaterProjectionData';
  exerciseDate: Scalars['CalendarDate']['output'];
  four09aPrice: CurrencyModel;
};

export type EpExerciseLaterProjectionDataInput = {
  exerciseDate: Scalars['CalendarDate']['input'];
  four09aPrice: CurrencyModelInput;
};

export type EpExerciseNowEarlyData = {
  __typename?: 'EPExerciseNowEarlyData';
  optionGrants: Array<EpExerciseNowEarlyGrantData>;
};

export type EpExerciseNowEarlyDataInput = {
  optionGrants: Array<EpExerciseNowEarlyGrantDataInput>;
};

export type EpExerciseNowEarlyGrantData = {
  __typename?: 'EPExerciseNowEarlyGrantData';
  quantity: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EpExerciseNowEarlyGrantDataInput = {
  quantity: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
};

export type EpExitDate = {
  __typename?: 'EPExitDate';
  description: Scalars['String']['output'];
  exitDate?: Maybe<Scalars['CalendarDate']['output']>;
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
};

export type EpExitDateChoices = {
  __typename?: 'EPExitDateChoices';
  choices: Array<EpExitDate>;
  id: Scalars['UUID']['output'];
};

export type EpExitMultiplier = {
  __typename?: 'EPExitMultiplier';
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  multiplier?: Maybe<Scalars['Float']['output']>;
};

export type EpExitMultiplierChoices = {
  __typename?: 'EPExitMultiplierChoices';
  choices: Array<EpExitMultiplier>;
  id: Scalars['UUID']['output'];
};

export type EpExitProjection = {
  chosenExitDate?: InputMaybe<Scalars['UUID']['input']>;
  chosenMultiplier?: InputMaybe<Scalars['UUID']['input']>;
  customMultiplierValue?: InputMaybe<Scalars['Float']['input']>;
  exitDate?: InputMaybe<Scalars['CalendarDate']['input']>;
  presentedExitDates?: InputMaybe<Scalars['UUID']['input']>;
  presentedMultipliers?: InputMaybe<Scalars['UUID']['input']>;
  sharePrice?: InputMaybe<Scalars['Float']['input']>;
};

export type EpExitProjectionItemData = {
  __typename?: 'EPExitProjectionItemData';
  exitDate?: Maybe<Scalars['CalendarDate']['output']>;
  sharePrice?: Maybe<Scalars['Float']['output']>;
};

export type EpExitValueEstimation = {
  __typename?: 'EPExitValueEstimation';
  netGains: Scalars['Float']['output'];
  optionGrants: Array<EpOptionGrantsExitValueEstimation>;
  shareGrants: Array<EpShareGrantsExitValueEstimation>;
};

export type EpGainsItemData = {
  __typename?: 'EPGainsItemData';
  gains?: Maybe<Scalars['Float']['output']>;
};

export type EpGrantExerciseCostsEstimation = {
  __typename?: 'EPGrantExerciseCostsEstimation';
  exerciseCost?: Maybe<Scalars['Float']['output']>;
  taxDueOnExercise?: Maybe<Scalars['Float']['output']>;
  totalExerciseCost?: Maybe<Scalars['Float']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vestedQuantity?: Maybe<Scalars['Int']['output']>;
};

export type EpGrantSold = {
  __typename?: 'EPGrantSold';
  quantity: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type EpLiquidityFromSecondaryMarketItemData = {
  __typename?: 'EPLiquidityFromSecondaryMarketItemData';
  netGains: Scalars['Float']['output'];
};

export type EpLiquidityFromTenderOfferItemData = {
  __typename?: 'EPLiquidityFromTenderOfferItemData';
  netGains: Scalars['Float']['output'];
};

export type EpOptionGrantItemData = {
  __typename?: 'EPOptionGrantItemData';
  optionGrant: StockOptionGrant;
};

export type EpOptionGrantsExitValueEstimation = {
  __typename?: 'EPOptionGrantsExitValueEstimation';
  netGains: Scalars['Float']['output'];
  optionType: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EpPlan = {
  __typename?: 'EPPlan';
  description: Scalars['String']['output'];
  exerciseCosts: Scalars['Float']['output'];
  exerciseDate: Scalars['CalendarDate']['output'];
  grossReturnFromSelling: Scalars['Float']['output'];
  netGains: Scalars['Float']['output'];
  optionGrants?: Maybe<Array<Maybe<EpPlanOptionGrant>>>;
  /** Total quantity of options defined in the equity grants considered by the plan */
  optionsQuantity: Scalars['Int']['output'];
  personalCapitalInvested: Scalars['Float']['output'];
  planType: EpPlanType;
  riskOfCostsIncreasing: Scalars['String']['output'];
  riskOfCostsIncreasingTooltipDescription: Scalars['String']['output'];
  securedShares: Scalars['Float']['output'];
  shareGrants?: Maybe<Array<Maybe<EpPlanShareGrant>>>;
  /** Share price on target date. */
  sharePrice: Scalars['Float']['output'];
  sharePriceOnSellDate: Scalars['Float']['output'];
  talkToStrategistOption: Scalars['Boolean']['output'];
  taxesDueOnExercise: Scalars['Float']['output'];
  taxesDueOnExit: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  totalExerciseCost: Scalars['Float']['output'];
  vestedOptionsAtExercise: Scalars['Int']['output'];
};

export type EpPlanOptionGrant = {
  __typename?: 'EPPlanOptionGrant';
  exercisableQuantity: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  remainingQuantity: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  vestedQuantity: Scalars['Int']['output'];
};

export type EpPlanShareGrant = {
  __typename?: 'EPPlanShareGrant';
  quantity: Scalars['Int']['output'];
};

export enum EpPlanType {
  ExerciseAtExit = 'ExerciseAtExit',
  ExerciseLater = 'ExerciseLater',
  ExerciseNowEarly = 'ExerciseNowEarly',
  ExerciseNowVested = 'ExerciseNowVested',
  SecondaryMarket = 'SecondaryMarket',
  TenderOffer = 'TenderOffer',
}

export type EpSecondaryMarketData = {
  __typename?: 'EPSecondaryMarketData';
  expectedDate: Scalars['CalendarDate']['output'];
  optionGrants: Array<EpSecondaryMarketGrantData>;
  price: Scalars['Float']['output'];
  shareGrants: Array<EpSecondaryMarketGrantData>;
};

export type EpSecondaryMarketDataInput = {
  expectedDate: Scalars['CalendarDate']['input'];
  optionGrants: Array<EpSecondaryMarketGrantDataInput>;
  price: Scalars['Float']['input'];
  shareGrants: Array<EpSecondaryMarketGrantDataInput>;
};

export type EpSecondaryMarketGrantData = {
  __typename?: 'EPSecondaryMarketGrantData';
  quantity: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EpSecondaryMarketGrantDataInput = {
  quantity: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
};

export type EpSecondaryMarketItemData = {
  __typename?: 'EPSecondaryMarketItemData';
  grantsSold: Array<EpGrantSold>;
};

export type EpShareGrantItemData = {
  __typename?: 'EPShareGrantItemData';
  shareGrant: EquityShareGrant;
};

export type EpShareGrantsExitValueEstimation = {
  __typename?: 'EPShareGrantsExitValueEstimation';
  netGains: Scalars['Float']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EpTenderOfferData = {
  __typename?: 'EPTenderOfferData';
  closeDate: Scalars['CalendarDate']['output'];
  investmentRound: Scalars['String']['output'];
  optionGrants: Array<EpTenderOfferGrantData>;
  price: Scalars['Float']['output'];
  shareGrants: Array<EpTenderOfferGrantData>;
};

export type EpTenderOfferDataInput = {
  closeDate: Scalars['CalendarDate']['input'];
  investmentRound: Scalars['String']['input'];
  optionGrants: Array<EpTenderOfferGrantDataInput>;
  price: Scalars['Float']['input'];
  shareGrants: Array<EpTenderOfferGrantDataInput>;
};

export type EpTenderOfferGrantData = {
  __typename?: 'EPTenderOfferGrantData';
  quantity: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EpTenderOfferGrantDataInput = {
  quantity: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
};

export type EpTenderOfferItemData = {
  __typename?: 'EPTenderOfferItemData';
  grantsSold: Array<EpGrantSold>;
};

export type EpTimeline = {
  __typename?: 'EPTimeline';
  configProgress: ConfigProgress;
  equityPlanConfigUuid: Scalars['UUID']['output'];
  exerciseLaterProjectionData?: Maybe<EpExerciseLaterProjectionData>;
  exerciseNowEarlyData?: Maybe<EpExerciseNowEarlyData>;
  hasEarlyExercisableGrant: Scalars['Boolean']['output'];
  hasGeneratedPlans: Scalars['Boolean']['output'];
  isSelectedPlansOutdated: Scalars['Boolean']['output'];
  items: Array<EpTimelineItem>;
  secondaryMarketData?: Maybe<EpSecondaryMarketData>;
  selectedPlans?: Maybe<Array<EpPlanType>>;
  tenderOfferData?: Maybe<EpTenderOfferData>;
};

export type EpTimelineItem = {
  __typename?: 'EPTimelineItem';
  data?: Maybe<EpTimelineItemData>;
  date?: Maybe<Scalars['CalendarDate']['output']>;
  plan?: Maybe<EpPlanType>;
  state?: Maybe<EpTimelineItemState>;
  title: Scalars['String']['output'];
  type: EpTimelineItemType;
};

export type EpTimelineItemData =
  | EpCompanyAndTaxDetailsItemData
  | EpCompanyPublicListingItemData
  | EpExerciseItemData
  | EpExitProjectionItemData
  | EpGainsItemData
  | EpLiquidityFromSecondaryMarketItemData
  | EpLiquidityFromTenderOfferItemData
  | EpOptionGrantItemData
  | EpSecondaryMarketItemData
  | EpShareGrantItemData
  | EpTenderOfferItemData;

export enum EpTimelineItemState {
  Completed = 'Completed',
  Empty = 'Empty',
}

export enum EpTimelineItemType {
  AddAnotherOptionGrant = 'AddAnotherOptionGrant',
  CompanyAndTaxDetails = 'CompanyAndTaxDetails',
  CompanyPublicListing = 'CompanyPublicListing',
  Exercise = 'Exercise',
  ExitProjection = 'ExitProjection',
  Gains = 'Gains',
  LiquidityFromSecondaryMarket = 'LiquidityFromSecondaryMarket',
  LiquidityFromTenderOffer = 'LiquidityFromTenderOffer',
  OptionGrant = 'OptionGrant',
  SecondaryMarket = 'SecondaryMarket',
  ShareGrant = 'ShareGrant',
  TenderOffer = 'TenderOffer',
}

export type EpTimelineWrapper = {
  __typename?: 'EPTimelineWrapper';
  timeline: EpTimeline;
};

export type EpVestedGrantQuantity = {
  __typename?: 'EPVestedGrantQuantity';
  uuid: Scalars['UUID']['output'];
  vestedQuantity: Scalars['Int']['output'];
};

/** Effective tax rates for all tax types in the calculations. */
export type EffectiveTaxRates = {
  __typename?: 'EffectiveTaxRates';
  amt?: Maybe<Scalars['Float']['output']>;
  capital_gains_tax?: Maybe<Scalars['Float']['output']>;
  income_tax?: Maybe<Scalars['Float']['output']>;
  medicare_tax?: Maybe<Scalars['Float']['output']>;
  net_investment_income_tax?: Maybe<Scalars['Float']['output']>;
  state_capital_gains_tax?: Maybe<Scalars['Float']['output']>;
  state_income_tax?: Maybe<Scalars['Float']['output']>;
  total_capital_gains_tax?: Maybe<Scalars['Float']['output']>;
};

export enum Employment {
  Employed = 'employed',
  NotEmployed = 'not_employed',
  Other = 'other',
  Retired = 'retired',
  SelfEmployed = 'self_employed',
  Student = 'student',
}

export type EquityCurrency = {
  __typename?: 'EquityCurrency';
  currency: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EquityGraph = {
  __typename?: 'EquityGraph';
  errors?: Maybe<Array<PortfolioError>>;
  markers: Array<GraphMarker>;
  totalDynamicValueLine: Array<TimelinePoint>;
  totalPrefValueLine: Array<TimelinePoint>;
  totalPublicValueLine: Array<TimelinePoint>;
  vestedDynamicValueLine: Array<TimelinePoint>;
  vestedPrefValueLine: Array<TimelinePoint>;
  vestedPublicValueLine: Array<TimelinePoint>;
};

export type EquityMutations = {
  __typename?: 'EquityMutations';
  createExerciseEvent: ExerciseEvent;
  createRsuGrant: RsuGrant;
  createShareGrant: EquityShareGrant;
  createStockOptionGrant: StockOptionGrant;
  deleteExerciseEvent?: Maybe<Scalars['Void']['output']>;
  deleteRsuGrant?: Maybe<Scalars['Void']['output']>;
  deleteShareGrant?: Maybe<Scalars['Void']['output']>;
  deleteStockOptionGrant?: Maybe<Scalars['Void']['output']>;
  updateExerciseEvent: ExerciseEvent;
  updateRsuGrant: RsuGrant;
  updateShareGrant: EquityShareGrant;
  updateStockOptionGrant: StockOptionGrant;
};

export type EquityMutationsCreateExerciseEventArgs = {
  payload: ExerciseEventInput;
};

export type EquityMutationsCreateRsuGrantArgs = {
  payload: RsuGrantInput;
};

export type EquityMutationsCreateShareGrantArgs = {
  payload: EquityShareGrantInput;
};

export type EquityMutationsCreateStockOptionGrantArgs = {
  payload: StockOptionGrantInput;
};

export type EquityMutationsDeleteExerciseEventArgs = {
  grantUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsDeleteRsuGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsDeleteShareGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsDeleteStockOptionGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsUpdateExerciseEventArgs = {
  payload: ExerciseEventInput;
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsUpdateRsuGrantArgs = {
  payload: RsuGrantInput;
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsUpdateShareGrantArgs = {
  payload: EquityShareGrantInput;
  uuid: Scalars['UUID']['input'];
};

export type EquityMutationsUpdateStockOptionGrantArgs = {
  payload: StockOptionGrantInput;
  uuid: Scalars['UUID']['input'];
};

export type EquityQueries = {
  __typename?: 'EquityQueries';
  calculateVestedOptions: VestedOptionsCalculation;
  getAllGrants: AllGrants;
  getRsuGrant: RsuGrant;
  getShareGrant: EquityShareGrant;
  getStockOptionGrant: StockOptionGrant;
  validateEmploymentEndDate: AllGrantsValidation;
};

export type EquityQueriesCalculateVestedOptionsArgs = {
  payload: VestedOptionsCalculationInput;
};

export type EquityQueriesGetAllGrantsArgs = {
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  customer: Scalars['UUID']['input'];
};

export type EquityQueriesGetRsuGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityQueriesGetShareGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityQueriesGetStockOptionGrantArgs = {
  uuid: Scalars['UUID']['input'];
};

export type EquityQueriesValidateEmploymentEndDateArgs = {
  affiliation: Scalars['UUID']['input'];
  customer: Scalars['UUID']['input'];
  eventDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Equity series associated with a captable */
export type EquitySeries = {
  __typename?: 'EquitySeries';
  cap_table: Scalars['ID']['output'];
  conversion_ratio?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['CalendarDate']['output']>;
  equity_type_issued?: Maybe<EquityType>;
  issue_price?: Maybe<CurrencyModel>;
  liquidation_pref_multiple?: Maybe<Scalars['Float']['output']>;
  liquidation_pref_order?: Maybe<Scalars['Float']['output']>;
  participation_type?: Maybe<ParticipationType>;
  series_name?: Maybe<Scalars['String']['output']>;
  shares_authorized: Scalars['Float']['output'];
  uuid: Scalars['ID']['output'];
};

export type EquitySeriesInput = {
  conversion_ratio?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['CalendarDate']['input']>;
  equity_type_issued?: InputMaybe<EquityType>;
  issue_price: CurrencyModelInput;
  liquidation_pref_multiple?: InputMaybe<Scalars['Float']['input']>;
  liquidation_pref_order?: InputMaybe<Scalars['Float']['input']>;
  participation_type?: InputMaybe<ParticipationType>;
  series_name?: InputMaybe<Scalars['String']['input']>;
  shares_authorized: Scalars['Int']['input'];
};

export type EquityShareGrant = {
  __typename?: 'EquityShareGrant';
  affiliation: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Scalars['UUID']['output'];
  exerciseDetail?: Maybe<ShareGrantExerciseDetail>;
  grantName: Scalars['String']['output'];
  grantType: EquityShareGrantType;
  issueOrPurchaseDate: Scalars['Date']['output'];
  issueOrPurchasePrice: EquityCurrency;
  migrationDefaultDateOfIssue: Scalars['Boolean']['output'];
  quantity: Scalars['Int']['output'];
  saleEvents: Array<SaleEvent>;
  source: ShareGrantSource;
  summary: EquityShareGrantSummary;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  verified: Scalars['Boolean']['output'];
  vestingEndDate?: Maybe<Scalars['Date']['output']>;
  vestingEvents?: Maybe<Array<VestingScheduleEvent>>;
  vestingScheduleTemplate?: Maybe<VestingScheduleTemplate>;
  vestingStartDate?: Maybe<Scalars['Date']['output']>;
};

export type EquityShareGrantInput = {
  /** The UUID of the affiliation to which the grant is associated */
  affiliation: Scalars['UUID']['input'];
  /** The UUID of the customer owning the grant */
  customer: Scalars['UUID']['input'];
  /** The name of the grant */
  grantName: Scalars['String']['input'];
  /** The name of the grant */
  grantType: EquityShareGrantType;
  /** The date when the share grant was issued if the grant is a share grant otherwise it's the date when the share was purchased from the secondary market */
  issueOrPurchaseDate: Scalars['Date']['input'];
  /** The price of the issued share grant if the grant is a share grant otherwise it's the price of the purchased share from the secondary market */
  issueOrPurchasePrice: CurrencyInput;
  /** The quantity issued as part of the grant */
  quantity: Scalars['Int']['input'];
  /** If this is a share grant or a share purchased from the secondary market */
  source: ShareGrantSource;
  /** The vesting schedule containing a list with each vesting event. */
  vestingSchedule?: InputMaybe<Array<VestingScheduleEventInput>>;
  /** The vesting scheme based on which a grant is vesting.  */
  vestingScheduleTemplate?: InputMaybe<VestingScheduleTemplateInput>;
  /** The date the grant starts vesting from. */
  vestingStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type EquityShareGrantSummary = {
  __typename?: 'EquityShareGrantSummary';
  /** Number of shares that are vested (if applicable) and not sold */
  ownedQuantity: Scalars['Int']['output'];
  /** Number of shares sold */
  soldQuantity: Scalars['Int']['output'];
  /** Number of shares vested regardless of whether they are sold or not. Equals to the quantity if there is no vesting schedule. */
  totalVestedQuantity: Scalars['Int']['output'];
};

export enum EquityShareGrantType {
  Common = 'COMMON',
  Preferred = 'PREFERRED',
  Unknown = 'UNKNOWN',
}

export enum EquityType {
  Common = 'Common',
  Preferred = 'Preferred',
  Unknown = 'Unknown',
}

/** Equity Verification model */
export type EquityVerification = {
  __typename?: 'EquityVerification';
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: Scalars['ID']['output'];
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type EquityVerificationFile = {
  __typename?: 'EquityVerificationFile';
  file_name: Scalars['String']['output'];
  file_type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EquityVerificationInput = {
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<FormModelStatus>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

/** Type for the Excercise on Exit Scenario */
export type ExcerciseOnExitScenario = {
  __typename?: 'ExcerciseOnExitScenario';
  amt_summary_at_exercise?: Maybe<AmtSummaryAtExcercise>;
  base_tax_details?: Maybe<TaxDetails>;
  borrower_returns?: Maybe<Scalars['Float']['output']>;
  exercise_tax_breakdown?: Maybe<TaxBreakdown>;
  exercise_tax_details?: Maybe<TaxDetails>;
  exit_equity_value?: Maybe<Scalars['Float']['output']>;
  exit_share_price?: Maybe<Scalars['Float']['output']>;
  exit_tax_breakdown?: Maybe<TaxBreakdown>;
  exit_tax_details?: Maybe<TaxDetails>;
  four09a_equity_value?: Maybe<Scalars['Float']['output']>;
  investor_returns?: Maybe<Scalars['Float']['output']>;
  selling_equity_value?: Maybe<Scalars['Float']['output']>;
  selling_price?: Maybe<Scalars['Float']['output']>;
  target_date?: Maybe<Scalars['CalendarDate']['output']>;
  total_exercise_cost?: Maybe<Scalars['Float']['output']>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
  total_tax_breakdown?: Maybe<TaxBreakdown>;
  total_tax_owed_on_exercise?: Maybe<Scalars['Float']['output']>;
  total_tax_owed_on_exit?: Maybe<Scalars['Float']['output']>;
};

export type ExercisableQuantity = {
  __typename?: 'ExercisableQuantity';
  optionType: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ExerciseDetailParentStockOptionGrant = {
  __typename?: 'ExerciseDetailParentStockOptionGrant';
  grantType: StockOptionGrantType;
  uuid: Scalars['UUID']['output'];
};

export type ExerciseEvent = {
  __typename?: 'ExerciseEvent';
  createdAt: Scalars['DateTime']['output'];
  /** Date of the exercise event */
  eventDate: Scalars['Date']['output'];
  /** Fair market value at the event date */
  fmv: EquityCurrency;
  migrationDefaultEventDate: Scalars['Boolean']['output'];
  /** Number of options exercised */
  quantity: Scalars['Int']['output'];
  saleEvents: Array<SaleEvent>;
  summary: ExerciseEventSummary;
  /** The UUID identifying the stock option grant */
  uuid: Scalars['ID']['output'];
};

export type ExerciseEventInput = {
  /** Date of the exercise event */
  eventDate: Scalars['Date']['input'];
  /** Fair market value at the event date */
  fmv: CurrencyInput;
  grantUuid: Scalars['UUID']['input'];
  /** Number of options exercised */
  quantity: Scalars['Int']['input'];
};

export type ExerciseEventSummary = {
  __typename?: 'ExerciseEventSummary';
  ownedQuantity: Scalars['Int']['output'];
  soldQuantity: Scalars['Int']['output'];
};

/** Model for building charts comparing exit prices for a company */
export type ExitComparison = {
  __typename?: 'ExitComparison';
  exit_scenarios: Array<ExitScenario>;
};

export type ExitProjection = {
  __typename?: 'ExitProjection';
  common_share_value?: Maybe<CurrencyModel>;
  date?: Maybe<Scalars['CalendarDate']['output']>;
  settlement_date?: Maybe<Scalars['CalendarDate']['output']>;
  valuation?: Maybe<CurrencyModel>;
};

export type ExitProjectionInput = {
  common_share_value?: InputMaybe<CurrencyModelInput>;
  date?: InputMaybe<Scalars['CalendarDate']['input']>;
  settlement_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  valuation?: InputMaybe<CurrencyModelInput>;
};

/** Relevant monetary information given a company exit price */
export type ExitScenario = {
  __typename?: 'ExitScenario';
  cashless_borrower_returns: Scalars['Float']['output'];
  difference_nominal: Scalars['Float']['output'];
  difference_percentage: Scalars['Float']['output'];
  secfi_scenario_borrower_returns: Scalars['Float']['output'];
  share_price: Scalars['Float']['output'];
  share_price_multiplier: Scalars['Float']['output'];
  valuation?: Maybe<Scalars['Float']['output']>;
};

/** Exit scenario details based on exit valuation or share price */
export type ExitScenarioDetail = {
  __typename?: 'ExitScenarioDetail';
  common_share_price: Scalars['Float']['output'];
  date_of_latest_equity_round?: Maybe<Scalars['CalendarDate']['output']>;
  exit_valuation: Scalars['Float']['output'];
  investors_cut: Scalars['Float']['output'];
  liquidation_preference_loss?: Maybe<Scalars['Float']['output']>;
  liquidation_preferences_applied: Scalars['Boolean']['output'];
  name_of_latest_equity_round?: Maybe<Scalars['String']['output']>;
  total_amount_invested?: Maybe<Scalars['Float']['output']>;
  total_common_shares: Scalars['Float']['output'];
  total_shares: Scalars['Float']['output'];
  total_value_of_common_shares: Scalars['Float']['output'];
};

/** response with exit scenario details based on exit valuations or share prices */
export type ExitScenarioDetails = {
  __typename?: 'ExitScenarioDetails';
  company_uuid: Scalars['ID']['output'];
  exit_scenario_details: Array<ExitScenarioDetail>;
  exit_valuations?: Maybe<Array<Scalars['Float']['output']>>;
  share_prices?: Maybe<Array<Scalars['Float']['output']>>;
};

export enum ExternalSource {
  Carta = 'carta',
}

export type Feedback = {
  __typename?: 'Feedback';
  created_at?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FeedbackInput = {
  created_at?: InputMaybe<Scalars['String']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Generic File type. */
export type File = {
  __typename?: 'File';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum FilingType {
  HeadOfHousehold = 'head_of_household',
  MarriedJoint = 'married_joint',
  MarriedSeparate = 'married_separate',
  Single = 'single',
}

export enum FinancialIndustryRegulatoryAuthorityRia {
  Finra = 'FINRA',
  RegisteredRep = 'REGISTERED_REP',
  Ria = 'RIA',
}

/** Computed state of an onboarding category */
export type FinancingCategoryStatus = {
  __typename?: 'FinancingCategoryStatus';
  /** Category name */
  category: FinancingFormCategory;
  /** Flag denoting if there is feedback for this category */
  has_feedback: Scalars['Boolean']['output'];
  /** Computed status of category in question */
  status: FormModelStatus;
};

export enum FinancingFormCategory {
  Contract = 'contract',
  CustomerDueDiligence = 'customer_due_diligence',
  DealClosing = 'deal_closing',
}

export type FinancingFormModel = {
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object?: Maybe<FinancingObject>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

/** State of a financing form */
export type FinancingFormStatus = {
  __typename?: 'FinancingFormStatus';
  /** Category this form is part of */
  category?: Maybe<FinancingFormCategory>;
  /** List of contract variants available for this form */
  contract_variants?: Maybe<Array<ContractVariant>>;
  /** Given feedback */
  feedback?: Maybe<Array<Feedback>>;
  /** Group for which the form belongs */
  form_grouping?: Maybe<DataCaptureFormGroups>;
  /** Name of the model */
  name: Scalars['String']['output'];
  /** The state the form is in */
  status: FormModelStatus;
  /** Uuid of the object in question. It can be null if the object is not created */
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type FinancingObject = {
  __typename?: 'FinancingObject';
  advisor?: Maybe<Advisor>;
  affinity_id?: Maybe<Scalars['String']['output']>;
  asana_task_url?: Maybe<Scalars['String']['output']>;
  date_proposal_accepted?: Maybe<Scalars['String']['output']>;
  document_storage_url?: Maybe<Scalars['String']['output']>;
  intake_call_invitee_id?: Maybe<Scalars['String']['output']>;
  intake_call_timestamp?: Maybe<Scalars['String']['output']>;
  intake_call_uuid?: Maybe<Scalars['ID']['output']>;
  is_intake_call_cancelled?: Maybe<Scalars['Boolean']['output']>;
  is_legacy_onboarding?: Maybe<Scalars['Boolean']['output']>;
  is_proposal_call_cancelled?: Maybe<Scalars['Boolean']['output']>;
  last_seen?: Maybe<Scalars['String']['output']>;
  loi?: Maybe<FinancingObjectLoi>;
  notes?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  possible_status_transitions: Array<FinancingStatusPair>;
  products_interested?: Maybe<Array<ProductsOffered>>;
  proposal_call_invitee_id?: Maybe<Scalars['String']['output']>;
  proposal_call_timestamp?: Maybe<Scalars['String']['output']>;
  proposal_call_uuid?: Maybe<Scalars['ID']['output']>;
  proposal_request: ProposalRequest;
  selected_proposal?: Maybe<Proposal>;
  signed_nda?: Maybe<Scalars['String']['output']>;
  status: FinancingStatus;
  step_detail_reason?: Maybe<LostWhyReason>;
  step_reason?: Maybe<StepReason>;
  step_reason_group?: Maybe<StepReasonGroup>;
  substatus: FinancingSubstatus;
  updated_at?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type FinancingObjectFilterInput = {
  advisor?: InputMaybe<Scalars['ID']['input']>;
  date_proposal_accepted?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  proposal_request?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  selected_proposal?: InputMaybe<Scalars['ID']['input']>;
  signed_nda?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

/** Mutable part of a proposal request */
export type FinancingObjectLoi = {
  __typename?: 'FinancingObjectLoi';
  docusign_envelope_id?: Maybe<Scalars['ID']['output']>;
  secfi_signed?: Maybe<Scalars['Boolean']['output']>;
  user_signed?: Maybe<Scalars['Boolean']['output']>;
};

export type FinancingObjectUpdateInput = {
  advisor?: InputMaybe<Scalars['ID']['input']>;
  document_storage_url?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  products_interested?: InputMaybe<Array<InputMaybe<ProductsOffered>>>;
  status?: InputMaybe<FinancingStatus>;
  step_detail_reason?: InputMaybe<Scalars['String']['input']>;
  step_reason?: InputMaybe<Scalars['String']['input']>;
  substatus?: InputMaybe<FinancingSubstatus>;
  trigger_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FinancingOptionGrant = {
  __typename?: 'FinancingOptionGrant';
  equity_verification?: Maybe<Scalars['UUID']['output']>;
  exercisable_quantity?: Maybe<Scalars['Int']['output']>;
  exercise_cost?: Maybe<Currency>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<EquityVerificationFile>;
  financing_object?: Maybe<Scalars['UUID']['output']>;
  grant_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  option_type?: Maybe<OptionGrantType>;
  owner?: Maybe<Scalars['UUID']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  selected_quantity?: Maybe<Scalars['Int']['output']>;
  strike_price?: Maybe<Currency>;
  user_modified_data?: Maybe<Scalars['Boolean']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  verification_status?: Maybe<VerificationStatus>;
};

export type FinancingShareGrant = {
  __typename?: 'FinancingShareGrant';
  date_of_acquisition?: Maybe<Scalars['String']['output']>;
  equity_verification?: Maybe<Scalars['UUID']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<EquityVerificationFile>;
  financing_object?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['UUID']['output']>;
  selected_quantity?: Maybe<Scalars['Int']['output']>;
  share_type?: Maybe<ShareGrantType>;
  share_value_at_acquisition?: Maybe<Currency>;
  shares_purchased?: Maybe<Scalars['Int']['output']>;
  user_modified_data?: Maybe<Scalars['Boolean']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  verification_status?: Maybe<VerificationStatus>;
  vested_quantity?: Maybe<Scalars['Int']['output']>;
};

export enum FinancingStatus {
  Closed = 'closed',
  Contract = 'contract',
  CustomerDueDiligence = 'customer_due_diligence',
  DealClosing = 'deal_closing',
  Intake = 'intake',
  Proposal = 'proposal',
  Request = 'request',
}

/** Count of records present on the platform for a given status */
export type FinancingStatusCount = {
  __typename?: 'FinancingStatusCount';
  count?: Maybe<Scalars['Int']['output']>;
  status: FinancingStatus;
  substatus: FinancingSubstatus;
};

/** Financing status pair (named) */
export type FinancingStatusPair = {
  __typename?: 'FinancingStatusPair';
  status: FinancingStatus;
  substatus: FinancingSubstatus;
};

export type FinancingStatusSubstatuses = {
  __typename?: 'FinancingStatusSubstatuses';
  status: FinancingStatus;
  substatuses: Array<FinancingSubstatus>;
};

export enum FinancingSubstatus {
  AwaitingClientFundsRelease = 'awaiting_client_funds_release',
  AwaitingClientSignature = 'awaiting_client_signature',
  AwaitingContractGeneration = 'awaiting_contract_generation',
  AwaitingInvestorSignature = 'awaiting_investor_signature',
  AwaitingIssuerFundsRelease = 'awaiting_issuer_funds_release',
  AwaitingProofOfShares = 'awaiting_proof_of_shares',
  AwaitingSettlement = 'awaiting_settlement',
  AwaitingShareTransfer = 'awaiting_share_transfer',
  AwaitingSignatureRelease = 'awaiting_signature_release',
  ClientReviewingProposal = 'client_reviewing_proposal',
  CompanyInTriage = 'company_in_triage',
  Declined = 'declined',
  FundingPending = 'funding_pending',
  GeneratingContracts = 'generating_contracts',
  GeneratingProposal = 'generating_proposal',
  IntakeCallPending = 'intake_call_pending',
  Lost = 'lost',
  NdaPending = 'nda_pending',
  NewRequest = 'new_request',
  OnHold = 'on_hold',
  OnboardingInProcess = 'onboarding_in_process',
  OnboardingPending = 'onboarding_pending',
  OnboardingReviewing = 'onboarding_reviewing',
  ProposalCallScheduled = 'proposal_call_scheduled',
  ScheduleIntakeCall = 'schedule_intake_call',
  ScheduleProposalCall = 'schedule_proposal_call',
}

export type FixedTermScenario = {
  __typename?: 'FixedTermScenario';
  advance_rate: Scalars['Float']['output'];
  equity_rate: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type FixedTermScenarioInput = {
  advance_rate: Scalars['Float']['input'];
  equity_rate: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export enum FormModelStatus {
  Approved = 'approved',
  Available = 'available',
  Locked = 'locked',
  NotApplicable = 'not_applicable',
  Submitted = 'submitted',
}

export enum FormModelStatusGql {
  Approved = 'approved',
  Available = 'available',
  Locked = 'locked',
  NotApplicable = 'not_applicable',
  Submitted = 'submitted',
}

/** All dropdown options (countries, subdivisions, filing types...). */
export type FormOptions = {
  __typename?: 'FormOptions';
  countries?: Maybe<Array<Maybe<BasicFormOption>>>;
  filing_types?: Maybe<Array<Maybe<BasicFormOption>>>;
  subdivisions?: Maybe<Array<Maybe<Subdivision>>>;
};

export type Fund = {
  __typename?: 'Fund';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type FundInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type GenericErrorDetails = {
  __typename?: 'GenericErrorDetails';
  message: Scalars['String']['output'];
};

export type GenericOrderInput = {
  properties?: InputMaybe<Array<InputMaybe<IOrderInput>>>;
};

export enum GrantType {
  Iso = 'ISO',
  Nso = 'NSO',
  Rsu = 'RSU',
  Share = 'SHARE',
}

export type GraphMarker =
  | GraphMarkerCliff
  | GraphMarkerEndOfEmployment
  | GraphMarkerEndOfVesting
  | GraphMarkerFirstHolding
  | GraphMarkerFundingRound
  | GraphMarkerIpo
  | GraphMarkerStockSplit;

export type GraphMarkerCliff = {
  __typename?: 'GraphMarkerCliff';
  company_name: Scalars['String']['output'];
  grant_type: Scalars['String']['output'];
  percentage_vested: Scalars['Float']['output'];
  timestamp: Scalars['Timestamp']['output'];
  vested: Scalars['Int']['output'];
};

export type GraphMarkerEndOfEmployment = {
  __typename?: 'GraphMarkerEndOfEmployment';
  company_name: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type GraphMarkerEndOfVesting = {
  __typename?: 'GraphMarkerEndOfVesting';
  company_name: Scalars['String']['output'];
  grant_type: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
  vested: Scalars['Int']['output'];
};

export type GraphMarkerFirstHolding = {
  __typename?: 'GraphMarkerFirstHolding';
  institution_name: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type GraphMarkerFundingRound = {
  __typename?: 'GraphMarkerFundingRound';
  company_name: Scalars['String']['output'];
  round_name: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type GraphMarkerIpo = {
  __typename?: 'GraphMarkerIPO';
  company_name: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type GraphMarkerStockSplit = {
  __typename?: 'GraphMarkerStockSplit';
  company_name: Scalars['String']['output'];
  split_ratio: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type GrossAtvTermsInput = {
  atv: Scalars['Float']['input'];
  company: Scalars['ID']['input'];
  fund: Scalars['ID']['input'];
};

/** Financing form to capture bank information from the user */
export type IBankAccountInformation = {
  account_number?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  credit_to_account?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object?: Maybe<FinancingObject>;
  reference?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  swift_code?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type IOrderInput = {
  direction?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<Scalars['String']['input']>>;
  property?: InputMaybe<Scalars['String']['input']>;
};

/** Files to legally identify customers, normally passports or national ids */
export type IdentificationFile = {
  __typename?: 'IdentificationFile';
  file_name: Scalars['String']['output'];
  file_type: Scalars['String']['output'];
  identification_type?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/** Financing form to capture files that let us legally identify the customer */
export type IdentificationInformation = {
  __typename?: 'IdentificationInformation';
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  files?: Maybe<Array<Maybe<IdentificationFile>>>;
  financing_object: Scalars['ID']['output'];
  inquiry?: Maybe<KycInquiry>;
  status: Scalars['String']['output'];
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type IdentificationInformationInput = {
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type IdentificationInformationRia = {
  __typename?: 'IdentificationInformationRIA';
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  ria_deal: Scalars['UUID']['output'];
  status?: Maybe<FormModelStatusGql>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ImportCompanyInput = {
  importKey: Scalars['String']['input'];
};

/** Pitchbook industry reference */
export type Industry = {
  __typename?: 'Industry';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

/** Pitchbook full industry tree */
export type IndustryTree = {
  __typename?: 'IndustryTree';
  industryCode?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  industrySector?: Maybe<Industry>;
  primary?: Maybe<Scalars['Boolean']['output']>;
};

export enum InquiryStatus {
  Approved = 'approved',
  Completed = 'completed',
  Created = 'created',
  Declined = 'declined',
  Expired = 'expired',
  Failed = 'failed',
  NeedsReview = 'needs_review',
  Pending = 'pending',
}

export type Institution = {
  __typename?: 'Institution';
  institution_id: Scalars['String']['output'];
  /** The "market" date holdings were last fetched. */
  last_snapshot_date?: Maybe<Scalars['CalendarDate']['output']>;
  /**
   * The time the institution was last updated
   * e.g. the link status changed or holdings were
   * last fetched.
   */
  last_updated?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Status;
  website?: Maybe<Scalars['String']['output']>;
};

/** Financial file the customer must sign to acknowledge the costs for having a trust */
export type IntermediaryServicesAgreement = {
  __typename?: 'IntermediaryServicesAgreement';
  docusign_id?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: FinancingObject;
  status: Scalars['String']['output'];
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type IntermediaryServicesAgreementInput = {
  docusign_id?: InputMaybe<Scalars['ID']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type Investor = {
  __typename?: 'Investor';
  name: Scalars['String']['output'];
  pitchbook_id?: Maybe<Scalars['String']['output']>;
  preferred: Scalars['Boolean']['output'];
  uuid: Scalars['ID']['output'];
};

export type InvestorInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  pitchbook_id?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum IssuerAllowsSale {
  IDoNotKnow = 'I_DO_NOT_KNOW',
  No = 'NO',
  Yes = 'YES',
}

export type JobPosition = {
  __typename?: 'JobPosition';
  createdAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type KycInquiry = {
  __typename?: 'KYCInquiry';
  inquiry_id?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InquiryStatus>;
};

export type KycInquiryCreationType = {
  __typename?: 'KYCInquiryCreationType';
  inquiry_id?: Maybe<Scalars['String']['output']>;
  session_token?: Maybe<Scalars['String']['output']>;
};

export type KycInquiryEndType = {
  __typename?: 'KYCInquiryEndType';
  financing_object?: Maybe<Scalars['UUID']['output']>;
  identification_form_verification_status: VerificationStatus;
  inquiry_id?: Maybe<Scalars['UUID']['output']>;
  ria_deal?: Maybe<Scalars['UUID']['output']>;
};

export enum KycStatus {
  Approved = 'approved',
  Completed = 'completed',
  Created = 'created',
  Declined = 'declined',
  Expired = 'expired',
  Failed = 'failed',
  NeedsReview = 'needs_review',
  Pending = 'pending',
}

export enum LiquidNetWorthRia {
  AboveK500001 = 'ABOVE_K500001',
  BelowK100000 = 'BELOW_K100000',
  K100001K250000 = 'K100001_K250000',
  K250001K500000 = 'K250001_K500000',
}

export type ListViewTotalCounts = {
  __typename?: 'ListViewTotalCounts';
  byStatus?: Maybe<Array<Maybe<FinancingStatusCount>>>;
  current?: Maybe<Scalars['Float']['output']>;
};

export enum LostWhyReason {
  ClientGhostedUs = 'client_ghosted_us',
  ClientHighAppetiteRisk = 'client_high_appetite_risk',
  NotAbleToServiceTheAmountLoans = 'not_able_to_service_the_amount_loans',
  RatesNotCompetitive = 'rates_not_competitive',
  SecfiTookTooLong = 'secfi_took_too_long',
}

export enum MaritalStatus {
  Divorced = 'divorced',
  Married = 'married',
  Separated = 'separated',
  Single = 'single',
  Widowed = 'widowed',
}

export enum MaritalStatusGql {
  Divorced = 'divorced',
  Married = 'married',
  Separated = 'separated',
  Single = 'single',
  Widowed = 'widowed',
}

export type MockExitScenario = {
  __typename?: 'MockExitScenario';
  exit_multiplier?: Maybe<Scalars['Float']['output']>;
  net_gain_exercise_now?: Maybe<CurrencyModel>;
  net_gain_wait_until_exit?: Maybe<CurrencyModel>;
};

export type MockProposal = {
  __typename?: 'MockProposal';
  collateral_shares?: Maybe<Scalars['Int']['output']>;
  exercise_cost?: Maybe<CurrencyModel>;
  exit_scenarios?: Maybe<MockExitScenario>;
  potential_liquidity?: Maybe<CurrencyModel>;
  total_advance?: Maybe<CurrencyModel>;
  total_fees?: Maybe<CurrencyModel>;
  total_selected_options?: Maybe<Scalars['Int']['output']>;
  total_selected_shares?: Maybe<Scalars['Int']['output']>;
  total_taxes?: Maybe<CurrencyModel>;
};

export type Mutation = {
  __typename?: 'Mutation';
  EP_moveToPlanPhase: EpTimelineWrapper;
  EP_selectOptionGrants: EpTimelineWrapper;
  EP_selectShareGrants: EpTimelineWrapper;
  EP_setCompanyAndTaxDetails: EpTimelineWrapper;
  EP_setExerciseLaterProjectionData: EpTimelineWrapper;
  EP_setExerciseNowEarlyData: EpTimelineWrapper;
  EP_setExitProjection: EpTimelineWrapper;
  EP_setSecondaryMarketData: EpTimelineWrapper;
  EP_setSelectedPlans: EpTimelineWrapper;
  EP_setTenderOfferData: EpTimelineWrapper;
  EP_updateSelectedPlanDates: EpTimelineWrapper;
  acceptProposal?: Maybe<FinancingObject>;
  addCustomerToWaitlist?: Maybe<CustomerWaitlist>;
  addSecondariesRequest?: Maybe<SecondariesRequest>;
  addUserToGroup?: Maybe<Scalars['Void']['output']>;
  bulkUpdateProposalRequest?: Maybe<Array<Maybe<ProposalRequest>>>;
  createAccountPasswordless?: Maybe<SignUpResponse>;
  createAffiliation?: Maybe<Affiliation>;
  createAsanaTask: AsanaTask;
  createCapTable?: Maybe<CapTable>;
  createCompany?: Maybe<Company>;
  createCompanyAssessment?: Maybe<CompanyAssessment>;
  createCompanyBankAccountInformation?: Maybe<CompanyBankAccountInformation>;
  createCompanyForecast?: Maybe<CompanyForecast>;
  createFund?: Maybe<Fund>;
  createOrUpdateBankAccountInformation?: Maybe<BankAccountInformation>;
  createOrUpdateComplianceInformation?: Maybe<ComplianceInformationFinancing>;
  createOrUpdateCustomerBankAccountInformation?: Maybe<CustomerBankAccountInformation>;
  createOrUpdateEquityVerification?: Maybe<EquityVerification>;
  createOrUpdateIdentificationInformation?: Maybe<IdentificationInformation>;
  createOrUpdateIntermediaryServicesAgreement?: Maybe<IntermediaryServicesAgreement>;
  createOrUpdateInvestmentDirectionLetter?: Maybe<Contract>;
  createOrUpdatePersonalInformation?: Maybe<PersonalInformationFinancing>;
  createOrUpdatePrivateSecuritiesContract?: Maybe<Contract>;
  createOrUpdateSeriesContract?: Maybe<Contract>;
  createOrUpdateSpringingLienPSC?: Maybe<Contract>;
  createOrUpdateSpringingLienStockPledge?: Maybe<Contract>;
  createOrUpdateUsTaxInformation?: Maybe<UsTaxInformation>;
  createOrUpdateW9Form?: Maybe<W9Form>;
  createProposal?: Maybe<Proposal>;
  createProposalRequest?: Maybe<ProposalRequest>;
  createScheduledAppointment: ScheduledAppointment;
  createScheduledAppointmentAndSignUp: ScheduleAndSignUpResponse;
  createScheduledAppointmentPublic: Scalars['Boolean']['output'];
  createStockSplit?: Maybe<StockSplit>;
  createTerms?: Maybe<Terms>;
  deleteAffiliation?: Maybe<Scalars['Float']['output']>;
  deleteProposal?: Maybe<Scalars['Float']['output']>;
  deleteStockSplit?: Maybe<Scalars['Float']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  disconnectCartaAccount: CartaAccountDisconnectResult;
  endKYCFlowFinancing?: Maybe<KycInquiryEndType>;
  equity: EquityMutations;
  exchangePublicToken: Scalars['Boolean']['output'];
  generateInvestmentDirectionLetter?: Maybe<Array<Maybe<File>>>;
  generatePrivateSecuritiesContract?: Maybe<Array<Maybe<File>>>;
  generateSeriesContract?: Maybe<Array<Maybe<File>>>;
  generateSpringingLienAssigmentAgreement?: Maybe<Array<Maybe<File>>>;
  generateSpringingLienPSC?: Maybe<Array<Maybe<File>>>;
  generateSpringingLienStockPledge?: Maybe<Array<Maybe<File>>>;
  getLinkToken: Scalars['String']['output'];
  getLinkTokenForUpdate: Scalars['String']['output'];
  importCartaData: CartaDataImportResult;
  initContractSigning?: Maybe<SignContract>;
  initKYCFlowFinancing?: Maybe<KycInquiryCreationType>;
  mergeCompanies?: Maybe<Scalars['Boolean']['output']>;
  mutationsRIA: MutationsRia;
  processScheduledCall?: Maybe<FinancingObject>;
  processSignUpWithCartaCallback: CartaPasswordlessSignupResponse;
  publishAndSelectProposal?: Maybe<Proposal>;
  publishProposal?: Maybe<Proposal>;
  relinkInstitution: Scalars['Boolean']['output'];
  removeUserFromGroup?: Maybe<Scalars['Void']['output']>;
  requestDeletion?: Maybe<Scalars['Boolean']['output']>;
  requestProposalRegeneration?: Maybe<ProposalRegenerationResponse>;
  riaDealMutations: RiaDealMutations;
  selectNewCompany?: Maybe<CompanyShortInfo>;
  setCustomerFlag: CustomerFlag;
  setDocumentStorageUrl?: Maybe<FinancingObject>;
  signInWithSignInToken?: Maybe<SignInWithSignInTokenResponse>;
  submitDealCalculations?: Maybe<DealCalculations>;
  submitForm?: Maybe<Scalars['Boolean']['output']>;
  toggleACHForms?: Maybe<Scalars['Boolean']['output']>;
  unlinkInstitution: Scalars['Boolean']['output'];
  updateAffiliation?: Maybe<Affiliation>;
  updateCompany?: Maybe<Company>;
  updateCompanyForecast?: Maybe<CompanyForecast>;
  updateCompanyFromPitchbook?: Maybe<Company>;
  updateCompanySubstatus?: Maybe<CompanySubstatusGql>;
  updateCustomer?: Maybe<Customer>;
  updateDealCalculations?: Maybe<DealCalculations>;
  updateFinancingObject?: Maybe<FinancingObject>;
  updateFinancingOptionGrant?: Maybe<FinancingOptionGrant>;
  updateFinancingShareGrant?: Maybe<FinancingShareGrant>;
  updateNotes?: Maybe<Terms>;
  updateProposal?: Maybe<Proposal>;
  updateProposalRequest?: Maybe<ProposalRequest>;
  updateProposalRequestPersonalSnapshot?: Maybe<ProposalRequestPersonalSnapshot>;
  updateScheduledAppointment: ScheduledAppointment;
  updateSecondariesRequest?: Maybe<SecondariesRequest>;
  updateStockSplit?: Maybe<StockSplit>;
  updateTaxWithholdings?: Maybe<TaxWithholdings>;
  updateTaxWithholdingsOptionRelation?: Maybe<TaxWithholdingsOptionRelation>;
};

export type MutationEp_MoveToPlanPhaseArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type MutationEp_SelectOptionGrantsArgs = {
  affiliation: Scalars['UUID']['input'];
  grantUuids: Array<Scalars['UUID']['input']>;
};

export type MutationEp_SelectShareGrantsArgs = {
  affiliation: Scalars['UUID']['input'];
  grantUuids: Array<Scalars['UUID']['input']>;
};

export type MutationEp_SetCompanyAndTaxDetailsArgs = {
  affiliation: Scalars['UUID']['input'];
  companyAndTaxDetails: EpCompanyAndTaxDetails;
};

export type MutationEp_SetExerciseLaterProjectionDataArgs = {
  affiliation: Scalars['UUID']['input'];
  data: EpExerciseLaterProjectionDataInput;
};

export type MutationEp_SetExerciseNowEarlyDataArgs = {
  affiliation: Scalars['UUID']['input'];
  data: EpExerciseNowEarlyDataInput;
};

export type MutationEp_SetExitProjectionArgs = {
  affiliation: Scalars['UUID']['input'];
  exitProjection: EpExitProjection;
};

export type MutationEp_SetSecondaryMarketDataArgs = {
  affiliation: Scalars['UUID']['input'];
  data: EpSecondaryMarketDataInput;
};

export type MutationEp_SetSelectedPlansArgs = {
  affiliation: Scalars['UUID']['input'];
  planTypes: Array<EpPlanType>;
};

export type MutationEp_SetTenderOfferDataArgs = {
  affiliation: Scalars['UUID']['input'];
  data: EpTenderOfferDataInput;
};

export type MutationEp_UpdateSelectedPlanDatesArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type MutationAcceptProposalArgs = {
  financingObjectUuid: Scalars['ID']['input'];
  proposalUuid: Scalars['ID']['input'];
};

export type MutationAddCustomerToWaitlistArgs = {
  questions?: InputMaybe<Array<InputMaybe<CustomerWaitlistQuestionInput>>>;
  source?: InputMaybe<Scalars['String']['input']>;
  waitlistCategory: Scalars['String']['input'];
  waitlistName: Scalars['String']['input'];
  waitlistPrice: Scalars['Float']['input'];
};

export type MutationAddSecondariesRequestArgs = {
  input: SecondariesRequestInput;
};

export type MutationAddUserToGroupArgs = {
  group: SecfiGroup;
  username: Scalars['String']['input'];
};

export type MutationBulkUpdateProposalRequestArgs = {
  payload: BulkUpdateProposalRequestsInput;
};

export type MutationCreateAccountPasswordlessArgs = {
  payload?: InputMaybe<SignUpPasswordlessInput>;
};

export type MutationCreateAffiliationArgs = {
  payload?: InputMaybe<AffiliationCreateInput>;
};

export type MutationCreateAsanaTaskArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
};

export type MutationCreateCapTableArgs = {
  payload?: InputMaybe<CapTableInput>;
};

export type MutationCreateCompanyArgs = {
  payload?: InputMaybe<CompanyCreateInput>;
};

export type MutationCreateCompanyAssessmentArgs = {
  payload?: InputMaybe<CompanyAssessmentCreateInput>;
};

export type MutationCreateCompanyBankAccountInformationArgs = {
  payload?: InputMaybe<CompanyBankAccountInformationInput>;
};

export type MutationCreateCompanyForecastArgs = {
  payload?: InputMaybe<CompanyForecastCreateInput>;
};

export type MutationCreateFundArgs = {
  payload?: InputMaybe<FundInput>;
};

export type MutationCreateOrUpdateBankAccountInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<BankAccountInformationInput>;
};

export type MutationCreateOrUpdateComplianceInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<ComplianceInformationFinancingInput>;
};

export type MutationCreateOrUpdateCustomerBankAccountInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<CustomerBankAccountInformationInput>;
};

export type MutationCreateOrUpdateEquityVerificationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<EquityVerificationInput>;
};

export type MutationCreateOrUpdateIdentificationInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<IdentificationInformationInput>;
};

export type MutationCreateOrUpdateIntermediaryServicesAgreementArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<IntermediaryServicesAgreementInput>;
};

export type MutationCreateOrUpdateInvestmentDirectionLetterArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<ContractInput>;
};

export type MutationCreateOrUpdatePersonalInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<PersonalInformationFinancingInput>;
};

export type MutationCreateOrUpdatePrivateSecuritiesContractArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<PrivateSecuritiesContractInput>;
};

export type MutationCreateOrUpdateSeriesContractArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<ContractInput>;
};

export type MutationCreateOrUpdateSpringingLienPscArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<ContractInput>;
};

export type MutationCreateOrUpdateSpringingLienStockPledgeArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<ContractInput>;
};

export type MutationCreateOrUpdateUsTaxInformationArgs = {
  payload?: InputMaybe<UsTaxInformationBaseInput>;
};

export type MutationCreateOrUpdateW9FormArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<W9FormInput>;
};

export type MutationCreateProposalArgs = {
  payload?: InputMaybe<ProposalCreateInput>;
};

export type MutationCreateProposalRequestArgs = {
  payload?: InputMaybe<ProposalRequestCreateInput>;
};

export type MutationCreateScheduledAppointmentArgs = {
  affiliationUuid?: InputMaybe<Scalars['ID']['input']>;
  analytics: ScheduledAppointmentAnalyticsInput;
  appointmentType: AppointmentType;
  calendlyEventUri: Scalars['String']['input'];
  calendlyInviteeUri: Scalars['String']['input'];
  rescheduledAppointmentUuid?: InputMaybe<Scalars['ID']['input']>;
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateScheduledAppointmentAndSignUpArgs = {
  appointmentType: AppointmentType;
  calendlyEventUri: Scalars['String']['input'];
  calendlyInviteeUri: Scalars['String']['input'];
};

export type MutationCreateScheduledAppointmentPublicArgs = {
  analytics: ScheduledAppointmentAnalyticsInput;
  calendlyEventUri: Scalars['String']['input'];
  calendlyInviteeUri: Scalars['String']['input'];
};

export type MutationCreateStockSplitArgs = {
  payload?: InputMaybe<StockSplitInput>;
};

export type MutationCreateTermsArgs = {
  payload?: InputMaybe<TermsInput>;
};

export type MutationDeleteAffiliationArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteProposalArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteStockSplitArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteUserArgs = {
  deletion_token: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEndKycFlowFinancingArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
  inquiry_id: Scalars['UUID']['input'];
};

export type MutationExchangePublicTokenArgs = {
  customer: Scalars['UUID']['input'];
  publicToken: Scalars['String']['input'];
};

export type MutationGenerateInvestmentDirectionLetterArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGeneratePrivateSecuritiesContractArgs = {
  contract_variant?: InputMaybe<Scalars['String']['input']>;
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGenerateSeriesContractArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGenerateSpringingLienAssigmentAgreementArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGenerateSpringingLienPscArgs = {
  contract_variant?: InputMaybe<Scalars['String']['input']>;
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGenerateSpringingLienStockPledgeArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  is_final?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGetLinkTokenArgs = {
  customer: Scalars['UUID']['input'];
};

export type MutationGetLinkTokenForUpdateArgs = {
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
};

export type MutationInitContractSigningArgs = {
  payload: SignContractInputType;
  uuid: Scalars['UUID']['input'];
};

export type MutationInitKycFlowFinancingArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
};

export type MutationMergeCompaniesArgs = {
  mergeCompanyUuid: Scalars['UUID']['input'];
  primaryCompanyUuid: Scalars['UUID']['input'];
};

export type MutationProcessScheduledCallArgs = {
  calendlyEventUri: Scalars['String']['input'];
  callType: CallType;
  financingObjectUuid: Scalars['ID']['input'];
  inviteeUri: Scalars['String']['input'];
  scheduledAppointmentUuid: Scalars['ID']['input'];
};

export type MutationProcessSignUpWithCartaCallbackArgs = {
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type MutationPublishAndSelectProposalArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPublishProposalArgs = {
  payload?: InputMaybe<ProposalPublishInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRelinkInstitutionArgs = {
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
};

export type MutationRemoveUserFromGroupArgs = {
  group: SecfiGroup;
  username: Scalars['String']['input'];
};

export type MutationRequestDeletionArgs = {
  deletion_feedback?: InputMaybe<Scalars['String']['input']>;
  deletion_reason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRequestProposalRegenerationArgs = {
  uuid: Scalars['ID']['input'];
};

export type MutationSelectNewCompanyArgs = {
  importCompany?: InputMaybe<ImportCompanyInput>;
  newCompany?: InputMaybe<NewCompanyInput>;
};

export type MutationSetCustomerFlagArgs = {
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  customer: Scalars['UUID']['input'];
  flag: Scalars['String']['input'];
};

export type MutationSetDocumentStorageUrlArgs = {
  documentStorageUrl: Scalars['String']['input'];
  emailBody: Scalars['String']['input'];
  financingObjectUuid: Scalars['ID']['input'];
};

export type MutationSignInWithSignInTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationSubmitDealCalculationsArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitFormArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  form_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationToggleAchFormsArgs = {
  ach_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  financing_object?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUnlinkInstitutionArgs = {
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
};

export type MutationUpdateAffiliationArgs = {
  payload?: InputMaybe<AffiliationInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanyArgs = {
  payload?: InputMaybe<CompanyInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanyForecastArgs = {
  payload?: InputMaybe<CompanyForecastCreateInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanyFromPitchbookArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanySubstatusArgs = {
  company: Scalars['UUID']['input'];
  substatus: CompanySubstatusGql;
};

export type MutationUpdateCustomerArgs = {
  payload?: InputMaybe<CustomerInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDealCalculationsArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<DealCalculationsInput>;
};

export type MutationUpdateFinancingObjectArgs = {
  payload: FinancingObjectUpdateInput;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateFinancingOptionGrantArgs = {
  financing_option_grant_uuid: Scalars['UUID']['input'];
  payload: UpdateFinancingOptionGrantInputType;
};

export type MutationUpdateFinancingShareGrantArgs = {
  financing_share_grant_uuid: Scalars['UUID']['input'];
  payload: UpdateFinancingShareGrantInputType;
};

export type MutationUpdateNotesArgs = {
  payload?: InputMaybe<TermsNoteUpdateInput>;
};

export type MutationUpdateProposalArgs = {
  payload?: InputMaybe<ProposalInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateProposalRequestArgs = {
  payload?: InputMaybe<ProposalRequestInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateProposalRequestPersonalSnapshotArgs = {
  payload?: InputMaybe<ProposalRequestPersonalSnapshotInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateScheduledAppointmentArgs = {
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
  uuid: Scalars['ID']['input'];
};

export type MutationUpdateSecondariesRequestArgs = {
  payload: UpdateSecondariesRequestInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateStockSplitArgs = {
  payload?: InputMaybe<StockSplitUpdateInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTaxWithholdingsArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<TaxWithholdingsInput>;
};

export type MutationUpdateTaxWithholdingsOptionRelationArgs = {
  financing_option_grant_uuid: Scalars['UUID']['input'];
  payload: UpdateTaxWithholdingsOptionRelationInputType;
};

export type MutationsRia = {
  __typename?: 'MutationsRIA';
  endKYCFlow?: Maybe<KycInquiryEndType>;
  initKYCFlow?: Maybe<KycInquiryCreationType>;
  updateAdvisoryAgreement?: Maybe<Scalars['Boolean']['output']>;
  updateComplianceInformation?: Maybe<ComplianceInformationRia>;
  updatePayStubAccountStatement?: Maybe<PayStubAccountStatement>;
  updatePersonalInformation?: Maybe<PersonalInformationRia>;
  updateRiskAssessment?: Maybe<RiskAssessment>;
};

export type MutationsRiaEndKycFlowArgs = {
  inquiry_id: Scalars['UUID']['input'];
  ria_deal: Scalars['UUID']['input'];
};

export type MutationsRiaInitKycFlowArgs = {
  owner: Scalars['UUID']['input'];
  ria_deal: Scalars['UUID']['input'];
};

export type MutationsRiaUpdateAdvisoryAgreementArgs = {
  owner: Scalars['UUID']['input'];
  payload: AdvisoryAgreementInput;
};

export type MutationsRiaUpdateComplianceInformationArgs = {
  owner: Scalars['UUID']['input'];
  payload: ComplianceInformationRiaInput;
};

export type MutationsRiaUpdatePayStubAccountStatementArgs = {
  owner: Scalars['UUID']['input'];
  payload: PayStubAccountStatementInput;
};

export type MutationsRiaUpdatePersonalInformationArgs = {
  owner: Scalars['UUID']['input'];
  payload: PersonalInformationRiaInput;
};

export type MutationsRiaUpdateRiskAssessmentArgs = {
  owner: Scalars['UUID']['input'];
  payload: RiskAssessmentInput;
};

/** Tax bracket information for the Object taxable incomes. */
export type NetRateComponents = {
  __typename?: 'NetRateComponents';
  capital_gains_tax_rate?: Maybe<Scalars['Float']['output']>;
  net_investment_income_tax_rate?: Maybe<Scalars['Float']['output']>;
  state_capital_gains_tax_rate?: Maybe<Scalars['Float']['output']>;
};

export type NewCompanyInput = {
  name: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

export type NullableCurrencyInput = {
  currency: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type OnboardingListResponse = {
  __typename?: 'OnboardingListResponse';
  nodes?: Maybe<Array<Maybe<OnboardingRow>>>;
  totalCounts?: Maybe<ListViewTotalCounts>;
};

/** Data displayed in the admin onboarding page */
export type OnboardingRow = {
  __typename?: 'OnboardingRow';
  advance_provided?: Maybe<CurrencyModel>;
  advisor?: Maybe<Scalars['String']['output']>;
  category_statuses?: Maybe<Array<Maybe<FinancingCategoryStatus>>>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_status?: Maybe<CompanyStatusGql>;
  company_substatus?: Maybe<CompanySubstatusGql>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  date_proposal_accepted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  financing_object_uuid?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  proposal_uuid?: Maybe<Scalars['String']['output']>;
  rampup_fees?: Maybe<Array<RampSchedule>>;
  rampup_fees_name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FinancingStatus>;
  substatus?: Maybe<FinancingSubstatus>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
};

export enum OptionGrantType {
  Iso = 'ISO',
  Nso = 'NSO',
  Rsu = 'RSU',
}

export enum ParticipationType {
  NonParticipating = 'NonParticipating',
  Participating = 'Participating',
  Unknown = 'Unknown',
}

export type PayStubAccountStatement = {
  __typename?: 'PayStubAccountStatement';
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  files: Array<RiaOnboardingFile>;
  status?: Maybe<FormModelStatusGql>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type PayStubAccountStatementInput = {
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  ria_deal: Scalars['UUID']['input'];
  status: FormModelStatusGql;
};

export type Period = {
  __typename?: 'Period';
  amount: Scalars['Int']['output'];
  timeUnit: TimeUnit;
};

export type PeriodInput = {
  amount: Scalars['Int']['input'];
  timeUnit: TimeUnit;
};

export type PersonalInformationDataRia = {
  __typename?: 'PersonalInformationDataRIA';
  address_street_1?: Maybe<Scalars['String']['output']>;
  address_street_2?: Maybe<Scalars['String']['output']>;
  citizenship?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_of_tax_residence?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['CalendarDate']['output']>;
  legal_name?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<MaritalStatusGql>;
  phone_number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  preferred_email?: Maybe<Scalars['String']['output']>;
  social_security?: Maybe<Scalars['String']['output']>;
  subdivision?: Maybe<Scalars['String']['output']>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
};

export type PersonalInformationDataRiaInput = {
  address_street_1?: InputMaybe<Scalars['String']['input']>;
  address_street_2?: InputMaybe<Scalars['String']['input']>;
  citizenship?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_of_tax_residence?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['CalendarDate']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<MaritalStatusGql>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  preferred_email?: InputMaybe<Scalars['String']['input']>;
  social_security?: InputMaybe<Scalars['String']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
};

/** Financing form to capture basic information about the customer */
export type PersonalInformationFinancing = {
  __typename?: 'PersonalInformationFinancing';
  address_street_1?: Maybe<Scalars['String']['output']>;
  address_street_2?: Maybe<Scalars['String']['output']>;
  citizenship?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_of_tax_residence?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['CalendarDate']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: FinancingObject;
  legal_name?: Maybe<Scalars['String']['output']>;
  mailing_address?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<MaritalStatus>;
  phone_number?: Maybe<Scalars['String']['output']>;
  physical_address?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  preferred_email?: Maybe<Scalars['String']['output']>;
  social_security?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  subdivision?: Maybe<Scalars['String']['output']>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type PersonalInformationFinancingInput = {
  address_street_1?: InputMaybe<Scalars['String']['input']>;
  address_street_2?: InputMaybe<Scalars['String']['input']>;
  citizenship?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_of_tax_residence?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['CalendarDate']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  mailing_address?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<MaritalStatus>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  physical_address?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  preferred_email?: InputMaybe<Scalars['String']['input']>;
  social_security?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type PersonalInformationRia = {
  __typename?: 'PersonalInformationRIA';
  data?: Maybe<PersonalInformationDataRia>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  ria_deal: Scalars['UUID']['output'];
  status?: Maybe<FormModelStatusGql>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type PersonalInformationRiaInput = {
  data?: InputMaybe<PersonalInformationDataRiaInput>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  ria_deal: Scalars['UUID']['input'];
  status?: InputMaybe<FormModelStatusGql>;
};

export type PortfolioError = {
  __typename?: 'PortfolioError';
  code: PortfolioErrorCode;
  details: PortfolioErrorDetails;
};

export enum PortfolioErrorCode {
  AffiliationHasNoGrants = 'AffiliationHasNoGrants',
  PrefPriceMissing = 'PrefPriceMissing',
  SomethingWentWrong = 'SomethingWentWrong',
}

export type PortfolioErrorDetails = GenericErrorDetails;

export type PortfolioGraph = {
  __typename?: 'PortfolioGraph';
  dailyGranularityStartDate?: Maybe<Scalars['Timestamp']['output']>;
  markers: Array<GraphMarker>;
  totalValue: Array<TimelinePoint>;
};

export enum PortfolioManagedBySelector {
  All = 'ALL',
  Secfi = 'SECFI',
  Self = 'SELF',
}

export enum PortfolioSyncStatus {
  LoginRequired = 'LOGIN_REQUIRED',
  Ready = 'READY',
  Syncing = 'SYNCING',
}

export type PortfolioView = {
  allocationTable: AllocationTable;
  graph: PortfolioGraph;
  syncStatus: PortfolioSyncStatus;
  willNotifyReady: Scalars['Boolean']['output'];
};

export type PrivateSecuritiesContract = FinancingFormModel & {
  __typename?: 'PrivateSecuritiesContract';
  client_signed_at?: Maybe<Scalars['String']['output']>;
  contract_variant?: Maybe<Scalars['String']['output']>;
  execution_date?: Maybe<Scalars['CalendarDate']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<PrivateSecuritiesContractFile>;
  files?: Maybe<Array<Maybe<PrivateSecuritiesContractFile>>>;
  financing_object?: Maybe<FinancingObject>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type PrivateSecuritiesContractFile = {
  __typename?: 'PrivateSecuritiesContractFile';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type PrivateSecuritiesContractInput = {
  contract_variant?: InputMaybe<Scalars['String']['input']>;
  execution_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum Product {
  EquityAndTaxPlanning = 'EquityAndTaxPlanning',
  IpoAndLiquidityPlanning = 'IPOAndLiquidityPlanning',
  InvestmentManagement = 'InvestmentManagement',
}

export enum ProductCategory {
  Advisory = 'Advisory',
  Financing = 'Financing',
  Wealth = 'Wealth',
}

export enum ProductsOffered {
  NonRecourse = 'NON_RECOURSE',
  Recourse = 'RECOURSE',
}

/** A Secfi financing offer to a customer */
export type Proposal = {
  __typename?: 'Proposal';
  additional_deductions?: Maybe<CurrencyModel>;
  additional_income?: Maybe<CurrencyModel>;
  advance_provided?: Maybe<CurrencyModel>;
  advance_rate?: Maybe<Scalars['Float']['output']>;
  advance_to_equity_ratio?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  average_strike_price?: Maybe<CurrencyModel>;
  charitable_contributions?: Maybe<CurrencyModel>;
  city?: Maybe<Scalars['String']['output']>;
  collateral_shares?: Maybe<Scalars['Float']['output']>;
  collateral_value?: Maybe<CurrencyModel>;
  /** Deprecated - use platform_fee instead */
  contract_fee?: Maybe<CurrencyModel>;
  contract_type?: Maybe<ContractType>;
  country?: Maybe<Scalars['String']['output']>;
  created_by: Scalars['String']['output'];
  dependents?: Maybe<Scalars['Float']['output']>;
  dividend_income?: Maybe<CurrencyModel>;
  enforce_minimum_platform_fee?: Maybe<Scalars['Boolean']['output']>;
  /** Deprecated - use exercise_cost instead */
  exercise?: Maybe<CurrencyModel>;
  exercise_cost?: Maybe<CurrencyModel>;
  exercise_need?: Maybe<CurrencyModel>;
  expected_exit_date?: Maybe<Scalars['String']['output']>;
  expected_exit_share_value?: Maybe<CurrencyModel>;
  expiration_date?: Maybe<Scalars['String']['output']>;
  filing_type?: Maybe<FilingType>;
  four01k_contributions?: Maybe<CurrencyModel>;
  four09a_share_value?: Maybe<CurrencyModel>;
  four09a_share_value_origin?: Maybe<Scalars['String']['output']>;
  hash_id?: Maybe<Scalars['String']['output']>;
  income?: Maybe<CurrencyModel>;
  interpolated_rampup_fees?: Maybe<Array<RampSchedule>>;
  ira_contribution?: Maybe<CurrencyModel>;
  is_auto_generated?: Maybe<Scalars['Boolean']['output']>;
  is_expired: Scalars['Boolean']['output'];
  is_trust_fee_included?: Maybe<Scalars['Boolean']['output']>;
  iso_taxes?: Maybe<CurrencyModel>;
  issued_date?: Maybe<Scalars['String']['output']>;
  liquidity?: Maybe<CurrencyModel>;
  long_term_capital_gains?: Maybe<CurrencyModel>;
  medical_expenses?: Maybe<CurrencyModel>;
  mortgage_interest?: Maybe<CurrencyModel>;
  name?: Maybe<Scalars['String']['output']>;
  net_advance_rate?: Maybe<Scalars['Float']['output']>;
  net_rampup_fees?: Maybe<Array<RampSchedule>>;
  net_rate_components?: Maybe<NetRateComponents>;
  net_rental_real_estate_income?: Maybe<CurrencyModel>;
  net_stock_fee?: Maybe<Scalars['Float']['output']>;
  nso_taxes?: Maybe<CurrencyModel>;
  one_year_peg?: Maybe<Scalars['Boolean']['output']>;
  option_grants: Array<SelectedOptionGrant>;
  options_to_exercise?: Maybe<Scalars['Float']['output']>;
  platform_fee?: Maybe<CurrencyModel>;
  platform_fee_percentage?: Maybe<Scalars['Float']['output']>;
  preferred_share_value?: Maybe<CurrencyModel>;
  property_state_local_taxes?: Maybe<CurrencyModel>;
  proposal_reason: Array<Scalars['String']['output']>;
  proposal_request: ProposalRequest;
  published: Scalars['Boolean']['output'];
  rampup_fees?: Maybe<Array<RampSchedule>>;
  rampup_fees_name?: Maybe<Scalars['String']['output']>;
  regeneration_requested?: Maybe<Scalars['Boolean']['output']>;
  retirement_plan?: Maybe<Scalars['Boolean']['output']>;
  self_employment_income?: Maybe<CurrencyModel>;
  share_grants: Array<SelectedShareGrant>;
  shares_advance: SharesAdvance;
  shares_advance_amount?: Maybe<CurrencyModel>;
  /** Deprecated - use collateral_shares instead */
  shares_used?: Maybe<Scalars['Float']['output']>;
  short_term_capital_gains?: Maybe<CurrencyModel>;
  show_proposal_breakdown?: Maybe<Scalars['Boolean']['output']>;
  show_waiting_for_ipo_comparison?: Maybe<Scalars['Boolean']['output']>;
  stock_fee?: Maybe<Scalars['Float']['output']>;
  subdivision?: Maybe<Scalars['String']['output']>;
  taxes?: Maybe<CurrencyModel>;
  terms_used?: Maybe<Terms>;
  total_financing_need?: Maybe<CurrencyModel>;
  trust_closing_fee?: Maybe<CurrencyModel>;
  trust_fees_between_today_and_exit?: Maybe<CurrencyModel>;
  trust_monthly_administrative_fee?: Maybe<CurrencyModel>;
  trust_monthly_fees_until_exit?: Maybe<CurrencyModel>;
  trust_setup_fee?: Maybe<CurrencyModel>;
  uuid: Scalars['ID']['output'];
  value_per_share?: Maybe<CurrencyModel>;
  value_per_share_origin?: Maybe<Scalars['String']['output']>;
};

export type ProposalCreateInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  advance_provided?: InputMaybe<CurrencyModelInput>;
  advance_rate?: InputMaybe<Scalars['Float']['input']>;
  advance_to_equity_ratio?: InputMaybe<Scalars['Float']['input']>;
  average_strike_price?: InputMaybe<CurrencyModelInput>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  collateral_value?: InputMaybe<CurrencyModelInput>;
  contract_fee?: InputMaybe<CurrencyModelInput>;
  contract_type?: InputMaybe<ContractType>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  enforce_minimum_platform_fee?: InputMaybe<Scalars['Boolean']['input']>;
  exercise?: InputMaybe<CurrencyModelInput>;
  expected_exit_date?: InputMaybe<Scalars['String']['input']>;
  expected_exit_share_value?: InputMaybe<CurrencyModelInput>;
  expiration_date?: InputMaybe<Scalars['String']['input']>;
  filing_type?: InputMaybe<FilingType>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  four09a_share_value_origin?: InputMaybe<Scalars['String']['input']>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  is_expired?: InputMaybe<Scalars['Boolean']['input']>;
  issued_date?: InputMaybe<Scalars['String']['input']>;
  liquidity?: InputMaybe<CurrencyModelInput>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortgage_interest?: InputMaybe<CurrencyModelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  net_advance_rate?: InputMaybe<Scalars['Float']['input']>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  net_stock_fee?: InputMaybe<Scalars['Float']['input']>;
  one_year_peg?: InputMaybe<Scalars['Boolean']['input']>;
  option_grants?: InputMaybe<Array<InputMaybe<SelectedOptionGrantInput>>>;
  options_to_exercise?: InputMaybe<Scalars['Float']['input']>;
  platform_fee_percentage?: InputMaybe<Scalars['Float']['input']>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  proposal_reason?: InputMaybe<Array<InputMaybe<ProposalRequestReason>>>;
  proposal_request: Scalars['ID']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
  rampup_fees?: InputMaybe<Array<InputMaybe<RampScheduleInput>>>;
  rampup_fees_name?: InputMaybe<Scalars['String']['input']>;
  rate_card?: InputMaybe<Scalars['String']['input']>;
  retirement_plan?: InputMaybe<Scalars['Boolean']['input']>;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  share_grants?: InputMaybe<Array<InputMaybe<SelectedShareGrantInput>>>;
  shares_advance?: InputMaybe<SharesAdvance>;
  shares_advance_amount?: InputMaybe<CurrencyModelInput>;
  shares_used?: InputMaybe<Scalars['Float']['input']>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  show_proposal_breakdown?: InputMaybe<Scalars['Boolean']['input']>;
  show_waiting_for_ipo_comparison?: InputMaybe<Scalars['Boolean']['input']>;
  stock_fee?: InputMaybe<Scalars['Float']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<CurrencyModelInput>;
  terms_used?: InputMaybe<Scalars['String']['input']>;
  trust_closing_fee?: InputMaybe<CurrencyModelInput>;
  trust_monthly_administrative_fee?: InputMaybe<CurrencyModelInput>;
  trust_setup_fee?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  value_per_share?: InputMaybe<CurrencyModelInput>;
  value_per_share_origin?: InputMaybe<Scalars['String']['input']>;
};

/** A file representing a financing proposal from Secfi */
export type ProposalFile = {
  __typename?: 'ProposalFile';
  file_description?: Maybe<Scalars['String']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  proposal?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ProposalInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  advance_provided?: InputMaybe<CurrencyModelInput>;
  advance_rate?: InputMaybe<Scalars['Float']['input']>;
  advance_to_equity_ratio?: InputMaybe<Scalars['Float']['input']>;
  average_strike_price?: InputMaybe<CurrencyModelInput>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  collateral_value?: InputMaybe<CurrencyModelInput>;
  contract_fee?: InputMaybe<CurrencyModelInput>;
  contract_type?: InputMaybe<ContractType>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  enforce_minimum_platform_fee?: InputMaybe<Scalars['Boolean']['input']>;
  exercise?: InputMaybe<CurrencyModelInput>;
  expected_exit_date?: InputMaybe<Scalars['String']['input']>;
  expected_exit_share_value?: InputMaybe<CurrencyModelInput>;
  expiration_date?: InputMaybe<Scalars['String']['input']>;
  filing_type?: InputMaybe<FilingType>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  four09a_share_value_origin?: InputMaybe<Scalars['String']['input']>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  is_expired?: InputMaybe<Scalars['Boolean']['input']>;
  issued_date?: InputMaybe<Scalars['String']['input']>;
  liquidity?: InputMaybe<CurrencyModelInput>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortgage_interest?: InputMaybe<CurrencyModelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  net_advance_rate?: InputMaybe<Scalars['Float']['input']>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  net_stock_fee?: InputMaybe<Scalars['Float']['input']>;
  one_year_peg?: InputMaybe<Scalars['Boolean']['input']>;
  option_grants?: InputMaybe<Array<InputMaybe<SelectedOptionGrantInput>>>;
  options_to_exercise?: InputMaybe<Scalars['Float']['input']>;
  platform_fee_percentage?: InputMaybe<Scalars['Float']['input']>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  proposal_reason?: InputMaybe<Array<InputMaybe<ProposalRequestReason>>>;
  proposal_request?: InputMaybe<Scalars['ID']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  rampup_fees?: InputMaybe<Array<InputMaybe<RampScheduleInput>>>;
  rampup_fees_name?: InputMaybe<Scalars['String']['input']>;
  rate_card?: InputMaybe<Scalars['String']['input']>;
  retirement_plan?: InputMaybe<Scalars['Boolean']['input']>;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  share_grants?: InputMaybe<Array<InputMaybe<SelectedShareGrantInput>>>;
  shares_advance?: InputMaybe<SharesAdvance>;
  shares_advance_amount?: InputMaybe<CurrencyModelInput>;
  shares_used?: InputMaybe<Scalars['Float']['input']>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  show_proposal_breakdown?: InputMaybe<Scalars['Boolean']['input']>;
  show_waiting_for_ipo_comparison?: InputMaybe<Scalars['Boolean']['input']>;
  stock_fee?: InputMaybe<Scalars['Float']['input']>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<CurrencyModelInput>;
  terms_used?: InputMaybe<Scalars['String']['input']>;
  trust_closing_fee?: InputMaybe<CurrencyModelInput>;
  trust_monthly_administrative_fee?: InputMaybe<CurrencyModelInput>;
  trust_setup_fee?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  value_per_share?: InputMaybe<CurrencyModelInput>;
  value_per_share_origin?: InputMaybe<Scalars['String']['input']>;
};

export type ProposalPublishInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  proposal_link?: InputMaybe<Scalars['String']['input']>;
  reply_to?: InputMaybe<Scalars['String']['input']>;
  schedule_proposal_call?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  trigger_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalRegenerationResponse = {
  __typename?: 'ProposalRegenerationResponse';
  regeneration_requested?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['ID']['output'];
};

/** A customer request for financing with Secfi */
export type ProposalRequest = {
  __typename?: 'ProposalRequest';
  additional_deductions?: Maybe<CurrencyModel>;
  additional_income?: Maybe<CurrencyModel>;
  advance_to_equity_ratio?: Maybe<Scalars['Float']['output']>;
  affiliation?: Maybe<Affiliation>;
  charitable_contributions?: Maybe<CurrencyModel>;
  city?: Maybe<Scalars['String']['output']>;
  collateral_shares?: Maybe<Scalars['Float']['output']>;
  collateral_value?: Maybe<CurrencyModel>;
  comment?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<User>;
  date_submitted?: Maybe<Scalars['String']['output']>;
  dependents?: Maybe<Scalars['Float']['output']>;
  dividend_income?: Maybe<CurrencyModel>;
  exercise_date?: Maybe<Scalars['CalendarDate']['output']>;
  filing_type?: Maybe<FilingType>;
  financing_object: FinancingObject;
  four01k_contributions?: Maybe<CurrencyModel>;
  four09a_share_value?: Maybe<CurrencyModel>;
  has_minimum_personal_info?: Maybe<Scalars['Boolean']['output']>;
  income?: Maybe<CurrencyModel>;
  ira_contribution?: Maybe<CurrencyModel>;
  long_term_capital_gains?: Maybe<CurrencyModel>;
  medical_expenses?: Maybe<CurrencyModel>;
  mortage_interest?: Maybe<CurrencyModel>;
  net_rental_real_estate_income?: Maybe<CurrencyModel>;
  option_grants: Array<SelectedOptionGrant>;
  preferred_share_value?: Maybe<CurrencyModel>;
  property_state_local_taxes?: Maybe<CurrencyModel>;
  proposal_reason?: Maybe<Array<Maybe<ProposalRequestReason>>>;
  retirement_plan?: Maybe<Scalars['Boolean']['output']>;
  self_employment_income?: Maybe<CurrencyModel>;
  share_grants: Array<SelectedShareGrant>;
  shares_advance: Scalars['String']['output'];
  shares_advance_amount?: Maybe<CurrencyModel>;
  short_term_capital_gains?: Maybe<CurrencyModel>;
  subdivision?: Maybe<Scalars['String']['output']>;
  total_financing_need?: Maybe<CurrencyModel>;
  user_share_price?: Maybe<CurrencyModel>;
  uuid: Scalars['ID']['output'];
};

export type ProposalRequestBulkUpdateInput = {
  uuid: Scalars['ID']['input'];
};

export type ProposalRequestCreateInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  affiliation: Scalars['ID']['input'];
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  exercise_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  filing_type?: InputMaybe<FilingType>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  is_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortage_interest?: InputMaybe<CurrencyModelInput>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  proposal_reason?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requested_financing_step?: InputMaybe<RequestedFinancingStep>;
  retirement_plan?: InputMaybe<Scalars['Boolean']['input']>;
  selected_option_grants?: InputMaybe<
    Array<InputMaybe<SelectedOptionGrantInput>>
  >;
  selected_share_grants?: InputMaybe<
    Array<InputMaybe<SelectedShareGrantInput>>
  >;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  share_quantity?: InputMaybe<Scalars['Float']['input']>;
  shares_advance?: InputMaybe<SharesAdvance>;
  shares_advance_amount?: InputMaybe<CurrencyModelInput>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  trigger_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalRequestInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  affiliation?: InputMaybe<Scalars['ID']['input']>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  exercise_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  filing_type?: InputMaybe<FilingType>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  is_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortage_interest?: InputMaybe<CurrencyModelInput>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  proposal_reason?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requested_financing_step?: InputMaybe<RequestedFinancingStep>;
  retirement_plan?: InputMaybe<Scalars['Boolean']['input']>;
  selected_option_grants?: InputMaybe<
    Array<InputMaybe<SelectedOptionGrantInput>>
  >;
  selected_share_grants?: InputMaybe<
    Array<InputMaybe<SelectedShareGrantInput>>
  >;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  share_quantity?: InputMaybe<Scalars['Float']['input']>;
  shares_advance?: InputMaybe<SharesAdvance>;
  shares_advance_amount?: InputMaybe<CurrencyModelInput>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
  trigger_email?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A customer's personal snapshot as part of the proposal request */
export type ProposalRequestPersonalSnapshot = {
  __typename?: 'ProposalRequestPersonalSnapshot';
  additional_deductions?: Maybe<CurrencyModel>;
  additional_income?: Maybe<CurrencyModel>;
  charitable_contributions?: Maybe<CurrencyModel>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dependents?: Maybe<Scalars['Float']['output']>;
  dividend_income?: Maybe<CurrencyModel>;
  filing_type?: Maybe<FilingType>;
  four01k_contributions?: Maybe<CurrencyModel>;
  four09a_share_value?: Maybe<CurrencyModel>;
  has_minimum_personal_info?: Maybe<Scalars['Boolean']['output']>;
  income?: Maybe<CurrencyModel>;
  ira_contribution?: Maybe<CurrencyModel>;
  long_term_capital_gains?: Maybe<CurrencyModel>;
  medical_expenses?: Maybe<CurrencyModel>;
  mortage_interest?: Maybe<CurrencyModel>;
  net_rental_real_estate_income?: Maybe<CurrencyModel>;
  option_grants: Array<SelectedOptionGrant>;
  preferred_share_value?: Maybe<CurrencyModel>;
  property_state_local_taxes?: Maybe<CurrencyModel>;
  proposal_reason?: Maybe<Array<Maybe<ProposalRequestReason>>>;
  retirement_plan?: Maybe<Scalars['Boolean']['output']>;
  self_employment_income?: Maybe<CurrencyModel>;
  share_grants: Array<SelectedShareGrant>;
  shares_advance: Scalars['String']['output'];
  shares_advance_amount?: Maybe<CurrencyModel>;
  short_term_capital_gains?: Maybe<CurrencyModel>;
  subdivision?: Maybe<Scalars['String']['output']>;
  user_share_price?: Maybe<CurrencyModel>;
};

export type ProposalRequestPersonalSnapshotInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  filing_type?: InputMaybe<FilingType>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  four09a_share_value?: InputMaybe<CurrencyModelInput>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortage_interest?: InputMaybe<CurrencyModelInput>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  preferred_share_value?: InputMaybe<CurrencyModelInput>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  proposal_reason?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  retirement_plan?: InputMaybe<Scalars['Boolean']['input']>;
  selected_option_grants?: InputMaybe<
    Array<InputMaybe<SelectedOptionGrantInput>>
  >;
  selected_share_grants?: InputMaybe<
    Array<InputMaybe<SelectedShareGrantInput>>
  >;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  shares_advance?: InputMaybe<SharesAdvance>;
  shares_advance_amount?: InputMaybe<CurrencyModelInput>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  subdivision?: InputMaybe<Scalars['String']['input']>;
};

export enum ProposalRequestReason {
  Both = 'both',
  Exercise = 'exercise',
  Liquidity = 'liquidity',
}

/** Data displayed in the admin proposals page */
export type ProposalRow = {
  __typename?: 'ProposalRow';
  advance_to_equity_ratio?: Maybe<Scalars['Float']['output']>;
  advisor?: Maybe<Scalars['String']['output']>;
  affinity_id?: Maybe<Scalars['String']['output']>;
  collateral_value?: Maybe<CurrencyModel>;
  comment?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_status?: Maybe<CompanyStatusGql>;
  company_substatus?: Maybe<CompanySubstatusGql>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  date_submitted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  exercise_cost?: Maybe<CurrencyModel>;
  exercise_date?: Maybe<Scalars['DateTimeISO']['output']>;
  financing_object_uuid?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  is_auto_generated?: Maybe<Scalars['Boolean']['output']>;
  issued_date?: Maybe<Scalars['DateTimeISO']['output']>;
  last_seen?: Maybe<Scalars['DateTimeISO']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  possible_status_transitions: Array<FinancingStatusPair>;
  proposal_request_uuid?: Maybe<Scalars['String']['output']>;
  proposal_uuid?: Maybe<Scalars['String']['output']>;
  shares_advance_amount?: Maybe<CurrencyModel>;
  status?: Maybe<FinancingStatus>;
  substatus?: Maybe<FinancingSubstatus>;
  taxes?: Maybe<CurrencyModel>;
  total_financing_need?: Maybe<CurrencyModel>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
};

/** All tax related information using data from the proposal, enriched with trust data */
export type ProposalTaxDetails = {
  __typename?: 'ProposalTaxDetails';
  after_tax_returns?: Maybe<CurrencyModel>;
  amt_crossover_scenario: Scenario;
  cash_left_over_on_exit?: Maybe<CurrencyModel>;
  exercise_on_exit_scenario: Scenario;
  exit_comparison?: Maybe<ExitComparison>;
  secfi_financing_scenario: SecfiFinancingScenario;
  self_fund_scenario: Scenario;
  tax_bracket_maximization_scenario: Scenario;
  trust_details: TrustDetails;
};

export type ProposalTaxDetailsInput = {
  expected_exit_date?: InputMaybe<Scalars['String']['input']>;
  expected_exit_share_value?: InputMaybe<CurrencyModelInput>;
};

export type ProposalsListResponse = {
  __typename?: 'ProposalsListResponse';
  nodes?: Maybe<Array<Maybe<ProposalRow>>>;
  totalCounts?: Maybe<ListViewTotalCounts>;
};

export type PublicPrice = {
  __typename?: 'PublicPrice';
  price: Scalars['Float']['output'];
  ticker: Scalars['String']['output'];
};

export type QueriesRia = {
  __typename?: 'QueriesRIA';
  ComplianceInformation?: Maybe<ComplianceInformationRia>;
  IdentificationInformation?: Maybe<IdentificationInformationRia>;
  PayStubAccountStatement?: Maybe<PayStubAccountStatement>;
  PersonalInformation?: Maybe<PersonalInformationRia>;
  RiskAssessment?: Maybe<RiskAssessment>;
};

export type QueriesRiaComplianceInformationArgs = {
  ria_deal: Scalars['UUID']['input'];
};

export type QueriesRiaIdentificationInformationArgs = {
  ria_deal: Scalars['UUID']['input'];
};

export type QueriesRiaPayStubAccountStatementArgs = {
  ria_deal: Scalars['UUID']['input'];
};

export type QueriesRiaPersonalInformationArgs = {
  ria_deal: Scalars['UUID']['input'];
};

export type QueriesRiaRiskAssessmentArgs = {
  ria_deal: Scalars['UUID']['input'];
};

export type Query = {
  __typename?: 'Query';
  Advisor?: Maybe<Advisor>;
  Affiliation?: Maybe<Affiliation>;
  CapTable?: Maybe<CapTable>;
  Company?: Maybe<Company>;
  CompanyAssessment?: Maybe<CompanyAssessment>;
  CompanyBankAccountInformation?: Maybe<CompanyBankAccountInformation>;
  CompanyForecast?: Maybe<CompanyForecast>;
  CompanyListView?: Maybe<CompanyListResponse>;
  CompanyTriageListView?: Maybe<CompanyTriageListResponse>;
  Customer?: Maybe<Customer>;
  CustomerBankAccountInformation?: Maybe<CustomerBankAccountInformation>;
  EP_equityPlanTimeline: EpTimeline;
  EP_exerciseCostsEstimation: EpExerciseCostsEstimation;
  EP_exitDates: EpExitDateChoices;
  EP_exitMultipliers?: Maybe<EpExitMultiplierChoices>;
  EP_exitValueEstimation: EpExitValueEstimation;
  /** Fetch the plans of the Equity plan associated with the given affiliation, if the Equity plan has reached the plan phase */
  EP_getPlans?: Maybe<Array<EpPlan>>;
  EP_vestedGrantQuantities: Array<Maybe<EpVestedGrantQuantity>>;
  ExitScenarioDetails?: Maybe<ExitScenarioDetails>;
  FinancingObject?: Maybe<FinancingObject>;
  FormOptions?: Maybe<FormOptions>;
  Fund?: Maybe<Fund>;
  Investor?: Maybe<Investor>;
  Proposal?: Maybe<Proposal>;
  ProposalRequest?: Maybe<ProposalRequest>;
  ProposalTaxDetails?: Maybe<ProposalTaxDetails>;
  RIADealListView?: Maybe<RiaDealListResponse>;
  SpringingLienAssignmentAgreement?: Maybe<SpringingLienAssignmentAgreement>;
  SpringingLienPSC?: Maybe<SpringingLienPsc>;
  SpringingLienStockPledge?: Maybe<SpringingLienStockPledge>;
  Terms?: Maybe<Terms>;
  User?: Maybe<User>;
  UsersListView?: Maybe<UsersListResponse>;
  affiliationGraph: EquityGraph;
  allAdvisors?: Maybe<AllAdvisorsResponse>;
  allAffiliations?: Maybe<AllAffiliationsResponse>;
  allCompanyAssessments?: Maybe<AllCompanyAssessmentsResponse>;
  allCompanyForecasts?: Maybe<AllCompanyForecastsResponse>;
  allCustomers?: Maybe<AllCustomersResponse>;
  /** Returns the status of each financing category */
  allFinancingCategoryStatuses?: Maybe<Array<Maybe<FinancingCategoryStatus>>>;
  /** Returns all financing forms associated with a financing object */
  allFinancingFormStatuses?: Maybe<Array<Maybe<FinancingFormStatus>>>;
  allFinancingObjects?: Maybe<AllFinancingObjects>;
  allFunds?: Maybe<AllFundsResponse>;
  allInvestors?: Maybe<AllInvestorsResponse>;
  allJobPositions?: Maybe<Array<Maybe<JobPosition>>>;
  allProposalRequests?: Maybe<AllProposalRequests>;
  allProposals?: Maybe<AllProposalsResponse>;
  allUsTaxInformation?: Maybe<AllUsTaxInformationResponse>;
  allWaitlists?: Maybe<Array<Maybe<CustomerWaitlist>>>;
  assetTypeOverview: AssetTypeOverview;
  bankAccountInformation?: Maybe<BankAccountInformation>;
  clientChecklistQueries: ClientChecklistQueries;
  companyTriageUsersListView?: Maybe<CompanyTriageUsersListResponse>;
  complianceInformation?: Maybe<ComplianceInformationFinancing>;
  currentPublicPrices?: Maybe<Array<Maybe<PublicPrice>>>;
  customerInstitutions?: Maybe<Array<Maybe<Institution>>>;
  equity: EquityQueries;
  financingStatusHierarchy: Array<FinancingStatusSubstatuses>;
  getATVGrossTerms?: Maybe<Terms>;
  getAllSecondariesRequests: Array<Maybe<SecondariesRequest>>;
  getAsanaTask?: Maybe<AsanaTask>;
  getCartaConnectionStatus: CartaConnectionStatusResult;
  getCartaLoginUrl: CartaLoginUrlResponse;
  getCustomerFlag?: Maybe<CustomerFlag>;
  getDealCalculationsByUuid?: Maybe<DealCalculations>;
  getEquityVerificationByUuid?: Maybe<EquityVerification>;
  getFinancingOptionGrants?: Maybe<Array<Maybe<FinancingOptionGrant>>>;
  getFinancingShareGrants?: Maybe<Array<Maybe<FinancingShareGrant>>>;
  getMockProposal?: Maybe<MockProposal>;
  getStockSplits?: Maybe<Array<Maybe<StockSplit>>>;
  getTaxWithholdingsByUuid?: Maybe<TaxWithholdings>;
  identificationInformation?: Maybe<IdentificationInformation>;
  intermediaryServicesAgreement?: Maybe<IntermediaryServicesAgreement>;
  investmentDirectionLetter?: Maybe<Contract>;
  latestPublicPriceForDate?: Maybe<PublicPrice>;
  onboardingListView?: Maybe<OnboardingListResponse>;
  personalInformation?: Maybe<PersonalInformationFinancing>;
  personalReferralCode: Scalars['String']['output'];
  possibleBulkStatusTransitions?: Maybe<Array<Maybe<FinancingStatusPair>>>;
  privateSecuritiesContract?: Maybe<PrivateSecuritiesContract>;
  proposalsListView?: Maybe<ProposalsListResponse>;
  queriesRIA: QueriesRia;
  requestsListView?: Maybe<RequestsListResponse>;
  riaDealQueries: RiaDealQueries;
  scheduledAppointments: Array<ScheduledAppointment>;
  searchCompanies?: Maybe<Array<Maybe<CompanySearchResultItem>>>;
  seriesContract?: Maybe<Contract>;
  totalNetWorth: TotalNetWorthPortfolioView;
  userIdentification: UserIdentification;
  w9Form?: Maybe<W9Form>;
};

export type QueryAdvisorArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAffiliationArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCapTableArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyAssessmentArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyBankAccountInformationArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyForecastArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyListViewArgs = {
  companySubstatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Float']['input']>;
  hasAffiliations?: InputMaybe<Scalars['Boolean']['input']>;
  hasCapTable?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoStatus?: InputMaybe<Scalars['Boolean']['input']>;
  hasTerms?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCompanyTriageListViewArgs = {
  companySubstatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Float']['input']>;
  hasAffiliations?: InputMaybe<Scalars['Boolean']['input']>;
  hasTerms?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCustomerArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCustomerBankAccountInformationArgs = {
  financing_object: Scalars['String']['input'];
};

export type QueryEp_EquityPlanTimelineArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type QueryEp_ExerciseCostsEstimationArgs = {
  affiliation: Scalars['UUID']['input'];
  companyAndTaxDetails: EpCompanyAndTaxDetails;
  targetDate?: InputMaybe<Scalars['CalendarDate']['input']>;
};

export type QueryEp_ExitDatesArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type QueryEp_ExitMultipliersArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type QueryEp_ExitValueEstimationArgs = {
  affiliation: Scalars['UUID']['input'];
  exitProjection: EpExitProjection;
};

export type QueryEp_GetPlansArgs = {
  affiliation: Scalars['UUID']['input'];
};

export type QueryEp_VestedGrantQuantitiesArgs = {
  affiliation: Scalars['UUID']['input'];
  targetDate: Scalars['CalendarDate']['input'];
};

export type QueryExitScenarioDetailsArgs = {
  exit_valuations?: InputMaybe<Array<Scalars['Float']['input']>>;
  share_prices?: InputMaybe<Array<Scalars['Float']['input']>>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFinancingObjectArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFundArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInvestorArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProposalArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProposalRequestArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProposalTaxDetailsArgs = {
  payload?: InputMaybe<ProposalTaxDetailsInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySpringingLienAssignmentAgreementArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySpringingLienPscArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySpringingLienStockPledgeArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTermsArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUsersListViewArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAffiliationGraphArgs = {
  affiliation: Scalars['UUID']['input'];
  customer: Scalars['UUID']['input'];
};

export type QueryAllAdvisorsArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryAllAffiliationsArgs = {
  filters?: InputMaybe<AffiliationInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  target_date?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllCompanyAssessmentsArgs = {
  filters?: InputMaybe<CompanyAssessmentFiltersInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
};

export type QueryAllCompanyForecastsArgs = {
  filters?: InputMaybe<CompanyForecastFiltersInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
};

export type QueryAllCustomersArgs = {
  filters?: InputMaybe<CustomerInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryAllFinancingCategoryStatusesArgs = {
  financingObjectUuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllFinancingFormStatusesArgs = {
  financingObjectUuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllFinancingObjectsArgs = {
  filters?: InputMaybe<FinancingObjectFilterInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllFundsArgs = {
  filters?: InputMaybe<FundInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllInvestorsArgs = {
  filters?: InputMaybe<InvestorInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  uuids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryAllJobPositionsArgs = {
  user: Scalars['UUID']['input'];
};

export type QueryAllProposalRequestsArgs = {
  filters?: InputMaybe<ProposalRequestInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllProposalsArgs = {
  filters?: InputMaybe<ProposalInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
};

export type QueryAllUsTaxInformationArgs = {
  filters?: InputMaybe<UsTaxInformationInput>;
  first?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryAssetTypeOverviewArgs = {
  assetType: AssetType;
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
};

export type QueryBankAccountInformationArgs = {
  financing_object: Scalars['String']['input'];
};

export type QueryCompanyTriageUsersListViewArgs = {
  companyUUID?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryComplianceInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCurrentPublicPricesArgs = {
  customer: Scalars['UUID']['input'];
  tickers: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryCustomerInstitutionsArgs = {
  customer: Scalars['UUID']['input'];
};

export type QueryGetAtvGrossTermsArgs = {
  payload?: InputMaybe<GrossAtvTermsInput>;
};

export type QueryGetAllSecondariesRequestsArgs = {
  user: Scalars['UUID']['input'];
};

export type QueryGetAsanaTaskArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
};

export type QueryGetCartaLoginUrlArgs = {
  flowType?: InputMaybe<CartaFlowType>;
  prioritisedIssuerMatchingCompanyUuids?: InputMaybe<
    Array<Scalars['UUID']['input']>
  >;
  redirectUrl: Scalars['String']['input'];
};

export type QueryGetCustomerFlagArgs = {
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  customer: Scalars['UUID']['input'];
  flag: Scalars['String']['input'];
};

export type QueryGetDealCalculationsByUuidArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetEquityVerificationByUuidArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFinancingOptionGrantsArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
};

export type QueryGetFinancingShareGrantsArgs = {
  financingObjectUuid: Scalars['UUID']['input'];
};

export type QueryGetMockProposalArgs = {
  affiliationUuid: Scalars['ID']['input'];
  exitMultiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetStockSplitsArgs = {
  company?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetTaxWithholdingsByUuidArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIdentificationInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIntermediaryServicesAgreementArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInvestmentDirectionLetterArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLatestPublicPriceForDateArgs = {
  customer: Scalars['UUID']['input'];
  pricingDate: Scalars['CalendarDate']['input'];
  ticker: Scalars['String']['input'];
};

export type QueryOnboardingListViewArgs = {
  advisorUUID?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  fqStatusList?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPersonalInformationArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPrivateSecuritiesContractArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProposalsListViewArgs = {
  advisorUUID?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  fqStatusList?: InputMaybe<Array<Scalars['String']['input']>>;
  isSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRequestsListViewArgs = {
  advisorUUID?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  fqStatusList?: InputMaybe<Array<Scalars['String']['input']>>;
  hasTerms?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  secfiCustomersIncluded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryScheduledAppointmentsArgs = {
  affiliationUuid?: InputMaybe<Scalars['ID']['input']>;
  appointmentType: AppointmentType;
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
  user: Scalars['ID']['input'];
};

export type QuerySearchCompaniesArgs = {
  term: Scalars['String']['input'];
};

export type QuerySeriesContractArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTotalNetWorthArgs = {
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
};

export type QueryUserIdentificationArgs = {
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryW9FormArgs = {
  financing_object?: InputMaybe<Scalars['String']['input']>;
};

export type RiaDeal = {
  __typename?: 'RIADeal';
  advisor?: Maybe<Advisor>;
  advisoryAgreementUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Customer;
  owner: Scalars['UUID']['output'];
  product: RiaDealProduct;
  productCategory: RiaDealProductCategory;
  status: RiaDealStatus;
  substatus: RiaDealSubstatus;
  updatedAt: Scalars['Date']['output'];
  uuid: Scalars['UUID']['output'];
};

export type RiaDealList = {
  __typename?: 'RIADealList';
  nodes: Array<RiaDeal>;
  total_count: Scalars['Int']['output'];
};

export type RiaDealListResponse = {
  __typename?: 'RIADealListResponse';
  nodes: Array<RiaDealRow>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export enum RiaDealProduct {
  EquityAndTaxPlanning = 'EquityAndTaxPlanning',
  IpoAndLiquidityPlanning = 'IPOAndLiquidityPlanning',
  InvestmentManagement = 'InvestmentManagement',
}

export enum RiaDealProductCategory {
  Advisory = 'Advisory',
  Wealth = 'Wealth',
}

export type RiaDealQuestionnaireAnswerInput = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type RiaDealRow = {
  __typename?: 'RIADealRow';
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  customer_groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customer_uuid?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  product_category?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  user_uuid?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export enum RiaDealSource {
  EquityPlanner = 'EquityPlanner',
  MagicLink = 'MagicLink',
  ProductSelection = 'ProductSelection',
}

export enum RiaDealStatus {
  Contract = 'Contract',
  Intake = 'Intake',
  Lost = 'Lost',
  Rejected = 'Rejected',
  Request = 'Request',
  Won = 'Won',
}

export enum RiaDealSubstatus {
  AdvisoryEngagement = 'AdvisoryEngagement',
  AwaitingClientSignatures = 'AwaitingClientSignatures',
  AwaitingGetStartedCall = 'AwaitingGetStartedCall',
  AwaitingInsightsCall = 'AwaitingInsightsCall',
  GetStartedCallScheduled = 'GetStartedCallScheduled',
  InsightsCallScheduled = 'InsightsCallScheduled',
  IntroCallScheduled = 'IntroCallScheduled',
  JoinedWaitlist = 'JoinedWaitlist',
  Lost = 'Lost',
  New = 'New',
  Nurture = 'Nurture',
  OffBoarding = 'OffBoarding',
  PlanningMeeting1 = 'PlanningMeeting1',
  PlanningMeeting2 = 'PlanningMeeting2',
  PlanningMeeting3 = 'PlanningMeeting3',
  PlanningMeeting4 = 'PlanningMeeting4',
  Rejected = 'Rejected',
  Terminated = 'Terminated',
  UnderManagement = 'UnderManagement',
}

export type RiaOnboardingFile = {
  __typename?: 'RIAOnboardingFile';
  created_at?: Maybe<Scalars['CalendarDate']['output']>;
  document_type?: Maybe<Scalars['String']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['CalendarDate']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type RsuGrant = {
  __typename?: 'RSUGrant';
  /** The UUID of the affiliation to which the grant is associated */
  affiliation: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The UUID of the customer owning the grant */
  customer: Scalars['ID']['output'];
  /** The name of the grant */
  grantName: Scalars['String']['output'];
  grantType: RsuGrantType;
  migrationDefaultVestingSchedule: Scalars['Boolean']['output'];
  /** If performance based trigger is included for the RSU grant to vest */
  performanceTrigger: Scalars['Boolean']['output'];
  /** The quantity issued as part of the grant */
  quantity: Scalars['Int']['output'];
  summary: RsuGrantSummary;
  updatedAt: Scalars['DateTime']['output'];
  /** The UUID identifying the stock option grant */
  uuid: Scalars['ID']['output'];
  verified: Scalars['Boolean']['output'];
  /** The date when the grant becomes fully vested */
  vestingEndDate: Scalars['Date']['output'];
  /** The vesting schedule containing a list with each vesting event. */
  vestingEvents: Array<VestingScheduleEvent>;
  /** The vesting scheme based on which a grant is vesting. */
  vestingScheduleTemplate?: Maybe<VestingScheduleTemplate>;
  /** The date the grant starts vesting from. */
  vestingStartDate: Scalars['Date']['output'];
};

export type RsuGrantInput = {
  /** The UUID of the affiliation to which the grant is associated */
  affiliation: Scalars['UUID']['input'];
  /** The UUID of the customer owning the grant */
  customer: Scalars['UUID']['input'];
  /** The name of the grant */
  grantName: Scalars['String']['input'];
  /** If performance based trigger is included for the RSU grant to vest */
  performanceTrigger: Scalars['Boolean']['input'];
  /** The quantity issued as part of the grant */
  quantity: Scalars['Int']['input'];
  /** The vesting schedule containing a list with each vesting event. */
  vestingSchedule?: InputMaybe<Array<VestingScheduleEventInput>>;
  /** The vesting scheme based on which a grant is vesting.  */
  vestingScheduleTemplate?: InputMaybe<VestingScheduleTemplateInput>;
  /** The date the grant starts vesting from. */
  vestingStartDate: Scalars['Date']['input'];
};

export type RsuGrantSummary = {
  __typename?: 'RSUGrantSummary';
  soldQuantity: Scalars['Int']['output'];
  totalVestedQuantity: Scalars['Int']['output'];
};

export enum RsuGrantType {
  Rsu = 'RSU',
}

export type RampSchedule = {
  __typename?: 'RampSchedule';
  equity_rate: Scalars['Float']['output'];
  month: Scalars['Int']['output'];
};

export type RampScheduleInput = {
  equity_rate: Scalars['Float']['input'];
  month: Scalars['Int']['input'];
};

export type RampScheduleOverview = {
  __typename?: 'RampScheduleOverview';
  equity_rate: Scalars['Float']['output'];
  month: Scalars['Int']['output'];
  monthly_rate_increment: Scalars['Float']['output'];
  rampup_rate_difference: Scalars['Float']['output'];
};

export type RampupTermScenarios = {
  __typename?: 'RampupTermScenarios';
  advance_rate: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  ramps: Array<RampSchedule>;
};

export type RampupTermScenariosInput = {
  advance_rate: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ramps: Array<RampScheduleInput>;
};

/** Data displayed in the admin requests page */
export type RequestRow = {
  __typename?: 'RequestRow';
  advisor?: Maybe<Scalars['String']['output']>;
  affinity_id?: Maybe<Scalars['String']['output']>;
  collateral_value?: Maybe<CurrencyModel>;
  comment?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_status?: Maybe<CompanyStatusGql>;
  company_substatus?: Maybe<CompanySubstatusGql>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  date_submitted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  exercise_cost?: Maybe<CurrencyModel>;
  exercise_date?: Maybe<Scalars['DateTimeISO']['output']>;
  exercise_tax?: Maybe<CurrencyModel>;
  financing_object_uuid?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  has_minimum_personal_info?: Maybe<Scalars['Boolean']['output']>;
  latest_company_assessment?: Maybe<CompanyAssessment>;
  nickname?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  possible_status_transitions: Array<FinancingStatusPair>;
  proposal_request_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  score_explanation?: Maybe<Scalars['String']['output']>;
  score_updated_at?: Maybe<Scalars['String']['output']>;
  shares_advance_amount?: Maybe<CurrencyModel>;
  status?: Maybe<FinancingStatus>;
  step_detail_reason?: Maybe<LostWhyReason>;
  step_reason?: Maybe<StepReason>;
  substatus?: Maybe<FinancingSubstatus>;
  terms_count?: Maybe<Scalars['Float']['output']>;
  total_financing_need?: Maybe<CurrencyModel>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
};

export enum RequestedFinancingStep {
  OutreachPending = 'outreach_pending',
  ScheduleProposalCall = 'schedule_proposal_call',
}

export type RequestsListResponse = {
  __typename?: 'RequestsListResponse';
  nodes?: Maybe<Array<Maybe<RequestRow>>>;
  totalCounts?: Maybe<ListViewTotalCounts>;
};

export type RiskAssessment = {
  __typename?: 'RiskAssessment';
  download_file_name?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  files?: Maybe<Array<Maybe<RiaOnboardingFile>>>;
  questionnaire?: Maybe<Array<Maybe<RiskAssessmentQuestionType>>>;
  status?: Maybe<FormModelStatusGql>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type RiskAssessmentAnswer = {
  __typename?: 'RiskAssessmentAnswer';
  answer_description?: Maybe<Scalars['String']['output']>;
  answer_title?: Maybe<Scalars['String']['output']>;
};

export type RiskAssessmentAnswerInput = {
  answer_description: Scalars['String']['input'];
  answer_title: Scalars['String']['input'];
};

export type RiskAssessmentInput = {
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  questionnaire?: InputMaybe<
    Array<InputMaybe<RiskAssessmentQuestionTypeInput>>
  >;
  ria_deal: Scalars['UUID']['input'];
  status: FormModelStatusGql;
};

export type RiskAssessmentQuestionType = {
  __typename?: 'RiskAssessmentQuestionType';
  answer_description?: Maybe<Scalars['String']['output']>;
  answer_title?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  possible_answers?: Maybe<Array<Maybe<RiskAssessmentAnswer>>>;
  title?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type RiskAssessmentQuestionTypeInput = {
  answer_description: Scalars['String']['input'];
  answer_title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  possible_answers: Array<InputMaybe<RiskAssessmentAnswerInput>>;
  title: Scalars['String']['input'];
};

export type SaleEvent = {
  __typename?: 'SaleEvent';
  createdAt: Scalars['DateTime']['output'];
  /** The date the shares were sold */
  eventDate: Scalars['Date']['output'];
  /** The Fair Market Value price at the moment of the sale */
  fmv?: Maybe<EquityCurrency>;
  /** The price the shares were sold at */
  price?: Maybe<EquityCurrency>;
  /** Number of shares sold */
  quantity: Scalars['Int']['output'];
  /** The UUID identifying the stock option grant */
  uuid: Scalars['ID']['output'];
};

/** All tax related information we use to define possible exit scenarios */
export type Scenario = {
  __typename?: 'Scenario';
  base_tax_details?: Maybe<TaxDetails>;
  borrower_returns?: Maybe<Scalars['Float']['output']>;
  exercise_tax_breakdown?: Maybe<TaxBreakdown>;
  exercise_tax_details?: Maybe<TaxDetails>;
  exit_equity_value?: Maybe<Scalars['Float']['output']>;
  exit_share_price?: Maybe<Scalars['Float']['output']>;
  exit_tax_breakdown?: Maybe<TaxBreakdown>;
  exit_tax_details?: Maybe<TaxDetails>;
  four09a_equity_value?: Maybe<Scalars['Float']['output']>;
  investor_returns?: Maybe<Scalars['Float']['output']>;
  selling_equity_value?: Maybe<Scalars['Float']['output']>;
  selling_price?: Maybe<Scalars['Float']['output']>;
  target_date?: Maybe<Scalars['CalendarDate']['output']>;
  total_exercise_cost?: Maybe<Scalars['Float']['output']>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
  total_tax_breakdown?: Maybe<TaxBreakdown>;
  total_tax_owed_on_exercise?: Maybe<Scalars['Float']['output']>;
  total_tax_owed_on_exit?: Maybe<Scalars['Float']['output']>;
};

export type ScheduleAndSignUpResponse = AccountAlreadyExistsError | SignInToken;

export type ScheduledAppointment = {
  __typename?: 'ScheduledAppointment';
  appointment_timestamp: Scalars['DateTime']['output'];
  appointment_type: AppointmentType;
  calendly_event_uri: Scalars['String']['output'];
  calendly_invitee_id: Scalars['String']['output'];
  is_cancelled: Scalars['Boolean']['output'];
  is_rescheduled: Scalars['Boolean']['output'];
  uuid: Scalars['ID']['output'];
};

export type ScheduledAppointmentAnalyticsInput = {
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  meeting_id: Scalars['String']['input'];
  meeting_url: Scalars['String']['input'];
  path: Scalars['String']['input'];
  product?: InputMaybe<Product>;
  product_category?: InputMaybe<ProductCategory>;
  url: Scalars['String']['input'];
};

/** All tax related information we use to define the scenario using secfi financing */
export type SecfiFinancingScenario = {
  __typename?: 'SecfiFinancingScenario';
  amt_summary_at_exercise?: Maybe<AmtSummaryAtExcercise>;
  base_tax_details?: Maybe<TaxDetails>;
  borrower_returns?: Maybe<Scalars['Float']['output']>;
  collateral_shares?: Maybe<Scalars['Float']['output']>;
  compounded_interest?: Maybe<Scalars['Float']['output']>;
  exercise_tax_breakdown?: Maybe<TaxBreakdown>;
  exercise_tax_details?: Maybe<TaxDetails>;
  exit_equity_value?: Maybe<Scalars['Float']['output']>;
  exit_share_price?: Maybe<Scalars['Float']['output']>;
  exit_tax_breakdown?: Maybe<TaxBreakdown>;
  exit_tax_details?: Maybe<TaxDetails>;
  financing_plus_interest?: Maybe<Scalars['Float']['output']>;
  four09a_equity_value?: Maybe<Scalars['Float']['output']>;
  investor_fees?: Maybe<Scalars['Float']['output']>;
  investor_returns?: Maybe<Scalars['Float']['output']>;
  long_term_capital_gains_on_exit?: Maybe<Scalars['Float']['output']>;
  long_term_capital_gains_on_exit_from_contract?: Maybe<
    Scalars['Float']['output']
  >;
  long_term_capital_gains_on_exit_from_shares?: Maybe<
    Scalars['Float']['output']
  >;
  selling_equity_value?: Maybe<Scalars['Float']['output']>;
  selling_price?: Maybe<Scalars['Float']['output']>;
  target_date?: Maybe<Scalars['CalendarDate']['output']>;
  total_exercise_cost?: Maybe<Scalars['Float']['output']>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
  total_stock_fee?: Maybe<Scalars['Float']['output']>;
  total_tax_breakdown?: Maybe<TaxBreakdown>;
  total_tax_owed_on_exercise?: Maybe<Scalars['Float']['output']>;
  total_tax_owed_on_exit?: Maybe<Scalars['Float']['output']>;
};

export enum SecfiGroup {
  Bdoperations = 'BDOPERATIONS',
  Bdsales = 'BDSALES',
  Bdsupervisor = 'BDSUPERVISOR',
  Investor = 'INVESTOR',
  Operations = 'OPERATIONS',
  Riasupervisor = 'RIASUPERVISOR',
  Sales = 'SALES',
  Superuser = 'SUPERUSER',
}

export enum SecondariesOfferIntent {
  ExploringOptions = 'EXPLORING_OPTIONS',
  WillingToSell = 'WILLING_TO_SELL',
}

export type SecondariesRequest = {
  __typename?: 'SecondariesRequest';
  affiliation: Scalars['String']['output'];
  issuer_allows_sale: IssuerAllowsSale;
  offer_intent: SecondariesOfferIntent;
  offer_price?: Maybe<Scalars['Float']['output']>;
  shares_available_for_sale: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export type SecondariesRequestInput = {
  affiliation: Scalars['String']['input'];
  issuer_allows_sale: IssuerAllowsSale;
  offer_intent: SecondariesOfferIntent;
  offer_price?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  shares_available_for_sale: Scalars['Int']['input'];
};

export type SelectedOptionGrant = {
  __typename?: 'SelectedOptionGrant';
  excluded?: Maybe<Scalars['Boolean']['output']>;
  expiration_date?: Maybe<Scalars['CalendarDate']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  option_type: OptionGrantType;
  parent_grant?: Maybe<Scalars['ID']['output']>;
  /** Granted quantity */
  quantity: Scalars['Float']['output'];
  selected_quantity: Scalars['Float']['output'];
  start_date?: Maybe<Scalars['CalendarDate']['output']>;
  strike_price: CurrencyModel;
  termination_deadline?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SelectedOptionGrantInput = {
  affiliation?: InputMaybe<Scalars['ID']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  option_type?: InputMaybe<OptionGrantType>;
  original_grant?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  selected_quantity?: InputMaybe<Scalars['Float']['input']>;
  start_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  strike_price?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type SelectedShareGrant = {
  __typename?: 'SelectedShareGrant';
  date_of_acquisition?: Maybe<Scalars['CalendarDate']['output']>;
  duration_months?: Maybe<Scalars['Float']['output']>;
  excluded?: Maybe<Scalars['Boolean']['output']>;
  quantity: Scalars['Float']['output'];
  selected_quantity: Scalars['Float']['output'];
  share_type?: Maybe<Scalars['String']['output']>;
  share_value_at_acquisition?: Maybe<CurrencyModel>;
  start_date?: Maybe<Scalars['CalendarDate']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
  vested_quantity?: Maybe<Scalars['Float']['output']>;
};

export type SelectedShareGrantInput = {
  affiliation?: InputMaybe<Scalars['ID']['input']>;
  date_of_acquisition?: InputMaybe<Scalars['CalendarDate']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  original_grant?: InputMaybe<Scalars['ID']['input']>;
  parent_grant?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  selected_quantity?: InputMaybe<Scalars['Float']['input']>;
  share_type?: InputMaybe<Scalars['String']['input']>;
  share_value_at_acquisition?: InputMaybe<CurrencyModelInput>;
  start_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type ShareGrantExerciseDetail = {
  __typename?: 'ShareGrantExerciseDetail';
  fmv: EquityCurrency;
  stockOptionGrant?: Maybe<ExerciseDetailParentStockOptionGrant>;
  uuid: Scalars['UUID']['output'];
};

export enum ShareGrantSource {
  Exercise = 'EXERCISE',
  Secondary = 'SECONDARY',
  ShareGrant = 'SHARE_GRANT',
}

export enum ShareGrantType {
  Common = 'Common',
}

export enum SharesAdvance {
  Custom = 'custom',
  DontKnow = 'dont_know',
  Max = 'max',
}

export type SignContract = {
  __typename?: 'SignContract';
  docusign_url?: Maybe<Scalars['String']['output']>;
};

export type SignContractInputType = {
  return_url: Scalars['String']['input'];
  type: ContractTypes;
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  access_token?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  is_verified?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type SignInToken = {
  __typename?: 'SignInToken';
  token: Scalars['String']['output'];
};

export type SignInTokenInvalidError = {
  __typename?: 'SignInTokenInvalidError';
  error: Scalars['String']['output'];
};

export type SignInWithSignInTokenResponse =
  | SignInResponse
  | SignInTokenInvalidError;

export type SignUpPasswordlessInput = {
  analytics?: InputMaybe<SignUpTrackingParamsInput>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  referral_code?: InputMaybe<Scalars['String']['input']>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  access_token?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  is_verified?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type SignUpTrackingParamsInput = {
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  companies?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  company_status?: InputMaybe<Scalars['String']['input']>;
  has_options?: InputMaybe<Scalars['Boolean']['input']>;
  has_shares?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  signed_up_via?: InputMaybe<Scalars['String']['input']>;
  signed_up_with?: InputMaybe<Scalars['String']['input']>;
};

export type SpringingLienAssignmentAgreement = FinancingFormModel & {
  __typename?: 'SpringingLienAssignmentAgreement';
  category?: Maybe<Scalars['String']['output']>;
  client_signed_at?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<SpringingLienAssignmentAgreementFile>;
  files?: Maybe<Array<Maybe<SpringingLienAssignmentAgreementFile>>>;
  financing_object?: Maybe<FinancingObject>;
  is_outdated?: Maybe<Scalars['Boolean']['output']>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SpringingLienAssignmentAgreementFile = {
  __typename?: 'SpringingLienAssignmentAgreementFile';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SpringingLienPsc = FinancingFormModel & {
  __typename?: 'SpringingLienPSC';
  category?: Maybe<Scalars['String']['output']>;
  client_signed_at?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<SpringingLienPscFile>;
  files?: Maybe<Array<Maybe<SpringingLienPscFile>>>;
  financing_object?: Maybe<FinancingObject>;
  is_outdated?: Maybe<Scalars['Boolean']['output']>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SpringingLienPscFile = {
  __typename?: 'SpringingLienPSCFile';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SpringingLienStockPledge = FinancingFormModel & {
  __typename?: 'SpringingLienStockPledge';
  category?: Maybe<Scalars['String']['output']>;
  client_signed_at?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<SpringingLienStockPledgeFile>;
  files?: Maybe<Array<Maybe<SpringingLienStockPledgeFile>>>;
  financing_object?: Maybe<FinancingObject>;
  is_outdated?: Maybe<Scalars['Boolean']['output']>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type SpringingLienStockPledgeFile = {
  __typename?: 'SpringingLienStockPledgeFile';
  file_name?: Maybe<Scalars['String']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum Status {
  LoginRequired = 'LOGIN_REQUIRED',
  Ok = 'OK',
}

export enum StepReason {
  ClientChoseCompetitor = 'client_chose_competitor',
  ClientDislikesProposalDealStructure = 'client_dislikes_proposal_deal_structure',
  ClientDislikesProposalDealTerms = 'client_dislikes_proposal_deal_terms',
  ClientNeedsMoreTime = 'client_needs_more_time',
  ClientWillSelfFund = 'client_will_self_fund',
  ClientWillSellShares = 'client_will_sell_shares',
  ClientWillTakeLoan = 'client_will_take_loan',
  CompanyBlackoutOr_409ahold = 'company_blackout_or_409ahold',
  CompanyDoesNotLikeSecfi = 'company_does_not_like_secfi',
  CompanyIssuerUnwillingToExecute = 'company_issuer_unwilling_to_execute',
  CompanyNoLongerApproved = 'company_no_longer_approved',
  DeadlineTooTight = 'deadline_too_tight',
  DealSizeTooSmall = 'deal_size_too_small',
  DormantReEngagementDate = 'dormant_re_engagement_date',
  Duplicate = 'duplicate',
  FailedKyc = 'failed_kyc',
  FailedSoftCreditCheck = 'failed_soft_credit_check',
  InvestorDeclined = 'investor_declined',
  IssuerNeedsMomentum = 'issuer_needs_momentum',
  IssuerUnwillingToExecute = 'issuer_unwilling_to_execute',
  NegativeDueDiligenceCompanyTooSmallOrEarlyStage = 'negative_due_diligence_company_too_small_or_early_stage',
  NegativeDueDiligenceNotBullishOnCompany = 'negative_due_diligence_not_bullish_on_company',
  NoAllocation = 'no_allocation',
  NoCompanyIntro = 'no_company_intro',
  NoDecision = 'no_decision',
  NoGrantsOrFakeLeadOrBadData = 'no_grants_or_fake_lead_or_bad_data',
  NonUsOrPublicCompany = 'non_us_or_public_company',
  Other = 'other',
  RequestRejectedByAutomatedTriage = 'request_rejected_by_automated_triage',
  WaitingOnCapitalProvider = 'waiting_on_capital_provider',
  WaitingOnSecfiInvestmentTeam = 'waiting_on_secfi_investment_team',
}

export enum StepReasonGroup {
  DeclinedBadData = 'declined_bad_data',
  DeclinedDeadlineTightOrDealSizeThreshold = 'declined_deadline_tight_or_deal_size_threshold',
  DeclinedEligibility = 'declined_eligibility',
  DeclinedMiscReason = 'declined_misc_reason',
}

export type StockOptionGrant = {
  __typename?: 'StockOptionGrant';
  affiliation: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Scalars['UUID']['output'];
  deadlineAfterLeaving?: Maybe<Period>;
  earlyExercisable: Scalars['Boolean']['output'];
  exerciseEvents?: Maybe<Array<ExerciseEvent>>;
  grantName: Scalars['String']['output'];
  grantType: StockOptionGrantType;
  migrationDefaultVestingSchedule: Scalars['Boolean']['output'];
  quantity: Scalars['Int']['output'];
  strikePrice: EquityCurrency;
  summary: StockOptionGrantSummary;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  verified: Scalars['Boolean']['output'];
  vestingEndDate: Scalars['Date']['output'];
  vestingEvents: Array<VestingScheduleEvent>;
  vestingScheduleTemplate?: Maybe<VestingScheduleTemplate>;
  vestingStartDate: Scalars['Date']['output'];
};

export type StockOptionGrantInput = {
  /** The UUID of the affiliation to which the grant is associated */
  affiliation: Scalars['UUID']['input'];
  /** The UUID of the customer owning the grant */
  customer: Scalars['UUID']['input'];
  /** The period after leaving the company while the grant can still be exercised */
  deadlineAfterLeaving?: InputMaybe<PeriodInput>;
  /** Whether the option grant can be exercised before vesting */
  earlyExercisable: Scalars['Boolean']['input'];
  /** The name of the grant */
  grantName: Scalars['String']['input'];
  /** The type of the grant, choice of ISO and NSO */
  grantType: StockOptionGrantType;
  /** The quantity issued as part of the grant */
  quantity: Scalars['Int']['input'];
  /** The price to exercise an option in the grant */
  strikePrice: CurrencyInput;
  /** The vesting schedule containing a list with each vesting event. */
  vestingSchedule?: InputMaybe<Array<VestingScheduleEventInput>>;
  /** The vesting scheme based on which a grant is vesting.  */
  vestingScheduleTemplate?: InputMaybe<VestingScheduleTemplateInput>;
  /** The date the grant starts vesting from. */
  vestingStartDate: Scalars['Date']['input'];
};

export type StockOptionGrantSummary = {
  __typename?: 'StockOptionGrantSummary';
  /** Number of options available to exercise at the moment */
  exercisableQuantity: Scalars['Int']['output'];
  /** Number of options exercised */
  exercisedQuantity: Scalars['Int']['output'];
  /** Number of options exercised and not sold */
  ownedQuantity: Scalars['Int']['output'];
  /** Number of shares sold including those from nested exercises */
  soldQuantity: Scalars['Int']['output'];
  /** Number of options vested at the moment regardless of whether they are exercised or not */
  totalVestedQuantity: Scalars['Int']['output'];
};

export enum StockOptionGrantType {
  Iso = 'ISO',
  Nso = 'NSO',
}

/** Stock split model */
export type StockSplit = {
  __typename?: 'StockSplit';
  company: Scalars['ID']['output'];
  denominator?: Maybe<Scalars['Float']['output']>;
  effective_date?: Maybe<Scalars['CalendarDate']['output']>;
  nominator?: Maybe<Scalars['Float']['output']>;
  uuid: Scalars['ID']['output'];
};

export type StockSplitInput = {
  company: Scalars['ID']['input'];
  denominator?: InputMaybe<Scalars['Float']['input']>;
  effective_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  nominator?: InputMaybe<Scalars['Float']['input']>;
};

export type StockSplitUpdateInput = {
  denominator?: InputMaybe<Scalars['Float']['input']>;
  effective_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  nominator?: InputMaybe<Scalars['Float']['input']>;
};

export type Subdivision = {
  __typename?: 'Subdivision';
  country_code?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Tax bracket information for the different taxable incomes. */
export type TaxBrackets = {
  __typename?: 'TaxBrackets';
  amt?: Maybe<Array<Maybe<Bracket>>>;
  capital_gains_tax?: Maybe<Array<Maybe<Bracket>>>;
  city_income_tax?: Maybe<Array<Maybe<Bracket>>>;
  income_tax?: Maybe<Array<Maybe<Bracket>>>;
  medicare_tax?: Maybe<Array<Maybe<Bracket>>>;
  state_capital_gains_tax?: Maybe<Array<Maybe<Bracket>>>;
  state_income_tax?: Maybe<Array<Maybe<Bracket>>>;
};

/** Tax breakdown for a specific scenario and moment. */
export type TaxBreakdown = {
  __typename?: 'TaxBreakdown';
  amt: Scalars['Float']['output'];
  capital_gains_tax: Scalars['Float']['output'];
  city_income_tax: Scalars['Float']['output'];
  income_tax: Scalars['Float']['output'];
  medicare_tax: Scalars['Float']['output'];
  net_investment_income_tax: Scalars['Float']['output'];
  social_security_tax: Scalars['Float']['output'];
  state_amt: Scalars['Float']['output'];
  state_capital_gains_tax: Scalars['Float']['output'];
  state_income_tax: Scalars['Float']['output'];
  total_capital_gains_tax: Scalars['Float']['output'];
  total_owed: Scalars['Float']['output'];
};

/** Tax specific information to be defined for base, exercise and exit contexts. */
export type TaxDetails = {
  __typename?: 'TaxDetails';
  additional_deductions?: Maybe<Scalars['Float']['output']>;
  additional_income?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  agi?: Maybe<Scalars['Float']['output']>;
  amt?: Maybe<Scalars['Float']['output']>;
  amt_credit?: Maybe<Scalars['Float']['output']>;
  amt_exemption?: Maybe<Scalars['Float']['output']>;
  amt_gap?: Maybe<Scalars['Float']['output']>;
  amt_income_gap?: Maybe<Scalars['Float']['output']>;
  brackets?: Maybe<TaxBrackets>;
  capital_gains_tax?: Maybe<Scalars['Float']['output']>;
  carry_over_capital_losses?: Maybe<Scalars['Float']['output']>;
  charitable_contributions?: Maybe<Scalars['Float']['output']>;
  city_income_tax?: Maybe<Scalars['Float']['output']>;
  constants?: Maybe<TaxDetailsConstants>;
  country?: Maybe<Scalars['String']['output']>;
  deducatable_four01k?: Maybe<Scalars['Float']['output']>;
  deductable_capital_losses?: Maybe<Scalars['Float']['output']>;
  deductable_charitable_contributions?: Maybe<Scalars['Float']['output']>;
  deductable_ira?: Maybe<Scalars['Float']['output']>;
  deductable_medical_expenses?: Maybe<Scalars['Float']['output']>;
  deductable_state_income_tax?: Maybe<Scalars['Float']['output']>;
  dependents?: Maybe<Scalars['Float']['output']>;
  dividend_income?: Maybe<Scalars['Float']['output']>;
  effective_tax_rates?: Maybe<EffectiveTaxRates>;
  fica_income?: Maybe<Scalars['Float']['output']>;
  filling_type?: Maybe<Scalars['String']['output']>;
  four01k_contributions?: Maybe<Scalars['Float']['output']>;
  gross_amt?: Maybe<Scalars['Float']['output']>;
  gross_amt_income?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
  income_tax?: Maybe<Scalars['Float']['output']>;
  ira_contribution?: Maybe<Scalars['Float']['output']>;
  iso_gains?: Maybe<Scalars['Float']['output']>;
  long_term_capital_gains?: Maybe<Scalars['Float']['output']>;
  magi?: Maybe<Scalars['Float']['output']>;
  magi_minus_niit_threshold?: Maybe<Scalars['Float']['output']>;
  medical_expenses?: Maybe<Scalars['Float']['output']>;
  medicare_tax?: Maybe<Scalars['Float']['output']>;
  mortage_interest?: Maybe<Scalars['Float']['output']>;
  net_amt_income?: Maybe<Scalars['Float']['output']>;
  net_income?: Maybe<Scalars['Float']['output']>;
  net_investment_income?: Maybe<Scalars['Float']['output']>;
  net_investment_income_tax?: Maybe<Scalars['Float']['output']>;
  net_rental_real_estate_income?: Maybe<Scalars['Float']['output']>;
  nso_gains?: Maybe<Scalars['Float']['output']>;
  personal_exemptions?: Maybe<Scalars['Float']['output']>;
  property_state_local_taxes?: Maybe<Scalars['Float']['output']>;
  retirement_plan?: Maybe<Scalars['Boolean']['output']>;
  rsu_gains?: Maybe<Scalars['Float']['output']>;
  self_employment_income?: Maybe<Scalars['Float']['output']>;
  self_employment_tax?: Maybe<Scalars['Float']['output']>;
  self_employment_tax_adjustment?: Maybe<Scalars['Float']['output']>;
  short_term_capital_gains?: Maybe<Scalars['Float']['output']>;
  social_security_tax?: Maybe<Scalars['Float']['output']>;
  standard_deduction?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  state_amt?: Maybe<Scalars['Float']['output']>;
  state_amt_credit?: Maybe<Scalars['Float']['output']>;
  state_amt_exemption?: Maybe<Scalars['Float']['output']>;
  state_capital_gains_tax?: Maybe<Scalars['Float']['output']>;
  state_gross_amt?: Maybe<Scalars['Float']['output']>;
  state_gross_amt_income?: Maybe<Scalars['Float']['output']>;
  state_income_tax?: Maybe<Scalars['Float']['output']>;
  state_net_income?: Maybe<Scalars['Float']['output']>;
  subtotal_state_income_tax?: Maybe<Scalars['Float']['output']>;
  target_date?: Maybe<Scalars['CalendarDate']['output']>;
  total_capital_gains_tax?: Maybe<Scalars['Float']['output']>;
  total_capital_losses?: Maybe<Scalars['Float']['output']>;
  total_deduction_above_the_line?: Maybe<Scalars['Float']['output']>;
  total_deduction_below_the_line?: Maybe<Scalars['Float']['output']>;
  total_deductions?: Maybe<Scalars['Float']['output']>;
  total_exemption_value?: Maybe<Scalars['Float']['output']>;
  total_federal_taxes?: Maybe<Scalars['Float']['output']>;
  total_fica?: Maybe<Scalars['Float']['output']>;
  total_gross_income?: Maybe<Scalars['Float']['output']>;
  total_long_term_capital_gains?: Maybe<Scalars['Float']['output']>;
  total_net_income?: Maybe<Scalars['Float']['output']>;
  total_owed?: Maybe<Scalars['Float']['output']>;
  total_state_taxes?: Maybe<Scalars['Float']['output']>;
  wages?: Maybe<Scalars['Float']['output']>;
};

/** Constant legal values attached to our tax details model. */
export type TaxDetailsConstants = {
  __typename?: 'TaxDetailsConstants';
  amt_exemption?: Maybe<Scalars['Float']['output']>;
  amt_exemption_phase_out_start?: Maybe<Scalars['Float']['output']>;
  amt_exemption_phase_out_step_size?: Maybe<Scalars['Float']['output']>;
  capital_gains_tax_max_deduction?: Maybe<Scalars['Float']['output']>;
  income_tax_deductable_state_tax_limit?: Maybe<Scalars['Float']['output']>;
  income_tax_deduction?: Maybe<Scalars['Float']['output']>;
  income_tax_exemption?: Maybe<Scalars['Float']['output']>;
  income_tax_exemption_phase_out_end?: Maybe<Scalars['Float']['output']>;
  income_tax_exemption_phase_out_start?: Maybe<Scalars['Float']['output']>;
  income_tax_four01k_max_deductable?: Maybe<Scalars['Float']['output']>;
  income_tax_ira_max_deductable_under_50?: Maybe<Scalars['Float']['output']>;
  income_tax_medical_expenses_threshold?: Maybe<Scalars['Float']['output']>;
  income_tax_pease_limit?: Maybe<Scalars['Float']['output']>;
  net_investment_income_tax_rate?: Maybe<Scalars['Float']['output']>;
  net_investment_income_tax_threshold?: Maybe<Scalars['Float']['output']>;
  satet_amt_rate?: Maybe<Scalars['Float']['output']>;
  social_security_tax_rate?: Maybe<Scalars['Float']['output']>;
  social_security_tax_wage_limit?: Maybe<Scalars['Float']['output']>;
  state_amt_exemption?: Maybe<Scalars['Float']['output']>;
  state_amt_exemption_phase_out_start?: Maybe<Scalars['Float']['output']>;
  state_amt_exemption_phase_out_step_size?: Maybe<Scalars['Float']['output']>;
  state_income_tax_deduction?: Maybe<Scalars['Float']['output']>;
  state_income_tax_exemption?: Maybe<Scalars['Float']['output']>;
  state_income_tax_exemption_dependent?: Maybe<Scalars['Float']['output']>;
};

/** Tax Withholdings model */
export type TaxWithholdings = {
  __typename?: 'TaxWithholdings';
  amount_type: TaxWithholdingsAmountType;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<TaxWithholdingsFile>;
  financing_object: Scalars['ID']['output'];
  financing_option_relations: Array<TaxWithholdingsOptionRelation>;
  single_amount?: Maybe<CurrencyModel>;
  status: FormModelStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum TaxWithholdingsAmountType {
  Multiple = 'multiple',
  Single = 'single',
}

export type TaxWithholdingsFile = {
  __typename?: 'TaxWithholdingsFile';
  file_name: Scalars['String']['output'];
  file_type: Scalars['String']['output'];
  tax_withholdings?: Maybe<Scalars['UUID']['output']>;
  tax_withholdings_option_relation?: Maybe<Scalars['UUID']['output']>;
  url: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type TaxWithholdingsInput = {
  amount_type: TaxWithholdingsAmountType;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  single_amount?: InputMaybe<CurrencyModelInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type TaxWithholdingsOptionRelation = {
  __typename?: 'TaxWithholdingsOptionRelation';
  amount?: Maybe<Currency>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  file?: Maybe<TaxWithholdingsFile>;
  financing_option_grant: FinancingOptionGrant;
  uuid?: Maybe<Scalars['UUID']['output']>;
  verification_status: VerificationStatus;
};

export type Terms = {
  __typename?: 'Terms';
  assumed_exit_date?: Maybe<Scalars['CalendarDate']['output']>;
  company: Scalars['ID']['output'];
  created_at: Scalars['String']['output'];
  created_by?: Maybe<User>;
  expiration_date: Scalars['String']['output'];
  file_url?: Maybe<Scalars['String']['output']>;
  fund: Fund;
  is_expired: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  rates: Array<TermsRate>;
  reference_price?: Maybe<CurrencyModel>;
  uuid: Scalars['ID']['output'];
};

export type TermsInput = {
  assumed_exit_date?: InputMaybe<Scalars['CalendarDate']['input']>;
  company: Scalars['ID']['input'];
  expiration_date: Scalars['String']['input'];
  fund: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  rates: Array<TermsRateInput>;
  reference_price?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type TermsNoteUpdateInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type TermsRate = {
  __typename?: 'TermsRate';
  approved: Scalars['Boolean']['output'];
  atv: Scalars['Float']['output'];
  fixed_scenarios?: Maybe<Array<FixedTermScenario>>;
  rampup_scenarios?: Maybe<Array<RampupTermScenarios>>;
  warning?: Maybe<Scalars['String']['output']>;
};

export type TermsRateInput = {
  approved: Scalars['Boolean']['input'];
  atv: Scalars['Float']['input'];
  fixed_scenarios?: InputMaybe<Array<InputMaybe<FixedTermScenarioInput>>>;
  rampup_scenarios?: InputMaybe<Array<InputMaybe<RampupTermScenariosInput>>>;
};

export enum TimeUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
}

export type TimelinePoint = {
  __typename?: 'TimelinePoint';
  timestamp: Scalars['Timestamp']['output'];
  value: Scalars['Float']['output'];
};

export type TotalNetWorthPortfolioView = PortfolioView & {
  __typename?: 'TotalNetWorthPortfolioView';
  allocationTable: AllocationTable;
  graph: PortfolioGraph;
  /**  Whether we'd like to have this customer become a wealth client  */
  hasWealthPotential: Scalars['Boolean']['output'];
  /** Latest date for which we have managed holdings */
  lastManagedHoldingsSnapshotDate?: Maybe<Scalars['CalendarDate']['output']>;
  /** Latest pref price issue date across all private assets */
  privateAssetsLatestPrefPriceDate?: Maybe<Scalars['Timestamp']['output']>;
  syncStatus: PortfolioSyncStatus;
  willNotifyReady: Scalars['Boolean']['output'];
};

export enum TotalNetWorthRia {
  AboveK500001 = 'ABOVE_K500001',
  BelowK100000 = 'BELOW_K100000',
  K100001K250000 = 'K100001_K250000',
  K250001K500000 = 'K250001_K500000',
}

/** Details about trust fees for proposals */
export type TrustDetails = {
  __typename?: 'TrustDetails';
  trust_closing_fee?: Maybe<CurrencyModel>;
  trust_fees_between_today_and_exit?: Maybe<CurrencyModel>;
  trust_monthly_administrative_fee?: Maybe<CurrencyModel>;
  trust_monthly_fees_until_exit?: Maybe<CurrencyModel>;
  trust_setup_fee?: Maybe<CurrencyModel>;
};

export type UpdateFinancingOptionGrantInputType = {
  exercisable_quantity?: InputMaybe<Scalars['Int']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  grant_date?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  option_type?: InputMaybe<OptionGrantType>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  selected_quantity?: InputMaybe<Scalars['Int']['input']>;
  strike_price?: InputMaybe<CurrencyModelInput>;
  user_modified_data?: InputMaybe<Scalars['Boolean']['input']>;
  verification_status?: InputMaybe<VerificationStatus>;
};

export type UpdateFinancingShareGrantInputType = {
  date_of_acquisition?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  selected_quantity?: InputMaybe<Scalars['Int']['input']>;
  share_type?: InputMaybe<ShareGrantType>;
  share_value_at_acquisition?: InputMaybe<CurrencyModelInput>;
  shares_purchased?: InputMaybe<Scalars['Int']['input']>;
  user_modified_data?: InputMaybe<Scalars['Boolean']['input']>;
  verification_status?: InputMaybe<VerificationStatus>;
  vested_quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTaxWithholdingsOptionRelationInputType = {
  amount?: InputMaybe<CurrencyModelInput>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  user_modified_data?: InputMaybe<Scalars['Boolean']['input']>;
  verification_status?: InputMaybe<VerificationStatus>;
};

/** All Us Schenarios Defined */
export type UsScenarios = {
  __typename?: 'UsScenarios';
  amt_crossover_scenario?: Maybe<AmtCrossOverScenario>;
  estimated_payout_scenario?: Maybe<Scenario>;
  exercise_on_exit_scenario?: Maybe<ExcerciseOnExitScenario>;
  secfi_financing_scenario?: Maybe<SecfiFinancingScenario>;
};

/** Required information from a user to compute US taxes */
export type UsTaxInformation = {
  __typename?: 'UsTaxInformation';
  additional_deductions: CurrencyModel;
  additional_income: CurrencyModel;
  age: Scalars['Float']['output'];
  charitable_contributions: CurrencyModel;
  customer: Customer;
  dependents: Scalars['Float']['output'];
  dividend_income: CurrencyModel;
  filing_type: FilingType;
  four01k_contributions: CurrencyModel;
  income: CurrencyModel;
  ira_contribution: CurrencyModel;
  long_term_capital_gains: CurrencyModel;
  medical_expenses: CurrencyModel;
  mortgage_interest: CurrencyModel;
  net_rental_real_estate_income: CurrencyModel;
  property_state_local_taxes: CurrencyModel;
  retirement_plan: Scalars['Boolean']['output'];
  self_employment_income: CurrencyModel;
  short_term_capital_gains: CurrencyModel;
  uuid: Scalars['ID']['output'];
};

export type UsTaxInformationBaseInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  age?: InputMaybe<Scalars['Float']['input']>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  filing_type?: InputMaybe<Scalars['String']['input']>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortgage_interest?: InputMaybe<CurrencyModelInput>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  retirement_plan?: InputMaybe<Scalars['Float']['input']>;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type UsTaxInformationInput = {
  additional_deductions?: InputMaybe<CurrencyModelInput>;
  additional_income?: InputMaybe<CurrencyModelInput>;
  age?: InputMaybe<Scalars['Float']['input']>;
  charitable_contributions?: InputMaybe<CurrencyModelInput>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  dependents?: InputMaybe<Scalars['Float']['input']>;
  dividend_income?: InputMaybe<CurrencyModelInput>;
  filing_type?: InputMaybe<Scalars['String']['input']>;
  four01k_contributions?: InputMaybe<CurrencyModelInput>;
  income?: InputMaybe<CurrencyModelInput>;
  ira_contribution?: InputMaybe<CurrencyModelInput>;
  long_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  medical_expenses?: InputMaybe<CurrencyModelInput>;
  mortgage_interest?: InputMaybe<CurrencyModelInput>;
  net_rental_real_estate_income?: InputMaybe<CurrencyModelInput>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  property_state_local_taxes?: InputMaybe<CurrencyModelInput>;
  retirement_plan?: InputMaybe<Scalars['Float']['input']>;
  self_employment_income?: InputMaybe<CurrencyModelInput>;
  short_term_capital_gains?: InputMaybe<CurrencyModelInput>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

/** Parent model for all types of users - mostly for authorization purposes */
export type User = {
  __typename?: 'User';
  account_source?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  date_joined?: Maybe<Scalars['String']['output']>;
  deletion_feedback?: Maybe<Scalars['String']['output']>;
  deletion_reason?: Maybe<UserDeletionReason>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  initials: Scalars['String']['output'];
  is_admin?: Maybe<Scalars['Boolean']['output']>;
  is_google_sso?: Maybe<Scalars['Boolean']['output']>;
  is_superuser?: Maybe<Scalars['Boolean']['output']>;
  is_verified?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum UserDeletionReason {
  BadExperience = 'bad_experience',
  NoLongerInterested = 'no_longer_interested',
  NotUsefulInMySituation = 'not_useful_in_my_situation',
  Other = 'other',
  ReduceDigitalFootprint = 'reduce_digital_footprint',
  TestAccount = 'test_account',
}

export type UserIdentification = {
  __typename?: 'UserIdentification';
  token: Scalars['String']['output'];
};

/** Data displayed in the admin users page */
export type UserRow = {
  __typename?: 'UserRow';
  affiliation_uuid?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_uuid?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  customer_uuid?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equity_value?: Maybe<CurrencyModel>;
  full_name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  preferred_share_value?: Maybe<CurrencyModel>;
  total_options?: Maybe<Scalars['Float']['output']>;
  total_rsus?: Maybe<Scalars['Float']['output']>;
  total_shares?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_uuid?: Maybe<Scalars['String']['output']>;
};

export enum UserTriageAnswers {
  AddDetailsLater = 'AddDetailsLater',
  AddEquityManually = 'AddEquityManually',
  ExerciseMyOption = 'ExerciseMyOption',
  FinancingProposal = 'FinancingProposal',
  InterestedInFinancing = 'InterestedInFinancing',
  JustBrowsing = 'JustBrowsing',
  JustCurious = 'JustCurious',
  KeepBrowsing = 'KeepBrowsing',
  LearnMore = 'LearnMore',
  OwnShares = 'OwnShares',
  TalkThroughMyOptions = 'TalkThroughMyOptions',
  UsingCarta = 'UsingCarta',
}

export type UsersListResponse = {
  __typename?: 'UsersListResponse';
  nodes: Array<UserRow>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export enum VerificationStatus {
  Approved = 'Approved',
  Available = 'Available',
  Feedback = 'Feedback',
  Submitted = 'Submitted',
}

/** Pitchbook vertical reference */
export type Vertical = {
  __typename?: 'Vertical';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type VestedOptionsCalculation = {
  __typename?: 'VestedOptionsCalculation';
  vestedOptions: Scalars['Int']['output'];
};

export type VestedOptionsCalculationInput = {
  byDate: Scalars['Date']['input'];
  /** The cliff in months. None means that vesting starts after first vesting_frequency passed */
  cliff?: InputMaybe<Scalars['Int']['input']>;
  employmentEndDate?: InputMaybe<Scalars['Date']['input']>;
  /** The overall number of options in the grant */
  quantity: Scalars['Int']['input'];
  /** The vesting frequency in months */
  vestingFrequency: Scalars['Int']['input'];
  /** The period of vesting in months */
  vestingPeriod: Scalars['Int']['input'];
  vestingStartDate: Scalars['Date']['input'];
};

export type VestingScheduleEvent = {
  __typename?: 'VestingScheduleEvent';
  /** The date when a vesting event occurs. */
  eventDate: Scalars['Date']['output'];
  /** The quantity vested at date. */
  quantity: Scalars['Int']['output'];
  /** ISO or NSO in the case of stock option grants, RSU in case of rsu grants, empty for others */
  type?: Maybe<GrantType>;
};

export type VestingScheduleEventInput = {
  /** The date when a vesting event occurs. */
  eventDate: Scalars['Date']['input'];
  /** The quantity vested at date. */
  quantity: Scalars['Int']['input'];
  /** ISO or NSO in the case of stock option grants, RSU in case of rsu grants, empty for others */
  type?: InputMaybe<GrantType>;
};

export type VestingScheduleTemplate = {
  __typename?: 'VestingScheduleTemplate';
  /** The cliff in months. None means that vesting starts after first vesting_frequency passed */
  cliff?: Maybe<Scalars['Int']['output']>;
  /** The vesting frequency in months */
  vestingFrequency: Scalars['Int']['output'];
  /** The period of vesting in months */
  vestingPeriod: Scalars['Int']['output'];
};

export type VestingScheduleTemplateInput = {
  /** The cliff in months. None means that vesting starts after first vesting_frequency passed */
  cliff?: InputMaybe<Scalars['Int']['input']>;
  /** The vesting frequency in months */
  vestingFrequency: Scalars['Int']['input'];
  /** The period of vesting in months */
  vestingPeriod: Scalars['Int']['input'];
};

/** Financial file the customer must sign for us to create a valid track of their trust wishes */
export type W9Form = {
  __typename?: 'W9Form';
  docusign_id?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  financing_object: FinancingObject;
  status: Scalars['String']['output'];
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type W9FormInput = {
  docusign_id?: InputMaybe<Scalars['ID']['input']>;
  feedback?: InputMaybe<Array<InputMaybe<FeedbackInput>>>;
  financing_object?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type ClientChecklistQueries = {
  __typename?: 'clientChecklistQueries';
  byRIADeal?: Maybe<ClientChecklist>;
};

export type ClientChecklistQueriesByRiaDealArgs = {
  riaDeal: Scalars['UUID']['input'];
};

export type RiaDealMutations = {
  __typename?: 'riaDealMutations';
  cancel: Scalars['Boolean']['output'];
  completePayment?: Maybe<RiaDeal>;
  create: RiaDeal;
  moveToStatus: RiaDeal;
  moveToWealthIntroCall: RiaDeal;
};

export type RiaDealMutationsCancelArgs = {
  riaDeal: Scalars['UUID']['input'];
};

export type RiaDealMutationsCompletePaymentArgs = {
  riaDeal: Scalars['UUID']['input'];
};

export type RiaDealMutationsCreateArgs = {
  customer: Scalars['UUID']['input'];
  product: RiaDealProduct;
  productCategory: RiaDealProductCategory;
  questionnaireAnswers?: InputMaybe<Array<RiaDealQuestionnaireAnswerInput>>;
  signedUpWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  signedUpWithWaitlist?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<RiaDealSource>;
};

export type RiaDealMutationsMoveToStatusArgs = {
  riaDeal: Scalars['UUID']['input'];
  status: RiaDealStatus;
  substatus: RiaDealSubstatus;
};

export type RiaDealMutationsMoveToWealthIntroCallArgs = {
  customer: Scalars['UUID']['input'];
};

export type RiaDealQueries = {
  __typename?: 'riaDealQueries';
  activeByUser?: Maybe<RiaDeal>;
};

export type RiaDealQueriesActiveByUserArgs = {
  user: Scalars['UUID']['input'];
};

export type UpdateSecondariesRequestInput = {
  issuer_allows_sale?: InputMaybe<IssuerAllowsSale>;
  offer_intent?: InputMaybe<SecondariesOfferIntent>;
  offer_price?: InputMaybe<Scalars['Float']['input']>;
  shares_available_for_sale?: InputMaybe<Scalars['Int']['input']>;
};

export type LatestPublicPriceForDateQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  ticker: Scalars['String']['input'];
  pricingDate: Scalars['CalendarDate']['input'];
}>;

export type LatestPublicPriceForDateQuery = {
  __typename?: 'Query';
  latestPublicPriceForDate?: {
    __typename?: 'PublicPrice';
    ticker: string;
    price: number;
  } | null;
};

export type ProcessCartaSignupCallbackMutationVariables = Exact<{
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
}>;

export type ProcessCartaSignupCallbackMutation = {
  __typename?: 'Mutation';
  processSignUpWithCartaCallback: {
    __typename?: 'CartaPasswordlessSignupResponse';
    uuid: any;
    refreshToken: string;
    accessToken: string;
    duration: number;
    token: string;
  };
};

export type CreateAccountPasswordlessMutationVariables = Exact<{
  payload?: InputMaybe<SignUpPasswordlessInput>;
}>;

export type CreateAccountPasswordlessMutation = {
  __typename?: 'Mutation';
  createAccountPasswordless?: {
    __typename?: 'SignUpResponse';
    refresh_token?: string | null;
    access_token?: string | null;
    token?: string | null;
    duration?: number | null;
    uuid?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    username?: string | null;
    customer?: string | null;
    is_verified?: boolean | null;
  } | null;
};

export type UserIdentificationQueryVariables = Exact<{
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserIdentificationQuery = {
  __typename?: 'Query';
  userIdentification: { __typename?: 'UserIdentification'; token: string };
};

export type GetMockProposalQueryVariables = Exact<{
  affiliationUuid: Scalars['ID']['input'];
  exitMultiplier: Scalars['Float']['input'];
}>;

export type GetMockProposalQuery = {
  __typename?: 'Query';
  mockProposal?: {
    __typename?: 'MockProposal';
    collateral_shares?: number | null;
    total_selected_options?: number | null;
    total_selected_shares?: number | null;
    exercise_cost?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    potential_liquidity?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_advance?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_fees?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    exit_scenarios?: {
      __typename?: 'MockExitScenario';
      net_gain_exercise_now?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      net_gain_wait_until_exit?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
  } | null;
};

type IBankAccountInformation_BankAccountInformation_Fragment = {
  __typename?: 'BankAccountInformation';
  uuid?: string | null;
  status: string;
  beneficiary_name?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  account_number?: string | null;
  routing_number?: string | null;
  swift_code?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

type IBankAccountInformation_CustomerBankAccountInformation_Fragment = {
  __typename?: 'CustomerBankAccountInformation';
  uuid?: string | null;
  status: string;
  beneficiary_name?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  account_number?: string | null;
  routing_number?: string | null;
  swift_code?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type IBankAccountInformationFragment =
  | IBankAccountInformation_BankAccountInformation_Fragment
  | IBankAccountInformation_CustomerBankAccountInformation_Fragment;

export type BankAccountInformationFragment = {
  __typename?: 'BankAccountInformation';
  uuid?: string | null;
  status: string;
  beneficiary_name?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  account_number?: string | null;
  routing_number?: string | null;
  swift_code?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type CustomerBankAccountInformationFragment = {
  __typename?: 'CustomerBankAccountInformation';
  contact_name?: string | null;
  contact_email?: string | null;
  contact_phone_number?: string | null;
  uuid?: string | null;
  status: string;
  beneficiary_name?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  account_number?: string | null;
  routing_number?: string | null;
  swift_code?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetBankAccountInformationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetBankAccountInformationQuery = {
  __typename?: 'Query';
  bankAccountInformation?: {
    __typename?: 'BankAccountInformation';
    uuid?: string | null;
    status: string;
    beneficiary_name?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    account_number?: string | null;
    routing_number?: string | null;
    swift_code?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdateBankAccountInformationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: BankAccountInformationInput;
}>;

export type CreateOrUpdateBankAccountInformationMutation = {
  __typename?: 'Mutation';
  bankAccountInformation?: {
    __typename?: 'BankAccountInformation';
    uuid?: string | null;
    status: string;
    beneficiary_name?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    account_number?: string | null;
    routing_number?: string | null;
    swift_code?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type GetCustomerBankAccountInformationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetCustomerBankAccountInformationQuery = {
  __typename?: 'Query';
  CustomerBankAccountInformation?: {
    __typename?: 'CustomerBankAccountInformation';
    contact_name?: string | null;
    contact_email?: string | null;
    contact_phone_number?: string | null;
    uuid?: string | null;
    status: string;
    beneficiary_name?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    account_number?: string | null;
    routing_number?: string | null;
    swift_code?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdateCustomerBankAccountInformationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: CustomerBankAccountInformationInput;
}>;

export type CreateOrUpdateCustomerBankAccountInformationMutation = {
  __typename?: 'Mutation';
  CustomerBankAccountInformation?: {
    __typename?: 'CustomerBankAccountInformation';
    contact_name?: string | null;
    contact_email?: string | null;
    contact_phone_number?: string | null;
    uuid?: string | null;
    status: string;
    beneficiary_name?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    account_number?: string | null;
    routing_number?: string | null;
    swift_code?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ComplianceInformationFinancingFragment = {
  __typename?: 'ComplianceInformationFinancing';
  uuid?: string | null;
  employment?: string | null;
  occupation?: string | null;
  employer?: string | null;
  employer_address?: string | null;
  employer_business?: string | null;
  position?: string | null;
  years_in_position?: number | null;
  net_worth?: string | null;
  is_broker_dealer?: boolean | null;
  is_related_to_broker_dealer?: boolean | null;
  is_financial_industry_regulatory_authority?: boolean | null;
  is_political_officer?: boolean | null;
  is_senior_officer?: boolean | null;
  is_fund_member?: boolean | null;
  other?: string | null;
  status: string;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetComplianceInformationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetComplianceInformationQuery = {
  __typename?: 'Query';
  complianceInformation?: {
    __typename?: 'ComplianceInformationFinancing';
    uuid?: string | null;
    employment?: string | null;
    occupation?: string | null;
    employer?: string | null;
    employer_address?: string | null;
    employer_business?: string | null;
    position?: string | null;
    years_in_position?: number | null;
    net_worth?: string | null;
    is_broker_dealer?: boolean | null;
    is_related_to_broker_dealer?: boolean | null;
    is_financial_industry_regulatory_authority?: boolean | null;
    is_political_officer?: boolean | null;
    is_senior_officer?: boolean | null;
    is_fund_member?: boolean | null;
    other?: string | null;
    status: string;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdateComplianceInformationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: ComplianceInformationFinancingInput;
}>;

export type CreateOrUpdateComplianceInformationMutation = {
  __typename?: 'Mutation';
  complianceInformation?: {
    __typename?: 'ComplianceInformationFinancing';
    uuid?: string | null;
    employment?: string | null;
    occupation?: string | null;
    employer?: string | null;
    employer_address?: string | null;
    employer_business?: string | null;
    position?: string | null;
    years_in_position?: number | null;
    net_worth?: string | null;
    is_broker_dealer?: boolean | null;
    is_related_to_broker_dealer?: boolean | null;
    is_financial_industry_regulatory_authority?: boolean | null;
    is_political_officer?: boolean | null;
    is_senior_officer?: boolean | null;
    is_fund_member?: boolean | null;
    other?: string | null;
    status: string;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type EquityVerificationFragment = {
  __typename?: 'EquityVerification';
  uuid?: string | null;
  financing_object: string;
  status: FormModelStatus;
};

export type GetEquityVerificationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetEquityVerificationQuery = {
  __typename?: 'Query';
  equityVerification?: {
    __typename?: 'EquityVerification';
    uuid?: string | null;
    financing_object: string;
    status: FormModelStatus;
  } | null;
};

export type CreateOrUpdateEquityVerificationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: EquityVerificationInput;
}>;

export type CreateOrUpdateEquityVerificationMutation = {
  __typename?: 'Mutation';
  equityVerification?: {
    __typename?: 'EquityVerification';
    uuid?: string | null;
    financing_object: string;
    status: FormModelStatus;
  } | null;
};

export type FinancingOptionGrantsFragment = {
  __typename?: 'FinancingOptionGrant';
  uuid?: any | null;
  equity_verification?: any | null;
  exercisable_quantity?: number | null;
  financing_object?: any | null;
  grant_date?: string | null;
  name?: string | null;
  option_type?: OptionGrantType | null;
  owner?: any | null;
  quantity?: number | null;
  selected_quantity?: number | null;
  verification_status?: VerificationStatus | null;
  exercise_cost?: {
    __typename?: 'Currency';
    currency?: string | null;
    value?: string | null;
  } | null;
  file?: {
    __typename?: 'EquityVerificationFile';
    file_name: string;
    file_type: string;
    url: string;
    uuid: any;
  } | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
  strike_price?: {
    __typename?: 'Currency';
    currency?: string | null;
    value?: string | null;
  } | null;
};

export type GetFinancingOptionGrantsQueryVariables = Exact<{
  financing_object: Scalars['UUID']['input'];
}>;

export type GetFinancingOptionGrantsQuery = {
  __typename?: 'Query';
  financingOptionGrants?: Array<{
    __typename?: 'FinancingOptionGrant';
    uuid?: any | null;
    equity_verification?: any | null;
    exercisable_quantity?: number | null;
    financing_object?: any | null;
    grant_date?: string | null;
    name?: string | null;
    option_type?: OptionGrantType | null;
    owner?: any | null;
    quantity?: number | null;
    selected_quantity?: number | null;
    verification_status?: VerificationStatus | null;
    exercise_cost?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
    file?: {
      __typename?: 'EquityVerificationFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: any;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
      created_at?: string | null;
      created_by?: string | null;
    } | null> | null;
    strike_price?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
  } | null> | null;
};

export type UpdateFinancingOptionGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: UpdateFinancingOptionGrantInputType;
}>;

export type UpdateFinancingOptionGrantMutation = {
  __typename?: 'Mutation';
  financingOptionGrants?: {
    __typename?: 'FinancingOptionGrant';
    uuid?: any | null;
    equity_verification?: any | null;
    exercisable_quantity?: number | null;
    financing_object?: any | null;
    grant_date?: string | null;
    name?: string | null;
    option_type?: OptionGrantType | null;
    owner?: any | null;
    quantity?: number | null;
    selected_quantity?: number | null;
    verification_status?: VerificationStatus | null;
    exercise_cost?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
    file?: {
      __typename?: 'EquityVerificationFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: any;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
      created_at?: string | null;
      created_by?: string | null;
    } | null> | null;
    strike_price?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
  } | null;
};

export type FinancingShareGrantsFragment = {
  __typename?: 'FinancingShareGrant';
  uuid?: any | null;
  equity_verification?: any | null;
  financing_object?: any | null;
  date_of_acquisition?: string | null;
  name?: string | null;
  owner?: any | null;
  selected_quantity?: number | null;
  shares_purchased?: number | null;
  share_type?: ShareGrantType | null;
  verification_status?: VerificationStatus | null;
  vested_quantity?: number | null;
  file?: {
    __typename?: 'EquityVerificationFile';
    file_name: string;
    file_type: string;
    url: string;
    uuid: any;
  } | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
  share_value_at_acquisition?: {
    __typename?: 'Currency';
    currency?: string | null;
    value?: string | null;
  } | null;
};

export type GetFinancingShareGrantsQueryVariables = Exact<{
  financing_object: Scalars['UUID']['input'];
}>;

export type GetFinancingShareGrantsQuery = {
  __typename?: 'Query';
  financingShareGrants?: Array<{
    __typename?: 'FinancingShareGrant';
    uuid?: any | null;
    equity_verification?: any | null;
    financing_object?: any | null;
    date_of_acquisition?: string | null;
    name?: string | null;
    owner?: any | null;
    selected_quantity?: number | null;
    shares_purchased?: number | null;
    share_type?: ShareGrantType | null;
    verification_status?: VerificationStatus | null;
    vested_quantity?: number | null;
    file?: {
      __typename?: 'EquityVerificationFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: any;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
      created_at?: string | null;
      created_by?: string | null;
    } | null> | null;
    share_value_at_acquisition?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
  } | null> | null;
};

export type UpdateFinancingShareGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: UpdateFinancingShareGrantInputType;
}>;

export type UpdateFinancingShareGrantMutation = {
  __typename?: 'Mutation';
  financingShareGrants?: {
    __typename?: 'FinancingShareGrant';
    uuid?: any | null;
    equity_verification?: any | null;
    financing_object?: any | null;
    date_of_acquisition?: string | null;
    name?: string | null;
    owner?: any | null;
    selected_quantity?: number | null;
    shares_purchased?: number | null;
    share_type?: ShareGrantType | null;
    verification_status?: VerificationStatus | null;
    vested_quantity?: number | null;
    file?: {
      __typename?: 'EquityVerificationFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: any;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
      created_at?: string | null;
      created_by?: string | null;
    } | null> | null;
    share_value_at_acquisition?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
  } | null;
};

type FinancingFormModel_Contract_Fragment = {
  __typename?: 'Contract';
  uuid?: string | null;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
};

type FinancingFormModel_PrivateSecuritiesContract_Fragment = {
  __typename?: 'PrivateSecuritiesContract';
  uuid?: string | null;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
};

type FinancingFormModel_SpringingLienAssignmentAgreement_Fragment = {
  __typename?: 'SpringingLienAssignmentAgreement';
  uuid?: string | null;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
};

type FinancingFormModel_SpringingLienPsc_Fragment = {
  __typename?: 'SpringingLienPSC';
  uuid?: string | null;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
};

type FinancingFormModel_SpringingLienStockPledge_Fragment = {
  __typename?: 'SpringingLienStockPledge';
  uuid?: string | null;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null> | null;
};

export type FinancingFormModelFragment =
  | FinancingFormModel_Contract_Fragment
  | FinancingFormModel_PrivateSecuritiesContract_Fragment
  | FinancingFormModel_SpringingLienAssignmentAgreement_Fragment
  | FinancingFormModel_SpringingLienPsc_Fragment
  | FinancingFormModel_SpringingLienStockPledge_Fragment;

export type IdentificationInformationFragment = {
  __typename?: 'IdentificationInformation';
  uuid?: string | null;
  status: string;
  files?: Array<{
    __typename?: 'IdentificationFile';
    uuid: string;
    file_name: string;
    file_type: string;
    url: string;
  } | null> | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetIdentificationInformationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetIdentificationInformationQuery = {
  __typename?: 'Query';
  identificationInformation?: {
    __typename?: 'IdentificationInformation';
    uuid?: string | null;
    status: string;
    files?: Array<{
      __typename?: 'IdentificationFile';
      uuid: string;
      file_name: string;
      file_type: string;
      url: string;
    } | null> | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdateIdentificationInformationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: IdentificationInformationInput;
}>;

export type CreateOrUpdateIdentificationInformationMutation = {
  __typename?: 'Mutation';
  identificationInformation?: {
    __typename?: 'IdentificationInformation';
    uuid?: string | null;
    status: string;
    files?: Array<{
      __typename?: 'IdentificationFile';
      uuid: string;
      file_name: string;
      file_type: string;
      url: string;
    } | null> | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type StartKycVerificationMutationVariables = Exact<{
  financingObjectUuid: Scalars['UUID']['input'];
}>;

export type StartKycVerificationMutation = {
  __typename?: 'Mutation';
  kycUser?: {
    __typename?: 'KYCInquiryCreationType';
    inquiry_id?: string | null;
    session_token?: string | null;
  } | null;
};

export type EndKycVerificationMutationVariables = Exact<{
  financingObjectUuid: Scalars['UUID']['input'];
  inquiry_id: Scalars['UUID']['input'];
}>;

export type EndKycVerificationMutation = {
  __typename?: 'Mutation';
  endKYC?: {
    __typename?: 'KYCInquiryEndType';
    financing_object?: any | null;
    identification_form_verification_status: VerificationStatus;
    inquiry_id?: any | null;
  } | null;
};

export type IntermediaryServicesAgreementFragment = {
  __typename?: 'IntermediaryServicesAgreement';
  uuid?: string | null;
  status: string;
  docusign_id?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetIntermediaryServicesAgreementQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetIntermediaryServicesAgreementQuery = {
  __typename?: 'Query';
  intermediaryServicesAgreement?: {
    __typename?: 'IntermediaryServicesAgreement';
    uuid?: string | null;
    status: string;
    docusign_id?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type PersonalInformationFinancingFragment = {
  __typename?: 'PersonalInformationFinancing';
  uuid?: string | null;
  legal_name?: string | null;
  date_of_birth?: any | null;
  citizenship?: string | null;
  country_of_tax_residence?: string | null;
  social_security?: string | null;
  taxpayer_id?: string | null;
  marital_status?: MaritalStatus | null;
  physical_address?: string | null;
  mailing_address?: string | null;
  phone_number?: string | null;
  preferred_email?: string | null;
  status: string;
  address_street_1?: string | null;
  address_street_2?: string | null;
  postal_code?: string | null;
  subdivision?: string | null;
  country?: string | null;
  city?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetPersonalInformationQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetPersonalInformationQuery = {
  __typename?: 'Query';
  personalInformation?: {
    __typename?: 'PersonalInformationFinancing';
    uuid?: string | null;
    legal_name?: string | null;
    date_of_birth?: any | null;
    citizenship?: string | null;
    country_of_tax_residence?: string | null;
    social_security?: string | null;
    taxpayer_id?: string | null;
    marital_status?: MaritalStatus | null;
    physical_address?: string | null;
    mailing_address?: string | null;
    phone_number?: string | null;
    preferred_email?: string | null;
    status: string;
    address_street_1?: string | null;
    address_street_2?: string | null;
    postal_code?: string | null;
    subdivision?: string | null;
    country?: string | null;
    city?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type GetPersonalInformationCitizenshipOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPersonalInformationCitizenshipOptionsQuery = {
  __typename?: 'Query';
  options?: {
    __typename?: 'FormOptions';
    countries?: Array<{
      __typename?: 'BasicFormOption';
      label?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdatePersonalInformationMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: PersonalInformationFinancingInput;
}>;

export type CreateOrUpdatePersonalInformationMutation = {
  __typename?: 'Mutation';
  personalInformation?: {
    __typename?: 'PersonalInformationFinancing';
    uuid?: string | null;
    legal_name?: string | null;
    date_of_birth?: any | null;
    citizenship?: string | null;
    country_of_tax_residence?: string | null;
    social_security?: string | null;
    taxpayer_id?: string | null;
    marital_status?: MaritalStatus | null;
    physical_address?: string | null;
    mailing_address?: string | null;
    phone_number?: string | null;
    preferred_email?: string | null;
    status: string;
    address_street_1?: string | null;
    address_street_2?: string | null;
    postal_code?: string | null;
    subdivision?: string | null;
    country?: string | null;
    city?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type TaxWithholdingsFragment = {
  __typename?: 'TaxWithholdings';
  uuid?: string | null;
  amount_type: TaxWithholdingsAmountType;
  financing_object: string;
  status: FormModelStatus;
  feedback?: Array<{
    __typename?: 'Feedback';
    created_at?: string | null;
    created_by?: string | null;
    message?: string | null;
  } | null> | null;
  file?: {
    __typename?: 'TaxWithholdingsFile';
    file_name: string;
    file_type: string;
    url: string;
    uuid: string;
  } | null;
  financing_option_relations: Array<{
    __typename?: 'TaxWithholdingsOptionRelation';
    uuid?: any | null;
    verification_status: VerificationStatus;
    amount?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      created_at?: string | null;
      created_by?: string | null;
      message?: string | null;
    } | null> | null;
    file?: {
      __typename?: 'TaxWithholdingsFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: string;
    } | null;
    financing_option_grant: {
      __typename?: 'FinancingOptionGrant';
      uuid?: any | null;
      option_type?: OptionGrantType | null;
      selected_quantity?: number | null;
      strike_price?: {
        __typename?: 'Currency';
        currency?: string | null;
        value?: string | null;
      } | null;
    };
  }>;
  single_amount?: {
    __typename?: 'CurrencyModel';
    currency: string;
    value: string;
  } | null;
};

export type TaxWithholdingsOptionRelationFragment = {
  __typename?: 'TaxWithholdingsOptionRelation';
  uuid?: any | null;
  verification_status: VerificationStatus;
  amount?: {
    __typename?: 'Currency';
    currency?: string | null;
    value?: string | null;
  } | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    created_at?: string | null;
    created_by?: string | null;
    message?: string | null;
  } | null> | null;
  file?: {
    __typename?: 'TaxWithholdingsFile';
    file_name: string;
    file_type: string;
    url: string;
    uuid: string;
  } | null;
  financing_option_grant: {
    __typename?: 'FinancingOptionGrant';
    uuid?: any | null;
    option_type?: OptionGrantType | null;
    selected_quantity?: number | null;
    strike_price?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
  };
};

export type GetTaxWithholdingsQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetTaxWithholdingsQuery = {
  __typename?: 'Query';
  taxWithholdings?: {
    __typename?: 'TaxWithholdings';
    uuid?: string | null;
    amount_type: TaxWithholdingsAmountType;
    financing_object: string;
    status: FormModelStatus;
    feedback?: Array<{
      __typename?: 'Feedback';
      created_at?: string | null;
      created_by?: string | null;
      message?: string | null;
    } | null> | null;
    file?: {
      __typename?: 'TaxWithholdingsFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: string;
    } | null;
    financing_option_relations: Array<{
      __typename?: 'TaxWithholdingsOptionRelation';
      uuid?: any | null;
      verification_status: VerificationStatus;
      amount?: {
        __typename?: 'Currency';
        currency?: string | null;
        value?: string | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      file?: {
        __typename?: 'TaxWithholdingsFile';
        file_name: string;
        file_type: string;
        url: string;
        uuid: string;
      } | null;
      financing_option_grant: {
        __typename?: 'FinancingOptionGrant';
        uuid?: any | null;
        option_type?: OptionGrantType | null;
        selected_quantity?: number | null;
        strike_price?: {
          __typename?: 'Currency';
          currency?: string | null;
          value?: string | null;
        } | null;
      };
    }>;
    single_amount?: {
      __typename?: 'CurrencyModel';
      currency: string;
      value: string;
    } | null;
  } | null;
};

export type UpdateTaxWithholdingsMutationVariables = Exact<{
  financing_object: Scalars['String']['input'];
  payload: TaxWithholdingsInput;
}>;

export type UpdateTaxWithholdingsMutation = {
  __typename?: 'Mutation';
  taxWithholdings?: {
    __typename?: 'TaxWithholdings';
    uuid?: string | null;
    amount_type: TaxWithholdingsAmountType;
    financing_object: string;
    status: FormModelStatus;
    feedback?: Array<{
      __typename?: 'Feedback';
      created_at?: string | null;
      created_by?: string | null;
      message?: string | null;
    } | null> | null;
    file?: {
      __typename?: 'TaxWithholdingsFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: string;
    } | null;
    financing_option_relations: Array<{
      __typename?: 'TaxWithholdingsOptionRelation';
      uuid?: any | null;
      verification_status: VerificationStatus;
      amount?: {
        __typename?: 'Currency';
        currency?: string | null;
        value?: string | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      file?: {
        __typename?: 'TaxWithholdingsFile';
        file_name: string;
        file_type: string;
        url: string;
        uuid: string;
      } | null;
      financing_option_grant: {
        __typename?: 'FinancingOptionGrant';
        uuid?: any | null;
        option_type?: OptionGrantType | null;
        selected_quantity?: number | null;
        strike_price?: {
          __typename?: 'Currency';
          currency?: string | null;
          value?: string | null;
        } | null;
      };
    }>;
    single_amount?: {
      __typename?: 'CurrencyModel';
      currency: string;
      value: string;
    } | null;
  } | null;
};

export type UpdateTaxWithholdingsOptionRelationMutationVariables = Exact<{
  financing_option_grant_uuid: Scalars['UUID']['input'];
  payload: UpdateTaxWithholdingsOptionRelationInputType;
}>;

export type UpdateTaxWithholdingsOptionRelationMutation = {
  __typename?: 'Mutation';
  taxWithholdings?: {
    __typename?: 'TaxWithholdingsOptionRelation';
    uuid?: any | null;
    verification_status: VerificationStatus;
    amount?: {
      __typename?: 'Currency';
      currency?: string | null;
      value?: string | null;
    } | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      created_at?: string | null;
      created_by?: string | null;
      message?: string | null;
    } | null> | null;
    file?: {
      __typename?: 'TaxWithholdingsFile';
      file_name: string;
      file_type: string;
      url: string;
      uuid: string;
    } | null;
    financing_option_grant: {
      __typename?: 'FinancingOptionGrant';
      uuid?: any | null;
      option_type?: OptionGrantType | null;
      selected_quantity?: number | null;
      strike_price?: {
        __typename?: 'Currency';
        currency?: string | null;
        value?: string | null;
      } | null;
    };
  } | null;
};

export type W9FormFragment = {
  __typename?: 'W9Form';
  uuid?: string | null;
  status: string;
  docusign_id?: string | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    message?: string | null;
  } | null> | null;
};

export type GetW9FormQueryVariables = Exact<{
  financing_object: Scalars['String']['input'];
}>;

export type GetW9FormQuery = {
  __typename?: 'Query';
  w9Form?: {
    __typename?: 'W9Form';
    uuid?: string | null;
    status: string;
    docusign_id?: string | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type FinancingCategoryStatusFragment = {
  __typename?: 'FinancingCategoryStatus';
  has_feedback: boolean;
  status: FormModelStatus;
  name: FinancingFormCategory;
};

export type GenericContractFragment = {
  __typename?: 'Contract';
  uuid?: string | null;
  status: FormModelStatus;
  client_signed_at?: string | null;
  file?: { __typename?: 'ContractFile'; url?: string | null } | null;
};

export type ProposalRequestFragmentFragment = {
  __typename?: 'ProposalRequest';
  uuid: string;
  proposal_reason?: Array<ProposalRequestReason | null> | null;
  shares_advance: string;
  comment?: string | null;
  date_submitted?: string | null;
  exercise_date?: any | null;
  filing_type?: FilingType | null;
  dependents?: number | null;
  retirement_plan?: boolean | null;
  country?: string | null;
  subdivision?: string | null;
  city?: string | null;
  collateral_shares?: number | null;
  advance_to_equity_ratio?: number | null;
  affiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    name?: string | null;
    period?: any | null;
    company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      verified: boolean;
    } | null;
    company: {
      __typename?: 'Company';
      uuid: string;
      name: string;
      company_status?: CompanyStatusGql | null;
      company_substatus?: CompanySubstatusGql | null;
    };
    customer: {
      __typename?: 'Customer';
      uuid: string;
      phone_number?: string | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        username: string;
        first_name?: string | null;
        last_name?: string | null;
      } | null;
    };
  } | null;
  shares_advance_amount?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  user_share_price?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  four01k_contributions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  property_state_local_taxes?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  mortage_interest?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  charitable_contributions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  ira_contribution?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  additional_deductions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  self_employment_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  dividend_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  short_term_capital_gains?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  net_rental_real_estate_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  long_term_capital_gains?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  additional_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  medical_expenses?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  option_grants: Array<{
    __typename?: 'SelectedOptionGrant';
    excluded?: boolean | null;
    name?: string | null;
    option_type: OptionGrantType;
    quantity: number;
    selected_quantity: number;
    start_date?: any | null;
    uuid?: string | null;
    strike_price: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
  }>;
  four09a_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  preferred_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  share_grants: Array<{
    __typename?: 'SelectedShareGrant';
    uuid?: string | null;
    quantity: number;
    selected_quantity: number;
    excluded?: boolean | null;
    share_type?: string | null;
    start_date?: any | null;
    share_value_at_acquisition?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  }>;
  collateral_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  total_financing_need?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  created_by?: { __typename?: 'User'; uuid: string; username: string } | null;
};

export type RequestProposalRegenerationMutationVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;

export type RequestProposalRegenerationMutation = {
  __typename?: 'Mutation';
  requestProposalRegeneration?: {
    __typename?: 'ProposalRegenerationResponse';
    uuid: string;
    regeneration_requested?: boolean | null;
  } | null;
};

export type SelectedOptionGrantFragmentFragment = {
  __typename?: 'SelectedOptionGrant';
  excluded?: boolean | null;
  name?: string | null;
  option_type: OptionGrantType;
  quantity: number;
  selected_quantity: number;
  start_date?: any | null;
  uuid?: string | null;
  strike_price: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
};

export type SelectedShareGrantFragmentFragment = {
  __typename?: 'SelectedShareGrant';
  uuid?: string | null;
  quantity: number;
  selected_quantity: number;
  excluded?: boolean | null;
  share_type?: string | null;
  start_date?: any | null;
  share_value_at_acquisition?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
};

export type TaxBreakdownFragmentFragment = {
  __typename?: 'TaxBreakdown';
  amt: number;
  capital_gains_tax: number;
  income_tax: number;
  medicare_tax: number;
  net_investment_income_tax: number;
  social_security_tax: number;
  state_amt: number;
  state_income_tax: number;
  total_owed: number;
};

export type ConfirmAcceptProposalMutationVariables = Exact<{
  financingObjectUuid: Scalars['ID']['input'];
  proposalUuid: Scalars['ID']['input'];
}>;

export type ConfirmAcceptProposalMutation = {
  __typename?: 'Mutation';
  acceptProposal?: {
    __typename?: 'FinancingObject';
    uuid: string;
    status: FinancingStatus;
    document_storage_url?: string | null;
    intake_call_timestamp?: string | null;
    intake_call_invitee_id?: string | null;
    intake_call_uuid?: string | null;
    is_intake_call_cancelled?: boolean | null;
    last_seen?: string | null;
    owner?: string | null;
    products_interested?: Array<ProductsOffered> | null;
    proposal_call_timestamp?: string | null;
    proposal_call_invitee_id?: string | null;
    proposal_call_uuid?: string | null;
    is_proposal_call_cancelled?: boolean | null;
    signed_nda?: string | null;
    substatus: FinancingSubstatus;
    is_legacy_onboarding?: boolean | null;
    date_proposal_accepted?: string | null;
    selected_proposal?: {
      __typename?: 'Proposal';
      uuid: string;
      collateral_shares?: number | null;
      advance_rate?: number | null;
      stock_fee?: number | null;
      hash_id?: string | null;
      issued_date?: string | null;
      name?: string | null;
      published: boolean;
      expiration_date?: string | null;
      is_expired: boolean;
      regeneration_requested?: boolean | null;
      proposal_reason: Array<string>;
      advance_provided?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      rampup_fees?: Array<{
        __typename?: 'RampSchedule';
        equity_rate: number;
        month: number;
      }> | null;
    } | null;
    advisor?: {
      __typename?: 'Advisor';
      uuid: string;
      avatar?: string | null;
      full_name?: string | null;
      phone?: string | null;
      public_email?: string | null;
      title?: string | null;
      bio?: string | null;
      calendly_intake_call_link?: string | null;
      calendly_proposal_call_link?: string | null;
      calendly_wealth_insights_call_link?: string | null;
      calendly_advisory_insights_call_link?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
    } | null;
  } | null;
};

export type CreateProposalRequestMutationVariables = Exact<{
  payload: ProposalRequestCreateInput;
}>;

export type CreateProposalRequestMutation = {
  __typename?: 'Mutation';
  proposalRequest?: {
    __typename?: 'ProposalRequest';
    uuid: string;
    proposal_reason?: Array<ProposalRequestReason | null> | null;
    shares_advance: string;
    comment?: string | null;
    date_submitted?: string | null;
    exercise_date?: any | null;
    filing_type?: FilingType | null;
    dependents?: number | null;
    retirement_plan?: boolean | null;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    collateral_shares?: number | null;
    advance_to_equity_ratio?: number | null;
    affiliation?: {
      __typename?: 'Affiliation';
      uuid: string;
      name?: string | null;
      period?: any | null;
      company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        verified: boolean;
      } | null;
      company: {
        __typename?: 'Company';
        uuid: string;
        name: string;
        company_status?: CompanyStatusGql | null;
        company_substatus?: CompanySubstatusGql | null;
      };
      customer: {
        __typename?: 'Customer';
        uuid: string;
        phone_number?: string | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          username: string;
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      };
    } | null;
    shares_advance_amount?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    user_share_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four01k_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    property_state_local_taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    mortage_interest?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    charitable_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    ira_contribution?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_deductions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    self_employment_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    dividend_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    short_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    net_rental_real_estate_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    long_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    medical_expenses?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    option_grants: Array<{
      __typename?: 'SelectedOptionGrant';
      excluded?: boolean | null;
      name?: string | null;
      option_type: OptionGrantType;
      quantity: number;
      selected_quantity: number;
      start_date?: any | null;
      uuid?: string | null;
      strike_price: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    share_grants: Array<{
      __typename?: 'SelectedShareGrant';
      uuid?: string | null;
      quantity: number;
      selected_quantity: number;
      excluded?: boolean | null;
      share_type?: string | null;
      start_date?: any | null;
      share_value_at_acquisition?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
    collateral_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_financing_need?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    created_by?: { __typename?: 'User'; uuid: string; username: string } | null;
  } | null;
};

export type InitContractSigningMutationVariables = Exact<{
  payload: SignContractInputType;
  uuid: Scalars['UUID']['input'];
}>;

export type InitContractSigningMutation = {
  __typename?: 'Mutation';
  initContractSigning?: {
    __typename?: 'SignContract';
    docusign_url?: string | null;
  } | null;
};

export type ProcessScheduledCallMutationVariables = Exact<{
  financingObjectUuid: Scalars['ID']['input'];
  calendlyEventUri: Scalars['String']['input'];
  inviteeUri: Scalars['String']['input'];
  callType: CallType;
  scheduledAppointmentUuid: Scalars['ID']['input'];
}>;

export type ProcessScheduledCallMutation = {
  __typename?: 'Mutation';
  processScheduledCall?: {
    __typename?: 'FinancingObject';
    uuid: string;
    status: FinancingStatus;
    document_storage_url?: string | null;
    intake_call_timestamp?: string | null;
    intake_call_invitee_id?: string | null;
    intake_call_uuid?: string | null;
    is_intake_call_cancelled?: boolean | null;
    last_seen?: string | null;
    owner?: string | null;
    products_interested?: Array<ProductsOffered> | null;
    proposal_call_timestamp?: string | null;
    proposal_call_invitee_id?: string | null;
    proposal_call_uuid?: string | null;
    is_proposal_call_cancelled?: boolean | null;
    signed_nda?: string | null;
    substatus: FinancingSubstatus;
    is_legacy_onboarding?: boolean | null;
    date_proposal_accepted?: string | null;
    advisor?: {
      __typename?: 'Advisor';
      uuid: string;
      avatar?: string | null;
      full_name?: string | null;
      phone?: string | null;
      public_email?: string | null;
      title?: string | null;
      bio?: string | null;
      calendly_intake_call_link?: string | null;
      calendly_proposal_call_link?: string | null;
      calendly_wealth_insights_call_link?: string | null;
      calendly_advisory_insights_call_link?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
    } | null;
  } | null;
};

export type SubmitFinancingOnboardingFormMutationVariables = Exact<{
  financing_object_uuid: Scalars['String']['input'];
  form_name: Scalars['String']['input'];
}>;

export type SubmitFinancingOnboardingFormMutation = {
  __typename?: 'Mutation';
  submitForm?: boolean | null;
};

export type UpdateProposalRequestMutationVariables = Exact<{
  payload: ProposalRequestInput;
  uuid: Scalars['String']['input'];
}>;

export type UpdateProposalRequestMutation = {
  __typename?: 'Mutation';
  proposalRequest?: {
    __typename?: 'ProposalRequest';
    uuid: string;
    proposal_reason?: Array<ProposalRequestReason | null> | null;
    shares_advance: string;
    comment?: string | null;
    date_submitted?: string | null;
    exercise_date?: any | null;
    filing_type?: FilingType | null;
    dependents?: number | null;
    retirement_plan?: boolean | null;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    collateral_shares?: number | null;
    advance_to_equity_ratio?: number | null;
    affiliation?: {
      __typename?: 'Affiliation';
      uuid: string;
      name?: string | null;
      period?: any | null;
      company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        verified: boolean;
      } | null;
      company: {
        __typename?: 'Company';
        uuid: string;
        name: string;
        company_status?: CompanyStatusGql | null;
        company_substatus?: CompanySubstatusGql | null;
      };
      customer: {
        __typename?: 'Customer';
        uuid: string;
        phone_number?: string | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          username: string;
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      };
    } | null;
    shares_advance_amount?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    user_share_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four01k_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    property_state_local_taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    mortage_interest?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    charitable_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    ira_contribution?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_deductions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    self_employment_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    dividend_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    short_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    net_rental_real_estate_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    long_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    medical_expenses?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    option_grants: Array<{
      __typename?: 'SelectedOptionGrant';
      excluded?: boolean | null;
      name?: string | null;
      option_type: OptionGrantType;
      quantity: number;
      selected_quantity: number;
      start_date?: any | null;
      uuid?: string | null;
      strike_price: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    share_grants: Array<{
      __typename?: 'SelectedShareGrant';
      uuid?: string | null;
      quantity: number;
      selected_quantity: number;
      excluded?: boolean | null;
      share_type?: string | null;
      start_date?: any | null;
      share_value_at_acquisition?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
    collateral_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_financing_need?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    created_by?: { __typename?: 'User'; uuid: string; username: string } | null;
  } | null;
};

export type GetSeriesContractQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetSeriesContractQuery = {
  __typename?: 'Query';
  seriesContract?: {
    __typename?: 'Contract';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: { __typename?: 'ContractFile'; url?: string | null } | null;
  } | null;
};

export type GetInvestmentDirectionLetterQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetInvestmentDirectionLetterQuery = {
  __typename?: 'Query';
  investmentDirectionLetter?: {
    __typename?: 'Contract';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: { __typename?: 'ContractFile'; url?: string | null } | null;
  } | null;
};

export type GetSpringingLienAssignmentAgreementQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetSpringingLienAssignmentAgreementQuery = {
  __typename?: 'Query';
  SpringingLienAssignmentAgreement?: {
    __typename?: 'SpringingLienAssignmentAgreement';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: {
      __typename?: 'SpringingLienAssignmentAgreementFile';
      url?: string | null;
    } | null;
  } | null;
};

export type GetSpringingLienPscQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetSpringingLienPscQuery = {
  __typename?: 'Query';
  SpringingLienPSC?: {
    __typename?: 'SpringingLienPSC';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: { __typename?: 'SpringingLienPSCFile'; url?: string | null } | null;
  } | null;
};

export type GetSpringingLienStockPledgeQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetSpringingLienStockPledgeQuery = {
  __typename?: 'Query';
  SpringingLienStockPledge?: {
    __typename?: 'SpringingLienStockPledge';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: {
      __typename?: 'SpringingLienStockPledgeFile';
      url?: string | null;
    } | null;
  } | null;
};

export type GetPrivateSecuritiesContractQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetPrivateSecuritiesContractQuery = {
  __typename?: 'Query';
  privateSecuritiesContract?: {
    __typename?: 'PrivateSecuritiesContract';
    uuid?: string | null;
    status: FormModelStatus;
    client_signed_at?: string | null;
    file?: {
      __typename?: 'PrivateSecuritiesContractFile';
      url?: string | null;
    } | null;
  } | null;
};

export type GetFinancingCategoryStatusesQueryVariables = Exact<{
  financingObjectUuid: Scalars['String']['input'];
}>;

export type GetFinancingCategoryStatusesQuery = {
  __typename?: 'Query';
  financingCategoryStatuses?: Array<{
    __typename?: 'FinancingCategoryStatus';
    has_feedback: boolean;
    status: FormModelStatus;
    name: FinancingFormCategory;
  } | null> | null;
};

export type GetProposalQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetProposalQuery = {
  __typename?: 'Query';
  proposal?: {
    __typename?: 'Proposal';
    uuid: string;
    issued_date?: string | null;
    published: boolean;
    is_expired: boolean;
    regeneration_requested?: boolean | null;
    name?: string | null;
    proposal_reason: Array<string>;
    country?: string | null;
    subdivision?: string | null;
    filing_type?: FilingType | null;
    dependents?: number | null;
    platform_fee_percentage?: number | null;
    collateral_shares?: number | null;
    advance_to_equity_ratio?: number | null;
    stock_fee?: number | null;
    advance_rate?: number | null;
    net_stock_fee?: number | null;
    net_advance_rate?: number | null;
    expected_exit_date?: string | null;
    show_proposal_breakdown?: boolean | null;
    expiration_date?: string | null;
    options_to_exercise?: number | null;
    show_waiting_for_ipo_comparison?: boolean | null;
    one_year_peg?: boolean | null;
    is_trust_fee_included?: boolean | null;
    income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    exercise_need?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    exercise_cost?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    platform_fee?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    collateral_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    value_per_share?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    advance_provided?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    average_strike_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_financing_need?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    liquidity?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    rampup_fees?: Array<{
      __typename?: 'RampSchedule';
      equity_rate: number;
      month: number;
    }> | null;
    net_rampup_fees?: Array<{
      __typename?: 'RampSchedule';
      equity_rate: number;
      month: number;
    }> | null;
    interpolated_rampup_fees?: Array<{
      __typename?: 'RampSchedule';
      equity_rate: number;
      month: number;
    }> | null;
    expected_exit_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    trust_setup_fee?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    trust_closing_fee?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    trust_monthly_administrative_fee?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    net_rate_components?: {
      __typename?: 'NetRateComponents';
      capital_gains_tax_rate?: number | null;
      state_capital_gains_tax_rate?: number | null;
      net_investment_income_tax_rate?: number | null;
    } | null;
    option_grants: Array<{
      __typename?: 'SelectedOptionGrant';
      excluded?: boolean | null;
      name?: string | null;
      option_type: OptionGrantType;
      quantity: number;
      selected_quantity: number;
      start_date?: any | null;
      uuid?: string | null;
      strike_price: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
    share_grants: Array<{
      __typename?: 'SelectedShareGrant';
      uuid?: string | null;
      quantity: number;
      selected_quantity: number;
      excluded?: boolean | null;
      share_type?: string | null;
      start_date?: any | null;
      share_value_at_acquisition?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
    proposal_request: { __typename?: 'ProposalRequest'; uuid: string };
  } | null;
};

export type GetProposalExitComparisonQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
  payload: ProposalTaxDetailsInput;
}>;

export type GetProposalExitComparisonQuery = {
  __typename?: 'Query';
  tax?: {
    __typename?: 'ProposalTaxDetails';
    exit_comparison?: {
      __typename?: 'ExitComparison';
      exit_scenarios: Array<{
        __typename?: 'ExitScenario';
        share_price_multiplier: number;
        share_price: number;
        valuation?: number | null;
        secfi_scenario_borrower_returns: number;
        cashless_borrower_returns: number;
        difference_nominal: number;
        difference_percentage: number;
      }>;
    } | null;
  } | null;
};

export type GetProposalRequestQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetProposalRequestQuery = {
  __typename?: 'Query';
  proposalRequest?: {
    __typename?: 'ProposalRequest';
    uuid: string;
    proposal_reason?: Array<ProposalRequestReason | null> | null;
    shares_advance: string;
    comment?: string | null;
    date_submitted?: string | null;
    exercise_date?: any | null;
    filing_type?: FilingType | null;
    dependents?: number | null;
    retirement_plan?: boolean | null;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    collateral_shares?: number | null;
    advance_to_equity_ratio?: number | null;
    affiliation?: {
      __typename?: 'Affiliation';
      uuid: string;
      name?: string | null;
      period?: any | null;
      company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        verified: boolean;
      } | null;
      company: {
        __typename?: 'Company';
        uuid: string;
        name: string;
        company_status?: CompanyStatusGql | null;
        company_substatus?: CompanySubstatusGql | null;
      };
      customer: {
        __typename?: 'Customer';
        uuid: string;
        phone_number?: string | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          username: string;
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      };
    } | null;
    shares_advance_amount?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    user_share_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four01k_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    property_state_local_taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    mortage_interest?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    charitable_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    ira_contribution?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_deductions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    self_employment_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    dividend_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    short_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    net_rental_real_estate_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    long_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    medical_expenses?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    option_grants: Array<{
      __typename?: 'SelectedOptionGrant';
      excluded?: boolean | null;
      name?: string | null;
      option_type: OptionGrantType;
      quantity: number;
      selected_quantity: number;
      start_date?: any | null;
      uuid?: string | null;
      strike_price: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    share_grants: Array<{
      __typename?: 'SelectedShareGrant';
      uuid?: string | null;
      quantity: number;
      selected_quantity: number;
      excluded?: boolean | null;
      share_type?: string | null;
      start_date?: any | null;
      share_value_at_acquisition?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
    collateral_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    total_financing_need?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    created_by?: { __typename?: 'User'; uuid: string; username: string } | null;
  } | null;
};

export type GetProposalTaxDetailsQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
  payload: ProposalTaxDetailsInput;
}>;

export type GetProposalTaxDetailsQuery = {
  __typename?: 'Query';
  tax?: {
    __typename?: 'ProposalTaxDetails';
    self_fund_scenario: {
      __typename?: 'Scenario';
      exercise_tax_breakdown?: {
        __typename?: 'TaxBreakdown';
        amt: number;
        capital_gains_tax: number;
        income_tax: number;
        medicare_tax: number;
        net_investment_income_tax: number;
        social_security_tax: number;
        state_amt: number;
        state_income_tax: number;
        total_owed: number;
      } | null;
    };
    secfi_financing_scenario: {
      __typename?: 'SecfiFinancingScenario';
      collateral_shares?: number | null;
      borrower_returns?: number | null;
      exit_equity_value?: number | null;
      financing_plus_interest?: number | null;
      compounded_interest?: number | null;
      investor_fees?: number | null;
      investor_returns?: number | null;
      long_term_capital_gains_on_exit_from_shares?: number | null;
      long_term_capital_gains_on_exit_from_contract?: number | null;
      long_term_capital_gains_on_exit?: number | null;
      total_stock_fee?: number | null;
      exit_tax_details?: {
        __typename?: 'TaxDetails';
        total_capital_gains_tax?: number | null;
        long_term_capital_gains?: number | null;
        effective_tax_rates?: {
          __typename?: 'EffectiveTaxRates';
          state_capital_gains_tax?: number | null;
          net_investment_income_tax?: number | null;
          capital_gains_tax?: number | null;
          total_capital_gains_tax?: number | null;
        } | null;
      } | null;
      exit_tax_breakdown?: {
        __typename?: 'TaxBreakdown';
        amt: number;
        capital_gains_tax: number;
        income_tax: number;
        medicare_tax: number;
        net_investment_income_tax: number;
        social_security_tax: number;
        state_amt: number;
        state_income_tax: number;
        total_owed: number;
      } | null;
    };
    trust_details: {
      __typename?: 'TrustDetails';
      trust_setup_fee?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      trust_closing_fee?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      trust_monthly_administrative_fee?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      trust_fees_between_today_and_exit?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      trust_monthly_fees_until_exit?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    };
    after_tax_returns?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    cash_left_over_on_exit?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    exercise_on_exit_scenario: {
      __typename?: 'Scenario';
      total_exercise_cost?: number | null;
      total_tax_owed_on_exercise?: number | null;
      total_tax_owed_on_exit?: number | null;
      borrower_returns?: number | null;
    };
  } | null;
};

export type FinancingFormStatusFragment = {
  __typename?: 'FinancingFormStatus';
  category?: FinancingFormCategory | null;
  name: string;
  status: FormModelStatus;
  uuid?: string | null;
  form_grouping?: DataCaptureFormGroups | null;
  feedback?: Array<{ __typename?: 'Feedback'; message?: string | null }> | null;
};

export type AddSecondariesRequestMutationVariables = Exact<{
  input: SecondariesRequestInput;
}>;

export type AddSecondariesRequestMutation = {
  __typename?: 'Mutation';
  addSecondariesRequest?: {
    __typename?: 'SecondariesRequest';
    uuid: string;
    shares_available_for_sale: number;
    offer_price?: number | null;
    offer_intent: SecondariesOfferIntent;
    issuer_allows_sale: IssuerAllowsSale;
  } | null;
};

export type UpdateFinancingObjectMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  payload: FinancingObjectUpdateInput;
}>;

export type UpdateFinancingObjectMutation = {
  __typename?: 'Mutation';
  updateFinancingObject?: {
    __typename?: 'FinancingObject';
    uuid: string;
    last_seen?: string | null;
  } | null;
};

export type UpdateSecondariesRequestMutationVariables = Exact<{
  payload: UpdateSecondariesRequestInput;
  uuid: Scalars['UUID']['input'];
}>;

export type UpdateSecondariesRequestMutation = {
  __typename?: 'Mutation';
  updateSecondariesRequest?: {
    __typename?: 'SecondariesRequest';
    uuid: string;
    shares_available_for_sale: number;
    offer_price?: number | null;
    offer_intent: SecondariesOfferIntent;
    issuer_allows_sale: IssuerAllowsSale;
    affiliation: string;
  } | null;
};

export type GetFinancingBasicInfoQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetFinancingBasicInfoQuery = {
  __typename?: 'Query';
  financingObject?: {
    __typename?: 'FinancingObject';
    uuid: string;
    status: FinancingStatus;
    document_storage_url?: string | null;
    intake_call_timestamp?: string | null;
    intake_call_invitee_id?: string | null;
    intake_call_uuid?: string | null;
    is_intake_call_cancelled?: boolean | null;
    last_seen?: string | null;
    owner?: string | null;
    products_interested?: Array<ProductsOffered> | null;
    proposal_call_timestamp?: string | null;
    proposal_call_invitee_id?: string | null;
    proposal_call_uuid?: string | null;
    is_proposal_call_cancelled?: boolean | null;
    signed_nda?: string | null;
    substatus: FinancingSubstatus;
    is_legacy_onboarding?: boolean | null;
    date_proposal_accepted?: string | null;
    selected_proposal?: {
      __typename?: 'Proposal';
      uuid: string;
      collateral_shares?: number | null;
      advance_rate?: number | null;
      stock_fee?: number | null;
      advance_provided?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
    advisor?: {
      __typename?: 'Advisor';
      uuid: string;
      avatar?: string | null;
      full_name?: string | null;
      phone?: string | null;
      public_email?: string | null;
      title?: string | null;
      bio?: string | null;
      calendly_intake_call_link?: string | null;
      calendly_proposal_call_link?: string | null;
      calendly_wealth_insights_call_link?: string | null;
      calendly_advisory_insights_call_link?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
    } | null;
  } | null;
};

export type GetFinancingFormStatusesQueryVariables = Exact<{
  financingObjectUuid: Scalars['String']['input'];
}>;

export type GetFinancingFormStatusesQuery = {
  __typename?: 'Query';
  financingFormStatuses?: Array<{
    __typename?: 'FinancingFormStatus';
    category?: FinancingFormCategory | null;
    name: string;
    status: FormModelStatus;
    uuid?: string | null;
    form_grouping?: DataCaptureFormGroups | null;
    feedback?: Array<{
      __typename?: 'Feedback';
      message?: string | null;
    }> | null;
  } | null> | null;
};

export type GetFinancingSummaryQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetFinancingSummaryQuery = {
  __typename?: 'Query';
  financingObject?: {
    __typename?: 'FinancingObject';
    step_reason_group?: StepReasonGroup | null;
    uuid: string;
    status: FinancingStatus;
    document_storage_url?: string | null;
    intake_call_timestamp?: string | null;
    intake_call_invitee_id?: string | null;
    intake_call_uuid?: string | null;
    is_intake_call_cancelled?: boolean | null;
    last_seen?: string | null;
    owner?: string | null;
    products_interested?: Array<ProductsOffered> | null;
    proposal_call_timestamp?: string | null;
    proposal_call_invitee_id?: string | null;
    proposal_call_uuid?: string | null;
    is_proposal_call_cancelled?: boolean | null;
    signed_nda?: string | null;
    substatus: FinancingSubstatus;
    is_legacy_onboarding?: boolean | null;
    date_proposal_accepted?: string | null;
    proposal_request: {
      __typename?: 'ProposalRequest';
      uuid: string;
      exercise_date?: any | null;
      comment?: string | null;
      collateral_shares?: number | null;
      option_grants: Array<{
        __typename?: 'SelectedOptionGrant';
        start_date?: any | null;
        name?: string | null;
        option_type: OptionGrantType;
        selected_quantity: number;
        quantity: number;
        strike_price: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      }>;
      affiliation?: {
        __typename?: 'Affiliation';
        uuid: string;
        company: {
          __typename?: 'Company';
          uuid: string;
          logo?: string | null;
          name: string;
          legal_name?: string | null;
          cap_table_verified?: boolean | null;
          is_fast_track?: boolean | null;
        };
        customer: {
          __typename?: 'Customer';
          uuid: string;
          phone_number?: string | null;
          user?: {
            __typename?: 'User';
            uuid: string;
            email: string;
            first_name?: string | null;
            last_name?: string | null;
            fullName: string;
            initials: string;
            username: string;
          } | null;
        };
      } | null;
    };
    selected_proposal?: {
      __typename?: 'Proposal';
      contract_type?: ContractType | null;
      uuid: string;
      collateral_shares?: number | null;
      advance_rate?: number | null;
      stock_fee?: number | null;
      hash_id?: string | null;
      issued_date?: string | null;
      name?: string | null;
      published: boolean;
      expiration_date?: string | null;
      is_expired: boolean;
      regeneration_requested?: boolean | null;
      proposal_reason: Array<string>;
      advance_provided?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      rampup_fees?: Array<{
        __typename?: 'RampSchedule';
        equity_rate: number;
        month: number;
      }> | null;
    } | null;
    advisor?: {
      __typename?: 'Advisor';
      uuid: string;
      avatar?: string | null;
      full_name?: string | null;
      phone?: string | null;
      public_email?: string | null;
      title?: string | null;
      bio?: string | null;
      calendly_intake_call_link?: string | null;
      calendly_proposal_call_link?: string | null;
      calendly_wealth_insights_call_link?: string | null;
      calendly_advisory_insights_call_link?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
    } | null;
  } | null;
};

export type GetProposalsListSummaryQueryVariables = Exact<{
  filters?: InputMaybe<ProposalInput>;
}>;

export type GetProposalsListSummaryQuery = {
  __typename?: 'Query';
  proposals?: {
    __typename?: 'AllProposalsResponse';
    nodes: Array<{
      __typename?: 'Proposal';
      uuid: string;
      collateral_shares?: number | null;
      advance_rate?: number | null;
      stock_fee?: number | null;
      hash_id?: string | null;
      issued_date?: string | null;
      name?: string | null;
      published: boolean;
      expiration_date?: string | null;
      is_expired: boolean;
      regeneration_requested?: boolean | null;
      proposal_reason: Array<string>;
      advance_provided?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      rampup_fees?: Array<{
        __typename?: 'RampSchedule';
        equity_rate: number;
        month: number;
      }> | null;
    }>;
  } | null;
};

export type GetAllSecondariesRequestsQueryVariables = Exact<{
  user: Scalars['UUID']['input'];
}>;

export type GetAllSecondariesRequestsQuery = {
  __typename?: 'Query';
  getAllSecondariesRequests: Array<{
    __typename?: 'SecondariesRequest';
    uuid: string;
    shares_available_for_sale: number;
    offer_price?: number | null;
    offer_intent: SecondariesOfferIntent;
    issuer_allows_sale: IssuerAllowsSale;
    affiliation: string;
  } | null>;
};

export type GetGraphAndTableForAssetTypeQueryVariables = Exact<{
  customerId: Scalars['UUID']['input'];
  assetType: AssetType;
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetGraphAndTableForAssetTypeQuery = {
  __typename?: 'Query';
  assetTypeOverview: {
    __typename?: 'AssetTypeOverview';
    allocationTable: {
      __typename?: 'AllocationTable';
      totalValue: number;
      items: Array<{
        __typename?: 'AllocationTableEntry';
        type: AssetType;
        name?: string | null;
        logo?: string | null;
        value: number;
        weight: number;
        quantity?: number | null;
        companyUuid?: any | null;
        errors?: Array<{
          __typename?: 'PortfolioError';
          code: PortfolioErrorCode;
          details: { __typename?: 'GenericErrorDetails'; message: string };
        }> | null;
      }>;
    };
    graph: {
      __typename?: 'PortfolioGraph';
      totalValue: Array<{
        __typename?: 'TimelinePoint';
        timestamp: any;
        value: number;
      }>;
      markers: Array<
        | {
            __typename: 'GraphMarkerCliff';
            timestamp: any;
            company_name: string;
            grant_type: string;
            percentage_vested: number;
            vested: number;
          }
        | {
            __typename: 'GraphMarkerEndOfEmployment';
            timestamp: any;
            company_name: string;
          }
        | {
            __typename: 'GraphMarkerEndOfVesting';
            timestamp: any;
            company_name: string;
            grant_type: string;
            vested: number;
          }
        | {
            __typename: 'GraphMarkerFirstHolding';
            timestamp: any;
            institution_name: string;
          }
        | {
            __typename: 'GraphMarkerFundingRound';
            timestamp: any;
            company_name: string;
            round_name: string;
          }
        | { __typename: 'GraphMarkerIPO'; timestamp: any; company_name: string }
        | {
            __typename: 'GraphMarkerStockSplit';
            timestamp: any;
            company_name: string;
            split_ratio: string;
          }
      >;
    };
  };
};

export type GetSyncStatusForAssetTypeQueryVariables = Exact<{
  customerId: Scalars['UUID']['input'];
  assetType: AssetType;
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetSyncStatusForAssetTypeQuery = {
  __typename?: 'Query';
  assetTypeOverview: {
    __typename?: 'AssetTypeOverview';
    syncStatus: PortfolioSyncStatus;
    willNotifyReady: boolean;
  };
};

export type ExchangePublicTokenMutationVariables = Exact<{
  customerUuid: Scalars['UUID']['input'];
  publicToken: Scalars['String']['input'];
}>;

export type ExchangePublicTokenMutation = {
  __typename?: 'Mutation';
  exchangePublicToken: boolean;
};

export type GetLinkTokenMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
}>;

export type GetLinkTokenMutation = { __typename?: 'Mutation'; token: string };

export type GetLinkTokenForUpdateMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
}>;

export type GetLinkTokenForUpdateMutation = {
  __typename?: 'Mutation';
  token: string;
};

export type EquityGraphFragment = {
  __typename?: 'EquityGraph';
  totalPrefValueLine: Array<{
    __typename?: 'TimelinePoint';
    timestamp: any;
    value: number;
  }>;
  totalPublicValueLine: Array<{
    __typename?: 'TimelinePoint';
    timestamp: any;
    value: number;
  }>;
  vestedPrefValueLine: Array<{
    __typename?: 'TimelinePoint';
    timestamp: any;
    value: number;
  }>;
  vestedPublicValueLine: Array<{
    __typename?: 'TimelinePoint';
    timestamp: any;
    value: number;
  }>;
  errors?: Array<{
    __typename?: 'PortfolioError';
    code: PortfolioErrorCode;
    details: { __typename?: 'GenericErrorDetails'; message: string };
  }> | null;
  markers: Array<
    | {
        __typename: 'GraphMarkerCliff';
        timestamp: any;
        company_name: string;
        grant_type: string;
        percentage_vested: number;
        vested: number;
      }
    | {
        __typename: 'GraphMarkerEndOfEmployment';
        timestamp: any;
        company_name: string;
      }
    | {
        __typename: 'GraphMarkerEndOfVesting';
        timestamp: any;
        company_name: string;
        grant_type: string;
        vested: number;
      }
    | {
        __typename: 'GraphMarkerFirstHolding';
        timestamp: any;
        institution_name: string;
      }
    | {
        __typename: 'GraphMarkerFundingRound';
        timestamp: any;
        company_name: string;
        round_name: string;
      }
    | { __typename: 'GraphMarkerIPO'; timestamp: any; company_name: string }
    | {
        __typename: 'GraphMarkerStockSplit';
        timestamp: any;
        company_name: string;
        split_ratio: string;
      }
  >;
};

export type GetPortfolioPrivateEquityGraphQueryVariables = Exact<{
  customerUuid: Scalars['UUID']['input'];
  affiliationId: Scalars['UUID']['input'];
}>;

export type GetPortfolioPrivateEquityGraphQuery = {
  __typename?: 'Query';
  affiliationGraph: {
    __typename?: 'EquityGraph';
    totalPrefValueLine: Array<{
      __typename?: 'TimelinePoint';
      timestamp: any;
      value: number;
    }>;
    totalPublicValueLine: Array<{
      __typename?: 'TimelinePoint';
      timestamp: any;
      value: number;
    }>;
    vestedPrefValueLine: Array<{
      __typename?: 'TimelinePoint';
      timestamp: any;
      value: number;
    }>;
    vestedPublicValueLine: Array<{
      __typename?: 'TimelinePoint';
      timestamp: any;
      value: number;
    }>;
    errors?: Array<{
      __typename?: 'PortfolioError';
      code: PortfolioErrorCode;
      details: { __typename?: 'GenericErrorDetails'; message: string };
    }> | null;
    markers: Array<
      | {
          __typename: 'GraphMarkerCliff';
          timestamp: any;
          company_name: string;
          grant_type: string;
          percentage_vested: number;
          vested: number;
        }
      | {
          __typename: 'GraphMarkerEndOfEmployment';
          timestamp: any;
          company_name: string;
        }
      | {
          __typename: 'GraphMarkerEndOfVesting';
          timestamp: any;
          company_name: string;
          grant_type: string;
          vested: number;
        }
      | {
          __typename: 'GraphMarkerFirstHolding';
          timestamp: any;
          institution_name: string;
        }
      | {
          __typename: 'GraphMarkerFundingRound';
          timestamp: any;
          company_name: string;
          round_name: string;
        }
      | { __typename: 'GraphMarkerIPO'; timestamp: any; company_name: string }
      | {
          __typename: 'GraphMarkerStockSplit';
          timestamp: any;
          company_name: string;
          split_ratio: string;
        }
    >;
  };
};

export type GetInstitutionsListQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
}>;

export type GetInstitutionsListQuery = {
  __typename?: 'Query';
  customerInstitutions?: Array<{
    __typename?: 'Institution';
    institution_id: string;
    last_snapshot_date?: any | null;
    last_updated?: any | null;
    logo?: string | null;
    name: string;
    status: Status;
    website?: string | null;
  } | null> | null;
};

export type UnlinkInstitutionMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
}>;

export type UnlinkInstitutionMutation = {
  __typename?: 'Mutation';
  unlinkInstitution: boolean;
};

export type RelinkInstitutionMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
  institution: Scalars['String']['input'];
}>;

export type RelinkInstitutionMutation = {
  __typename?: 'Mutation';
  relinkInstitution: boolean;
};

export type GetAllFinancingObjectsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<GenericOrderInput>;
  filters?: InputMaybe<FinancingObjectFilterInput>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
}>;

export type GetAllFinancingObjectsQuery = {
  __typename?: 'Query';
  financingObjects?: {
    __typename?: 'AllFinancingObjects';
    nodes: Array<{
      __typename?: 'FinancingObject';
      uuid: string;
      substatus: FinancingSubstatus;
      status: FinancingStatus;
      last_seen?: string | null;
      advisor?: {
        __typename?: 'Advisor';
        uuid: string;
        avatar?: string | null;
        full_name?: string | null;
        phone?: string | null;
        public_email?: string | null;
        title?: string | null;
        bio?: string | null;
        calendly_intake_call_link?: string | null;
        calendly_proposal_call_link?: string | null;
        calendly_wealth_insights_call_link?: string | null;
        calendly_advisory_insights_call_link?: string | null;
        linkedin?: string | null;
        twitter?: string | null;
      } | null;
      proposal_request: {
        __typename?: 'ProposalRequest';
        uuid: string;
        date_submitted?: string | null;
        option_grants: Array<{
          __typename?: 'SelectedOptionGrant';
          excluded?: boolean | null;
          name?: string | null;
          option_type: OptionGrantType;
          quantity: number;
          selected_quantity: number;
          start_date?: any | null;
          uuid?: string | null;
          strike_price: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          };
        }>;
        share_grants: Array<{
          __typename?: 'SelectedShareGrant';
          uuid?: string | null;
          quantity: number;
          selected_quantity: number;
          excluded?: boolean | null;
          share_type?: string | null;
          start_date?: any | null;
          share_value_at_acquisition?: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          } | null;
        }>;
        affiliation?: {
          __typename?: 'Affiliation';
          uuid: string;
          company: {
            __typename?: 'Company';
            uuid: string;
            name: string;
            is_fast_track?: boolean | null;
            logo?: string | null;
          };
        } | null;
      };
    }>;
  } | null;
};

export type GetPortfolioOverviewQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetPortfolioOverviewQuery = {
  __typename?: 'Query';
  totalNetWorth: {
    __typename?: 'TotalNetWorthPortfolioView';
    hasWealthPotential: boolean;
    lastManagedHoldingsSnapshotDate?: any | null;
    privateAssetsLatestPrefPriceDate?: any | null;
    graph: {
      __typename?: 'PortfolioGraph';
      dailyGranularityStartDate?: any | null;
      totalValue: Array<{
        __typename?: 'TimelinePoint';
        timestamp: any;
        value: number;
      }>;
      markers: Array<
        | {
            __typename: 'GraphMarkerCliff';
            timestamp: any;
            company_name: string;
            grant_type: string;
            percentage_vested: number;
            vested: number;
          }
        | {
            __typename: 'GraphMarkerEndOfEmployment';
            timestamp: any;
            company_name: string;
          }
        | {
            __typename: 'GraphMarkerEndOfVesting';
            timestamp: any;
            company_name: string;
            grant_type: string;
            vested: number;
          }
        | {
            __typename: 'GraphMarkerFirstHolding';
            timestamp: any;
            institution_name: string;
          }
        | {
            __typename: 'GraphMarkerFundingRound';
            timestamp: any;
            company_name: string;
            round_name: string;
          }
        | { __typename: 'GraphMarkerIPO'; timestamp: any; company_name: string }
        | {
            __typename: 'GraphMarkerStockSplit';
            timestamp: any;
            company_name: string;
            split_ratio: string;
          }
      >;
    };
  };
  customerInstitutions?: Array<{
    __typename?: 'Institution';
    institution_id: string;
    last_snapshot_date?: any | null;
    last_updated?: any | null;
    logo?: string | null;
    name: string;
    status: Status;
    website?: string | null;
  } | null> | null;
};

export type GetPortfolioOverviewAllocationTableQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetPortfolioOverviewAllocationTableQuery = {
  __typename?: 'Query';
  totalNetWorth: {
    __typename?: 'TotalNetWorthPortfolioView';
    allocationTable: {
      __typename?: 'AllocationTable';
      totalValue: number;
      items: Array<{
        __typename?: 'AllocationTableEntry';
        type: AssetType;
        name?: string | null;
        logo?: string | null;
        value: number;
        weight: number;
        quantity?: number | null;
        companyUuid?: any | null;
        errors?: Array<{
          __typename?: 'PortfolioError';
          code: PortfolioErrorCode;
          details: { __typename?: 'GenericErrorDetails'; message: string };
        }> | null;
      }>;
    };
  };
};

export type GetPortfolioOverviewSyncStatusQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetPortfolioOverviewSyncStatusQuery = {
  __typename?: 'Query';
  totalNetWorth: {
    __typename?: 'TotalNetWorthPortfolioView';
    syncStatus: PortfolioSyncStatus;
    willNotifyReady: boolean;
  };
};

export type PersonalReferralCodeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PersonalReferralCodeQuery = {
  __typename?: 'Query';
  personalReferralCode: string;
};

export type RequestAccountDeletionMutationVariables = Exact<{
  feedback?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
}>;

export type RequestAccountDeletionMutation = {
  __typename?: 'Mutation';
  requestDeletion?: boolean | null;
};

export type ConfirmAccountDeletionMutationVariables = Exact<{
  token: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
}>;

export type ConfirmAccountDeletionMutation = {
  __typename?: 'Mutation';
  deleteUser?: boolean | null;
};

export type MoveToPlanPhaseMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type MoveToPlanPhaseMutation = {
  __typename?: 'Mutation';
  EP_moveToPlanPhase: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetExerciseLaterProjectionDataMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  data: EpExerciseLaterProjectionDataInput;
}>;

export type EpSetExerciseLaterProjectionDataMutation = {
  __typename?: 'Mutation';
  EP_setExerciseLaterProjectionData: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetExerciseNowEarlyDataMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  data: EpExerciseNowEarlyDataInput;
}>;

export type EpSetExerciseNowEarlyDataMutation = {
  __typename?: 'Mutation';
  EP_setExerciseNowEarlyData: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSelectOptionGrantsMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  grantUuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type EpSelectOptionGrantsMutation = {
  __typename?: 'Mutation';
  EP_selectOptionGrants: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSelectShareGrantsMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  grantUuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type EpSelectShareGrantsMutation = {
  __typename?: 'Mutation';
  EP_selectShareGrants: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetCompanyAndTaxDetailsMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  details: EpCompanyAndTaxDetails;
}>;

export type EpSetCompanyAndTaxDetailsMutation = {
  __typename?: 'Mutation';
  EP_setCompanyAndTaxDetails: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetExitProjectionMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  exitProjection: EpExitProjection;
}>;

export type EpSetExitProjectionMutation = {
  __typename?: 'Mutation';
  EP_setExitProjection: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type UpdateSelectedPlanDatesMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type UpdateSelectedPlanDatesMutation = {
  __typename?: 'Mutation';
  EP_updateSelectedPlanDates: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetSelectedPlansMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  planTypes: Array<EpPlanType> | EpPlanType;
}>;

export type EpSetSelectedPlansMutation = {
  __typename?: 'Mutation';
  EP_setSelectedPlans: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetTenderOfferDataMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  data: EpTenderOfferDataInput;
}>;

export type EpSetTenderOfferDataMutation = {
  __typename?: 'Mutation';
  EP_setTenderOfferData: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type EpSetSecondaryMarketDataMutationVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  data: EpSecondaryMarketDataInput;
}>;

export type EpSetSecondaryMarketDataMutation = {
  __typename?: 'Mutation';
  EP_setSecondaryMarketData: {
    __typename?: 'EPTimelineWrapper';
    timeline: {
      __typename?: 'EPTimeline';
      hasGeneratedPlans: boolean;
      isSelectedPlansOutdated: boolean;
      hasEarlyExercisableGrant: boolean;
      equityPlanConfigUuid: any;
      exerciseNowEarlyData?: {
        __typename?: 'EPExerciseNowEarlyData';
        optionGrants: Array<{
          __typename?: 'EPExerciseNowEarlyGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      tenderOfferData?: {
        __typename?: 'EPTenderOfferData';
        closeDate: any;
        investmentRound: string;
        price: number;
        optionGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
        shareGrants: Array<{
          __typename?: 'EPTenderOfferGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      secondaryMarketData?: {
        __typename?: 'EPSecondaryMarketData';
        expectedDate: any;
        price: number;
        shareGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
        optionGrants: Array<{
          __typename?: 'EPSecondaryMarketGrantData';
          quantity: number;
          uuid: any;
        }>;
      } | null;
      exerciseLaterProjectionData?: {
        __typename?: 'EPExerciseLaterProjectionData';
        exerciseDate: any;
        four09aPrice: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
      configProgress: {
        __typename?: 'ConfigProgress';
        overallProgress: number;
        breakdown: {
          __typename?: 'ConfigProgressBreakdown';
          grants: boolean;
          companyAndTaxDetails: boolean;
          exitProjection: boolean;
          plans: boolean;
        };
      };
      items: Array<{
        __typename?: 'EPTimelineItem';
        title: string;
        type: EpTimelineItemType;
        date?: any | null;
        state?: EpTimelineItemState | null;
        plan?: EpPlanType | null;
        data?:
          | {
              __typename: 'EPCompanyAndTaxDetailsItemData';
              country?: string | null;
              dependents?: number | null;
              filingType?: string | null;
              four09aPrice?: number | null;
              income?: number | null;
              subdivision?: string | null;
              usTaxInformationUuid?: any | null;
              additionalDeductions?: number | null;
              additionalIncome?: number | null;
              charitableContributions?: number | null;
              dividendIncome?: number | null;
              four01kContributions?: number | null;
              iraContribution?: number | null;
              longTermCapitalGains?: number | null;
              medicalExpenses?: number | null;
              mortgageInterest?: number | null;
              netRentalRealEstateIncome?: number | null;
              propertyStateLocalTaxes?: number | null;
              selfEmploymentIncome?: number | null;
              shortTermCapitalGains?: number | null;
            }
          | { __typename: 'EPCompanyPublicListingItemData' }
          | {
              __typename: 'EPExerciseItemData';
              investment: number;
              exercisableQuantities: Array<{
                __typename?: 'ExercisableQuantity';
                optionType: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPExitProjectionItemData';
              exitDate?: any | null;
              sharePrice?: number | null;
            }
          | { __typename: 'EPGainsItemData'; gains?: number | null }
          | {
              __typename: 'EPLiquidityFromSecondaryMarketItemData';
              netGains: number;
            }
          | {
              __typename: 'EPLiquidityFromTenderOfferItemData';
              netGains: number;
            }
          | {
              __typename: 'EPOptionGrantItemData';
              optionGrant: {
                __typename?: 'StockOptionGrant';
                uuid: any;
                affiliation: any;
                createdAt: any;
                customer: any;
                migrationDefaultVestingSchedule: boolean;
                earlyExercisable: boolean;
                grantName: string;
                grantType: StockOptionGrantType;
                quantity: number;
                updatedAt: any;
                verified: boolean;
                vestingStartDate: any;
                vestingEndDate: any;
                deadlineAfterLeaving?: {
                  __typename?: 'Period';
                  amount: number;
                  timeUnit: TimeUnit;
                } | null;
                exerciseEvents?: Array<{
                  __typename?: 'ExerciseEvent';
                  createdAt: any;
                  eventDate: any;
                  migrationDefaultEventDate: boolean;
                  quantity: number;
                  uuid: string;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  saleEvents: Array<{
                    __typename?: 'SaleEvent';
                    createdAt: any;
                    eventDate: any;
                    quantity: number;
                    uuid: string;
                    fmv?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                    price?: {
                      __typename?: 'EquityCurrency';
                      currency: string;
                      value: number;
                    } | null;
                  }>;
                }> | null;
                strikePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'StockOptionGrantSummary';
                  exercisableQuantity: number;
                  exercisedQuantity: number;
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }>;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
              };
            }
          | {
              __typename: 'EPSecondaryMarketItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | {
              __typename: 'EPShareGrantItemData';
              shareGrant: {
                __typename?: 'EquityShareGrant';
                affiliation: any;
                uuid: any;
                createdAt: any;
                customer: any;
                issueOrPurchaseDate: any;
                grantName: string;
                grantType: EquityShareGrantType;
                migrationDefaultDateOfIssue: boolean;
                quantity: number;
                source: ShareGrantSource;
                updatedAt: any;
                verified: boolean;
                vestingStartDate?: any | null;
                vestingEndDate?: any | null;
                issueOrPurchasePrice: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                summary: {
                  __typename?: 'EquityShareGrantSummary';
                  soldQuantity: number;
                  ownedQuantity: number;
                  totalVestedQuantity: number;
                };
                vestingEvents?: Array<{
                  __typename?: 'VestingScheduleEvent';
                  eventDate: any;
                  quantity: number;
                  type?: GrantType | null;
                }> | null;
                vestingScheduleTemplate?: {
                  __typename?: 'VestingScheduleTemplate';
                  cliff?: number | null;
                  vestingFrequency: number;
                  vestingPeriod: number;
                } | null;
                exerciseDetail?: {
                  __typename?: 'ShareGrantExerciseDetail';
                  uuid: any;
                  fmv: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  };
                  stockOptionGrant?: {
                    __typename?: 'ExerciseDetailParentStockOptionGrant';
                    uuid: any;
                    grantType: StockOptionGrantType;
                  } | null;
                } | null;
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              };
            }
          | {
              __typename: 'EPTenderOfferItemData';
              grantsSold: Array<{
                __typename?: 'EPGrantSold';
                type: string;
                quantity: number;
              }>;
            }
          | null;
      }>;
    };
  };
};

export type GetCustomerQueryVariables = Exact<{
  customerUuid: Scalars['String']['input'];
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
  } | null;
};

export type ExerciseCostEstimationGrantFragment = {
  __typename?: 'EPGrantExerciseCostsEstimation';
  exerciseCost?: number | null;
  taxDueOnExercise?: number | null;
  totalExerciseCost?: number | null;
  uuid?: string | null;
  vestedQuantity?: number | null;
};

export type GetExerciseCostsEstimationQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  details: EpCompanyAndTaxDetails;
  targetDate?: InputMaybe<Scalars['CalendarDate']['input']>;
}>;

export type GetExerciseCostsEstimationQuery = {
  __typename?: 'Query';
  exerciseCostsEstimation: {
    __typename?: 'EPExerciseCostsEstimation';
    totalExerciseCost?: number | null;
    grantsExerciseCost?: Array<{
      __typename?: 'EPGrantExerciseCostsEstimation';
      exerciseCost?: number | null;
      taxDueOnExercise?: number | null;
      totalExerciseCost?: number | null;
      uuid?: string | null;
      vestedQuantity?: number | null;
    } | null> | null;
  };
};

export type OptionGrantExitValueEstimationFragment = {
  __typename?: 'EPOptionGrantsExitValueEstimation';
  uuid: any;
  optionType: string;
  netGains: number;
};

export type ShareGrantsExitValueEstimationFragment = {
  __typename?: 'EPShareGrantsExitValueEstimation';
  uuid: any;
  netGains: number;
};

export type GetExitValueEstimationQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  exitProjection: EpExitProjection;
}>;

export type GetExitValueEstimationQuery = {
  __typename?: 'Query';
  exitValueEstimation: {
    __typename?: 'EPExitValueEstimation';
    netGains: number;
    optionGrants: Array<{
      __typename?: 'EPOptionGrantsExitValueEstimation';
      uuid: any;
      optionType: string;
      netGains: number;
    }>;
    shareGrants: Array<{
      __typename?: 'EPShareGrantsExitValueEstimation';
      uuid: any;
      netGains: number;
    }>;
  };
};

export type GetExitMultiplierChoicesQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type GetExitMultiplierChoicesQuery = {
  __typename?: 'Query';
  exitMultiplierChoices?: {
    __typename?: 'EPExitMultiplierChoices';
    id: any;
    choices: Array<{
      __typename?: 'EPExitMultiplier';
      description: string;
      id: any;
      label: string;
      multiplier?: number | null;
    }>;
  } | null;
};

export type GetExitDatesChoicesQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type GetExitDatesChoicesQuery = {
  __typename?: 'Query';
  exitDatesChoices: {
    __typename?: 'EPExitDateChoices';
    id: any;
    choices: Array<{
      __typename?: 'EPExitDate';
      description: string;
      exitDate?: any | null;
      id: any;
      label: string;
    }>;
  };
};

export type GetPlansQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type GetPlansQuery = {
  __typename?: 'Query';
  EP_getPlans?: Array<{
    __typename?: 'EPPlan';
    planType: EpPlanType;
    exerciseCosts: number;
    grossReturnFromSelling: number;
    sharePriceOnSellDate: number;
    netGains: number;
    personalCapitalInvested: number;
    riskOfCostsIncreasing: string;
    riskOfCostsIncreasingTooltipDescription: string;
    securedShares: number;
    title: string;
    taxesDueOnExercise: number;
    totalExerciseCost: number;
    taxesDueOnExit: number;
    vestedOptionsAtExercise: number;
    description: string;
    talkToStrategistOption: boolean;
    optionsQuantity: number;
    exerciseDate: any;
    sharePrice: number;
    shareGrants?: Array<{
      __typename?: 'EPPlanShareGrant';
      quantity: number;
    } | null> | null;
    optionGrants?: Array<{
      __typename?: 'EPPlanOptionGrant';
      quantity: number;
      type: string;
      vestedQuantity: number;
      remainingQuantity: number;
    } | null> | null;
  }> | null;
};

export type GetVestedGrantsForDateQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  targetDate: Scalars['CalendarDate']['input'];
}>;

export type GetVestedGrantsForDateQuery = {
  __typename?: 'Query';
  EP_vestedGrantQuantities: Array<{
    __typename?: 'EPVestedGrantQuantity';
    uuid: any;
    vestedQuantity: number;
  } | null>;
};

export type CompanyForecastFragment = {
  __typename?: 'CompanyForecast';
  uuid: string;
  company: string;
  nr_of_outstanding_shares: number;
  updated_at: string;
  exit_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
};

export type GetAllCompanyForecastsQueryVariables = Exact<{
  filters?: InputMaybe<CompanyForecastFiltersInput>;
  orderBy?: InputMaybe<GenericOrderInput>;
}>;

export type GetAllCompanyForecastsQuery = {
  __typename?: 'Query';
  companyForecasts?: {
    __typename?: 'AllCompanyForecastsResponse';
    nodes: Array<{
      __typename?: 'CompanyForecast';
      uuid: string;
      company: string;
      nr_of_outstanding_shares: number;
      updated_at: string;
      exit_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
  } | null;
};

export type CreateCompanyForecastMutationVariables = Exact<{
  companyForecast?: InputMaybe<CompanyForecastCreateInput>;
}>;

export type CreateCompanyForecastMutation = {
  __typename?: 'Mutation';
  companyForecast?: {
    __typename?: 'CompanyForecast';
    uuid: string;
    company: string;
    nr_of_outstanding_shares: number;
    updated_at: string;
    exit_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
};

export type UpdateCompanyForecastMutationVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']['input']>;
  companyForecast?: InputMaybe<CompanyForecastCreateInput>;
}>;

export type UpdateCompanyForecastMutation = {
  __typename?: 'Mutation';
  companyForecast?: {
    __typename?: 'CompanyForecast';
    uuid: string;
    company: string;
    nr_of_outstanding_shares: number;
    updated_at: string;
    exit_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
};

export type ExitScenarioDetailFragment = {
  __typename?: 'ExitScenarioDetail';
  common_share_price: number;
  investors_cut: number;
  total_common_shares: number;
  total_shares: number;
  total_value_of_common_shares: number;
  liquidation_preferences_applied: boolean;
  liquidation_preference_loss?: number | null;
  date_of_latest_equity_round?: any | null;
  name_of_latest_equity_round?: string | null;
  total_amount_invested?: number | null;
  exit_valuation: number;
};

export type GetExitScenarioDetailsQueryVariables = Exact<{
  companyUuid?: InputMaybe<Scalars['String']['input']>;
  exitValuations?: InputMaybe<
    Array<Scalars['Float']['input']> | Scalars['Float']['input']
  >;
  shareValues?: InputMaybe<
    Array<Scalars['Float']['input']> | Scalars['Float']['input']
  >;
}>;

export type GetExitScenarioDetailsQuery = {
  __typename?: 'Query';
  exitScenario?: {
    __typename?: 'ExitScenarioDetails';
    company_uuid: string;
    exit_valuations?: Array<number> | null;
    share_prices?: Array<number> | null;
    exit_scenario_details: Array<{
      __typename?: 'ExitScenarioDetail';
      common_share_price: number;
      investors_cut: number;
      total_common_shares: number;
      total_shares: number;
      total_value_of_common_shares: number;
      liquidation_preferences_applied: boolean;
      liquidation_preference_loss?: number | null;
      date_of_latest_equity_round?: any | null;
      name_of_latest_equity_round?: string | null;
      total_amount_invested?: number | null;
      exit_valuation: number;
    }>;
  } | null;
};

export type RequestVerificationMutationVariables = Exact<{
  payload: CompanyAssessmentCreateInput;
}>;

export type RequestVerificationMutation = {
  __typename?: 'Mutation';
  requestVerification?: {
    __typename?: 'CompanyAssessment';
    uuid: string;
    updated_at?: string | null;
    verified: boolean;
    ipo_or_manda?: number | null;
    expected_exit_date?: any | null;
    expected_exit_multiplier?: number | null;
    verified_captable_requested?: boolean | null;
    common_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    expected_exit_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
};

export type GetLatestVerifiedCompanyStatsQueryVariables = Exact<{
  companyUuid: Scalars['String']['input'];
}>;

export type GetLatestVerifiedCompanyStatsQuery = {
  __typename?: 'Query';
  Company?: {
    __typename?: 'Company';
    uuid: string;
    cap_table_verified?: boolean | null;
    latest_equity_series_date?: any | null;
    total_raised?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    latest_valuation?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    latest_verified_pref_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    latest_verified_company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
  } | null;
};

export type ProposalRequestPersonalSnapshotFragmentFragment = {
  __typename?: 'ProposalRequestPersonalSnapshot';
  proposal_reason?: Array<ProposalRequestReason | null> | null;
  shares_advance: string;
  filing_type?: FilingType | null;
  dependents?: number | null;
  retirement_plan?: boolean | null;
  country?: string | null;
  subdivision?: string | null;
  city?: string | null;
  shares_advance_amount?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  user_share_price?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  four01k_contributions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  property_state_local_taxes?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  mortage_interest?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  charitable_contributions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  ira_contribution?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  additional_deductions?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  self_employment_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  dividend_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  short_term_capital_gains?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  net_rental_real_estate_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  long_term_capital_gains?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  additional_income?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  medical_expenses?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  option_grants: Array<{
    __typename?: 'SelectedOptionGrant';
    excluded?: boolean | null;
    name?: string | null;
    option_type: OptionGrantType;
    quantity: number;
    selected_quantity: number;
    start_date?: any | null;
    uuid?: string | null;
    strike_price: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
  }>;
  four09a_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  preferred_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  share_grants: Array<{
    __typename?: 'SelectedShareGrant';
    uuid?: string | null;
    quantity: number;
    selected_quantity: number;
    excluded?: boolean | null;
    share_type?: string | null;
    start_date?: any | null;
    share_value_at_acquisition?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  }>;
};

export type UpdateProposalRequestPersonalSnapshotMutationVariables = Exact<{
  payload?: InputMaybe<ProposalRequestPersonalSnapshotInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateProposalRequestPersonalSnapshotMutation = {
  __typename?: 'Mutation';
  updateProposalRequestPersonalSnapshot?: {
    __typename?: 'ProposalRequestPersonalSnapshot';
    proposal_reason?: Array<ProposalRequestReason | null> | null;
    shares_advance: string;
    filing_type?: FilingType | null;
    dependents?: number | null;
    retirement_plan?: boolean | null;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    shares_advance_amount?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    user_share_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four01k_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    property_state_local_taxes?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    mortage_interest?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    charitable_contributions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    ira_contribution?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_deductions?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    self_employment_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    dividend_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    short_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    net_rental_real_estate_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    long_term_capital_gains?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    additional_income?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    medical_expenses?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    option_grants: Array<{
      __typename?: 'SelectedOptionGrant';
      excluded?: boolean | null;
      name?: string | null;
      option_type: OptionGrantType;
      quantity: number;
      selected_quantity: number;
      start_date?: any | null;
      uuid?: string | null;
      strike_price: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    share_grants: Array<{
      __typename?: 'SelectedShareGrant';
      uuid?: string | null;
      quantity: number;
      selected_quantity: number;
      excluded?: boolean | null;
      share_type?: string | null;
      start_date?: any | null;
      share_value_at_acquisition?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    }>;
  } | null;
};

export type GetAllProposalRequestsQueryVariables = Exact<{
  filters?: InputMaybe<ProposalRequestInput>;
}>;

export type GetAllProposalRequestsQuery = {
  __typename?: 'Query';
  allProposalRequests?: {
    __typename?: 'AllProposalRequests';
    nodes: Array<{
      __typename?: 'ProposalRequest';
      uuid: string;
      date_submitted?: string | null;
      affiliation?: { __typename?: 'Affiliation'; uuid: string } | null;
    }>;
  } | null;
};

export type EpOptionGrantItemFragment = {
  __typename?: 'EPOptionGrantItemData';
  optionGrant: {
    __typename?: 'StockOptionGrant';
    uuid: any;
    affiliation: any;
    createdAt: any;
    customer: any;
    migrationDefaultVestingSchedule: boolean;
    earlyExercisable: boolean;
    grantName: string;
    grantType: StockOptionGrantType;
    quantity: number;
    updatedAt: any;
    verified: boolean;
    vestingStartDate: any;
    vestingEndDate: any;
    deadlineAfterLeaving?: {
      __typename?: 'Period';
      amount: number;
      timeUnit: TimeUnit;
    } | null;
    exerciseEvents?: Array<{
      __typename?: 'ExerciseEvent';
      createdAt: any;
      eventDate: any;
      migrationDefaultEventDate: boolean;
      quantity: number;
      uuid: string;
      fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    }> | null;
    strikePrice: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    };
    summary: {
      __typename?: 'StockOptionGrantSummary';
      exercisableQuantity: number;
      exercisedQuantity: number;
      soldQuantity: number;
      ownedQuantity: number;
      totalVestedQuantity: number;
    };
    vestingEvents: Array<{
      __typename?: 'VestingScheduleEvent';
      eventDate: any;
      quantity: number;
      type?: GrantType | null;
    }>;
    vestingScheduleTemplate?: {
      __typename?: 'VestingScheduleTemplate';
      cliff?: number | null;
      vestingFrequency: number;
      vestingPeriod: number;
    } | null;
  };
};

export type EpShareGrantItemFragment = {
  __typename?: 'EPShareGrantItemData';
  shareGrant: {
    __typename?: 'EquityShareGrant';
    affiliation: any;
    uuid: any;
    createdAt: any;
    customer: any;
    issueOrPurchaseDate: any;
    grantName: string;
    grantType: EquityShareGrantType;
    migrationDefaultDateOfIssue: boolean;
    quantity: number;
    source: ShareGrantSource;
    updatedAt: any;
    verified: boolean;
    vestingStartDate?: any | null;
    vestingEndDate?: any | null;
    issueOrPurchasePrice: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    };
    summary: {
      __typename?: 'EquityShareGrantSummary';
      soldQuantity: number;
      ownedQuantity: number;
      totalVestedQuantity: number;
    };
    vestingEvents?: Array<{
      __typename?: 'VestingScheduleEvent';
      eventDate: any;
      quantity: number;
      type?: GrantType | null;
    }> | null;
    vestingScheduleTemplate?: {
      __typename?: 'VestingScheduleTemplate';
      cliff?: number | null;
      vestingFrequency: number;
      vestingPeriod: number;
    } | null;
    exerciseDetail?: {
      __typename?: 'ShareGrantExerciseDetail';
      uuid: any;
      fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
      stockOptionGrant?: {
        __typename?: 'ExerciseDetailParentStockOptionGrant';
        uuid: any;
        grantType: StockOptionGrantType;
      } | null;
    } | null;
    saleEvents: Array<{
      __typename?: 'SaleEvent';
      createdAt: any;
      eventDate: any;
      quantity: number;
      uuid: string;
      fmv?: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      } | null;
      price?: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      } | null;
    }>;
  };
};

export type EpExerciseNowEarlyGrantDataFragment = {
  __typename?: 'EPExerciseNowEarlyGrantData';
  quantity: number;
  uuid: any;
};

export type EpExerciseNowEarlyDataFragment = {
  __typename?: 'EPExerciseNowEarlyData';
  optionGrants: Array<{
    __typename?: 'EPExerciseNowEarlyGrantData';
    quantity: number;
    uuid: any;
  }>;
};

export type EpCompanyAndTaxDetailsItemFragment = {
  __typename?: 'EPCompanyAndTaxDetailsItemData';
  country?: string | null;
  dependents?: number | null;
  filingType?: string | null;
  four09aPrice?: number | null;
  income?: number | null;
  subdivision?: string | null;
  usTaxInformationUuid?: any | null;
  additionalDeductions?: number | null;
  additionalIncome?: number | null;
  charitableContributions?: number | null;
  dividendIncome?: number | null;
  four01kContributions?: number | null;
  iraContribution?: number | null;
  longTermCapitalGains?: number | null;
  medicalExpenses?: number | null;
  mortgageInterest?: number | null;
  netRentalRealEstateIncome?: number | null;
  propertyStateLocalTaxes?: number | null;
  selfEmploymentIncome?: number | null;
  shortTermCapitalGains?: number | null;
};

export type ExerciseLaterProjectionDataFragment = {
  __typename?: 'EPExerciseLaterProjectionData';
  exerciseDate: any;
  four09aPrice: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
};

export type EpTimelineFragment = {
  __typename?: 'EPTimeline';
  hasGeneratedPlans: boolean;
  isSelectedPlansOutdated: boolean;
  hasEarlyExercisableGrant: boolean;
  equityPlanConfigUuid: any;
  exerciseNowEarlyData?: {
    __typename?: 'EPExerciseNowEarlyData';
    optionGrants: Array<{
      __typename?: 'EPExerciseNowEarlyGrantData';
      quantity: number;
      uuid: any;
    }>;
  } | null;
  tenderOfferData?: {
    __typename?: 'EPTenderOfferData';
    closeDate: any;
    investmentRound: string;
    price: number;
    optionGrants: Array<{
      __typename?: 'EPTenderOfferGrantData';
      quantity: number;
      uuid: any;
    }>;
    shareGrants: Array<{
      __typename?: 'EPTenderOfferGrantData';
      quantity: number;
      uuid: any;
    }>;
  } | null;
  secondaryMarketData?: {
    __typename?: 'EPSecondaryMarketData';
    expectedDate: any;
    price: number;
    shareGrants: Array<{
      __typename?: 'EPSecondaryMarketGrantData';
      quantity: number;
      uuid: any;
    }>;
    optionGrants: Array<{
      __typename?: 'EPSecondaryMarketGrantData';
      quantity: number;
      uuid: any;
    }>;
  } | null;
  exerciseLaterProjectionData?: {
    __typename?: 'EPExerciseLaterProjectionData';
    exerciseDate: any;
    four09aPrice: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
  } | null;
  configProgress: {
    __typename?: 'ConfigProgress';
    overallProgress: number;
    breakdown: {
      __typename?: 'ConfigProgressBreakdown';
      grants: boolean;
      companyAndTaxDetails: boolean;
      exitProjection: boolean;
      plans: boolean;
    };
  };
  items: Array<{
    __typename?: 'EPTimelineItem';
    title: string;
    type: EpTimelineItemType;
    date?: any | null;
    state?: EpTimelineItemState | null;
    plan?: EpPlanType | null;
    data?:
      | {
          __typename: 'EPCompanyAndTaxDetailsItemData';
          country?: string | null;
          dependents?: number | null;
          filingType?: string | null;
          four09aPrice?: number | null;
          income?: number | null;
          subdivision?: string | null;
          usTaxInformationUuid?: any | null;
          additionalDeductions?: number | null;
          additionalIncome?: number | null;
          charitableContributions?: number | null;
          dividendIncome?: number | null;
          four01kContributions?: number | null;
          iraContribution?: number | null;
          longTermCapitalGains?: number | null;
          medicalExpenses?: number | null;
          mortgageInterest?: number | null;
          netRentalRealEstateIncome?: number | null;
          propertyStateLocalTaxes?: number | null;
          selfEmploymentIncome?: number | null;
          shortTermCapitalGains?: number | null;
        }
      | { __typename: 'EPCompanyPublicListingItemData' }
      | {
          __typename: 'EPExerciseItemData';
          investment: number;
          exercisableQuantities: Array<{
            __typename?: 'ExercisableQuantity';
            optionType: string;
            quantity: number;
          }>;
        }
      | {
          __typename: 'EPExitProjectionItemData';
          exitDate?: any | null;
          sharePrice?: number | null;
        }
      | { __typename: 'EPGainsItemData'; gains?: number | null }
      | {
          __typename: 'EPLiquidityFromSecondaryMarketItemData';
          netGains: number;
        }
      | { __typename: 'EPLiquidityFromTenderOfferItemData'; netGains: number }
      | {
          __typename: 'EPOptionGrantItemData';
          optionGrant: {
            __typename?: 'StockOptionGrant';
            uuid: any;
            affiliation: any;
            createdAt: any;
            customer: any;
            migrationDefaultVestingSchedule: boolean;
            earlyExercisable: boolean;
            grantName: string;
            grantType: StockOptionGrantType;
            quantity: number;
            updatedAt: any;
            verified: boolean;
            vestingStartDate: any;
            vestingEndDate: any;
            deadlineAfterLeaving?: {
              __typename?: 'Period';
              amount: number;
              timeUnit: TimeUnit;
            } | null;
            exerciseEvents?: Array<{
              __typename?: 'ExerciseEvent';
              createdAt: any;
              eventDate: any;
              migrationDefaultEventDate: boolean;
              quantity: number;
              uuid: string;
              fmv: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              };
              saleEvents: Array<{
                __typename?: 'SaleEvent';
                createdAt: any;
                eventDate: any;
                quantity: number;
                uuid: string;
                fmv?: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                } | null;
                price?: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                } | null;
              }>;
            }> | null;
            strikePrice: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            };
            summary: {
              __typename?: 'StockOptionGrantSummary';
              exercisableQuantity: number;
              exercisedQuantity: number;
              soldQuantity: number;
              ownedQuantity: number;
              totalVestedQuantity: number;
            };
            vestingEvents: Array<{
              __typename?: 'VestingScheduleEvent';
              eventDate: any;
              quantity: number;
              type?: GrantType | null;
            }>;
            vestingScheduleTemplate?: {
              __typename?: 'VestingScheduleTemplate';
              cliff?: number | null;
              vestingFrequency: number;
              vestingPeriod: number;
            } | null;
          };
        }
      | {
          __typename: 'EPSecondaryMarketItemData';
          grantsSold: Array<{
            __typename?: 'EPGrantSold';
            type: string;
            quantity: number;
          }>;
        }
      | {
          __typename: 'EPShareGrantItemData';
          shareGrant: {
            __typename?: 'EquityShareGrant';
            affiliation: any;
            uuid: any;
            createdAt: any;
            customer: any;
            issueOrPurchaseDate: any;
            grantName: string;
            grantType: EquityShareGrantType;
            migrationDefaultDateOfIssue: boolean;
            quantity: number;
            source: ShareGrantSource;
            updatedAt: any;
            verified: boolean;
            vestingStartDate?: any | null;
            vestingEndDate?: any | null;
            issueOrPurchasePrice: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            };
            summary: {
              __typename?: 'EquityShareGrantSummary';
              soldQuantity: number;
              ownedQuantity: number;
              totalVestedQuantity: number;
            };
            vestingEvents?: Array<{
              __typename?: 'VestingScheduleEvent';
              eventDate: any;
              quantity: number;
              type?: GrantType | null;
            }> | null;
            vestingScheduleTemplate?: {
              __typename?: 'VestingScheduleTemplate';
              cliff?: number | null;
              vestingFrequency: number;
              vestingPeriod: number;
            } | null;
            exerciseDetail?: {
              __typename?: 'ShareGrantExerciseDetail';
              uuid: any;
              fmv: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              };
              stockOptionGrant?: {
                __typename?: 'ExerciseDetailParentStockOptionGrant';
                uuid: any;
                grantType: StockOptionGrantType;
              } | null;
            } | null;
            saleEvents: Array<{
              __typename?: 'SaleEvent';
              createdAt: any;
              eventDate: any;
              quantity: number;
              uuid: string;
              fmv?: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              } | null;
              price?: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              } | null;
            }>;
          };
        }
      | {
          __typename: 'EPTenderOfferItemData';
          grantsSold: Array<{
            __typename?: 'EPGrantSold';
            type: string;
            quantity: number;
          }>;
        }
      | null;
  }>;
};

export type GetEquityPlanTimelineQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
}>;

export type GetEquityPlanTimelineQuery = {
  __typename?: 'Query';
  EP_equityPlanTimeline: {
    __typename?: 'EPTimeline';
    hasGeneratedPlans: boolean;
    isSelectedPlansOutdated: boolean;
    hasEarlyExercisableGrant: boolean;
    equityPlanConfigUuid: any;
    exerciseNowEarlyData?: {
      __typename?: 'EPExerciseNowEarlyData';
      optionGrants: Array<{
        __typename?: 'EPExerciseNowEarlyGrantData';
        quantity: number;
        uuid: any;
      }>;
    } | null;
    tenderOfferData?: {
      __typename?: 'EPTenderOfferData';
      closeDate: any;
      investmentRound: string;
      price: number;
      optionGrants: Array<{
        __typename?: 'EPTenderOfferGrantData';
        quantity: number;
        uuid: any;
      }>;
      shareGrants: Array<{
        __typename?: 'EPTenderOfferGrantData';
        quantity: number;
        uuid: any;
      }>;
    } | null;
    secondaryMarketData?: {
      __typename?: 'EPSecondaryMarketData';
      expectedDate: any;
      price: number;
      shareGrants: Array<{
        __typename?: 'EPSecondaryMarketGrantData';
        quantity: number;
        uuid: any;
      }>;
      optionGrants: Array<{
        __typename?: 'EPSecondaryMarketGrantData';
        quantity: number;
        uuid: any;
      }>;
    } | null;
    exerciseLaterProjectionData?: {
      __typename?: 'EPExerciseLaterProjectionData';
      exerciseDate: any;
      four09aPrice: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    } | null;
    configProgress: {
      __typename?: 'ConfigProgress';
      overallProgress: number;
      breakdown: {
        __typename?: 'ConfigProgressBreakdown';
        grants: boolean;
        companyAndTaxDetails: boolean;
        exitProjection: boolean;
        plans: boolean;
      };
    };
    items: Array<{
      __typename?: 'EPTimelineItem';
      title: string;
      type: EpTimelineItemType;
      date?: any | null;
      state?: EpTimelineItemState | null;
      plan?: EpPlanType | null;
      data?:
        | {
            __typename: 'EPCompanyAndTaxDetailsItemData';
            country?: string | null;
            dependents?: number | null;
            filingType?: string | null;
            four09aPrice?: number | null;
            income?: number | null;
            subdivision?: string | null;
            usTaxInformationUuid?: any | null;
            additionalDeductions?: number | null;
            additionalIncome?: number | null;
            charitableContributions?: number | null;
            dividendIncome?: number | null;
            four01kContributions?: number | null;
            iraContribution?: number | null;
            longTermCapitalGains?: number | null;
            medicalExpenses?: number | null;
            mortgageInterest?: number | null;
            netRentalRealEstateIncome?: number | null;
            propertyStateLocalTaxes?: number | null;
            selfEmploymentIncome?: number | null;
            shortTermCapitalGains?: number | null;
          }
        | { __typename: 'EPCompanyPublicListingItemData' }
        | {
            __typename: 'EPExerciseItemData';
            investment: number;
            exercisableQuantities: Array<{
              __typename?: 'ExercisableQuantity';
              optionType: string;
              quantity: number;
            }>;
          }
        | {
            __typename: 'EPExitProjectionItemData';
            exitDate?: any | null;
            sharePrice?: number | null;
          }
        | { __typename: 'EPGainsItemData'; gains?: number | null }
        | {
            __typename: 'EPLiquidityFromSecondaryMarketItemData';
            netGains: number;
          }
        | { __typename: 'EPLiquidityFromTenderOfferItemData'; netGains: number }
        | {
            __typename: 'EPOptionGrantItemData';
            optionGrant: {
              __typename?: 'StockOptionGrant';
              uuid: any;
              affiliation: any;
              createdAt: any;
              customer: any;
              migrationDefaultVestingSchedule: boolean;
              earlyExercisable: boolean;
              grantName: string;
              grantType: StockOptionGrantType;
              quantity: number;
              updatedAt: any;
              verified: boolean;
              vestingStartDate: any;
              vestingEndDate: any;
              deadlineAfterLeaving?: {
                __typename?: 'Period';
                amount: number;
                timeUnit: TimeUnit;
              } | null;
              exerciseEvents?: Array<{
                __typename?: 'ExerciseEvent';
                createdAt: any;
                eventDate: any;
                migrationDefaultEventDate: boolean;
                quantity: number;
                uuid: string;
                fmv: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                saleEvents: Array<{
                  __typename?: 'SaleEvent';
                  createdAt: any;
                  eventDate: any;
                  quantity: number;
                  uuid: string;
                  fmv?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                  price?: {
                    __typename?: 'EquityCurrency';
                    currency: string;
                    value: number;
                  } | null;
                }>;
              }> | null;
              strikePrice: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              };
              summary: {
                __typename?: 'StockOptionGrantSummary';
                exercisableQuantity: number;
                exercisedQuantity: number;
                soldQuantity: number;
                ownedQuantity: number;
                totalVestedQuantity: number;
              };
              vestingEvents: Array<{
                __typename?: 'VestingScheduleEvent';
                eventDate: any;
                quantity: number;
                type?: GrantType | null;
              }>;
              vestingScheduleTemplate?: {
                __typename?: 'VestingScheduleTemplate';
                cliff?: number | null;
                vestingFrequency: number;
                vestingPeriod: number;
              } | null;
            };
          }
        | {
            __typename: 'EPSecondaryMarketItemData';
            grantsSold: Array<{
              __typename?: 'EPGrantSold';
              type: string;
              quantity: number;
            }>;
          }
        | {
            __typename: 'EPShareGrantItemData';
            shareGrant: {
              __typename?: 'EquityShareGrant';
              affiliation: any;
              uuid: any;
              createdAt: any;
              customer: any;
              issueOrPurchaseDate: any;
              grantName: string;
              grantType: EquityShareGrantType;
              migrationDefaultDateOfIssue: boolean;
              quantity: number;
              source: ShareGrantSource;
              updatedAt: any;
              verified: boolean;
              vestingStartDate?: any | null;
              vestingEndDate?: any | null;
              issueOrPurchasePrice: {
                __typename?: 'EquityCurrency';
                currency: string;
                value: number;
              };
              summary: {
                __typename?: 'EquityShareGrantSummary';
                soldQuantity: number;
                ownedQuantity: number;
                totalVestedQuantity: number;
              };
              vestingEvents?: Array<{
                __typename?: 'VestingScheduleEvent';
                eventDate: any;
                quantity: number;
                type?: GrantType | null;
              }> | null;
              vestingScheduleTemplate?: {
                __typename?: 'VestingScheduleTemplate';
                cliff?: number | null;
                vestingFrequency: number;
                vestingPeriod: number;
              } | null;
              exerciseDetail?: {
                __typename?: 'ShareGrantExerciseDetail';
                uuid: any;
                fmv: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                };
                stockOptionGrant?: {
                  __typename?: 'ExerciseDetailParentStockOptionGrant';
                  uuid: any;
                  grantType: StockOptionGrantType;
                } | null;
              } | null;
              saleEvents: Array<{
                __typename?: 'SaleEvent';
                createdAt: any;
                eventDate: any;
                quantity: number;
                uuid: string;
                fmv?: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                } | null;
                price?: {
                  __typename?: 'EquityCurrency';
                  currency: string;
                  value: number;
                } | null;
              }>;
            };
          }
        | {
            __typename: 'EPTenderOfferItemData';
            grantsSold: Array<{
              __typename?: 'EPGrantSold';
              type: string;
              quantity: number;
            }>;
          }
        | null;
    }>;
  };
};

export type CompletePaymentMutationVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type CompletePaymentMutation = {
  __typename?: 'Mutation';
  riaDealMutations: {
    __typename?: 'riaDealMutations';
    completePayment?: {
      __typename?: 'RIADeal';
      product: RiaDealProduct;
      productCategory: RiaDealProductCategory;
      status: RiaDealStatus;
      substatus: RiaDealSubstatus;
      advisoryAgreementUrl?: string | null;
      uuid: any;
      createdAt: any;
      updatedAt: any;
      advisor?: {
        __typename?: 'Advisor';
        uuid: string;
        avatar?: string | null;
        full_name?: string | null;
        phone?: string | null;
        public_email?: string | null;
        title?: string | null;
        bio?: string | null;
        calendly_intake_call_link?: string | null;
        calendly_proposal_call_link?: string | null;
        calendly_wealth_insights_call_link?: string | null;
        calendly_advisory_insights_call_link?: string | null;
        linkedin?: string | null;
        twitter?: string | null;
      } | null;
    } | null;
  };
};

export type EndKycFlowMutationVariables = Exact<{
  inquiryUUID: Scalars['UUID']['input'];
  riaDeal: Scalars['UUID']['input'];
}>;

export type EndKycFlowMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    endKYCFlow?: {
      __typename?: 'KYCInquiryEndType';
      inquiry_id?: any | null;
    } | null;
  };
};

export type InitKycFlowMutationVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
  owner: Scalars['UUID']['input'];
}>;

export type InitKycFlowMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    initKYCFlow?: {
      __typename?: 'KYCInquiryCreationType';
      inquiry_id?: string | null;
      session_token?: string | null;
    } | null;
  };
};

export type UpdateAdvisoryAgreementMutationVariables = Exact<{
  owner: Scalars['UUID']['input'];
  payload: AdvisoryAgreementInput;
}>;

export type UpdateAdvisoryAgreementMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    updateAdvisoryAgreement?: boolean | null;
  };
};

export type UpdateComplianceInformationMutationVariables = Exact<{
  owner: Scalars['UUID']['input'];
  payload: ComplianceInformationRiaInput;
}>;

export type UpdateComplianceInformationMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    updateComplianceInformation?: {
      __typename?: 'ComplianceInformationRIA';
      ria_deal: any;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      data?: {
        __typename?: 'ComplianceInformationDataRIA';
        employer?: string | null;
        employment?: Employment | null;
        financial_industry_regulatory_authority?: FinancialIndustryRegulatoryAuthorityRia | null;
        income?: AnnualIncomeRia | null;
        industry?: string | null;
        is_financial_industry_regulatory_authority?: boolean | null;
        is_political_officer?: boolean | null;
        is_senior_officer?: boolean | null;
        liquid_net_worth?: LiquidNetWorthRia | null;
        political_officer_country?: string | null;
        position?: string | null;
        senior_officer_company?: string | null;
        senior_officer_ticker?: string | null;
        total_net_worth?: TotalNetWorthRia | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
    } | null;
  };
};

export type UpdatePayStubAccountStatementMutationVariables = Exact<{
  owner: Scalars['UUID']['input'];
  payload: PayStubAccountStatementInput;
}>;

export type UpdatePayStubAccountStatementMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    updatePayStubAccountStatement?: {
      __typename?: 'PayStubAccountStatement';
      uuid?: any | null;
      status?: FormModelStatusGql | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      files: Array<{
        __typename?: 'RIAOnboardingFile';
        created_at?: any | null;
        document_type?: string | null;
        file_name?: string | null;
        file_type?: string | null;
        updated_at?: any | null;
        url?: string | null;
        uuid?: any | null;
      }>;
    } | null;
  };
};

export type UpdatePersonalInformationMutationVariables = Exact<{
  owner: Scalars['UUID']['input'];
  payload: PersonalInformationRiaInput;
}>;

export type UpdatePersonalInformationMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    updatePersonalInformation?: {
      __typename?: 'PersonalInformationRIA';
      ria_deal: any;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      data?: {
        __typename?: 'PersonalInformationDataRIA';
        address_street_1?: string | null;
        address_street_2?: string | null;
        citizenship?: string | null;
        city?: string | null;
        country?: string | null;
        country_of_tax_residence?: string | null;
        date_of_birth?: any | null;
        legal_name?: string | null;
        marital_status?: MaritalStatusGql | null;
        phone_number?: string | null;
        postal_code?: string | null;
        preferred_email?: string | null;
        social_security?: string | null;
        subdivision?: string | null;
        taxpayer_id?: string | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
    } | null;
  };
};

export type UpdateRiskAssessmentMutationVariables = Exact<{
  owner: Scalars['UUID']['input'];
  payload: RiskAssessmentInput;
}>;

export type UpdateRiskAssessmentMutation = {
  __typename?: 'Mutation';
  mutationsRIA: {
    __typename?: 'MutationsRIA';
    updateRiskAssessment?: {
      __typename?: 'RiskAssessment';
      download_file_name?: string | null;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      files?: Array<{
        __typename?: 'RIAOnboardingFile';
        created_at?: any | null;
        file_name?: string | null;
        file_type?: string | null;
        updated_at?: any | null;
        url?: string | null;
        uuid?: any | null;
      } | null> | null;
    } | null;
  };
};

export type GetRiaComplianceInformationQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type GetRiaComplianceInformationQuery = {
  __typename?: 'Query';
  queriesRIA: {
    __typename?: 'QueriesRIA';
    ComplianceInformation?: {
      __typename?: 'ComplianceInformationRIA';
      ria_deal: any;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      data?: {
        __typename?: 'ComplianceInformationDataRIA';
        employer?: string | null;
        employment?: Employment | null;
        financial_industry_regulatory_authority?: FinancialIndustryRegulatoryAuthorityRia | null;
        income?: AnnualIncomeRia | null;
        industry?: string | null;
        is_financial_industry_regulatory_authority?: boolean | null;
        is_political_officer?: boolean | null;
        is_senior_officer?: boolean | null;
        liquid_net_worth?: LiquidNetWorthRia | null;
        political_officer_country?: string | null;
        position?: string | null;
        senior_officer_company?: string | null;
        senior_officer_ticker?: string | null;
        total_net_worth?: TotalNetWorthRia | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
    } | null;
  };
};

export type GetRiaIdentificationInformationQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type GetRiaIdentificationInformationQuery = {
  __typename?: 'Query';
  queriesRIA: {
    __typename?: 'QueriesRIA';
    IdentificationInformation?: {
      __typename?: 'IdentificationInformationRIA';
      ria_deal: any;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        message?: string | null;
      } | null> | null;
    } | null;
  };
};

export type PayStubAccountStatementFragment = {
  __typename?: 'PayStubAccountStatement';
  uuid?: any | null;
  status?: FormModelStatusGql | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    created_at?: string | null;
    created_by?: string | null;
    message?: string | null;
  } | null> | null;
  files: Array<{
    __typename?: 'RIAOnboardingFile';
    created_at?: any | null;
    document_type?: string | null;
    file_name?: string | null;
    file_type?: string | null;
    updated_at?: any | null;
    url?: string | null;
    uuid?: any | null;
  }>;
};

export type GetPayStubAccountStatementQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type GetPayStubAccountStatementQuery = {
  __typename?: 'Query';
  queriesRIA: {
    __typename?: 'QueriesRIA';
    PayStubAccountStatement?: {
      __typename?: 'PayStubAccountStatement';
      uuid?: any | null;
      status?: FormModelStatusGql | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      files: Array<{
        __typename?: 'RIAOnboardingFile';
        created_at?: any | null;
        document_type?: string | null;
        file_name?: string | null;
        file_type?: string | null;
        updated_at?: any | null;
        url?: string | null;
        uuid?: any | null;
      }>;
    } | null;
  };
};

export type GetRiaPersonalInformationQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type GetRiaPersonalInformationQuery = {
  __typename?: 'Query';
  queriesRIA: {
    __typename?: 'QueriesRIA';
    PersonalInformation?: {
      __typename?: 'PersonalInformationRIA';
      ria_deal: any;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      data?: {
        __typename?: 'PersonalInformationDataRIA';
        address_street_1?: string | null;
        address_street_2?: string | null;
        citizenship?: string | null;
        city?: string | null;
        country?: string | null;
        country_of_tax_residence?: string | null;
        date_of_birth?: any | null;
        legal_name?: string | null;
        marital_status?: MaritalStatusGql | null;
        phone_number?: string | null;
        postal_code?: string | null;
        preferred_email?: string | null;
        social_security?: string | null;
        subdivision?: string | null;
        taxpayer_id?: string | null;
      } | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
    } | null;
  };
};

export type RiskAssessmentFragment = {
  __typename?: 'RiskAssessment';
  download_file_name?: string | null;
  status?: FormModelStatusGql | null;
  uuid?: any | null;
  questionnaire?: Array<{
    __typename?: 'RiskAssessmentQuestionType';
    uuid?: any | null;
    title?: string | null;
    answer_title?: string | null;
    possible_answers?: Array<{
      __typename?: 'RiskAssessmentAnswer';
      answer_title?: string | null;
    } | null> | null;
  } | null> | null;
  feedback?: Array<{
    __typename?: 'Feedback';
    created_at?: string | null;
    created_by?: string | null;
    message?: string | null;
  } | null> | null;
  files?: Array<{
    __typename?: 'RIAOnboardingFile';
    created_at?: any | null;
    file_name?: string | null;
    file_type?: string | null;
    updated_at?: any | null;
    url?: string | null;
    uuid?: any | null;
  } | null> | null;
};

export type GetRiskAssessmentQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type GetRiskAssessmentQuery = {
  __typename?: 'Query';
  queriesRIA: {
    __typename?: 'QueriesRIA';
    RiskAssessment?: {
      __typename?: 'RiskAssessment';
      download_file_name?: string | null;
      status?: FormModelStatusGql | null;
      uuid?: any | null;
      questionnaire?: Array<{
        __typename?: 'RiskAssessmentQuestionType';
        uuid?: any | null;
        title?: string | null;
        answer_title?: string | null;
        possible_answers?: Array<{
          __typename?: 'RiskAssessmentAnswer';
          answer_title?: string | null;
        } | null> | null;
      } | null> | null;
      feedback?: Array<{
        __typename?: 'Feedback';
        created_at?: string | null;
        created_by?: string | null;
        message?: string | null;
      } | null> | null;
      files?: Array<{
        __typename?: 'RIAOnboardingFile';
        created_at?: any | null;
        file_name?: string | null;
        file_type?: string | null;
        updated_at?: any | null;
        url?: string | null;
        uuid?: any | null;
      } | null> | null;
    } | null;
  };
};

export type CreateRiaDealsMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
  productCategory: RiaDealProductCategory;
  product: RiaDealProduct;
  signedUpWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  source: RiaDealSource;
  signedUpWithWaitList?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaireAnswers?: InputMaybe<
    Array<RiaDealQuestionnaireAnswerInput> | RiaDealQuestionnaireAnswerInput
  >;
}>;

export type CreateRiaDealsMutation = {
  __typename?: 'Mutation';
  riaDealMutations: {
    __typename?: 'riaDealMutations';
    create: {
      __typename?: 'RIADeal';
      productCategory: RiaDealProductCategory;
      uuid: any;
    };
  };
};

export type MoveAdvisoryCustomerToWealthMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
}>;

export type MoveAdvisoryCustomerToWealthMutation = {
  __typename?: 'Mutation';
  riaDealMutations: {
    __typename?: 'riaDealMutations';
    moveToWealthIntroCall: { __typename?: 'RIADeal'; uuid: any };
  };
};

export type MoveToStatusMutationVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
  status: RiaDealStatus;
  substatus: RiaDealSubstatus;
}>;

export type MoveToStatusMutation = {
  __typename?: 'Mutation';
  riaDealMutations: {
    __typename?: 'riaDealMutations';
    moveToStatus: {
      __typename?: 'RIADeal';
      uuid: any;
      status: RiaDealStatus;
      substatus: RiaDealSubstatus;
    };
  };
};

export type GetCustomerActiveRiaDealQueryVariables = Exact<{
  user: Scalars['UUID']['input'];
}>;

export type GetCustomerActiveRiaDealQuery = {
  __typename?: 'Query';
  riaDealQueries: {
    __typename?: 'riaDealQueries';
    activeByUser?: {
      __typename?: 'RIADeal';
      product: RiaDealProduct;
      productCategory: RiaDealProductCategory;
      status: RiaDealStatus;
      substatus: RiaDealSubstatus;
      advisoryAgreementUrl?: string | null;
      uuid: any;
      createdAt: any;
      updatedAt: any;
      advisor?: {
        __typename?: 'Advisor';
        uuid: string;
        avatar?: string | null;
        full_name?: string | null;
        phone?: string | null;
        public_email?: string | null;
        title?: string | null;
        bio?: string | null;
        calendly_intake_call_link?: string | null;
        calendly_proposal_call_link?: string | null;
        calendly_wealth_insights_call_link?: string | null;
        calendly_advisory_insights_call_link?: string | null;
        linkedin?: string | null;
        twitter?: string | null;
      } | null;
    } | null;
  };
};

export type RiaDealFragment = {
  __typename?: 'RIADeal';
  product: RiaDealProduct;
  productCategory: RiaDealProductCategory;
  status: RiaDealStatus;
  substatus: RiaDealSubstatus;
  advisoryAgreementUrl?: string | null;
  uuid: any;
  createdAt: any;
  updatedAt: any;
  advisor?: {
    __typename?: 'Advisor';
    uuid: string;
    avatar?: string | null;
    full_name?: string | null;
    phone?: string | null;
    public_email?: string | null;
    title?: string | null;
    bio?: string | null;
    calendly_intake_call_link?: string | null;
    calendly_proposal_call_link?: string | null;
    calendly_wealth_insights_call_link?: string | null;
    calendly_advisory_insights_call_link?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
  } | null;
};

export type CustomerWaitlistFragment = {
  __typename?: 'CustomerWaitlist';
  createdAt: any;
  uuid: string;
  waitlistCategory: string;
  waitlistName: string;
  waitlistPrice?: number | null;
};

export type AddCustomerToWaitlistMutationVariables = Exact<{
  waitlistCategory: Scalars['String']['input'];
  waitlistName: Scalars['String']['input'];
  waitlistPrice: Scalars['Float']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<
    | Array<InputMaybe<CustomerWaitlistQuestionInput>>
    | InputMaybe<CustomerWaitlistQuestionInput>
  >;
}>;

export type AddCustomerToWaitlistMutation = {
  __typename?: 'Mutation';
  addCustomerToWaitlist?: {
    __typename?: 'CustomerWaitlist';
    createdAt: any;
    uuid: string;
    waitlistCategory: string;
    waitlistName: string;
    waitlistPrice?: number | null;
  } | null;
};

export type GetWaitlistsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWaitlistsQuery = {
  __typename?: 'Query';
  allWaitlists?: Array<{
    __typename?: 'CustomerWaitlist';
    createdAt: any;
    uuid: string;
    waitlistCategory: string;
    waitlistName: string;
    waitlistPrice?: number | null;
  } | null> | null;
};

export type SearchCompaniesQueryVariables = Exact<{
  term: Scalars['String']['input'];
}>;

export type SearchCompaniesQuery = {
  __typename?: 'Query';
  companies?: Array<{
    __typename?: 'CompanySearchResultItem';
    uuid?: any | null;
    name: string;
    logo?: string | null;
    website?: string | null;
    imported?: boolean | null;
    importKey?: string | null;
    isPublic?: boolean | null;
  } | null> | null;
};

export type SelectNewCompanyMutationVariables = Exact<{
  importCompany?: InputMaybe<ImportCompanyInput>;
  newCompany?: InputMaybe<NewCompanyInput>;
}>;

export type SelectNewCompanyMutation = {
  __typename?: 'Mutation';
  selectNewCompany?: {
    __typename?: 'CompanyShortInfo';
    uuid?: any | null;
    name: string;
    logo?: string | null;
    website: string;
  } | null;
};

export type GetCompanyStatusAndSubstatusQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetCompanyStatusAndSubstatusQuery = {
  __typename?: 'Query';
  Company?: {
    __typename?: 'Company';
    company_status?: CompanyStatusGql | null;
    company_substatus?: CompanySubstatusGql | null;
  } | null;
};

export type CompanyShortInfoFragment = {
  __typename?: 'CompanyShortInfo';
  uuid?: any | null;
  name: string;
  logo?: string | null;
  website: string;
};

export type CompanySearchResultItemFragment = {
  __typename?: 'CompanySearchResultItem';
  uuid?: any | null;
  name: string;
  logo?: string | null;
  website?: string | null;
  imported?: boolean | null;
  importKey?: string | null;
  isPublic?: boolean | null;
};

export type CreateScheduledAppointmentMutationVariables = Exact<{
  affiliationUuid?: InputMaybe<Scalars['ID']['input']>;
  analytics: ScheduledAppointmentAnalyticsInput;
  appointmentType: AppointmentType;
  calendlyEventUri: Scalars['String']['input'];
  calendlyInviteeUri: Scalars['String']['input'];
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
  rescheduledAppointmentUuid?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateScheduledAppointmentMutation = {
  __typename?: 'Mutation';
  createScheduledAppointment: {
    __typename?: 'ScheduledAppointment';
    uuid: string;
    appointment_timestamp: any;
    appointment_type: AppointmentType;
    calendly_event_uri: string;
    calendly_invitee_id: string;
  };
};

export type ScheduledAppointmentFragment = {
  __typename?: 'ScheduledAppointment';
  uuid: string;
  appointment_timestamp: any;
  appointment_type: AppointmentType;
  calendly_event_uri: string;
  calendly_invitee_id: string;
};

export type GetScheduledAppointmentsQueryVariables = Exact<{
  user: Scalars['ID']['input'];
  affiliationUuid?: InputMaybe<Scalars['ID']['input']>;
  appointmentType: AppointmentType;
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetScheduledAppointmentsQuery = {
  __typename?: 'Query';
  scheduledAppointments: Array<{
    __typename?: 'ScheduledAppointment';
    uuid: string;
    appointment_timestamp: any;
    appointment_type: AppointmentType;
    calendly_event_uri: string;
    calendly_invitee_id: string;
  }>;
};

export type UpdateScheduledAppointmentMutationVariables = Exact<{
  appointment_uuid: Scalars['ID']['input'];
  riaDeal?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateScheduledAppointmentMutation = {
  __typename?: 'Mutation';
  updateScheduledAppointment: {
    __typename?: 'ScheduledAppointment';
    uuid: string;
    appointment_timestamp: any;
    appointment_type: AppointmentType;
    calendly_event_uri: string;
    calendly_invitee_id: string;
  };
};

export type StockOptionGrantFragment = {
  __typename?: 'StockOptionGrant';
  uuid: any;
  affiliation: any;
  createdAt: any;
  customer: any;
  migrationDefaultVestingSchedule: boolean;
  earlyExercisable: boolean;
  grantName: string;
  grantType: StockOptionGrantType;
  quantity: number;
  updatedAt: any;
  verified: boolean;
  vestingStartDate: any;
  vestingEndDate: any;
  deadlineAfterLeaving?: {
    __typename?: 'Period';
    amount: number;
    timeUnit: TimeUnit;
  } | null;
  exerciseEvents?: Array<{
    __typename?: 'ExerciseEvent';
    createdAt: any;
    eventDate: any;
    migrationDefaultEventDate: boolean;
    quantity: number;
    uuid: string;
    fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
    saleEvents: Array<{
      __typename?: 'SaleEvent';
      createdAt: any;
      eventDate: any;
      quantity: number;
      uuid: string;
      fmv?: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      } | null;
      price?: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      } | null;
    }>;
  }> | null;
  strikePrice: {
    __typename?: 'EquityCurrency';
    currency: string;
    value: number;
  };
  summary: {
    __typename?: 'StockOptionGrantSummary';
    exercisableQuantity: number;
    exercisedQuantity: number;
    soldQuantity: number;
    ownedQuantity: number;
    totalVestedQuantity: number;
  };
  vestingEvents: Array<{
    __typename?: 'VestingScheduleEvent';
    eventDate: any;
    quantity: number;
    type?: GrantType | null;
  }>;
  vestingScheduleTemplate?: {
    __typename?: 'VestingScheduleTemplate';
    cliff?: number | null;
    vestingFrequency: number;
    vestingPeriod: number;
  } | null;
};

export type EquityShareGrantFragment = {
  __typename?: 'EquityShareGrant';
  affiliation: any;
  uuid: any;
  createdAt: any;
  customer: any;
  issueOrPurchaseDate: any;
  grantName: string;
  grantType: EquityShareGrantType;
  migrationDefaultDateOfIssue: boolean;
  quantity: number;
  source: ShareGrantSource;
  updatedAt: any;
  verified: boolean;
  vestingStartDate?: any | null;
  vestingEndDate?: any | null;
  issueOrPurchasePrice: {
    __typename?: 'EquityCurrency';
    currency: string;
    value: number;
  };
  summary: {
    __typename?: 'EquityShareGrantSummary';
    soldQuantity: number;
    ownedQuantity: number;
    totalVestedQuantity: number;
  };
  vestingEvents?: Array<{
    __typename?: 'VestingScheduleEvent';
    eventDate: any;
    quantity: number;
    type?: GrantType | null;
  }> | null;
  vestingScheduleTemplate?: {
    __typename?: 'VestingScheduleTemplate';
    cliff?: number | null;
    vestingFrequency: number;
    vestingPeriod: number;
  } | null;
  exerciseDetail?: {
    __typename?: 'ShareGrantExerciseDetail';
    uuid: any;
    fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
    stockOptionGrant?: {
      __typename?: 'ExerciseDetailParentStockOptionGrant';
      uuid: any;
      grantType: StockOptionGrantType;
    } | null;
  } | null;
  saleEvents: Array<{
    __typename?: 'SaleEvent';
    createdAt: any;
    eventDate: any;
    quantity: number;
    uuid: string;
    fmv?: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    } | null;
    price?: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    } | null;
  }>;
};

export type RsuGrantFragment = {
  __typename?: 'RSUGrant';
  affiliation: string;
  createdAt: any;
  uuid: string;
  customer: string;
  grantName: string;
  performanceTrigger: boolean;
  quantity: number;
  migrationDefaultVestingSchedule: boolean;
  updatedAt: any;
  grantType: RsuGrantType;
  verified: boolean;
  vestingEndDate: any;
  vestingStartDate: any;
  summary: {
    __typename?: 'RSUGrantSummary';
    soldQuantity: number;
    totalVestedQuantity: number;
  };
  vestingEvents: Array<{
    __typename?: 'VestingScheduleEvent';
    eventDate: any;
    quantity: number;
    type?: GrantType | null;
  }>;
  vestingScheduleTemplate?: {
    __typename?: 'VestingScheduleTemplate';
    cliff?: number | null;
    vestingFrequency: number;
    vestingPeriod: number;
  } | null;
};

export type PeriodFragment = {
  __typename?: 'Period';
  amount: number;
  timeUnit: TimeUnit;
};

export type EquityCurrencyFragment = {
  __typename?: 'EquityCurrency';
  currency: string;
  value: number;
};

export type ExerciseEventFragment = {
  __typename?: 'ExerciseEvent';
  createdAt: any;
  eventDate: any;
  migrationDefaultEventDate: boolean;
  quantity: number;
  uuid: string;
  fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
  saleEvents: Array<{
    __typename?: 'SaleEvent';
    createdAt: any;
    eventDate: any;
    quantity: number;
    uuid: string;
    fmv?: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    } | null;
    price?: {
      __typename?: 'EquityCurrency';
      currency: string;
      value: number;
    } | null;
  }>;
};

export type StockOptionGrantSummaryFragment = {
  __typename?: 'StockOptionGrantSummary';
  exercisableQuantity: number;
  exercisedQuantity: number;
  soldQuantity: number;
  ownedQuantity: number;
  totalVestedQuantity: number;
};

export type EquityShareGrantSummaryFragment = {
  __typename?: 'EquityShareGrantSummary';
  soldQuantity: number;
  ownedQuantity: number;
  totalVestedQuantity: number;
};

export type RsuGrantSummaryFragment = {
  __typename?: 'RSUGrantSummary';
  soldQuantity: number;
  totalVestedQuantity: number;
};

export type VestingScheduleEventFragment = {
  __typename?: 'VestingScheduleEvent';
  eventDate: any;
  quantity: number;
  type?: GrantType | null;
};

export type VestingScheduleTemplateFragment = {
  __typename?: 'VestingScheduleTemplate';
  cliff?: number | null;
  vestingFrequency: number;
  vestingPeriod: number;
};

export type CreateExerciseEventMutationVariables = Exact<{
  payload: ExerciseEventInput;
}>;

export type CreateExerciseEventMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    createExerciseEvent: {
      __typename?: 'ExerciseEvent';
      createdAt: any;
      eventDate: any;
      migrationDefaultEventDate: boolean;
      quantity: number;
      uuid: string;
      fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    };
  };
};

export type CreateRsuGrantMutationVariables = Exact<{
  payload: RsuGrantInput;
}>;

export type CreateRsuGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    createRsuGrant: {
      __typename?: 'RSUGrant';
      affiliation: string;
      createdAt: any;
      uuid: string;
      customer: string;
      grantName: string;
      performanceTrigger: boolean;
      quantity: number;
      migrationDefaultVestingSchedule: boolean;
      updatedAt: any;
      grantType: RsuGrantType;
      verified: boolean;
      vestingEndDate: any;
      vestingStartDate: any;
      summary: {
        __typename?: 'RSUGrantSummary';
        soldQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type CreateEquityShareGrantMutationVariables = Exact<{
  payload: EquityShareGrantInput;
}>;

export type CreateEquityShareGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    createShareGrant: {
      __typename?: 'EquityShareGrant';
      affiliation: any;
      uuid: any;
      createdAt: any;
      customer: any;
      issueOrPurchaseDate: any;
      grantName: string;
      grantType: EquityShareGrantType;
      migrationDefaultDateOfIssue: boolean;
      quantity: number;
      source: ShareGrantSource;
      updatedAt: any;
      verified: boolean;
      vestingStartDate?: any | null;
      vestingEndDate?: any | null;
      issueOrPurchasePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'EquityShareGrantSummary';
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents?: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }> | null;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
      exerciseDetail?: {
        __typename?: 'ShareGrantExerciseDetail';
        uuid: any;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        stockOptionGrant?: {
          __typename?: 'ExerciseDetailParentStockOptionGrant';
          uuid: any;
          grantType: StockOptionGrantType;
        } | null;
      } | null;
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    };
  };
};

export type CreateStockOptionGrantMutationVariables = Exact<{
  payload: StockOptionGrantInput;
}>;

export type CreateStockOptionGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    createStockOptionGrant: {
      __typename?: 'StockOptionGrant';
      uuid: any;
      affiliation: any;
      createdAt: any;
      customer: any;
      migrationDefaultVestingSchedule: boolean;
      earlyExercisable: boolean;
      grantName: string;
      grantType: StockOptionGrantType;
      quantity: number;
      updatedAt: any;
      verified: boolean;
      vestingStartDate: any;
      vestingEndDate: any;
      deadlineAfterLeaving?: {
        __typename?: 'Period';
        amount: number;
        timeUnit: TimeUnit;
      } | null;
      exerciseEvents?: Array<{
        __typename?: 'ExerciseEvent';
        createdAt: any;
        eventDate: any;
        migrationDefaultEventDate: boolean;
        quantity: number;
        uuid: string;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        saleEvents: Array<{
          __typename?: 'SaleEvent';
          createdAt: any;
          eventDate: any;
          quantity: number;
          uuid: string;
          fmv?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
          price?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
        }>;
      }> | null;
      strikePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'StockOptionGrantSummary';
        exercisableQuantity: number;
        exercisedQuantity: number;
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type DeleteExerciseEventMutationVariables = Exact<{
  grantUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteExerciseEventMutation = {
  __typename?: 'Mutation';
  equity: { __typename?: 'EquityMutations'; deleteExerciseEvent?: any | null };
};

export type DeleteRsuGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteRsuGrantMutation = {
  __typename?: 'Mutation';
  equity: { __typename?: 'EquityMutations'; deleteRsuGrant?: any | null };
};

export type DeleteEquityShareGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteEquityShareGrantMutation = {
  __typename?: 'Mutation';
  equity: { __typename?: 'EquityMutations'; deleteShareGrant?: any | null };
};

export type DeleteStockOptionGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteStockOptionGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    deleteStockOptionGrant?: any | null;
  };
};

export type UpdateRsuGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: RsuGrantInput;
}>;

export type UpdateRsuGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    updateRsuGrant: {
      __typename?: 'RSUGrant';
      uuid: string;
      affiliation: string;
      createdAt: any;
      customer: string;
      grantName: string;
      performanceTrigger: boolean;
      quantity: number;
      migrationDefaultVestingSchedule: boolean;
      updatedAt: any;
      grantType: RsuGrantType;
      verified: boolean;
      vestingEndDate: any;
      vestingStartDate: any;
      summary: {
        __typename?: 'RSUGrantSummary';
        soldQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type UpdateEquityShareGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: EquityShareGrantInput;
}>;

export type UpdateEquityShareGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    updateShareGrant: {
      __typename?: 'EquityShareGrant';
      uuid: any;
      affiliation: any;
      createdAt: any;
      customer: any;
      issueOrPurchaseDate: any;
      grantName: string;
      grantType: EquityShareGrantType;
      migrationDefaultDateOfIssue: boolean;
      quantity: number;
      source: ShareGrantSource;
      updatedAt: any;
      verified: boolean;
      vestingStartDate?: any | null;
      vestingEndDate?: any | null;
      issueOrPurchasePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'EquityShareGrantSummary';
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents?: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }> | null;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
      exerciseDetail?: {
        __typename?: 'ShareGrantExerciseDetail';
        uuid: any;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        stockOptionGrant?: {
          __typename?: 'ExerciseDetailParentStockOptionGrant';
          uuid: any;
          grantType: StockOptionGrantType;
        } | null;
      } | null;
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    };
  };
};

export type UpdateStockOptionGrantMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: StockOptionGrantInput;
}>;

export type UpdateStockOptionGrantMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    updateStockOptionGrant: {
      __typename?: 'StockOptionGrant';
      uuid: any;
      affiliation: any;
      createdAt: any;
      customer: any;
      migrationDefaultVestingSchedule: boolean;
      earlyExercisable: boolean;
      grantName: string;
      grantType: StockOptionGrantType;
      quantity: number;
      updatedAt: any;
      verified: boolean;
      vestingStartDate: any;
      vestingEndDate: any;
      deadlineAfterLeaving?: {
        __typename?: 'Period';
        amount: number;
        timeUnit: TimeUnit;
      } | null;
      exerciseEvents?: Array<{
        __typename?: 'ExerciseEvent';
        createdAt: any;
        eventDate: any;
        migrationDefaultEventDate: boolean;
        quantity: number;
        uuid: string;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        saleEvents: Array<{
          __typename?: 'SaleEvent';
          createdAt: any;
          eventDate: any;
          quantity: number;
          uuid: string;
          fmv?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
          price?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
        }>;
      }> | null;
      strikePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'StockOptionGrantSummary';
        exercisableQuantity: number;
        exercisedQuantity: number;
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type UpdateExerciseEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  payload: ExerciseEventInput;
}>;

export type UpdateExerciseEventMutation = {
  __typename?: 'Mutation';
  equity: {
    __typename?: 'EquityMutations';
    updateExerciseEvent: {
      __typename?: 'ExerciseEvent';
      uuid: string;
      createdAt: any;
      eventDate: any;
      migrationDefaultEventDate: boolean;
      quantity: number;
      fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    };
  };
};

export type CalculateVestedOptionsQueryVariables = Exact<{
  payload: VestedOptionsCalculationInput;
}>;

export type CalculateVestedOptionsQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    calculateVestedOptions: {
      __typename?: 'VestedOptionsCalculation';
      vestedOptions: number;
    };
  };
};

export type GetAllGrantsQueryVariables = Exact<{
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  customer: Scalars['UUID']['input'];
}>;

export type GetAllGrantsQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getAllGrants: {
      __typename?: 'AllGrants';
      stockOptionGrants: Array<{
        __typename?: 'StockOptionGrant';
        uuid: any;
        affiliation: any;
        createdAt: any;
        customer: any;
        migrationDefaultVestingSchedule: boolean;
        earlyExercisable: boolean;
        grantName: string;
        grantType: StockOptionGrantType;
        quantity: number;
        updatedAt: any;
        verified: boolean;
        vestingStartDate: any;
        vestingEndDate: any;
        deadlineAfterLeaving?: {
          __typename?: 'Period';
          amount: number;
          timeUnit: TimeUnit;
        } | null;
        exerciseEvents?: Array<{
          __typename?: 'ExerciseEvent';
          createdAt: any;
          eventDate: any;
          migrationDefaultEventDate: boolean;
          quantity: number;
          uuid: string;
          fmv: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          };
          saleEvents: Array<{
            __typename?: 'SaleEvent';
            createdAt: any;
            eventDate: any;
            quantity: number;
            uuid: string;
            fmv?: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            } | null;
            price?: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            } | null;
          }>;
        }> | null;
        strikePrice: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        };
        summary: {
          __typename?: 'StockOptionGrantSummary';
          exercisableQuantity: number;
          exercisedQuantity: number;
          soldQuantity: number;
          ownedQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }>;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
      }>;
      shareGrants: Array<{
        __typename?: 'EquityShareGrant';
        affiliation: any;
        uuid: any;
        createdAt: any;
        customer: any;
        issueOrPurchaseDate: any;
        grantName: string;
        grantType: EquityShareGrantType;
        migrationDefaultDateOfIssue: boolean;
        quantity: number;
        source: ShareGrantSource;
        updatedAt: any;
        verified: boolean;
        vestingStartDate?: any | null;
        vestingEndDate?: any | null;
        issueOrPurchasePrice: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        };
        summary: {
          __typename?: 'EquityShareGrantSummary';
          soldQuantity: number;
          ownedQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents?: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }> | null;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
        exerciseDetail?: {
          __typename?: 'ShareGrantExerciseDetail';
          uuid: any;
          fmv: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          };
          stockOptionGrant?: {
            __typename?: 'ExerciseDetailParentStockOptionGrant';
            uuid: any;
            grantType: StockOptionGrantType;
          } | null;
        } | null;
        saleEvents: Array<{
          __typename?: 'SaleEvent';
          createdAt: any;
          eventDate: any;
          quantity: number;
          uuid: string;
          fmv?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
          price?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
        }>;
      }>;
      rsuGrants: Array<{
        __typename?: 'RSUGrant';
        affiliation: string;
        createdAt: any;
        uuid: string;
        customer: string;
        grantName: string;
        performanceTrigger: boolean;
        quantity: number;
        migrationDefaultVestingSchedule: boolean;
        updatedAt: any;
        grantType: RsuGrantType;
        verified: boolean;
        vestingEndDate: any;
        vestingStartDate: any;
        summary: {
          __typename?: 'RSUGrantSummary';
          soldQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }>;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
      }>;
    };
  };
};

export type GetCartaConnectionStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCartaConnectionStatusQuery = {
  __typename?: 'Query';
  getCartaConnectionStatus: {
    __typename?: 'CartaConnectionStatusResult';
    status: CartaConnectionStatus;
    disconnectedAt?: any | null;
    lastUpdatedAt?: any | null;
  };
};

export type GetCartaLoginUrlQueryVariables = Exact<{
  redirectUrl: Scalars['String']['input'];
  flowType: CartaFlowType;
  companyUuids?: InputMaybe<
    Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  >;
}>;

export type GetCartaLoginUrlQuery = {
  __typename?: 'Query';
  getCartaLoginUrl: {
    __typename?: 'CartaLoginUrlResponse';
    cartaLoginUrl: string;
  };
};

export type GetEquityShareGrantQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GetEquityShareGrantQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getShareGrant: {
      __typename?: 'EquityShareGrant';
      affiliation: any;
      uuid: any;
      createdAt: any;
      customer: any;
      issueOrPurchaseDate: any;
      grantName: string;
      grantType: EquityShareGrantType;
      migrationDefaultDateOfIssue: boolean;
      quantity: number;
      source: ShareGrantSource;
      updatedAt: any;
      verified: boolean;
      vestingStartDate?: any | null;
      vestingEndDate?: any | null;
      issueOrPurchasePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'EquityShareGrantSummary';
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents?: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }> | null;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
      exerciseDetail?: {
        __typename?: 'ShareGrantExerciseDetail';
        uuid: any;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        stockOptionGrant?: {
          __typename?: 'ExerciseDetailParentStockOptionGrant';
          uuid: any;
          grantType: StockOptionGrantType;
        } | null;
      } | null;
      saleEvents: Array<{
        __typename?: 'SaleEvent';
        createdAt: any;
        eventDate: any;
        quantity: number;
        uuid: string;
        fmv?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
        price?: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        } | null;
      }>;
    };
  };
};

export type GetRsuGrantQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GetRsuGrantQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getRsuGrant: {
      __typename?: 'RSUGrant';
      affiliation: string;
      createdAt: any;
      uuid: string;
      customer: string;
      grantName: string;
      performanceTrigger: boolean;
      quantity: number;
      migrationDefaultVestingSchedule: boolean;
      updatedAt: any;
      grantType: RsuGrantType;
      verified: boolean;
      vestingEndDate: any;
      vestingStartDate: any;
      summary: {
        __typename?: 'RSUGrantSummary';
        soldQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type GetRsuGrantsQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  customer: Scalars['UUID']['input'];
}>;

export type GetRsuGrantsQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getAllGrants: {
      __typename?: 'AllGrants';
      rsuGrants: Array<{
        __typename?: 'RSUGrant';
        affiliation: string;
        createdAt: any;
        uuid: string;
        customer: string;
        grantName: string;
        performanceTrigger: boolean;
        quantity: number;
        migrationDefaultVestingSchedule: boolean;
        updatedAt: any;
        grantType: RsuGrantType;
        verified: boolean;
        vestingEndDate: any;
        vestingStartDate: any;
        summary: {
          __typename?: 'RSUGrantSummary';
          soldQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }>;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
      }>;
    };
  };
};

export type GetShareGrantsQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  customer: Scalars['UUID']['input'];
}>;

export type GetShareGrantsQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getAllGrants: {
      __typename?: 'AllGrants';
      shareGrants: Array<{
        __typename?: 'EquityShareGrant';
        affiliation: any;
        uuid: any;
        createdAt: any;
        customer: any;
        issueOrPurchaseDate: any;
        grantName: string;
        grantType: EquityShareGrantType;
        migrationDefaultDateOfIssue: boolean;
        quantity: number;
        source: ShareGrantSource;
        updatedAt: any;
        verified: boolean;
        vestingStartDate?: any | null;
        vestingEndDate?: any | null;
        issueOrPurchasePrice: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        };
        summary: {
          __typename?: 'EquityShareGrantSummary';
          soldQuantity: number;
          ownedQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents?: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }> | null;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
        exerciseDetail?: {
          __typename?: 'ShareGrantExerciseDetail';
          uuid: any;
          fmv: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          };
          stockOptionGrant?: {
            __typename?: 'ExerciseDetailParentStockOptionGrant';
            uuid: any;
            grantType: StockOptionGrantType;
          } | null;
        } | null;
        saleEvents: Array<{
          __typename?: 'SaleEvent';
          createdAt: any;
          eventDate: any;
          quantity: number;
          uuid: string;
          fmv?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
          price?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
        }>;
      }>;
    };
  };
};

export type GetStockOptionGrantQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GetStockOptionGrantQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getStockOptionGrant: {
      __typename?: 'StockOptionGrant';
      uuid: any;
      affiliation: any;
      createdAt: any;
      customer: any;
      migrationDefaultVestingSchedule: boolean;
      earlyExercisable: boolean;
      grantName: string;
      grantType: StockOptionGrantType;
      quantity: number;
      updatedAt: any;
      verified: boolean;
      vestingStartDate: any;
      vestingEndDate: any;
      deadlineAfterLeaving?: {
        __typename?: 'Period';
        amount: number;
        timeUnit: TimeUnit;
      } | null;
      exerciseEvents?: Array<{
        __typename?: 'ExerciseEvent';
        createdAt: any;
        eventDate: any;
        migrationDefaultEventDate: boolean;
        quantity: number;
        uuid: string;
        fmv: { __typename?: 'EquityCurrency'; currency: string; value: number };
        saleEvents: Array<{
          __typename?: 'SaleEvent';
          createdAt: any;
          eventDate: any;
          quantity: number;
          uuid: string;
          fmv?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
          price?: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          } | null;
        }>;
      }> | null;
      strikePrice: {
        __typename?: 'EquityCurrency';
        currency: string;
        value: number;
      };
      summary: {
        __typename?: 'StockOptionGrantSummary';
        exercisableQuantity: number;
        exercisedQuantity: number;
        soldQuantity: number;
        ownedQuantity: number;
        totalVestedQuantity: number;
      };
      vestingEvents: Array<{
        __typename?: 'VestingScheduleEvent';
        eventDate: any;
        quantity: number;
        type?: GrantType | null;
      }>;
      vestingScheduleTemplate?: {
        __typename?: 'VestingScheduleTemplate';
        cliff?: number | null;
        vestingFrequency: number;
        vestingPeriod: number;
      } | null;
    };
  };
};

export type GetStockOptionGrantsQueryVariables = Exact<{
  affiliation: Scalars['UUID']['input'];
  customer: Scalars['UUID']['input'];
}>;

export type GetStockOptionGrantsQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    getAllGrants: {
      __typename?: 'AllGrants';
      stockOptionGrants: Array<{
        __typename?: 'StockOptionGrant';
        uuid: any;
        affiliation: any;
        createdAt: any;
        customer: any;
        migrationDefaultVestingSchedule: boolean;
        earlyExercisable: boolean;
        grantName: string;
        grantType: StockOptionGrantType;
        quantity: number;
        updatedAt: any;
        verified: boolean;
        vestingStartDate: any;
        vestingEndDate: any;
        deadlineAfterLeaving?: {
          __typename?: 'Period';
          amount: number;
          timeUnit: TimeUnit;
        } | null;
        exerciseEvents?: Array<{
          __typename?: 'ExerciseEvent';
          createdAt: any;
          eventDate: any;
          migrationDefaultEventDate: boolean;
          quantity: number;
          uuid: string;
          fmv: {
            __typename?: 'EquityCurrency';
            currency: string;
            value: number;
          };
          saleEvents: Array<{
            __typename?: 'SaleEvent';
            createdAt: any;
            eventDate: any;
            quantity: number;
            uuid: string;
            fmv?: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            } | null;
            price?: {
              __typename?: 'EquityCurrency';
              currency: string;
              value: number;
            } | null;
          }>;
        }> | null;
        strikePrice: {
          __typename?: 'EquityCurrency';
          currency: string;
          value: number;
        };
        summary: {
          __typename?: 'StockOptionGrantSummary';
          exercisableQuantity: number;
          exercisedQuantity: number;
          soldQuantity: number;
          ownedQuantity: number;
          totalVestedQuantity: number;
        };
        vestingEvents: Array<{
          __typename?: 'VestingScheduleEvent';
          eventDate: any;
          quantity: number;
          type?: GrantType | null;
        }>;
        vestingScheduleTemplate?: {
          __typename?: 'VestingScheduleTemplate';
          cliff?: number | null;
          vestingFrequency: number;
          vestingPeriod: number;
        } | null;
      }>;
    };
  };
};

export type ImportCartaDataMutationVariables = Exact<{ [key: string]: never }>;

export type ImportCartaDataMutation = {
  __typename?: 'Mutation';
  importCartaData: { __typename?: 'CartaDataImportResult'; scheduled: boolean };
};

export type GetCompanyNameByAffiliationQueryVariables = Exact<{
  affiliation: Scalars['String']['input'];
}>;

export type GetCompanyNameByAffiliationQuery = {
  __typename?: 'Query';
  affiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    company: { __typename?: 'Company'; uuid: string; name: string };
  } | null;
};

export type UsTaxInformationFragment = {
  __typename?: 'UsTaxInformation';
  uuid: string;
  dependents: number;
  filing_type: FilingType;
  income: { __typename?: 'CurrencyModel'; value: string; currency: string };
  dividend_income: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  medical_expenses: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  ira_contribution: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  mortgage_interest: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  additional_income: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  additional_deductions: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  four01k_contributions: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  self_employment_income: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  long_term_capital_gains: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  charitable_contributions: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  short_term_capital_gains: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  property_state_local_taxes: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
  net_rental_real_estate_income: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  };
};

export type CreateOrUpdateUsTaxInformationMutationVariables = Exact<{
  usTaxInfo?: InputMaybe<UsTaxInformationBaseInput>;
}>;

export type CreateOrUpdateUsTaxInformationMutation = {
  __typename?: 'Mutation';
  usTaxInfo?: {
    __typename?: 'UsTaxInformation';
    uuid: string;
    dependents: number;
    filing_type: FilingType;
    income: { __typename?: 'CurrencyModel'; value: string; currency: string };
    dividend_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    medical_expenses: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    ira_contribution: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    mortgage_interest: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    additional_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    additional_deductions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    four01k_contributions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    self_employment_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    long_term_capital_gains: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    charitable_contributions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    short_term_capital_gains: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    property_state_local_taxes: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    net_rental_real_estate_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
  } | null;
};

export type GetAllUsTaxInfoQueryVariables = Exact<{
  customer?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAllUsTaxInfoQuery = {
  __typename?: 'Query';
  allTaxInfo?: {
    __typename?: 'AllUsTaxInformationResponse';
    totalCount?: number | null;
    nodes: Array<{
      __typename?: 'UsTaxInformation';
      uuid: string;
      dependents: number;
      filing_type: FilingType;
      income: { __typename?: 'CurrencyModel'; value: string; currency: string };
      dividend_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      medical_expenses: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      ira_contribution: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      mortgage_interest: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      additional_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      additional_deductions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      four01k_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      self_employment_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      long_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      charitable_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      short_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      property_state_local_taxes: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      net_rental_real_estate_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    }>;
  } | null;
};

export type DeleteAffiliationMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type DeleteAffiliationMutation = {
  __typename?: 'Mutation';
  deleteAffiliation?: number | null;
};

export type ValidateEmploymentEndDateQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  affiliation: Scalars['UUID']['input'];
  eventDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type ValidateEmploymentEndDateQuery = {
  __typename?: 'Query';
  equity: {
    __typename?: 'EquityQueries';
    validateEmploymentEndDate: {
      __typename?: 'AllGrantsValidation';
      isValid: boolean;
    };
  };
};

export type AffiliationDetailsFragment = {
  __typename?: 'Affiliation';
  uuid: string;
  country?: string | null;
  subdivision?: string | null;
  city?: string | null;
  period?: any | null;
  external_source?: ExternalSource | null;
  company: {
    __typename?: 'Company';
    uuid: string;
    name: string;
    latest_equity_series_date?: any | null;
    cap_table_verified?: boolean | null;
    is_fast_track?: boolean | null;
    has_verified_pref_price?: boolean | null;
    logo?: string | null;
    ipo_date?: any | null;
    company_status?: CompanyStatusGql | null;
    company_substatus?: CompanySubstatusGql | null;
  };
  company_assessment?: {
    __typename?: 'CompanyAssessment';
    uuid: string;
    updated_at?: string | null;
    verified: boolean;
    ipo_or_manda?: number | null;
    expected_exit_date?: any | null;
    expected_exit_multiplier?: number | null;
    verified_captable_requested?: boolean | null;
    common_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    expected_exit_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
};

export type AffiliationOverviewFragment = {
  __typename?: 'Affiliation';
  uuid: string;
  country?: string | null;
  subdivision?: string | null;
  city?: string | null;
  period?: any | null;
  user_triage_answers?: Array<UserTriageAnswers | null> | null;
  has_active_financing_request: boolean;
  external_source?: ExternalSource | null;
  has_financing_request: boolean;
  has_pending_financing_request: boolean;
  has_generated_plans: boolean;
  company: {
    __typename?: 'Company';
    uuid: string;
    name: string;
    logo?: string | null;
    legal_name?: string | null;
    latest_equity_series_date?: any | null;
    last_issue_price_date?: any | null;
    cap_table_verified?: boolean | null;
    company_status?: CompanyStatusGql | null;
    company_substatus?: CompanySubstatusGql | null;
    is_fast_track?: boolean | null;
    website?: string | null;
    ipo_date?: any | null;
    ticker?: string | null;
    ipo_lockup_end_date?: any | null;
    public_price?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    adjusted_funding_rounds?: Array<{
      __typename?: 'AdjustedFundingRound';
      description?: string | null;
      date: string;
      adjusted_issue_price?: string | null;
      issue_price: string;
      post_valuation?: string | null;
    } | null> | null;
    latest_verified_company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      updated_at?: string | null;
      verified: boolean;
      ipo_or_manda?: number | null;
      expected_exit_date?: any | null;
      expected_exit_multiplier?: number | null;
      verified_captable_requested?: boolean | null;
      common_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      preferred_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      expected_exit_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
  };
  customer: {
    __typename?: 'Customer';
    uuid: string;
    show_initial_screen?: boolean | null;
  };
  company_assessment?: {
    __typename?: 'CompanyAssessment';
    uuid: string;
    updated_at?: string | null;
    verified: boolean;
    ipo_or_manda?: number | null;
    expected_exit_date?: any | null;
    expected_exit_multiplier?: number | null;
    verified_captable_requested?: boolean | null;
    common_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    expected_exit_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
  us_tax_information?: {
    __typename?: 'UsTaxInformation';
    uuid: string;
    dependents: number;
    filing_type: FilingType;
    income: { __typename?: 'CurrencyModel'; value: string; currency: string };
    additional_deductions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    additional_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    charitable_contributions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    dividend_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    four01k_contributions: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    ira_contribution: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    long_term_capital_gains: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    medical_expenses: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    mortgage_interest: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    net_rental_real_estate_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    property_state_local_taxes: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    self_employment_income: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
    short_term_capital_gains: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    };
  } | null;
};

export type AllocationTableEntryErrorFragment = {
  __typename?: 'PortfolioError';
  code: PortfolioErrorCode;
  details: { __typename?: 'GenericErrorDetails'; message: string };
};

export type AllocationTableEntryFragment = {
  __typename?: 'AllocationTableEntry';
  type: AssetType;
  name?: string | null;
  logo?: string | null;
  value: number;
  weight: number;
  quantity?: number | null;
  companyUuid?: any | null;
  errors?: Array<{
    __typename?: 'PortfolioError';
    code: PortfolioErrorCode;
    details: { __typename?: 'GenericErrorDetails'; message: string };
  }> | null;
};

export type NetWorthAllocationTableFragment = {
  __typename?: 'AllocationTable';
  totalValue: number;
  items: Array<{
    __typename?: 'AllocationTableEntry';
    type: AssetType;
    name?: string | null;
    logo?: string | null;
    value: number;
    weight: number;
    quantity?: number | null;
    companyUuid?: any | null;
    errors?: Array<{
      __typename?: 'PortfolioError';
      code: PortfolioErrorCode;
      details: { __typename?: 'GenericErrorDetails'; message: string };
    }> | null;
  }>;
};

type GraphMarker_GraphMarkerCliff_Fragment = {
  __typename: 'GraphMarkerCliff';
  timestamp: any;
  company_name: string;
  grant_type: string;
  percentage_vested: number;
  vested: number;
};

type GraphMarker_GraphMarkerEndOfEmployment_Fragment = {
  __typename: 'GraphMarkerEndOfEmployment';
  timestamp: any;
  company_name: string;
};

type GraphMarker_GraphMarkerEndOfVesting_Fragment = {
  __typename: 'GraphMarkerEndOfVesting';
  timestamp: any;
  company_name: string;
  grant_type: string;
  vested: number;
};

type GraphMarker_GraphMarkerFirstHolding_Fragment = {
  __typename: 'GraphMarkerFirstHolding';
  timestamp: any;
  institution_name: string;
};

type GraphMarker_GraphMarkerFundingRound_Fragment = {
  __typename: 'GraphMarkerFundingRound';
  timestamp: any;
  company_name: string;
  round_name: string;
};

type GraphMarker_GraphMarkerIpo_Fragment = {
  __typename: 'GraphMarkerIPO';
  timestamp: any;
  company_name: string;
};

type GraphMarker_GraphMarkerStockSplit_Fragment = {
  __typename: 'GraphMarkerStockSplit';
  timestamp: any;
  company_name: string;
  split_ratio: string;
};

export type GraphMarkerFragment =
  | GraphMarker_GraphMarkerCliff_Fragment
  | GraphMarker_GraphMarkerEndOfEmployment_Fragment
  | GraphMarker_GraphMarkerEndOfVesting_Fragment
  | GraphMarker_GraphMarkerFirstHolding_Fragment
  | GraphMarker_GraphMarkerFundingRound_Fragment
  | GraphMarker_GraphMarkerIpo_Fragment
  | GraphMarker_GraphMarkerStockSplit_Fragment;

export type CustomerFlagFragment = {
  __typename?: 'CustomerFlag';
  customer: any;
  affiliation?: any | null;
  flag: string;
  createdAt: any;
};

export type CreateAffiliationMutationVariables = Exact<{
  payload?: InputMaybe<AffiliationCreateInput>;
}>;

export type CreateAffiliationMutation = {
  __typename?: 'Mutation';
  createAffiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    period?: any | null;
    user_triage_answers?: Array<UserTriageAnswers | null> | null;
    has_active_financing_request: boolean;
    external_source?: ExternalSource | null;
    has_financing_request: boolean;
    has_pending_financing_request: boolean;
    has_generated_plans: boolean;
    company: {
      __typename?: 'Company';
      uuid: string;
      name: string;
      logo?: string | null;
      legal_name?: string | null;
      latest_equity_series_date?: any | null;
      last_issue_price_date?: any | null;
      cap_table_verified?: boolean | null;
      company_status?: CompanyStatusGql | null;
      company_substatus?: CompanySubstatusGql | null;
      is_fast_track?: boolean | null;
      website?: string | null;
      ipo_date?: any | null;
      ticker?: string | null;
      ipo_lockup_end_date?: any | null;
      public_price?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      adjusted_funding_rounds?: Array<{
        __typename?: 'AdjustedFundingRound';
        description?: string | null;
        date: string;
        adjusted_issue_price?: string | null;
        issue_price: string;
        post_valuation?: string | null;
      } | null> | null;
      latest_verified_company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        updated_at?: string | null;
        verified: boolean;
        ipo_or_manda?: number | null;
        expected_exit_date?: any | null;
        expected_exit_multiplier?: number | null;
        verified_captable_requested?: boolean | null;
        common_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        preferred_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        four09a_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        expected_exit_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
      } | null;
    };
    customer: {
      __typename?: 'Customer';
      uuid: string;
      show_initial_screen?: boolean | null;
    };
    company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      updated_at?: string | null;
      verified: boolean;
      ipo_or_manda?: number | null;
      expected_exit_date?: any | null;
      expected_exit_multiplier?: number | null;
      verified_captable_requested?: boolean | null;
      common_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      preferred_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      expected_exit_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
    us_tax_information?: {
      __typename?: 'UsTaxInformation';
      uuid: string;
      dependents: number;
      filing_type: FilingType;
      income: { __typename?: 'CurrencyModel'; value: string; currency: string };
      additional_deductions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      additional_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      charitable_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      dividend_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      four01k_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      ira_contribution: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      long_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      medical_expenses: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      mortgage_interest: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      net_rental_real_estate_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      property_state_local_taxes: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      self_employment_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      short_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    } | null;
  } | null;
};

export type DisconnectCartaAccountMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DisconnectCartaAccountMutation = {
  __typename?: 'Mutation';
  disconnectCartaAccount: {
    __typename?: 'CartaAccountDisconnectResult';
    disconnected: boolean;
  };
};

export type SetCustomerFlagMutationVariables = Exact<{
  customer: Scalars['UUID']['input'];
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  flag: Scalars['String']['input'];
}>;

export type SetCustomerFlagMutation = {
  __typename?: 'Mutation';
  setCustomerFlag: {
    __typename?: 'CustomerFlag';
    customer: any;
    affiliation?: any | null;
    flag: string;
    createdAt: any;
  };
};

export type SignInWithSignInTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type SignInWithSignInTokenMutation = {
  __typename?: 'Mutation';
  signInWithSignInToken?:
    | {
        __typename?: 'SignInResponse';
        refresh_token?: string | null;
        access_token?: string | null;
        token?: string | null;
        duration?: number | null;
        uuid?: string | null;
        customer?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        username?: string | null;
        is_verified?: boolean | null;
      }
    | { __typename?: 'SignInTokenInvalidError'; error: string }
    | null;
};

export type UpdateAffiliationDetailsMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  payload: AffiliationInput;
}>;

export type UpdateAffiliationDetailsMutation = {
  __typename?: 'Mutation';
  updateAffiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    period?: any | null;
    external_source?: ExternalSource | null;
    company: {
      __typename?: 'Company';
      uuid: string;
      name: string;
      latest_equity_series_date?: any | null;
      cap_table_verified?: boolean | null;
      is_fast_track?: boolean | null;
      has_verified_pref_price?: boolean | null;
      logo?: string | null;
      ipo_date?: any | null;
      company_status?: CompanyStatusGql | null;
      company_substatus?: CompanySubstatusGql | null;
    };
    company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      updated_at?: string | null;
      verified: boolean;
      ipo_or_manda?: number | null;
      expected_exit_date?: any | null;
      expected_exit_multiplier?: number | null;
      verified_captable_requested?: boolean | null;
      common_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      preferred_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      expected_exit_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  payload: CustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: 'Mutation';
  customer?: {
    __typename?: 'Customer';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
  } | null;
};

export type GetAffiliationDetailsQueryVariables = Exact<{
  affiliationUuid: Scalars['String']['input'];
}>;

export type GetAffiliationDetailsQuery = {
  __typename?: 'Query';
  affiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    period?: any | null;
    external_source?: ExternalSource | null;
    company: {
      __typename?: 'Company';
      uuid: string;
      name: string;
      latest_equity_series_date?: any | null;
      cap_table_verified?: boolean | null;
      is_fast_track?: boolean | null;
      has_verified_pref_price?: boolean | null;
      logo?: string | null;
      ipo_date?: any | null;
      company_status?: CompanyStatusGql | null;
      company_substatus?: CompanySubstatusGql | null;
    };
    company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      updated_at?: string | null;
      verified: boolean;
      ipo_or_manda?: number | null;
      expected_exit_date?: any | null;
      expected_exit_multiplier?: number | null;
      verified_captable_requested?: boolean | null;
      common_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      preferred_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      expected_exit_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
  } | null;
};

export type GetAffiliationOverviewQueryVariables = Exact<{
  affiliation: Scalars['String']['input'];
}>;

export type GetAffiliationOverviewQuery = {
  __typename?: 'Query';
  affiliation?: {
    __typename?: 'Affiliation';
    uuid: string;
    country?: string | null;
    subdivision?: string | null;
    city?: string | null;
    period?: any | null;
    user_triage_answers?: Array<UserTriageAnswers | null> | null;
    has_active_financing_request: boolean;
    external_source?: ExternalSource | null;
    has_financing_request: boolean;
    has_pending_financing_request: boolean;
    has_generated_plans: boolean;
    company: {
      __typename?: 'Company';
      uuid: string;
      name: string;
      logo?: string | null;
      legal_name?: string | null;
      latest_equity_series_date?: any | null;
      last_issue_price_date?: any | null;
      cap_table_verified?: boolean | null;
      company_status?: CompanyStatusGql | null;
      company_substatus?: CompanySubstatusGql | null;
      is_fast_track?: boolean | null;
      website?: string | null;
      ipo_date?: any | null;
      ticker?: string | null;
      ipo_lockup_end_date?: any | null;
      public_price?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      adjusted_funding_rounds?: Array<{
        __typename?: 'AdjustedFundingRound';
        description?: string | null;
        date: string;
        adjusted_issue_price?: string | null;
        issue_price: string;
        post_valuation?: string | null;
      } | null> | null;
      latest_verified_company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        updated_at?: string | null;
        verified: boolean;
        ipo_or_manda?: number | null;
        expected_exit_date?: any | null;
        expected_exit_multiplier?: number | null;
        verified_captable_requested?: boolean | null;
        common_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        preferred_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        four09a_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        expected_exit_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
      } | null;
    };
    customer: {
      __typename?: 'Customer';
      uuid: string;
      show_initial_screen?: boolean | null;
    };
    company_assessment?: {
      __typename?: 'CompanyAssessment';
      uuid: string;
      updated_at?: string | null;
      verified: boolean;
      ipo_or_manda?: number | null;
      expected_exit_date?: any | null;
      expected_exit_multiplier?: number | null;
      verified_captable_requested?: boolean | null;
      common_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      preferred_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      four09a_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
      expected_exit_share_value?: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      } | null;
    } | null;
    us_tax_information?: {
      __typename?: 'UsTaxInformation';
      uuid: string;
      dependents: number;
      filing_type: FilingType;
      income: { __typename?: 'CurrencyModel'; value: string; currency: string };
      additional_deductions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      additional_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      charitable_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      dividend_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      four01k_contributions: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      ira_contribution: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      long_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      medical_expenses: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      mortgage_interest: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      net_rental_real_estate_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      property_state_local_taxes: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      self_employment_income: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
      short_term_capital_gains: {
        __typename?: 'CurrencyModel';
        value: string;
        currency: string;
      };
    } | null;
  } | null;
};

export type GetAllAffiliationOverviewQueryVariables = Exact<{
  customerUuid: Scalars['ID']['input'];
}>;

export type GetAllAffiliationOverviewQuery = {
  __typename?: 'Query';
  affiliations?: {
    __typename?: 'AllAffiliationsResponse';
    nodes: Array<{
      __typename?: 'Affiliation';
      uuid: string;
      country?: string | null;
      subdivision?: string | null;
      city?: string | null;
      period?: any | null;
      user_triage_answers?: Array<UserTriageAnswers | null> | null;
      has_active_financing_request: boolean;
      external_source?: ExternalSource | null;
      has_financing_request: boolean;
      has_pending_financing_request: boolean;
      has_generated_plans: boolean;
      company: {
        __typename?: 'Company';
        uuid: string;
        name: string;
        logo?: string | null;
        legal_name?: string | null;
        latest_equity_series_date?: any | null;
        last_issue_price_date?: any | null;
        cap_table_verified?: boolean | null;
        company_status?: CompanyStatusGql | null;
        company_substatus?: CompanySubstatusGql | null;
        is_fast_track?: boolean | null;
        website?: string | null;
        ipo_date?: any | null;
        ticker?: string | null;
        ipo_lockup_end_date?: any | null;
        public_price?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        adjusted_funding_rounds?: Array<{
          __typename?: 'AdjustedFundingRound';
          description?: string | null;
          date: string;
          adjusted_issue_price?: string | null;
          issue_price: string;
          post_valuation?: string | null;
        } | null> | null;
        latest_verified_company_assessment?: {
          __typename?: 'CompanyAssessment';
          uuid: string;
          updated_at?: string | null;
          verified: boolean;
          ipo_or_manda?: number | null;
          expected_exit_date?: any | null;
          expected_exit_multiplier?: number | null;
          verified_captable_requested?: boolean | null;
          common_share_value?: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          } | null;
          preferred_share_value?: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          } | null;
          four09a_share_value?: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          } | null;
          expected_exit_share_value?: {
            __typename?: 'CurrencyModel';
            value: string;
            currency: string;
          } | null;
        } | null;
      };
      customer: {
        __typename?: 'Customer';
        uuid: string;
        show_initial_screen?: boolean | null;
      };
      company_assessment?: {
        __typename?: 'CompanyAssessment';
        uuid: string;
        updated_at?: string | null;
        verified: boolean;
        ipo_or_manda?: number | null;
        expected_exit_date?: any | null;
        expected_exit_multiplier?: number | null;
        verified_captable_requested?: boolean | null;
        common_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        preferred_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        four09a_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
        expected_exit_share_value?: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        } | null;
      } | null;
      us_tax_information?: {
        __typename?: 'UsTaxInformation';
        uuid: string;
        dependents: number;
        filing_type: FilingType;
        income: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        additional_deductions: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        additional_income: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        charitable_contributions: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        dividend_income: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        four01k_contributions: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        ira_contribution: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        long_term_capital_gains: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        medical_expenses: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        mortgage_interest: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        net_rental_real_estate_income: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        property_state_local_taxes: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        self_employment_income: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
        short_term_capital_gains: {
          __typename?: 'CurrencyModel';
          value: string;
          currency: string;
        };
      } | null;
    }>;
  } | null;
};

export type GetCurrentPublicPricesQueryVariables = Exact<{
  customerUuid: Scalars['UUID']['input'];
  tickers:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type GetCurrentPublicPricesQuery = {
  __typename?: 'Query';
  currentPublicPrices?: Array<{
    __typename?: 'PublicPrice';
    ticker: string;
    price: number;
  } | null> | null;
};

export type GetCustomerFlagQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  affiliation?: InputMaybe<Scalars['UUID']['input']>;
  flag: Scalars['String']['input'];
}>;

export type GetCustomerFlagQuery = {
  __typename?: 'Query';
  getCustomerFlag?: {
    __typename?: 'CustomerFlag';
    customer: any;
    affiliation?: any | null;
    flag: string;
    createdAt: any;
  } | null;
};

export type GetCustomersByUserQueryVariables = Exact<{
  userUuid?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetCustomersByUserQuery = {
  __typename?: 'Query';
  customers?: {
    __typename?: 'AllCustomersResponse';
    nodes: Array<{
      __typename?: 'Customer';
      uuid: string;
      address_1?: string | null;
      address_2?: string | null;
      city?: string | null;
      country?: string | null;
      kyc_status?: KycStatus | null;
      nickname?: string | null;
      phone_number?: string | null;
      pipedrive_deal_id?: number | null;
      subdivision?: string | null;
      zip_code?: string | null;
      show_equity_planner?: boolean | null;
    }>;
  } | null;
};

export type GetFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFormOptionsQuery = {
  __typename?: 'Query';
  formOptions?: {
    __typename?: 'FormOptions';
    countries?: Array<{
      __typename?: 'BasicFormOption';
      label?: string | null;
      value?: string | null;
    } | null> | null;
    subdivisions?: Array<{
      __typename?: 'Subdivision';
      country_code?: string | null;
      label?: string | null;
      value?: string | null;
    } | null> | null;
    filing_types?: Array<{
      __typename?: 'BasicFormOption';
      value?: string | null;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type GetTotalNetWorthQueryVariables = Exact<{
  customer: Scalars['UUID']['input'];
  managedBy?: InputMaybe<PortfolioManagedBySelector>;
}>;

export type GetTotalNetWorthQuery = {
  __typename?: 'Query';
  totalNetWorth: {
    __typename?: 'TotalNetWorthPortfolioView';
    allocationTable: {
      __typename?: 'AllocationTable';
      totalValue: number;
      items: Array<{
        __typename?: 'AllocationTableEntry';
        type: AssetType;
        name?: string | null;
        logo?: string | null;
        value: number;
        weight: number;
        quantity?: number | null;
        companyUuid?: any | null;
        errors?: Array<{
          __typename?: 'PortfolioError';
          code: PortfolioErrorCode;
          details: { __typename?: 'GenericErrorDetails'; message: string };
        }> | null;
      }>;
    };
  };
};

export type GetUserQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    account_source?: string | null;
    date_joined?: string | null;
    email: string;
    first_name?: string | null;
    fullName: string;
    is_google_sso?: boolean | null;
    initials: string;
    is_admin?: boolean | null;
    is_verified?: boolean | null;
    last_name?: string | null;
    username: string;
    uuid: string;
    customer?: {
      __typename?: 'Customer';
      uuid: string;
      show_equity_planner?: boolean | null;
      nickname?: string | null;
    } | null;
  } | null;
};

export type FetchClientChecklistQueryVariables = Exact<{
  riaDeal: Scalars['UUID']['input'];
}>;

export type FetchClientChecklistQuery = {
  __typename?: 'Query';
  clientChecklistQueries: {
    __typename?: 'clientChecklistQueries';
    byRIADeal?: {
      __typename?: 'ClientChecklist';
      uuid: any;
      owner?: any | null;
      customer?: { __typename?: 'Customer'; uuid: string } | null;
      checklistItems?: Array<{
        __typename?: 'ClientChecklistItem';
        uuid: any;
        isCompleted?: boolean | null;
        completedAt?: any | null;
        actionUrl?: string | null;
        key: ChecklistItemKey;
      } | null> | null;
    } | null;
  };
};

export type CurrencyFragmentFragment = {
  __typename?: 'CurrencyModel';
  value: string;
  currency: string;
};

export type UserInfoFragmentFragment = {
  __typename?: 'User';
  uuid: string;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  fullName: string;
  initials: string;
  username: string;
};

export type AdvisorFragment = {
  __typename?: 'Advisor';
  uuid: string;
  avatar?: string | null;
  full_name?: string | null;
  phone?: string | null;
  public_email?: string | null;
  title?: string | null;
  bio?: string | null;
  calendly_intake_call_link?: string | null;
  calendly_proposal_call_link?: string | null;
  calendly_wealth_insights_call_link?: string | null;
  calendly_advisory_insights_call_link?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
};

export type FinancingObjectFragment = {
  __typename?: 'FinancingObject';
  uuid: string;
  status: FinancingStatus;
  document_storage_url?: string | null;
  intake_call_timestamp?: string | null;
  intake_call_invitee_id?: string | null;
  intake_call_uuid?: string | null;
  is_intake_call_cancelled?: boolean | null;
  last_seen?: string | null;
  owner?: string | null;
  products_interested?: Array<ProductsOffered> | null;
  proposal_call_timestamp?: string | null;
  proposal_call_invitee_id?: string | null;
  proposal_call_uuid?: string | null;
  is_proposal_call_cancelled?: boolean | null;
  signed_nda?: string | null;
  substatus: FinancingSubstatus;
  is_legacy_onboarding?: boolean | null;
  date_proposal_accepted?: string | null;
  advisor?: {
    __typename?: 'Advisor';
    uuid: string;
    avatar?: string | null;
    full_name?: string | null;
    phone?: string | null;
    public_email?: string | null;
    title?: string | null;
    bio?: string | null;
    calendly_intake_call_link?: string | null;
    calendly_proposal_call_link?: string | null;
    calendly_wealth_insights_call_link?: string | null;
    calendly_advisory_insights_call_link?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
  } | null;
};

export type ProposalSummaryFragment = {
  __typename?: 'Proposal';
  uuid: string;
  collateral_shares?: number | null;
  advance_rate?: number | null;
  stock_fee?: number | null;
  hash_id?: string | null;
  issued_date?: string | null;
  name?: string | null;
  published: boolean;
  expiration_date?: string | null;
  is_expired: boolean;
  regeneration_requested?: boolean | null;
  proposal_reason: Array<string>;
  advance_provided?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  rampup_fees?: Array<{
    __typename?: 'RampSchedule';
    equity_rate: number;
    month: number;
  }> | null;
};

export type CompanyAssessmentFragment = {
  __typename?: 'CompanyAssessment';
  uuid: string;
  updated_at?: string | null;
  verified: boolean;
  ipo_or_manda?: number | null;
  expected_exit_date?: any | null;
  expected_exit_multiplier?: number | null;
  verified_captable_requested?: boolean | null;
  common_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  preferred_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  four09a_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  expected_exit_share_value?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
};

export type CompanyFragment = {
  __typename?: 'Company';
  uuid: string;
  name: string;
  logo?: string | null;
  legal_name?: string | null;
  latest_equity_series_date?: any | null;
  last_issue_price_date?: any | null;
  cap_table_verified?: boolean | null;
  company_status?: CompanyStatusGql | null;
  company_substatus?: CompanySubstatusGql | null;
  is_fast_track?: boolean | null;
  website?: string | null;
  ipo_date?: any | null;
  ticker?: string | null;
  ipo_lockup_end_date?: any | null;
  public_price?: {
    __typename?: 'CurrencyModel';
    value: string;
    currency: string;
  } | null;
  adjusted_funding_rounds?: Array<{
    __typename?: 'AdjustedFundingRound';
    description?: string | null;
    date: string;
    adjusted_issue_price?: string | null;
    issue_price: string;
    post_valuation?: string | null;
  } | null> | null;
  latest_verified_company_assessment?: {
    __typename?: 'CompanyAssessment';
    uuid: string;
    updated_at?: string | null;
    verified: boolean;
    ipo_or_manda?: number | null;
    expected_exit_date?: any | null;
    expected_exit_multiplier?: number | null;
    verified_captable_requested?: boolean | null;
    common_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    preferred_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    four09a_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
    expected_exit_share_value?: {
      __typename?: 'CurrencyModel';
      value: string;
      currency: string;
    } | null;
  } | null;
};

export type AdjustedFundingRoundFragment = {
  __typename?: 'AdjustedFundingRound';
  description?: string | null;
  date: string;
  adjusted_issue_price?: string | null;
  issue_price: string;
  post_valuation?: string | null;
};

export type TimelinePointFragment = {
  __typename?: 'TimelinePoint';
  timestamp: any;
  value: number;
};

export type UserFragment = {
  __typename?: 'User';
  account_source?: string | null;
  date_joined?: string | null;
  email: string;
  first_name?: string | null;
  fullName: string;
  is_google_sso?: boolean | null;
  initials: string;
  is_admin?: boolean | null;
  is_verified?: boolean | null;
  last_name?: string | null;
  username: string;
  uuid: string;
  customer?: {
    __typename?: 'Customer';
    uuid: string;
    show_equity_planner?: boolean | null;
    nickname?: string | null;
  } | null;
};

export type CustomerFragment = {
  __typename?: 'Customer';
  uuid: string;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  country?: string | null;
  kyc_status?: KycStatus | null;
  nickname?: string | null;
  phone_number?: string | null;
  pipedrive_deal_id?: number | null;
  subdivision?: string | null;
  zip_code?: string | null;
  show_equity_planner?: boolean | null;
};

export type SaleEventFragment = {
  __typename?: 'SaleEvent';
  createdAt: any;
  eventDate: any;
  quantity: number;
  uuid: string;
  fmv?: {
    __typename?: 'EquityCurrency';
    currency: string;
    value: number;
  } | null;
  price?: {
    __typename?: 'EquityCurrency';
    currency: string;
    value: number;
  } | null;
};

export type InstitutionFragment = {
  __typename?: 'Institution';
  institution_id: string;
  last_snapshot_date?: any | null;
  last_updated?: any | null;
  logo?: string | null;
  name: string;
  status: Status;
  website?: string | null;
};
