export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Warn = 'warn',
  Info = 'info',
}

export interface Notification {
  text: string;
  type: NotificationType;
}

export type ShowNotification = (type: NotificationType, text: string) => void;

interface PromiseToastOptions {
  loading?: string;
  success?: string;
  error?: string;
  style?: React.CSSProperties;
}

export type ShowAsyncNotification = (
  promise: Promise<unknown>,
  options: PromiseToastOptions
) => Promise<unknown>;
