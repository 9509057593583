/**
 * Use this function when you simultaneously need to:
 *
 * 1. Retain the shape (keys) of the literal object type.
 * 2. Make sure that all the values are of the same type.
 *
 * ... without explicitly creating an interface (so you don't
 * repeat initializing code twice). Also, helpful when the object
 * literal must is inlined.
 *
 * ```
 * const obj: Record<string, number> = {
 *   a: 1,
 *   b: 2,
 *   c: 3,
 *   d: '4' // <- ERROR! Good, values are guaranteed to be numbers
 * };
 *
 * console.log(obj.e); // <- No error. Bad, keys are erased.
 *
 * const obj = {
 *   a: 1,
 *   b: 2,
 *   c: 3,
 *   d: '4' // <- No error. Bad, values are not guaranteed to be numbers.
 * };
 *
 * console.log(obj.e); // <- ERROR! Good, keys are retained.
 *
 * interface SomeObject {
 *   a: number;
 *   b: number;
 *   c: number;
 *   d: number;
 * }
 *
 * const obj: SomeObject = {
 *   a: 1,
 *   b: 2,
 *   c: 3,
 *   d: '4' // <- ERROR! Good, values are guaranteed to be numbers
 * };
 *
 * console.log(obj.e); // <- ERROR! Good, keys are retained.
 *
 * const obj = coerceObjectValues<number>()({
 *   a: 1,
 *   b: 2,
 *   c: 3,
 *   d: '4' // <- ERROR! Good, values are guaranteed to be numbers
 * });
 *
 * console.log(obj.e); // <- ERROR! Good, keys are retained.
 * ```
 */
export function coerceObjectLiteralValues<V>() {
  return function <T extends Record<string, V>>(
    x: T
  ): {
    [K in keyof T]: V;
  } {
    return x;
  };
}
