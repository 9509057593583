/**
 * This is a type-safe version of `hasOwnProperty`.
 *
 * ```
 * const obj: unknown = { prop: '123' };
 *
 * if(has(obj, 'prop')) {
 *   // typeof obj => { prop: unknown; }
 * }
 * ```
 *
 * Please note: this function does not check for
 * properties in the prototype chain.
 *
 * ```
 * const obj = { value: 'some value' };
 *
 * has(obj, 'value') // => true
 * console.log(obj.value) // => 'some value'
 *
 * const parent = { value: 'some value' };
 * const child = Object.create(parent);
 *
 * has(child, 'value') // => !!! false
 * console.log(child.value) // => 'some value'
 * ```
 *
 * Use `hasInPrototype` to check for the property
 * in the prototype.
 */
export function has<N extends string>(
  obj: unknown,
  propertyName: N
): obj is {
  [K in N]: unknown;
} {
  return (
    Object(obj) === obj &&
    Object.prototype.hasOwnProperty.call(obj, propertyName)
  );
}
