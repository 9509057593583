export enum EnvironmentName {
  Production = 'PRODUCTION',
  Staging = 'STAGING',
  Review = 'REVIEW',
  Feature = 'FEATURE',
  Local = 'LOCAL',
}

export const isLocalEnvironment = () =>
  ![
    EnvironmentName.Production,
    EnvironmentName.Staging,
    EnvironmentName.Feature,
  ].includes(import.meta.env.VITE_DEPLOYMENT_ENV as EnvironmentName);

export const isNonProdEnvironment = () =>
  [EnvironmentName.Staging, EnvironmentName.Feature].includes(
    import.meta.env.VITE_DEPLOYMENT_ENV as EnvironmentName
  ) || isLocalEnvironment();
